import React, { useEffect, useState } from "react";
import Calendar from "assets/images/Report_Icons/JSX/Icon-Calendar";
import EditIcon from "assets/images/icons/JSX/Icon-Edit";
import DropDownContainer from "components/DropDown/Container";
import { SimpleListItem } from "components/DropDown/ListItems";
import moment from "moment";
import styles from "../../sharedStyles.module.scss";
import SelectInput from "components/DropDown/Select";

export default ({
    toggleOpen,
    togglePresetOpen,
    isOpen,
    handlePresetClick,
    isPresetOpen,
    dateRange,
    presets = [],
    from,
    to,
    hasCustomDateRange,
    presetOptions,
}) => {
    const [presetOptionsLabel, setPresetOptionsLabel] = useState("");

    useEffect(() => {
        const presetActive = presetOptions?.find(({ isActive }) => isActive);
        const presetLabel = presetActive?.activeLabel || presetActive?.label;
        setPresetOptionsLabel(presetLabel || "Choose Date Range");
    }, [presetOptions]);

    const dayActive =
        presets.includes("day") &&
        moment(dateRange.start).format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .format("YYYYMMDD") &&
        moment(dateRange.end).format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .format("YYYYMMDD");

    const weekActive =
        presets.includes("week") &&
        moment(dateRange.start).format("YYYYMMDD") ===
            moment()
                .add(-7, "days")
                .format("YYYYMMDD") &&
        moment(dateRange.end).format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .format("YYYYMMDD");

    const lastWeekActive =
        presets.includes("last-week-iso") &&
        moment(dateRange.start).format("YYYYMMDD") ===
            moment()
                .add(-7, "days")
                .startOf("isoWeek")
                .format("YYYYMMDD") &&
        moment(dateRange.end)
            .add(-7)
            .endOf("isoWeek")
            .format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .format("YYYYMMDD");

    const lastTwoWeeksActive =
        presets.includes("last-two-week-iso") &&
        moment(dateRange.start).format("YYYYMMDD") ===
            moment()
                .add(-7 * 1 - 6, "days")
                .startOf("isoWeek")
                .format("YYYYMMDD") &&
        moment(dateRange.end)
            .add(-6)
            .endOf("isoWeek")
            .format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .format("YYYYMMDD");

    const lastFourWeeksActive =
        presets.includes("last-four-week-iso") &&
        moment(dateRange.start).format("YYYYMMDD") ===
            moment()
                .add(-7 * 3 - 6, "days")
                .startOf("isoWeek")
                .format("YYYYMMDD") &&
        moment(dateRange.end)
            .add(-6)
            .endOf("isoWeek")
            .format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .format("YYYYMMDD");

    const lastTwelveWeeksActive =
        presets.includes("last-twelve-week-iso") &&
        moment(dateRange.start).format("YYYYMMDD") ===
            moment()
                .add(-7 * 11 - 6, "days")
                .startOf("isoWeek")
                .format("YYYYMMDD") &&
        moment(dateRange.end)
            .add(-6)
            .endOf("isoWeek")
            .format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .format("YYYYMMDD");

    const monthActive =
        presets.includes("month") &&
        moment(dateRange.start).format("YYYYMMDD") ===
            moment()
                .add(-30, "days")
                .format("YYYYMMDD") &&
        moment(dateRange.end).format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .format("YYYYMMDD");

    const thisMonthActive =
        presets.includes("this month") &&
        moment(dateRange.start).format("YYYYMMDD") ===
            moment()
                .startOf("month")
                .format("YYYYMMDD") &&
        moment(dateRange.end).format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .format("YYYYMMDD");

    const lastMonthActive =
        presets.includes("last month") &&
        moment(dateRange.start).format("YYYYMMDD") ===
            moment()
                .add(-1, "months")
                .startOf("month")
                .format("YYYYMMDD") &&
        moment(dateRange.end).format("YYYYMMDD") ===
            moment()
                .add(-1, "months")
                .endOf("month")
                .format("YYYYMMDD");

    const threeMonthsActive =
        presets.includes("3 months") &&
        moment(dateRange.start).format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .add(-3, "months")
                .format("YYYYMMDD") &&
        moment(dateRange.end).format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .format("YYYYMMDD");

    const sixMonthsActive =
        presets.includes("6 months") &&
        moment(dateRange.start).format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .add(-6, "months")
                .format("YYYYMMDD") &&
        moment(dateRange.end).format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .format("YYYYMMDD");

    const yearActive =
        presets.includes("year") &&
        moment(dateRange.start).format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .add(-12, "months")
                .format("YYYYMMDD") &&
        moment(dateRange.end).format("YYYYMMDD") ===
            moment()
                .add(-1, "days")
                .format("YYYYMMDD");

    const customActive =
        !dayActive &&
        !weekActive &&
        !lastWeekActive &&
        !lastTwoWeeksActive &&
        !lastFourWeeksActive &&
        !lastTwelveWeeksActive &&
        !monthActive &&
        !thisMonthActive &&
        !lastMonthActive &&
        !threeMonthsActive &&
        !sixMonthsActive &&
        !yearActive;

    const isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent);
    const DATE_FORMAT = isMobile ? "MMM D" : "MMM D, YYYY";

    return (
        <SelectInput
            icon={Calendar}
            refFunction={() => togglePresetOpen(false)}
            handleClick={() => {
                toggleOpen(false);
                if (!isOpen) {
                    togglePresetOpen(!isPresetOpen);
                }
            }}
            value={
                moment(from).isValid() && moment(to).isValid()
                    ? `${from ? moment(from).format(DATE_FORMAT) : "From"} - ${
                          to ? moment(to).format(DATE_FORMAT) : "To"
                      }`
                    : presetOptionsLabel
            }
            isOpen={isOpen || isPresetOpen}
            styles={{ width: isMobile ? "11.25rem" : "17.125rem" }}
            calendarIcon
        >
            <DropDownContainer key="selector" className={styles.presetSelector} isOpen={isPresetOpen} triangle={false}>
                {presetOptions &&
                    presetOptions.map(({ onClick, isActive, label, id }) => (
                        <SimpleListItem
                            key={`${label}_${id}`}
                            onClick={() => {
                                togglePresetOpen(false);
                                if (id === "dateRange") {
                                    return toggleOpen(true);
                                }
                                onClick();
                                setPresetOptionsLabel(label);
                                togglePresetOpen(false);
                            }}
                            isActive={isActive}
                            label={label}
                            key={id}
                        />
                    ))}
                {presets.includes("day") && (
                    <SimpleListItem
                        onClick={() => handlePresetClick(moment().add(-1, "days")._d, moment().add(-1, "days")._d)}
                        isActive={dayActive}
                        label="Previous Day"
                    />
                )}
                {presets.includes("week") && (
                    <SimpleListItem
                        onClick={() => handlePresetClick(moment().add(-7, "days")._d, moment().add(-1, "days")._d)}
                        isActive={weekActive}
                        label="Last 7 Days"
                    />
                )}
                {presets.includes("last-week-iso") && (
                    <SimpleListItem
                        onClick={() =>
                            handlePresetClick(
                                moment()
                                    .add(-7, "days")
                                    .startOf("isoWeek")._d,
                                moment()
                                    .add(-7, "days")
                                    .endOf("isoWeek")._d,
                            )
                        }
                        isActive={lastWeekActive}
                        label="Last Week"
                    />
                )}
                {presets.includes("last-two-week-iso") && (
                    <SimpleListItem
                        onClick={() =>
                            handlePresetClick(
                                moment()
                                    .add(-7 * 1 - 6, "days")
                                    .startOf("isoWeek")._d,
                                moment()
                                    .add(-6, "days")
                                    .endOf("isoWeek")._d,
                            )
                        }
                        isActive={lastWeekActive}
                        label="Last 2 Weeks"
                    />
                )}
                {presets.includes("last-four-week-iso") && (
                    <SimpleListItem
                        onClick={() =>
                            handlePresetClick(
                                moment()
                                    .add(-7 * 3 - 6, "days")
                                    .startOf("isoWeek")._d,
                                moment()
                                    .add(-6, "days")
                                    .endOf("isoWeek")._d,
                            )
                        }
                        isActive={lastWeekActive}
                        label="Last 4 Weeks"
                    />
                )}
                {presets.includes("last-twelve-week-iso") && (
                    <SimpleListItem
                        onClick={() =>
                            handlePresetClick(
                                moment()
                                    .add(-7 * 11 - 6, "days")
                                    .startOf("isoWeek")._d,
                                moment()
                                    .add(-6, "days")
                                    .endOf("isoWeek")._d,
                            )
                        }
                        isActive={lastWeekActive}
                        label="Last 12 Weeks"
                    />
                )}
                {presets.includes("month") && (
                    <SimpleListItem
                        onClick={() => handlePresetClick(moment().add(-30, "days")._d, moment().add(-1, "days")._d)}
                        isActive={monthActive}
                        label="Last 30 Days"
                    />
                )}
                {presets.includes("this month") && (
                    <SimpleListItem
                        onClick={() =>
                            handlePresetClick(
                                moment()
                                    .add(-1, "days")
                                    .startOf("month")._d,
                                moment().add(-1, "days")._d,
                            )
                        }
                        isActive={thisMonthActive}
                        label="This Month"
                    />
                )}
                {presets.includes("last month") && (
                    <SimpleListItem
                        onClick={() =>
                            handlePresetClick(
                                moment()
                                    .add(-1, "days")
                                    .add(-1, "months")
                                    .startOf("month")._d,
                                moment()
                                    .add(-1, "days")
                                    .add(-1, "months")
                                    .endOf("month")._d,
                            )
                        }
                        isActive={lastMonthActive}
                        label="Last Month"
                    />
                )}
                {presets.includes("3 months") && (
                    <SimpleListItem
                        onClick={() =>
                            handlePresetClick(
                                moment()
                                    .add(-1, "days")
                                    .add(-3, "months")
                                    .subtract(1, "d")._d,
                                moment().add(-1, "days")._d,
                            )
                        }
                        isActive={threeMonthsActive}
                        label="Last 3 Months"
                    />
                )}
                {presets.includes("6 months") && (
                    <SimpleListItem
                        onClick={() =>
                            handlePresetClick(
                                moment()
                                    .add(-6, "months")
                                    .subtract(1, "d")._d,
                                moment().add(-1, "days")._d,
                            )
                        }
                        isActive={sixMonthsActive}
                        label="Last 6 Months"
                    />
                )}
                {presets.includes("year") && (
                    <SimpleListItem
                        onClick={() =>
                            handlePresetClick(
                                moment()
                                    .add(-12, "months")
                                    .subtract(1, "d")._d,
                                moment().add(-1, "days")._d,
                            )
                        }
                        isActive={yearActive}
                        label="Past Year"
                    />
                )}
                {hasCustomDateRange && (
                    <div className={styles.customDateRange}>
                        <SimpleListItem
                            onClick={() => {
                                toggleOpen(true);
                                togglePresetOpen(false);
                            }}
                            isActive={customActive}
                        >
                            <span className={styles.customLabel}>
                                <EditIcon /> Custom Date Range
                            </span>
                        </SimpleListItem>
                    </div>
                )}
            </DropDownContainer>
        </SelectInput>
    );
};
