import { connect } from "react-redux";
import { makeSelectUserId } from "_redux/users/selectors";
import Component from ".";
import { operations } from "../ducks";
import { makeSelectAccounts } from "../ducks/selectors";

const { duplicateAccount } = operations;

const mapStateToProps = (state, { accountId }) => {
    const userId = makeSelectUserId()(state);
    const accounts = makeSelectAccounts()(state);
    const name = accounts[accountId]?.name;
    return {
        accountId,
        accountOriginName: name,
        userId,
    };
};

const mapDispatchToProps = (dispatch) => ({
    duplicateAccount: ({ userId, accountId, name }) => dispatch(duplicateAccount({ userId, accountId, name })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
