import React from "react";
import Button from "../";
import style from "./style.module.scss";
import { pure } from "recompose";
import IconCopy from "assets/images/icons/JSX/Icon-Copy";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import Checkmark from "assets/images/Form/JSX/Icon-Check-Stoke";

export default pure(({ copied, ...props }) => (
    <Button
        medium
        hollow
        {...props}
        className={`${style.button} ${copied && style.copied}`}
    >
        <SwitchTransition mode="out-in">
            <CSSTransition
                key={copied}
                classNames={{
                    enter: style.contentEnter,
                    enterActive: style.contentEnterActive,
                    exit: style.contentExit,
                    exitActive: style.contentExitActive,
                    enterDone: style.contentEnterDone
                }}
                timeout={300}
            >
                <div className={style.content}>
                    {copied ? (
                        <Checkmark className={style.check} />
                    ) : (
                        <div className={style.text}>
                            <IconCopy />
                            Copy
                        </div>
                    )}
                </div>
            </CSSTransition>
        </SwitchTransition>
    </Button>
));
