import { numberWithCommas } from "lib/metricCalculations";

/**
 * Produce an array of index of given character in a string
 *
 * @param {string} char;
 * @param {string} str
 *
 * @return {Array.<number>} array of index of found character
 */

function indexsOfChar(char, str) {
    let a = [],
        i = -1;
    while ((i = str.indexOf(char, i + 1)) >= 0) a.push(i);
    return a;
}

/**
 * Produce a string that detects the correct decimal symbol and removes duplicates;
 * @param {string} str
 *
 * @return {obj}
 */

function removeDuplicateDecimals(str) {
    const comma = indexsOfChar(",", str);
    const period = indexsOfChar(".", str);

    if (!comma.length && !period.length) return str;

    const lowestIndexComma = Math.min.apply(null, comma);
    const lowestIndexPeriod = Math.min.apply(null, period);

    const last = lowestIndexComma < lowestIndexPeriod ? period : comma;

    if (last.length > 1) {
        //cut off the rest and only return the last two didgets + the symbol (3) characters.
        return str.substr(0, last[0] + 3);
    }

    return str;
}

/**
 * Produce a boolean that checks if number is whole
 * @param {string} str
 *
 * @return {obj}
 */

function isWhole(str) {
    const comma = indexsOfChar(",", str);
    const period = indexsOfChar(".", str);

    if (comma.length > 1 && !period.length) {
        return true;
    }

    if (!comma.length && period.length > 1) {
        return true;
    }

    return false;
}

/**
 * produce a number that has the correct decimal place
 * @param {Array.<string>} groups
 *
 * @return {string}
 */

function formatDecimal(groups) {
    const array = Array.from(groups);

    if (!array.length) return "0";

    if (array.length === 1) {
        // full number already
        return array.join("");
    }

    //If the number has only one separator
    //ex 1,023 0.23
    if (array.length === 2) {
        //Periods are what you call the number between , (to reduce confusion)
        const periods = parseInt(array.slice(0, -1).join(""));
        const startPeriod = array[0];
        const decimal = array[array.length - 1];

        if (!decimal.length) {
            //if number has stray decimal ex 1000.
            return `${periods}`;
        }

        if (decimal.length === 1) {
            //Tack on 0 if 1 decimal exists
            return `${periods}.${decimal}0`;
        }

        if (decimal.length === 2) {
            // correct format just return
            return `${periods}.${decimal}`;
        }

        if (periods === 0 && decimal.length > 2) {
            // this is a malformned number == 0.22323
            return `0.${decimal.slice(0, 2)}`;
        }

        if (startPeriod.length > 3 && decimal.length > 2) {
            // this is a malformatted number ex 1000.2322 .. cut down to two decimal places
            return `${periods}.${decimal.slice(0, 2)}`;
        }

        if (decimal.length > 2) {
            // this is a full number ex 1,00023123 -> 100023123 || 1,000 -> 1000
            return `${periods}${decimal}`;
        }
    }

    // ELSE
    //if the number has multiple separators
    //ex 1,000.23
    if (array.length > 2) {
        const periods = parseInt(array.slice(0, -1).join(""));
        const decimal = array[array.length - 1];

        if (!decimal.length) {
            //if number has stray decimal ex 1000.
            return `${periods}`;
        }

        if (decimal.length === 1) {
            //Tack on 0 if 1 decimal exists
            return `${periods}.${decimal}0`;
        }

        if (decimal.length === 2) {
            // correct format just return
            return `${periods}.${decimal}`;
        }

        if (decimal.length > 2) {
            // this is a malformatted number ex 1,000.2322 .. cut down to two decimal places
            return `${periods}${decimal.slice(0, 2)}`;
        }
    }
}

/**
 * Produce a string with only numbers commas and periods
 * @param {string} str
 */
function sanitizeNumberString(str) {
    return str.replace(/[^.,0-9]/g, "");
}

/**
 * Produce a formatted english currency from a string or number (ex 1,000,000.20)
 * @param {string|number} value
 *
 * @return {string} // Correctly formatted english currency
 */

export function toEnglishNumberFormat(value) {
    if (!value) {
        return "0";
    }

    // remove spaces
    const parsedValue = value.toString().replace(/\s/g, "");

    // dont allow any letters or symbols other than a period
    const symbolParse = sanitizeNumberString(parsedValue);

    // remove occurances of double decimal places
    const separators = removeDuplicateDecimals(symbolParse);
    const isWholeNumber = isWhole(separators);
    //split number by commas and periods
    const groups = separators.split(/,|\./);

    //takes the groups and formats them into a English decimal format if it's not a whole number
    const numberString = !isWholeNumber
        ? formatDecimal(groups)
        : groups.join("");

    return numberWithCommas(numberString);
}

/**
 * Produce a float given a formatted string
 * @param { string | number } value { string | number }
 *
 * @return {number} Float
 */

export const parseStringToFloat = (value) => {
    return parseFloat(
        toEnglishNumberFormat(value)
            .split(",")
            .join("")
    );
};
