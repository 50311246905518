import React from "react";
import style from "./style.module.scss";
import { TooltipDark } from "components/Tooltip";
import HelpIcon from "assets/images/icons/JSX/CircleHelpIcon";

export default ({ id, dataTip }) => {
    return (
        <span data-for={id} data-tip={dataTip} className={style.moreInfo}>
            <HelpIcon />
            <TooltipDark id={id} proximity={2} />
        </span>
    );
};
