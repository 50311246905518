import { connect } from "react-redux";
import IntegrateStep from "./index";
import { operations as connectOperations } from "features/Integrations/_ducks";
import { change, getFormValues } from "redux-form";
import { actions, operations } from "features/Onboarding/ducks";
import { makeSelectGetIntegrationIdList } from "_redux/sites/selectors";
import { makeSelectUserId, makeSelectUserConnections } from "_redux/users/selectors";
import { makeSelectOnboardingIntegrations } from "features/Onboarding/ducks/selectors";
import { makeSelectIntegrationOpts } from "features/Integrations/_ducks/selectors";

const { exchangeAnalyticsCode } = connectOperations;
const { onboardingPlatformConnection } = actions;
const { getAnalyticsAccounts } = operations;

const mapStateToProps = (state, { formName, connection, integrationId }) => {
    const { form } = state;
    const alreadyIntegrated = makeSelectGetIntegrationIdList("analytics")(state);
    const userId = makeSelectUserId()(state);
    const { analytics } = makeSelectUserConnections()(state);
    const allIntegrations = makeSelectOnboardingIntegrations()(state);
    const { analytics: analyticsOptions } = makeSelectIntegrationOpts()(state);

    const { [`${connection}_analytics`]: analyticsIntegrations = false } = allIntegrations;
    const formValues = getFormValues(formName)({ form });

    return {
        formName,
        userId,
        list: analytics,
        selected: connection,
        options: analyticsOptions,
        selectedIntegrations: formValues,
        integrations: analyticsIntegrations,
        integrationId,
        alreadyIntegrated: formName === "onboarding" ? [] : alreadyIntegrated,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getAnalyticsAccounts: ({ userId, connectionId }) => dispatch(getAnalyticsAccounts({ userId, connectionId })),
    exchangeAnalyticsCode: (code) => {
        return dispatch(exchangeAnalyticsCode(code)).then(({ id }) => {
            dispatch(
                onboardingPlatformConnection({
                    platform: "analytics",
                    connectionId: id,
                }),
            );
        });
    },
    change: (formName, ...args) => dispatch(change(formName, ...args)),
});

const mergeProps = ({ formName, ...stateProps }, { change, ...dispatchProps }, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    change: (...args) => {
        return change(formName, ...args);
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IntegrateStep);
