import React from "react";
import isEmpty from "lodash/isEmpty";
import Analytics from "features/Integrations/Independant/Analytics";
import AnalyticsV4 from "features/Integrations/Independant/AnalyticsV4";
import Adwords from "features/Integrations/Independant/Adwords";
import Linkedin from "features/Integrations/Independant/Linkedin";
import TikTok from "features/Integrations/Independant/TikTok";
import Pinterest from "features/Integrations/Independant/Pinterest";
import Maropost from "features/Integrations/Independant/Maropost/container";
import Klaviyo from "features/Integrations/Independant/Klaviyo/container";
import Bing from "features/Integrations/Independant/Bing";
import Facebook from "features/Integrations/Independant/Facebook";
import Slack from "features/Integrations/Independant/Slack";
import Shopify from "features/Integrations/Independant/Shopify/container";
import SearchConsole from "features/Integrations/Independant/SearchConsole/container";
import DataStudio from "features/Integrations/Independant/DataStudio/container";
import GoogleBusinessProfile from "features/Integrations/Independant/GoogleBusinessProfile/container";
import BigQuery from "features/Integrations/Independant/BigQuery";
import OrganicFacebook from "features/Integrations/Independant/OrganicFacebook";
import StackAdapt from "features/Integrations/Independant/StackAdapt/container";

export default ({
    changeConnection,
    updateIntegration,
    accountId,
    integration,
    integrationId,
    onCancel,
    connections,
    userId,
    integrations: allAccountIntegrations,
}) => {
    if (!integration) return null;

    const connection = connections && connections.hasOwnProperty(integration) ? connections[integration] : "";
    const { [`${connection}_${integration}`]: accountIntegrations = {} } = allAccountIntegrations;
    const hasIntegrations = !isEmpty(accountIntegrations);

    const integrations = {
        analytics: (
            <Analytics
                onCancel={onCancel}
                changeConnection={changeConnection}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                connections={connections}
                userId={userId}
                hasIntegrations={hasIntegrations}
            />
        ),
        analyticsV4: (
            <AnalyticsV4
                onCancel={onCancel}
                changeConnection={changeConnection}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                connections={connections}
                userId={userId}
                hasIntegrations={hasIntegrations}
            />
        ),
        searchConsole: (
            <SearchConsole
                onCancel={onCancel}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                changeConnection={changeConnection}
                connections={connections}
                userId={userId}
            />
        ),
        googleBusinessProfile: (
            <GoogleBusinessProfile
                onCancel={onCancel}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                userId={userId}
                changeConnection={changeConnection}
                connections={connections}
            />
        ),
        adwords: (
            <Adwords
                onCancel={onCancel}
                changeConnection={changeConnection}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                connections={connections}
                userId={userId}
                hasIntegrations={hasIntegrations}
            />
        ),
        linkedin: (
            <Linkedin
                onCancel={onCancel}
                changeConnection={changeConnection}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                connections={connections}
                userId={userId}
                hasIntegrations={hasIntegrations}
            />
        ),
        tiktok: (
            <TikTok
                onCancel={onCancel}
                changeConnection={changeConnection}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                connections={connections}
                userId={userId}
                hasIntegrations={hasIntegrations}
            />
        ),
        pinterest: (
            <Pinterest
                onCancel={onCancel}
                changeConnection={changeConnection}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                connections={connections}
                userId={userId}
                hasIntegrations={hasIntegrations}
            />
        ),
        bing: (
            <Bing
                onCancel={onCancel}
                changeConnection={changeConnection}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                connections={connections}
                userId={userId}
                hasIntegrations={hasIntegrations}
            />
        ),
        facebook: (
            <Facebook
                onCancel={onCancel}
                changeConnection={changeConnection}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                connections={connections}
                userId={userId}
                hasIntegrations={hasIntegrations}
            />
        ),
        organicFacebook: (
            <OrganicFacebook
                onCancel={onCancel}
                changeConnection={changeConnection}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                connections={connections}
                userId={userId}
                hasIntegrations={hasIntegrations}
            />
        ),
        slack: (
            <Slack
                onCancel={onCancel}
                changeConnection={changeConnection}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                connections={connections}
                userId={userId}
            />
        ),
        shopify: (
            <Shopify
                onCancel={onCancel}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                userId={userId}
            />
        ),
        maropost: (
            <Maropost
                onCancel={onCancel}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                userId={userId}
            />
        ),
        klaviyo: (
            <Klaviyo
                onCancel={onCancel}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                userId={userId}
            />
        ),
        dataStudio: <DataStudio onCancel={onCancel} accountId={accountId} />,
        bigQuery: (
            <BigQuery
                onCancel={onCancel}
                changeConnection={changeConnection}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                connections={connections}
                userId={userId}
                hasIntegrations={hasIntegrations}
            />
        ),
        stackAdapt: (
            <StackAdapt
                onCancel={onCancel}
                updateIntegration={updateIntegration}
                accountId={accountId}
                integrationId={integrationId}
                userId={userId}
            />
        ),
    };

    if (integrations.hasOwnProperty(integration)) {
        return integrations[integration];
    }

    return null;
};
