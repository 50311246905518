import React from "react";

export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <g fill="none" transform="translate(1)">
            <path
                fill="#F9AB00"
                d="M10.1111111,1.92977769 L10.1111111,13.882838 C10.1111111,15.220313 11.0091048,15.9655682 11.9599216,15.9655682 C12.8403076,15.9655682 13.8087322,15.3316938 13.8087322,13.882838 L13.8087322,2.02033118 C13.8087322,0.794236966 12.9283462,0.0281544656 11.9599216,0.0281544656 C10.9914971,0.0281544656 10.1111111,0.873018499 10.1111111,1.92977769 Z"
            />
            <path
                fill="#E37400"
                d="M5.09444444,7.99686132 L5.09444444,13.882838 C5.09444444,15.220313 5.99243813,15.9655682 6.94325497,15.9655682 C7.82364094,15.9655682 8.7920655,15.3316938 8.7920655,13.882838 L8.7920655,8.08741481 C8.7920655,6.8613206 7.91167954,6.0952381 6.94325497,6.0952381 C5.97483041,6.0952381 5.09444444,6.94010213 5.09444444,7.99686132 Z"
            />
            <ellipse
                cx="1.849"
                cy="14.064"
                fill="#E37400"
                rx="1.849"
                ry="1.902"
            />
        </g>
    </svg>
);
