import createReducer from "../../../lib/createReducer";
import { handle } from "redux-pack";
import types from "./types";
import merge from "lodash/merge";

const defaultState = {
    available: {},
    favourites: [],
    isLoading: false,
    isLoadingPerformanceData: true,
    performanceData: {},
    showMetricsManager: false,
    showExportManager: false,
    isloadingAnalyticsGoals: false,
    showAddAccountModal: false,
    search: "",
    sortByDataType: "actual",
    overviewUi: {
        tags: [],
        contains: "any",
        sort: "default",
        sortOrder: "asc",
    },
    overviewTagsAvailable: [],
    opportunityData: [],
    topWins: [],
};

export default createReducer(defaultState, {
    SET_ACCOUNT_NOTIFICATIONS(state, { payload }) {
        const {
            landingPageProtection,
            goalPacing,
            goalPace,
            goalStatus,
            goalPaceInterval,
            goalPaceMetrics,
            goalProtection,
            goalProtectionInterval,
            goalProtectionMetrics,
            budgetPacingSpend,
            budgetPacing,
            budgetStatus,
            budgetPace,
            budgetPaceInterval,
            budgetReminder,
            budgetReminderPercent,
            budgetProtection,
            budgetProtectionInterval,
            applyToAllAccounts,
            mySeoPacing,
            mySeoPacingDifference,
            mySeoPacingStatus,
            competitorSeoPacing,
            competitorSeoPacingDifference,
            competitorSeoPacingStatus,
        } = payload;

        const settings = {
            landingPageSettings: {
                active: landingPageProtection,
            },
            pacingSettings: {
                goals: {
                    active: goalPacing,
                    deviation: goalPace,
                    interval: goalPaceInterval,
                    metrics: goalPaceMetrics,
                    status: goalStatus,
                },
                budgets: {
                    active: budgetPacing,
                    deviation: budgetPace,
                    interval: budgetPaceInterval,
                    notifyOnOverBudget: budgetPacingSpend,
                    status: budgetStatus,
                    budgetReminder,
                    budgetReminderPercent,
                },
            },
            seoSettings: {
                mySeoPacing,
                mySeoPacingDifference,
                mySeoPacingStatus,
                competitorSeoPacing,
                competitorSeoPacingDifference,
                competitorSeoPacingStatus,
            },
            zeroStateSettings: {
                goals: {
                    active: goalProtection,
                    interval: goalProtectionInterval,
                    metrics: goalProtectionMetrics,
                },
                budgets: {
                    active: budgetProtection,
                    interval: budgetProtectionInterval,
                },
            },
        };
        if (applyToAllAccounts) {
            return merge({}, state, {
                available: Object.keys(state.available)
                    .map((key) => {
                        return {
                            _id: state.available[key]._id,
                            alertSettings: settings,
                        };
                    })
                    .reduce((obj, next) => {
                        return {
                            ...obj,
                            [next._id]: next,
                        };
                    }, {}),
            });
        }

        return merge({}, state, {
            available: {
                [payload.account]: {
                    alertSettings: settings,
                },
            },
        });
    },
    [types.TOGGLE_PINNED_VIEW](state, action) {
        const { [action.meta.accountId]: pinnedView, ...views } = state.available;

        return {
            ...state,
            available: {
                ...views,
                [action.meta.accountId]: {
                    ...pinnedView,
                    pinned: action.meta.pinned,
                },
            },
        };
    },
    [types.CLEAR_ACCOUNT_DATA](state, action) {
        return {
            ...state,
            performanceData: {},
        };
    },
    [types.CLEAR_METRICS_DATA](state, action) {
        return {
            ...state,
            performanceMetricsData: {},
        };
    },
    [types.ACCOUNT_FETCH_DATA]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                isLoadingPerformanceData: true,
            }),
            success: (prevState) => ({
                ...prevState,
                performanceData: action.payload,
            }),
            finish: (prevState) => ({
                ...prevState,
                isLoadingPerformanceData: false,
            }),
        }),
    [types.ACCOUNT_FETCH_METRICS]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                isLoadingPerformanceData: true,
            }),
            success: (prevState) => ({
                ...prevState,
                performanceMetricsData: action.payload,
            }),
            finish: (prevState) => ({
                ...prevState,
                isLoadingPerformanceData: false,
            }),
        }),
    [types.ACCOUNT_UPDATE_BUDGET]: (state, action) =>
        handle(state, action, {
            success: (prevState) => ({
                ...prevState,
                available: {
                    ...prevState.available,
                    [action.meta.accountId]: {
                        ...prevState.available[action.meta.accountId],
                        goals: {
                            ...prevState.available[action.meta.accountId]?.goals,
                            values: {
                                ...prevState.available[action.meta.accountId]?.goals?.values,
                                [action.meta.month]: {
                                    ...prevState.available[action.meta.accountId]?.goals?.values?.[action.meta.month],
                                    [`${action.meta.platform}Budget`]: {
                                        value: action.meta.value,
                                    },
                                },
                            },
                        },
                    },
                },
            }),
        }),
    [types.ACCOUNT_UPDATE_GOAL]: (state, action) =>
        handle(state, action, {
            success: (prevState) => ({
                ...prevState,
                available: {
                    ...prevState.available,
                    [action.meta.accountId]: {
                        ...prevState.available[action.meta.accountId],
                        goals: {
                            ...prevState.available[action.meta.accountId]?.goals,
                            values: {
                                ...prevState.available[action.meta.accountId]?.goals?.values,
                                [action.meta.month]: {
                                    ...prevState.available[action.meta.accountId]?.goals?.values?.[action.meta.month],
                                    [action.meta.metric]: {
                                        value: action.meta.value,
                                    },
                                },
                            },
                        },
                    },
                },
            }),
        }),
    [types.ACCOUNT_UPDATE_ALERT]: (state, action) =>
        handle(state, action, {
            success: (prevState) => ({
                ...prevState,
                performanceData: {
                    ...prevState.performanceData,
                    [action.meta.accountId]: {
                        ...prevState.performanceData[action.meta.accountId],
                        [action.meta.type]: action.meta.value,
                    },
                },
            }),
        }),
    [types.UPDATE_TAGS]: (state, action) => {
        const { [action.meta.accountId]: accountData, ...views } = state.available;
        return {
            ...state,
            available: {
                ...views,
                [action.meta.accountId]: {
                    ...accountData,
                    tags: action.meta.tags,
                },
            },
        };
    },
    [types.CREATE_VIEW]: (state, action) =>
        handle(state, action, {
            start: (prevState) =>
                merge(prevState, {
                    isAddingView: true,
                }),
            success: (prevState) => ({
                ...prevState,
                available: {
                    ...prevState.available,
                    [action.payload.data._id]: {
                        ...action.payload.data,
                        created_at: new Date(),
                    },
                },
            }),
            finish: (prevState) =>
                merge(prevState, {
                    isAddingView: false,
                }),
        }),
    [types.LOAD_ANALYTICS_CHANNELS]: (state, action) =>
        handle(state, action, {
            success: (prevState) => {
                const { [action.meta?.accountId]: accountInfo = {} } = prevState.available || {};
                return {
                    ...prevState,
                    available: {
                        ...prevState.available,
                        [action.meta?.accountId]: {
                            ...accountInfo,
                            googleAnalyticsChannels: action.payload,
                        },
                    },
                };
            },
        }),
    [types.LOAD_ANALYTICS_ALL_GOALS]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                isloadingAnalyticsGoals: true,
            }),
            success: (prevState) => {
                const { [action.meta?.accountId]: accountInfo = {} } = prevState.available || {};
                return {
                    ...prevState,
                    available: {
                        ...prevState.available,
                        [action.meta?.accountId]: {
                            ...accountInfo,
                            googleAnalyticsAllGoals: action.payload || [],
                        },
                    },
                };
            },
            finish: (prevState) => ({
                ...prevState,
                isloadingAnalyticsGoals: false,
            }),
        }),
    [types.REMOVE_VIEW]: (state, action) =>
        handle(state, action, {
            start: (prevState) =>
                merge(prevState, {
                    isRemovingView: true,
                }),
            success: (prevState) => {
                const { [action.meta.accountId]: _, ...views } = prevState.available;
                return {
                    ...prevState,
                    available: {
                        ...views,
                    },
                };
            },
            finish: (prevState) =>
                merge(prevState, {
                    isRemovingView: false,
                }),
        }),
    [types.UPDATE_NAME](state, action) {
        const { [action.meta.accountId]: toggleView, ...views } = state.available;

        return {
            ...state,
            available: {
                ...views,
                [action.meta.accountId]: {
                    ...toggleView,
                    name: action.meta.name,
                },
            },
        };
    },
    [types.GET_SITES_FUFILLED](state, action) {
        return {
            ...state,
            error: false,
            available: action.payload.data.reduce((obj, view) => ({ ...obj, [view._id]: view }), {}),
        };
    },
    [types.GET_SITES_REJECTED](state, action) {
        return {
            ...state,
            error: action.error,
        };
    },
    [types.GET_SITES_PENDING](state, action) {
        return {
            ...state,
            pending: action.pending,
        };
    },
    [types.TOGGLE_METRICS_MANAGER](state, action) {
        return {
            ...state,
            showMetricsManager: !state.showMetricsManager,
        };
    },
    [types.TOGGLE_EXPORT_MANAGER](state, action) {
        return {
            ...state,
            showExportManager: !state.showExportManager,
        };
    },
    [types.UPDATE_OVERVIEW_SEARCH](state, action) {
        return {
            ...state,
            search: action.search,
        };
    },
    [types.UPDATE_OVERVIEW_SORT_BY_DATA_TYPE](state, action) {
        return {
            ...state,
            sortByDataType: action.sortByDataType,
        };
    },
    [types.UPDATE_OVERVIEW_UI](state, action) {
        return {
            ...state,
            overviewUi: {
                ...state.overviewUi,
                ...action.overviewUi,
            },
        };
    },
    [types.UPDATE_OVERVIEW_TAGS_AVAILABLE](state, action) {
        return {
            ...state,
            overviewTagsAvailable: action.tags,
        };
    },
    [types.SET_SHOW_ADD_ACCOUNT_MODAL](state, { payload }) {
        return {
            ...state,
            showAddAccountModal: payload,
        };
    },
    [types.ACCOUNT_UPDATE_INTEGRATIONS](state, { payload }) {
        const { accountId, integrations } = payload;
        const { available } = state;
        return {
            ...state,
            available: {
                ...available,
                [accountId]: {
                    ...available[accountId],
                    integrations,
                },
            },
        };
    },
    [types.ACCOUNT_FETCH_OPPORTUNITIES]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                isLoadingOpportunities: true,
            }),
            success: (prevState) => ({
                ...prevState,
                opportunityData: action.payload,
            }),
            finish: (prevState) => ({
                ...prevState,
                isLoadingOpportunities: false,
            }),
        }),
    [types.ACCOUNT_FETCH_TOP_WINS]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                isLoadingTopWins: true,
            }),
            success: (prevState) => ({
                ...prevState,
                topWins: action.payload,
            }),
            finish: (prevState) => ({
                ...prevState,
                isLoadingTopWins: false,
            }),
        }),
});
