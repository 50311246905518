import React from "react";
import withErrorBoundary from "../../../../components/ErrorBoundary";
import { FACEBOOK_AUTHENTICATION_SCOPE } from 'lib/campaignUtils';

class FacebookConnect extends React.Component {
    state = {
        loading: false,
        platform: "facebook",
        label: "Meta Ads",
        error: false
    };

    facebookCallback = (response) => {
        if (response.authResponse) {
            this.props.saveFacebookAccessToken(
                response.authResponse.accessToken
            );
            return this.setState({ loading: false });
        } else {
            return this.setState({ loading: false, error: true });
        }
    };

    componentDidMount() {
        window.FB.init({
            appId: process.env.REACT_APP_FACEBOOK_APP_ID,
            cookie: true,
            xfbml: true,
            version: "v3.2"
        });
    }

    onAdd = async (event) => {
        await this.setState({ loading: true });
        await window.FB.login(this.facebookCallback, {
            scope: FACEBOOK_AUTHENTICATION_SCOPE
        });
        return this.setState({ loading: false });
    };

    render() {
        const { loading, platform, label, error } = this.state;
        const { component: Component } = this.props;
        return (
            <Component
                platform={platform}
                loading={loading}
                error={error}
                onAdd={this.onAdd}
                label={label}
            />
        );
    }
}

export default withErrorBoundary()(FacebookConnect);
