import React from "react";
import MaterialUITable from "components/Table/MaterialUITable";
import { platforms as platformMeta } from "lib/paidPlatforms";
import StatusCell from "features/Dashboard/CampaignGroups/CellComponent/StatusCell";
import BudgetCell from "features/Dashboard/CampaignGroups/CellComponent/BudgetCell";
import { getStatus, PLATFORM_STATUSES, headCells, EXPORTABLE_METRICS, TABLE_FIELD_MAPPING } from "../helpers";
import style from "./style.module.scss";
import { getPlatformStatus } from "lib/campaignUtils";
import FormattedNumber from "features/Reports/CampaignPerformance/FormattedNumber";

const SubTable = ({
    campaigns = [],
    cammpaignsCompare = [],
    filterStatus,
    groupId,
    accountId,
    hasBlendedCurrencies,
    extraCampaignGroupsInfoLoading,
    campaignGroupsPending,
    editCampaignHandle,
    showGrowth,
    ...props
}) => {
    const filteredCampaignByStatus = campaigns.filter(({ status }) => {
        if (filterStatus === "all") {
            return true;
        }

        return getStatus(status).order === getStatus(filterStatus).order;
    });

    return (
        <MaterialUITable
            {...props}
            rowClass={style.rowClass}
            className={style.subTable}
            headCells={headCells.map(({ subPadding: padding = "", ...rest }) => ({
                ...rest,
                padding,
            }))}
            hideHeadCells
            pagination
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            defaultRowsPerPage={5}
            disableLoadingCircles={true}
            rows={filteredCampaignByStatus
                .sort(
                    ({ status: statusA = "-", platfrom: platformA }, { status: statusB = "-", platform: platformB }) =>
                        getStatus(statusA, platformA).order - getStatus(statusB, platformB).order,
                )
                .map(
                    ({
                        campaign,
                        campaignId,
                        type = "",
                        platform,
                        performance = {},
                        relatedAnomalies = [],
                        relatedPpcAudit = [],
                        relatedSuggestions = [],
                        status = "",
                        budgets,
                        budget,
                        budgetType = "",
                        currency = "USD",
                        readOnly = "",
                    }) => {
                        const { performance: performanceCompare = {} } =
                            cammpaignsCompare.find(
                                ({ campaignId: campaignComapreId }) => campaignComapreId === campaignId,
                            ) || {};

                        const { active = true, message = "" } = PLATFORM_STATUSES[platform] || {};
                        const isStatusDisable = Boolean(readOnly) || !active;
                        const isBudgetDisable =
                            Boolean(readOnly) || !Boolean(budgetType) || !active || platform === "stackAdapt";
                        const disableMessage = active ? readOnly : message;

                        const onEditCampaign = () => editCampaignHandle(groupId, platform, campaignId);
                        return {
                            id: campaignId,
                            name: {
                                content: (
                                    <div className={style.subCampaignName}>
                                        <span>{campaign}</span>
                                        {platform && (
                                            <span className={style.nameDetails}>
                                                <span className={style.platform}>
                                                    <img
                                                        src={platformMeta[platform]?.colorIcon}
                                                        alt={platformMeta[platform]?.name}
                                                    />
                                                    {platformMeta[platform]?.name}
                                                </span>
                                                {type && (
                                                    <>
                                                        <span className={style.dot}></span>
                                                        <span className={style.type}>
                                                            {type
                                                                .split("_")
                                                                .join(" ")
                                                                .toLowerCase()}
                                                        </span>
                                                    </>
                                                )}
                                            </span>
                                        )}
                                    </div>
                                ),
                                value: campaign,
                            },
                            status: {
                                content: (
                                    <StatusCell
                                        id={`status-${campaign}-${campaignId}`}
                                        status={getPlatformStatus(platform)[status]}
                                        platform={platform}
                                        disable={isStatusDisable}
                                        disableMessage={disableMessage}
                                        onEditHandle={onEditCampaign}
                                    />
                                ),
                                value: status,
                                isCellLoading: extraCampaignGroupsInfoLoading,
                            },
                            budget: {
                                content: (
                                    <BudgetCell
                                        id={`budget-${campaign}-${campaignId}`}
                                        budgets={budgets}
                                        budget={budget}
                                        currency={currency}
                                        type={budgetType}
                                        disable={isBudgetDisable}
                                        disableMessage={disableMessage}
                                        onEditHandle={onEditCampaign}
                                    />
                                ),
                                value: budget,
                                isCellLoading: extraCampaignGroupsInfoLoading,
                            },
                            ...EXPORTABLE_METRICS.reduce((cache, metric) => {
                                const actual = performance[metric];
                                const actualCompare = performanceCompare[metric];
                                return {
                                    ...cache,
                                    [TABLE_FIELD_MAPPING[metric] || metric]: {
                                        content: (
                                            <FormattedNumber
                                                actual={actual}
                                                showGrowth={showGrowth}
                                                previousPeriodGrowth={((actual - actualCompare) * 100) / actualCompare}
                                                metric={metric}
                                                previousPeriod={actualCompare}
                                                platform={platform}
                                            />
                                        ),
                                        value: actual,
                                        oldValue: actualCompare,
                                        isCellLoading: campaignGroupsPending,
                                    },
                                };
                            }, {}),
                        };
                    },
                )}
        />
    );
};

export default SubTable;
