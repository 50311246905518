import React from "react";

export default () => (
    <svg
        width="40px"
        height="40px"
        viewBox="0 0 40 40"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <linearGradient
                x1="0.335443038%"
                y1="50.0031646%"
                x2="100.094937%"
                y2="50.0031646%"
                id="linearGradient-1"
            >
                <stop stopColor="#E96F0B" offset="0%"></stop>
                <stop stopColor="#F37901" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g
            id="Onboarding-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="2.0-Step-2.1-()"
                transform="translate(-781.000000, -307.000000)"
                fillRule="nonzero"
            >
                <g id="Framework">
                    <g
                        id="Header"
                        transform="translate(520.000000, 307.000000)"
                    >
                        <g
                            id="Icon"
                            transform="translate(261.000000, 0.000000)"
                        >
                            <path
                                d="M18.1428571,39.9009524 L3.37460317,39.9009524 C1.56095238,39.9009524 0.0926984127,38.4326984 0.0926984127,36.6190476 L0.0926984127,29.5365079 C0.0926984127,27.7228571 1.56095238,26.2546032 3.37460317,26.2546032 L12.7022222,26.2546032 L12.7022222,15.8911111 C12.7022222,14.0774603 14.1704762,12.6092063 15.984127,12.6092063 L26.3479365,12.6092063 L26.3479365,39.8142857 L18.1428571,39.8142857 L18.1428571,39.9009524 Z"
                                id="Path"
                                fill="#FFC517"
                            ></path>
                            <path
                                d="M36.711746,39.9009524 L26.3479365,39.9009524 L26.3479365,3.28190476 C26.3479365,1.46825397 27.8161905,7.10542736e-15 29.6298413,7.10542736e-15 L36.711746,7.10542736e-15 C38.5253968,7.10542736e-15 39.9936508,1.46825397 39.9936508,3.28190476 L39.9936508,36.6190476 C39.9073016,38.4326984 38.4390476,39.9009524 36.711746,39.9009524 L36.711746,39.9009524 Z"
                                id="Path"
                                fill="#F57E02"
                            ></path>
                            <path
                                d="M39.9072222,26.2550803 L39.9072222,36.6189279 C39.9072222,38.431463 38.4378722,39.900813 36.6253371,39.900813 L26.2614895,39.900813 L26.2614895,12.6093476 L39.9072222,26.2550803 Z"
                                id="Path"
                                fill="url(#linearGradient-1)"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
