import { connect } from "react-redux";
import { getFormMeta, reduxForm, getFormValues } from "redux-form";
import compose from "lodash/fp/flowRight";
import Component from ".";
import v from "validate.js";
import { makeSelectIntegrationIds } from "_redux/sites/selectors";
import { makeSelectUserId } from "_redux/users/selectors";
import moment from "moment";
import { selectOnboarding } from "features/Onboarding/ducks/selectors";

const convertCampaignsToSave = (campaignsSelected) => {
    return Object.keys(campaignsSelected)
        .filter((key) => {
            return campaignsSelected[key];
        })
        .reduce((obj, key) => {
            const [name, value] = key.split("____");
            return {
                ...obj,
                [value]: {
                    id: value,
                    name: name,
                },
            };
        }, {});
};

const validate = (values) => {
    const isValid = v(values, {
        apiKey: {
            presence: { allowEmpty: false, message: "^API Key is required" },
        },
        timezone: {
            presence: { allowEmpty: false, message: "^Timezone is required" },
        },
    });

    return isValid;
};

const mapStateToProps = (state, { integrationId }) => {
    const integrations = makeSelectIntegrationIds()(state);
    const { form } = state;
    const formMetaData = getFormMeta("klaviyo")({ form });
    const isFormActive = formMetaData?.apiKey?.active;

    const {
        klaviyo: {
            [integrationId]: {
                apiKey,
                "klaviyo-campaigns": klaviyoCampains = { _all: true },
                regex: regexklaviyo = "",
                integrationName,
                timezone = "",
            } = {},
        } = {},
    } = integrations;

    return {
        initialValues: {
            "klaviyo-campaigns": klaviyoCampains,
            regexklaviyo,
            apiKey,
            integrationName,
            timezone: timezone && {
                value: timezone,
                label: timezone,
            },
        },
        disabled:
            selectOnboarding(state).campaignsLoading || !getFormValues("klaviyo")({ form })?.apiKey || isFormActive,
        userId: makeSelectUserId()(state),
    };
};

export default compose(
    connect(mapStateToProps),
    reduxForm({
        form: "klaviyo",
        enableReinitialize: true,
        validate,
        onSubmit: async (
            { "klaviyo-campaigns": selectedCampaigns, integrationName = "", apiKey, timezone, ...rest },
            _,
            { updateIntegration, onCancel, accountId, userId, platform, dispatch, integrationId }
        ) => {
            const allCurrentAndFuture = selectedCampaigns["_all"];
            const campaignsSelected = allCurrentAndFuture ? {} : selectedCampaigns;
            const campaignsToSave = convertCampaignsToSave(campaignsSelected);

            await updateIntegration({
                integrations: {
                    klaviyo: {
                        value: {
                            [apiKey]: {
                                connectionId: apiKey,
                                integrationName: integrationName || apiKey,
                                campaigns: campaignsToSave,
                                timezone: timezone.value,
                                apiKey,
                                userId,
                                regex: rest[`regexklaviyo`],
                                created_at: moment().toISOString(),
                            },
                        },
                    },
                },
                accountId,
            });
            onCancel();
        },
    })
)(Component);
