import isEmpty from "lodash/isEmpty";

/**
 * Produces selected campaigns collection from redux
 * @param {object} options
 * @param {string} options.selectedAccount
 * @param {object} options.reduxCampaigns
 * @param {object} options.formCampaignsLength
 *
 * @return {object} selectedCampaigns collection
 */

export function hasFormCampaigns({ selectedAccount, reduxCampaigns, formCampaignsLength }) {
    /**
     * If there are no campaigns at all
     */
    if (!selectedAccount || !isEmpty(reduxCampaigns && formCampaignsLength !== 0)) return;
    /**
     * If there campaigns in the onboarding redux storage and
     * If there are NO campaigns in the form storage
     */
    if (!isEmpty(reduxCampaigns) && formCampaignsLength === 0) {
        return Object.keys(reduxCampaigns).reduce((obj, id) => {
            const keyName = `${reduxCampaigns[id].name}____${reduxCampaigns.id}`;
            return {
                ...obj,
                [keyName.replace(/[^\w\s]/gi, "")]: true,
            };
        }, {});
    }
}
/**
 * Format Campaign Data to be consumable for redux form
 * @param {object} data data from campaign fetch
 * @param {object | undefined} savedCampaigns current saved campaigns to check against for redux form
 * @return {object} formatted data for redux form
 */

export function campaignFetchDataToReduxFormData(data, savedCampaigns, savedRegex) {
    if (!data) return null;
    return data.reduce((obj, { id, name, campaignName }) => {
        const keyName = `${name || campaignName}____${id}`;

        if ((!savedCampaigns || isEmpty(savedCampaigns)) && !savedRegex) {
            return {
                _all: true,
            };
        }

        if (!savedCampaigns) {
            return {};
        }

        return {
            ...obj,
            [keyName.replace(/[^\w\s]/gi, "")]: savedCampaigns.hasOwnProperty(id),
        };
    }, {});
}
