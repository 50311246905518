import React from "react";
import SlackIcon from "assets/images/Integrations/JSX/Logo-Slack";
import SlackIntegrate from "features/Integrations/Dependant/Slack/container";
import Icon from "features/Onboarding/IntegrateStep/Icon";
import IntegrationWrapper from "features/Integrations/Independant/IntegrationWrapper/container";
import style from "../style.module.scss";
import { useSelector } from "react-redux";
import { makeSelectUserConnections } from "_redux/users/selectors";
import { makeSelectIntegrationIds } from "_redux/sites/selectors";
import moment from "moment";

export default ({ onCancel, changeConnection, connections, accountId, updateIntegration, userId }) => {
    const { slack: slackConnections } = useSelector((state) => makeSelectUserConnections()(state));
    const { slack: slackIntegratation = {} } = useSelector((state) => makeSelectIntegrationIds()(state));

    return (
        <div className={`${style.integration} ${style.narrow}`}>
            <Icon icon={SlackIcon} />
            <h1>Integrate Slack</h1>
            <p>Connect your Slack account and receive real-time alerts directly into your companies work-flow.</p>

            <IntegrationWrapper
                onCancel={onCancel}
                onSave={async (_, __, { connection }) => {
                    const { channels } = slackIntegratation[connection] || {};
                    await updateIntegration({
                        integrations: {
                            slack: {
                                value: {
                                    [connection]: {
                                        integrationName: slackConnections[connection]?.name,
                                        integrationId: connection,
                                        connectionId: connection,
                                        channels,
                                        created_at: moment().toISOString(),
                                        userId,
                                    },
                                },
                            },
                        },
                        accountId,
                    });
                    onCancel();
                }}
                platform="slack"
                integration={SlackIntegrate}
                changeConnection={changeConnection}
                connection={connections?.slack}
                isValid={Boolean(connections?.slack)}
            />
        </div>
    );
};
