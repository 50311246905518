import React, { useEffect, useState } from "react";
import { campaignFetchDataToReduxFormData, hasFormCampaigns } from "features/Integrations/Dependant/util";
import isEmpty from "lodash/fp/isEmpty";
import { CardWarning } from "components/Warnings";
import onboardStyles from "features/Onboarding/onboarding.module.scss";
import { checkRegex } from "features/Integrations/Dependant/RegexInput";
import LogoStackAdapt from "assets/images/Integrations/JSX/Logo-StackAdapt";
import CheckboxSearch from "components/SearchBox/CheckboxSearchWithField";
import RegexInput from "features/Integrations/Dependant/RegexInput/container";
import Loading from "features/Integrations/Dependant/common/Loading";

export const STACKADAPT_CAMPAIGNS = "stackAdapt-campaigns";

const Warning = () => {
    return (
        <CardWarning
            className={onboardStyles.warning}
            title="StackAdapt Campaigns Warning"
            subtitle="We have connected your StackAdapt account successfully, but did not find any campaigns. If you are currently only running video ad campaigns, there is a known limitation in the StackAdapt API with regards to providing campaigns at this stage of the connection process."
        />
    );
};

const StackAdaptCampaigns = ({
    options,
    selected,
    getStackAdaptCampaigns,
    userId,
    regex,
    campaigns,
    selectedCampaignLength,
    savedCampaigns,
    connectionId,
    change,
    isLoading,
    toggleLoading,
    currentAndFuture = false,
    savedRegex,
    isSandboxAccount,
    apiKey,
}) => {
    const [regexEnabled, toggleRegex] = useState(false);

    useEffect(() => {
        const formCampaigns = hasFormCampaigns({
            selectedAccount: selected,
            reduxCampaigns: campaigns[selected],
            formCampaigns: selectedCampaignLength,
        });

        if (formCampaigns) {
            return change(STACKADAPT_CAMPAIGNS, formCampaigns);
        }
        toggleLoading(true);

        getStackAdaptCampaigns({
            isSandboxAccount,
            apiKey: apiKey,
        })
            .then((data) => {
                const campaigns = campaignFetchDataToReduxFormData(data.payload.campaigns, savedCampaigns, savedRegex);
                change(STACKADAPT_CAMPAIGNS, campaigns);
                return data;
            })
            .finally(() => toggleLoading(false));
    }, [selected, userId, connectionId, savedRegex]);

    useEffect(() => {
        if (savedRegex) {
            change("regexstackAdapt", savedRegex);
        }
        toggleRegex(!currentAndFuture);
    }, [currentAndFuture]);

    if (isLoading) {
        return <Loading />;
    }

    if (selected && isEmpty(campaigns[selected])) {
        return <Warning />;
    }

    if (!campaigns || !campaigns[selected]) {
        return null;
    }

    const isValid = checkRegex(regex);
    const regexParse = isValid ? new RegExp(regex.toLowerCase()) : { test: () => false };
    const selectAllCurrentAndFuture = regex
        ? false
        : (value) => {
              const changeValue = value ? { _all: true } : {};
              change(STACKADAPT_CAMPAIGNS, changeValue);
          };
    const onChangeAll = (options, isAllSelected) => {
        options.forEach((option) => {
            change(option.name, isAllSelected);
        });
    };

    const checkboxOptions = Object.keys(campaigns[selected]).map((id) => {
        const label = campaigns[selected][id].name;
        const isRegexMatched = regexParse.test(label.toLowerCase());
        const keyName = `${campaigns[selected][id].name}____${campaigns[selected][id].id}`;

        return {
            checked: isRegexMatched,
            disabled: isRegexMatched,
            label: label,
            name: `${STACKADAPT_CAMPAIGNS}.${keyName.replace(/[^\w\s]/gi, "")}`,
            id: campaigns[selected][id].id,
        };
    });
    const regexPlaceholder = regexEnabled
        ? "Type Selection"
        : 'To use regex, uncheck "Select All Current and Future Campaigns"';

    const CampaignsSelected = () => (
        <p className={onboardStyles.campaignsSelected}>
            <LogoStackAdapt />
            <strong>{currentAndFuture ? Object.keys(campaigns[selected]).length : selectedCampaignLength}</strong>{" "}
            Campaigns Selected
        </p>
    );

    return (
        <div>
            <p className={onboardStyles.labelText}>{options[STACKADAPT_CAMPAIGNS].humanReadable}</p>
            <CheckboxSearch
                className={onboardStyles.checkboxSearch}
                search={{
                    placeholder: "Search StackAdapt Campaigns",
                    accessor: "label",
                }}
                options={checkboxOptions}
                onChangeAll={onChangeAll}
                selectAllTxt="Select All Campaigns"
                searchTermInText
                selectedValueLength={selectedCampaignLength}
                hideSelectAll
                itemType="Campaigns"
                bottomBar={CampaignsSelected}
                selectAllCurrentAndFuture={selectAllCurrentAndFuture}
                selectAllCurrentAndFutureDefault={currentAndFuture}
            />
            <RegexInput
                disabled={!regexEnabled}
                platform="stackAdapt"
                regex={regex}
                className={onboardStyles.connectRegex}
                placeholder={regexPlaceholder}
                darkBorder
            />
        </div>
    );
};

export default StackAdaptCampaigns;
