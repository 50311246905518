import React from "react";
import { connect } from "react-redux";
import AnalyticsV4Connect from "./index";
import scriptLoader from "react-async-script-loader";
import { compose } from "recompose";

const loadScripts = ({ scripts, loader: Loader }) =>
    compose(scriptLoader(scripts), (Component) => (props) => {
        return props.isScriptLoaded ? <Component {...props} /> : null;
    });

export default connect()(
    loadScripts({
        scripts: ["https://accounts.google.com/gsi/client"],
    })(AnalyticsV4Connect),
);
