import { makeSelectAccountId, makeSelectAccountLoading, makeSelectAccountName } from "_redux/sites/selectors";
import { makeSelectUserAccountFavourites, makeSelectUserId, selectUserMetaOverviewUi } from "_redux/users/selectors";
import { makeSelectAccounts, selectOverviewTagsAvailable } from "features/ControlPanel/ducks/selectors";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import AccountSelector from ".";
import { updateOverviewUi } from "_redux/users";

const arrayOfAccounts = createSelector(
    [makeSelectAccounts(), makeSelectUserAccountFavourites(), makeSelectAccountId()],
    (accounts, favourites, currentAccountId) => {
        return Object.keys(accounts).map((id) => ({
            ...accounts[id],
            favourite: favourites.hasOwnProperty(id),
            isActive: id === currentAccountId,
        }));
    },
);

const mapStateToProps = (state) => {
    const { tags = [], platforms = {}, contains = "any" } = selectUserMetaOverviewUi(state);

    return {
        selectedAccountLoading: makeSelectAccountLoading()(state),
        accounts: arrayOfAccounts(state),
        currentAccountTitle: makeSelectAccountName()(state),
        availableTags: selectOverviewTagsAvailable(state),
        userId: makeSelectUserId()(state),
        tags,
        platforms,
        contains,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateOverviewUi: (data) => dispatch(updateOverviewUi(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSelector);
