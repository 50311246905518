import React, { useState } from "react";
import style from "./style.module.scss";
import SelectInput from "components/DropDown/Select";
import DropDownContainer from "components/DropDown/Container";
import { SimpleListItem } from "components/DropDown/ListItems";

export default ({
    icon,
    styles,
    options = [],
    input,
    placeholder,
    className = "",
    label,
    valueLabel,
    name,
    optionClassName,
    iconClassName,
    dropdownWidth,
    dropdownAnchor,
    isDisabled,
}) => {
    const [open, toggleOpen] = useState(false);
    const { value, onChange } = input;

    return (
        <div>
            {label && <label className={style.label}>{label}</label>}
            <SelectInput
                className={className}
                icon={icon}
                refFunction={() => toggleOpen(false)}
                handleClick={() => toggleOpen(!open)}
                value={valueLabel || value || placeholder}
                isOpen={open}
                styles={styles}
                iconClassName={iconClassName}
                disabled={isDisabled}
            >
                <DropDownContainer
                    isOpen={open}
                    className={style.dropdown}
                    dropdownWidth={dropdownWidth}
                    anchor={dropdownAnchor}
                >
                    {options.map((option) => (
                        <SimpleListItem
                            key={option.value}
                            label={option.label}
                            onClick={() => {
                                onChange(option.value);
                                toggleOpen(false);
                            }}
                            isActive={value === option.value}
                            className={optionClassName}
                        />
                    ))}
                </DropDownContainer>
            </SelectInput>
        </div>
    );
};
