import React from "react";
import { mixpanel, sendToSlack } from "components/Mixpanel";
import { connect } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";
import compose from "lodash/fp/flowRight";
import { createSelector } from "reselect";
import SubmitButtons from "../_Components/SubmitButtons";
import {
    makeSelectAccountId,
    makeSelectAccountName,
    makeSelectGetIntegration,
    makeSelectIntegrationIds,
    makeSelectIntegrationCampaignArray,
} from "_redux/sites/selectors";
import { makeSelectIntegrationOpts } from "features/Integrations/_ducks/selectors";
import { selectOnboardingCampaigns, makeSelectOnboardingIntegrations } from "features/Onboarding/ducks/selectors";
import { isEmpty, get } from "lodash";
import { formatIntegrationKey } from "lib/bigQuery/helpers";

const getBigQueryValues = ({ currentIntegrations, integrationId }) => {
    const validIntegrationId = formatIntegrationKey(integrationId);
    const foundIntegration = get(currentIntegrations, ["bigQuery", validIntegrationId]);

    return foundIntegration
        ? {
              ...foundIntegration,
              bigQuery: {
                  label: foundIntegration.integrationName,
                  value: foundIntegration.integrationId,
              },
          }
        : undefined;
};

// GET INITIAL VALUES FOR FORM
const foundValues = createSelector(
    ({ accountOptions }) => accountOptions,
    ({ platform }) => platform,
    ({ integrations }) => integrations,
    ({ connection }) => connection,
    ({ integrationId }) => integrationId,
    ({ currentIntegrations }) => currentIntegrations,
    (accountOptions, platform, integrations, connection, integrationId, currentIntegrations) => {
        const platformOptions = Object.keys(accountOptions[platform]);
        const { [platform]: foundIntegration = {} } = integrations[`${connection}_${platform}`] || {};

        if (platform === "bigQuery") {
            return getBigQueryValues({ currentIntegrations, integrationId });
        }

        if (!connection) {
            return {};
        }

        // MOVE LEGACY NAMING OF ACCOUNT VIEWS TO NOT USE UNDERSCORES IF POSSIBLE
        // FORMATTING OF CERTAIN ID'S TO BE CONSISTENT THROUGHOUT.... GA + FACEBOOK

        return platformOptions.reduce((obj, key) => {
            if (key.includes("analytics")) {
                const { [key]: analyticsIntegration = {} } = integrations[`${connection}_${platform}`] || {};
                if (!Object.keys(analyticsIntegration).length) {
                    return obj;
                }

                const { [integrationId]: { [key]: { value: id } = {} } = {} } = currentIntegrations[platform] || {};
                const { [id]: foundAccount } = analyticsIntegration || {};

                if (!foundAccount) {
                    return obj;
                }

                return {
                    ...obj,
                    [key]: {
                        label: foundAccount.name,
                        value: foundAccount.id,
                        currency: foundAccount.currency,
                    },
                };
            }

            if (!foundIntegration || !foundIntegration[integrationId]) {
                return obj;
            }

            const idFormatting = (id, key) => {
                if (typeof id !== "string") return id;

                //Google analytics
                if (id.includes("ga:")) return id.split("ga:")[1];
                //Facebook
                if (key === "facebook" && !id.includes("act_")) return `act_${id}`;

                return id;
            };

            const parsedExternalId = idFormatting(integrationId, key);
            const { [parsedExternalId]: foundAccount } = foundIntegration;

            if (!foundAccount) {
                return obj;
            }

            return {
                ...obj,
                [key]: {
                    label: foundAccount.name || foundAccount.id,
                    value: foundAccount.id || foundAccount.advertiserId,
                    currency: foundAccount.currency,
                },
            };
        }, {});
    },
);

const mapStateToProps = (
    state,
    { onSave, platform, connection, disabled = false, integrationId, changeConnection },
) => {
    const { form } = state;
    const platformValues = getFormValues(platform)({ form }) || {};

    const accountId = makeSelectAccountId()(state);
    const accountName = makeSelectAccountName()(state);
    const currentIntegrations = makeSelectIntegrationIds()(state);
    const { regex: selectedRegex = "", integrationName = "" } = makeSelectGetIntegration(
        platform,
        integrationId,
    )(state);
    const selectedCampaigns = makeSelectIntegrationCampaignArray(platform, integrationId)(state);
    const accountOptions = makeSelectIntegrationOpts()(state);
    const integrations = makeSelectOnboardingIntegrations()(state);
    const campaigns = selectOnboardingCampaigns(state);

    const initialVals = {
        ...foundValues({
            accountOptions,
            platform,
            integrations,
            connection,
            integrationId,
            currentIntegrations,
        }),
        [`${platform}-campaigns`]: isEmpty(selectedCampaigns) ? { _all: true } : selectedCampaigns,
        [`regex${platform}`]: selectedRegex,
        [`${platform}IntegrationName`]: integrationName,
    };

    return {
        form: platform,
        onSubmit: onSave,
        connection,
        campaigns,
        selectedCampaigns,
        selectedRegex,
        accountOptions,
        platformValues,
        disabled,
        initialValues: initialVals,
        accountId,
        accountName,
        platform,
        changeConnection,
        integrationId,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        enableReinitialize: true,
    }),
)(
    ({
        integration: Integration,
        handleSubmit,
        pristine,
        submitting,
        onCancel,
        selectedCampaigns,
        selectedRegex,
        accountOptions,
        platformValues,
        disabled,
        saveNotificationChannels,
        accountId,
        accountName,
        platform,
        integrationId,
        invalid,
        ...props
    }) => {
        return (
            <>
                {React.createElement(Integration, {
                    formName: props.form,
                    integrationId,
                    ...props,
                })}
                <SubmitButtons
                    disabled={disabled || invalid}
                    invalid={submitting}
                    onClick={async () => {
                        mixpanel.track("Save Integration", {
                            Platform: props.form,
                            "Is Editing": !!integrationId,
                        });

                        handleSubmit().then(() => {
                            // await saveNotificationChannels({ accountId });
                            sendToSlack(`Save Integration: ${props.form}`);

                            // refresh the page to get the new integration
                            window.location.reload();
                        });
                    }}
                    onCancel={() => {
                        mixpanel.track("Save Integration", {
                            Platform: props.form,
                            Canceled: true,
                        });
                        onCancel();
                    }}
                />
            </>
        );
    },
);
