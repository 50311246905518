import React from "react";
import Button from "components/Button";
import style from "./style.module.scss";

const UpgradeTip = ({ title = "", message = "", onClick }) => (
    <div className={style.upgradeTip}>
        <h3>{title}</h3>
        <p>{message}</p>
        <Button medium onClick={onClick}>
            Upgrade Now
        </Button>
    </div>
);

export default UpgradeTip;
