import React, { useState } from "react";
import style from "./style.module.scss";
import { ReactComponent as SettingsIcon } from "assets/images/icons/Icon-Settings.svg";
import { ReactComponent as IconChangeLog } from "assets/images/icons/Icon-Changelog.svg";
import { mixpanel } from "components/Mixpanel";
import OptionsIcon from "assets/images/Dashboard/JSX/HorizontalMore";
import Container from "components/DropDown/Container";
import { SimpleListItem } from "components/DropDown/ListItems";
import classNames from "classnames";
import { useHandleOutsideClickHook } from "lib/handleOutsideClick";
import { TooltipDark } from "components/Tooltip";
import { history } from "_redux";

const AdditionalMenu = ({ accountId }) => {
    const [menuOpen, toggleMenu] = useState(false);
    const outsideClick = useHandleOutsideClickHook(() => {
        if (menuOpen) {
            toggleMenu(false);
        }
    }, [menuOpen]);

    const handleSettingClick = () => {
        mixpanel.track("Button Click", {
            "Button Text": "Dashboard - Campaign Group Settings",
            "Button Name": "Dashboard - Campaign Group Settings",
        });
        history.push(`/dashboard/${accountId}/settings/campaign-groups`);
    };

    const handleChangeLogsClick = () => {
        mixpanel.track("Button Click", {
            "Button Text": "Dashboard - Campaign Changes Logs",
            "Button Name": "Dashboard - Campaign Changes Logs",
        });
        history.push(`/dashboard/${accountId}/settings/change-log`);
    };

    return (
        <div className={style.options} ref={outsideClick}>
            <div
                data-tip={"Campaign Group Settings, Change logs"}
                data-for={"campaign-menu"}
                className={classNames(style.menu, { [style.open]: menuOpen })}
                onClick={() => toggleMenu(!menuOpen)}
            >
                <OptionsIcon />
            </div>
            <Container
                className={style.optionsDropdown}
                isOpen={menuOpen}
                dropdownWidth="auto"
                anchor="right"
            >
                <SimpleListItem
                    className={style.option}
                    onClick={handleSettingClick}
                    label={
                        <div className={style.menuLabel}>
                            <SettingsIcon />
                            Campaign Group Settings
                        </div>
                    }
                />
                <SimpleListItem
                    className={style.option}
                    onClick={handleChangeLogsClick}
                    label={
                        <div className={style.menuLabel}>
                            <IconChangeLog />
                            Campaign Changes Logs
                        </div>
                    }
                />
            </Container>
            <TooltipDark id={"campaign-menu"} proximity={6} position="top" />
        </div>
    );
};

export default AdditionalMenu;
