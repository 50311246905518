import React, { useEffect } from "react";
import isEmpty from "lodash/fp/isEmpty";
import { CardWarning } from "components/Warnings";
import onboardStyles from "features/Onboarding/onboarding.module.scss";
import { Field } from "redux-form";
import MultiSelector from "components/MultiSelector";
import { TooltipDark } from "components/Tooltip";
import Loading from "features/Integrations/Dependant/common/Loading";

const required = (value) => (value ? undefined : "This field is required");

const Warning = () => {
    return (
        <CardWarning
            className={onboardStyles.warning}
            title="Google BigQuery Error"
            subtitle="We could not find any data source in your connection. Please choose another Google BigQuery connection."
        />
    );
};

const BigQueryTables = ({
    connectionId,
    userId,
    selectedIntegrations,
    isEditing,
    bigQueryTables = [],
    bigQueryTablesLoading,
    getBigQueryTables,
    dataset,
    alreadyIntegrated,
}) => {
    useEffect(() => {
        const [projectId, datasetId] = dataset.split(".");
        getBigQueryTables({
            userId,
            connectionId,
            projectId,
            datasetId,
        });
    }, [dataset]);

    if (bigQueryTablesLoading) return <Loading />;

    const tableOptions = bigQueryTables.map((table) => {
        const isDisabled = alreadyIntegrated.includes(
            `${dataset}.${table.tableGroupKey}`
        );
        return {
            value: table.tableGroupKey,
            label: `${table.tableGroupKey}${table.suffix}${
                isDisabled ? " (In Use)" : ""
            }`,
            latestTable: table.latestTable,
            disabled: isDisabled,
            suffix: table.suffix || undefined,
        };
    });

    if (isEmpty(tableOptions)) return <Warning />;

    return (
        <div className={onboardStyles.additionalIntegrationAccount}>
            <div
                className={onboardStyles.multiSelectWrapper}
                data-for="bigQuery-field-table"
                data-tip="This field isn't editable. You may remove this integration and add a new one."
                data-tip-disable={!isEditing}
            >
                <Field
                    component={MultiSelector}
                    onChange={() => {}}
                    name="bigQueryTable"
                    placeholder="Select Google BigQuery Table or Table Group"
                    className={onboardStyles.multiSelect}
                    label="Google BigQuery Data Source"
                    options={tableOptions}
                    darkBorder
                    validate={[required]}
                    disabled={isEditing}
                />
            </div>

            <TooltipDark id="bigQuery-field-table" proximity={34} />
        </div>
    );
};
export default BigQueryTables;
