import React, { useMemo, useState } from "react";
import Checkbox from "components/Checkbox";
import { Field, getFormValues } from "redux-form";
import style from "./style.module.scss";
import CheckboxSearchWrapper from "../CheckboxSearchWrapper";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FormName } from "redux-form";
import { get } from "lodash";
import SelectAllCurrentAndFuture from "../SelectAllCurrentAndFuture";
import LazilyRender from "react-lazily-render";
import classNames from "classnames";

/**
 * Produces a search component with redux form checkbox state
 * @param {object} props
 * @param {string} props.className classname of component
 * @param {Array<Object>} props.options Collection of checkboxes objects to iterate and search over
 * @param {object} props.search the accessor and the placeholder
 * @param {object} props.search.accessor search component key accessor for options
 * @param {object} props.search.placeholder search component placeholder
 * @param {function} props.onChangeAll when the change all button is clicked
 * @param {function} props.sortFn custom sort for the selection default is alphabetical by accessor
 * @param {bool} props.selectAllByDefault lets the user change all by default?
 * @param {bool} props.disableSelectAll disable the select all
 * @param {string} props.selectAllTxt select all text
 * @param {JSX.Element} props.bottomBar bottom bar of component
 * @param {bool} props.largeSelect is a large select box
 * @param {bool} props.isLoading is loading
 * @param {string} props.itemType itemType???
 * @param {func} props.onChange function on change
 * @param {bool} props.reverse is the checkbox options reversed
 * @param {obj} props.filters object for the dropdown filter types
 *
 * @returns {JSX.Element}
 */

function CheckboxSearchWithField({
    options = [],
    className,
    search = {},
    onChangeAll,
    selectAllByDefault,
    disableSelectAll = false,
    selectAllTxt,
    bottomBar: BottomBar,
    largeSelect = false,
    isLoading = false,
    itemType = "",
    sortFn,
    onChange,
    filters,
    reverse = false,
    hideSelectAll,
    searchTermInText = false,
    selectAllCurrentAndFuture,
    selectAllCurrentAndFutureDefault,
    lazyLoad = false,
}) {
    return (
        <FormName>
            {({ form }) => {
                return (
                    <FormWrappedComponent
                        form={form}
                        filters={filters}
                        options={options}
                        className={className}
                        search={search}
                        onChangeAll={onChangeAll}
                        selectAllByDefault={selectAllByDefault}
                        sortFn={sortFn}
                        disableSelectAll={disableSelectAll}
                        selectAllTxt={selectAllTxt}
                        bottomBar={BottomBar}
                        largeSelect={largeSelect}
                        hideSelectAll={hideSelectAll}
                        reverse={reverse}
                        onChange={onChange}
                        isLoading={isLoading}
                        itemType={itemType}
                        searchTermInText={searchTermInText}
                        selectAllCurrentAndFuture={selectAllCurrentAndFuture}
                        selectAllCurrentAndFutureDefault={selectAllCurrentAndFutureDefault}
                        lazyLoad={lazyLoad}
                    />
                );
            }}
        </FormName>
    );
}
function FormWrappedComponent({
    options = [],
    className,
    search = {},
    onChangeAll,
    selectAllByDefault,
    disableSelectAll = false,
    selectAllTxt = "",
    bottomBar: BottomBar,
    largeSelect = false,
    isLoading = false,
    itemType = "",
    sortFn,
    onChange,
    reverse = false,
    hideSelectAll,
    selectedValueLength = 0,
    form,
    filters,
    searchTermInText = false,
    selectAllCurrentAndFuture,
    selectAllCurrentAndFutureDefault = false,
    lazyLoad,
}) {
    const [currentAndFuture, toggleCurrentAndFuture] = useState(selectAllCurrentAndFutureDefault);
    const [searchTerm, changeSearchTerm] = useState("");
    const formValues = useSelector(getFormValues(form));

    const opts = useMemo(
        () =>
            options.map((option) => {
                const value = get(formValues, option.name);
                return {
                    ...option,
                    checked: value,
                };
            }),
        [options, formValues],
    );
    return (
        <CheckboxSearchWrapper
            options={opts}
            className={className}
            filters={filters}
            search={search}
            onChangeAll={onChangeAll}
            selectAllByDefault={selectAllByDefault}
            sortFn={sortFn}
            disableSelectAll={disableSelectAll}
            selectAllTxt={selectAllTxt}
            bottomBar={BottomBar}
            largeSelect={largeSelect}
            hideSelectAll={hideSelectAll}
            reverse={reverse}
            selectedValueLength={selectedValueLength}
            searchTermInText={searchTermInText}
            currentAndFuture={currentAndFuture}
            searchTerm={searchTerm}
            changeSearchTerm={changeSearchTerm}
            isLoadingData={isLoading}
        >
            {({ foundList }) => {
                return (
                    <CheckboxListWithField
                        foundList={foundList}
                        reverse={reverse}
                        onChange={onChange}
                        isLoading={isLoading}
                        itemType={itemType}
                        selectAllCurrentAndFuture={selectAllCurrentAndFuture}
                        toggleCurrentAndFuture={toggleCurrentAndFuture}
                        currentAndFuture={currentAndFuture}
                        searchTerm={searchTerm}
                        lazyLoad={lazyLoad}
                    />
                );
            }}
        </CheckboxSearchWrapper>
    );
}
CheckboxSearchWithField.propTypes = {
    options: PropTypes.array,
    className: PropTypes.string,
    search: PropTypes.object,
    onChangeAll: PropTypes.func,
    selectAllByDefault: PropTypes.bool,
    disableSelectAll: PropTypes.bool,
    selectAllTxt: PropTypes.string,
    bottomBar: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    largeSelect: PropTypes.bool,
    isLoading: PropTypes.bool,
    itemType: PropTypes.string,
    onChange: PropTypes.func,
    sortFn: PropTypes.func,
    reverse: PropTypes.bool,
    selectAllCurrentAndFuture: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default CheckboxSearchWithField;

function CheckBoxPlaceHolder() {
    return (
        <div
            style={{
                height: "3.125rem",
                width: "100%",
                borderRadius: "0.5rem",
                backgroundColor: "#f8f9fb",
                display: "block",
                marginBottom: "0.25rem",
            }}
        ></div>
    );
}

export function CheckboxListWithField({
    isLoading,
    foundList,
    itemType,
    reverse,
    onChange,
    selectAllCurrentAndFuture,
    currentAndFuture,
    toggleCurrentAndFuture,
    searchTerm,
    lazyLoad = false,
    className,
}) {
    return (
        <>
            {isLoading && (
                <div className={style.loading}>{itemType ? <p>Loading {itemType}...</p> : <p>Loading...</p>}</div>
            )}
            {!isLoading && !searchTerm && typeof selectAllCurrentAndFuture === "function" && (
                <SelectAllCurrentAndFuture
                    active={currentAndFuture}
                    onChange={() => {
                        toggleCurrentAndFuture(!currentAndFuture);
                        selectAllCurrentAndFuture(!currentAndFuture);
                    }}
                    itemType={itemType}
                />
            )}
            {!isLoading &&
                foundList.map(
                    ({ label, labelDetails, subLabel, name, id, image, disabled, tip = null, autoChecked }, index) => {
                        const RenderField = (
                            <div
                                className={`${style.Option} ${
                                    autoChecked || currentAndFuture ? style.autoChecked : ""
                                }`}
                                key={name}
                            >
                                {image && <img className={style.image} src={image} alt="member" />}
                                <Field
                                    flipped={reverse}
                                    label={label}
                                    subLabel={subLabel}
                                    labelDetails={labelDetails}
                                    name={name}
                                    id={id}
                                    disabled={disabled || currentAndFuture}
                                    component={Checkbox}
                                    className={classNames(style.checkboxOption, className)}
                                    type="checkbox"
                                    onChange={onChange}
                                    autoChecked={autoChecked || currentAndFuture}
                                    tip={tip}
                                />
                            </div>
                        );

                        if (lazyLoad) {
                            return (
                                <LazilyRender
                                    key={`${id}-${index}`}
                                    className={style.lazyWrapper}
                                    content={RenderField}
                                    placeholder={<CheckBoxPlaceHolder />}
                                />
                            );
                        } else {
                            return RenderField;
                        }
                    },
                )}
        </>
    );
}

CheckboxListWithField.propTypes = {
    isLoading: PropTypes.bool,
    foundList: PropTypes.array,
    reverse: PropTypes.bool,
    itemType: PropTypes.string,
    onChange: PropTypes.func,
    filters: PropTypes.object,
};
