import React, { useEffect, useState } from "react";
import AnalyticsConnect from "features/Integrations/Connections/AnalyticsConnect/container";
import Connect from "features/Integrations/Dependant/ConnectButton";
import { Field } from "redux-form";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import style from "./style.module.scss";
import onboardingStyles from "features/Onboarding/onboarding.module.scss";
import MultiSelector from "components/MultiSelector";
import Loading from "components/Loading/ThreeDots";
import { CardWarning } from "components/Warnings";
import RenderInput from "components/RenderInput";
import { TooltipDark } from "components/Tooltip";

const required = (value) => (value ? undefined : "This field is required");

const ConnectButton = ({ changeConnection, label, list, loading, onAdd, platform, selected, disabled }) => {
    return (
        <div className={`${style.connectButtonWrapper} ${onboardingStyles.formInputs}`}>
            <Connect
                selected={selected}
                list={list}
                changeConnection={({ connectionId }) => changeConnection({ connectionId, platform })}
                loading={loading}
                onAdd={onAdd}
                label={label}
                disabled={disabled}
            />
        </div>
    );
};

const AnalyticsIntegrate = ({
    change,
    getAnalyticsAccounts,
    integrations,
    options,
    selected,
    selectedIntegrations,
    userId,
    isLoading,
    toggleLoading,
    integrationId,
    alreadyIntegrated,
}) => {
    useEffect(() => {
        if (isEmpty(selected) || integrations) return;
        toggleLoading(true);
        getAnalyticsAccounts({ userId, connectionId: selected }).finally(() => toggleLoading(false));
    }, [selected, userId]);

    const isEditing = !!integrationId;

    if (isLoading) return <Loading style={{ margin: "2rem auto 0 auto" }} color="#BCCFDC" />;

    if (!isEmpty(selected) && isEmpty(integrations) && !isLoading) {
        return (
            <CardWarning
                className={onboardingStyles.warning}
                title="Universal Analytics Error"
                subtitle="We could not find any accounts in your connection. Please choose another Analytics connection."
            />
        );
    }

    if (!integrations) return null;

    return (
        <div className={`${style.analyticsIntegrateWrapper} ${onboardingStyles.formInputs}`}>
            <Field
                component={RenderInput}
                className={onboardingStyles.renderInput}
                name="analyticsIntegrationName"
                label="Connection Name"
                darkBorder
                large
                type="text"
                placeholder={
                    selectedIntegrations?.analyticsProfiles?.label
                        ? `Default: ${selectedIntegrations?.analyticsProfiles?.label}`
                        : ""
                }
                tip="Name that will apearin the app forthis Ads account."
            />
            <div
                className={onboardingStyles.multiSelectWrapper}
                data-for="analytics-field-account"
                data-tip="This field isn't editible. You may remove this connection and add a new one."
                data-tip-disable={!isEditing}
            >
                <Field
                    component={MultiSelector}
                    className={onboardingStyles.multiSelect}
                    name="analyticsAccounts"
                    label={options.analyticsAccounts.humanReadable}
                    onChange={(e, curr, prev) => {
                        // Reset property and view
                        if (prev && !isEqual(prev, curr)) {
                            change("analyticsWebProperties", null);
                            change("analyticsProfiles", null);
                        }
                    }}
                    options={Object.keys(integrations.analyticsAccounts).map((id) => {
                        const analyticsAccount = integrations.analyticsAccounts[id];
                        return {
                            value: id,
                            label: `${analyticsAccount.name} - ${analyticsAccount.id}`,
                        };
                    })}
                    disabled={isEditing}
                    darkBorder
                    validate={[required]}
                />
            </div>
            <div
                className={onboardingStyles.multiSelectWrapper}
                data-for="analytics-field-property"
                data-tip="This field isn't editible. You may remove this connection and add a new one."
                data-tip-disable={!isEditing}
            >
                <Field
                    component={MultiSelector}
                    className={onboardingStyles.multiSelect}
                    name="analyticsWebProperties"
                    label={options.analyticsWebProperties.humanReadable}
                    onChange={(e, curr, prev) => {
                        if (prev && !isEqual(prev, curr)) {
                            change("analyticsProfiles", null);
                        }

                        if (!curr || !curr.value) return;
                        const property = integrations.analyticsWebProperties[curr.value];

                        if (!selectedIntegrations.analyticsAccounts) {
                            const account = integrations.analyticsAccounts[property.accountId];
                            change("analyticsAccounts", {
                                value: account.id,
                                label: account.name,
                            });
                        }
                    }}
                    options={Object.keys(integrations.analyticsWebProperties)
                        .filter((id) => {
                            if (!selectedIntegrations) return true;
                            if (
                                selectedIntegrations.hasOwnProperty("analyticsAccounts") &&
                                selectedIntegrations.analyticsAccounts
                            ) {
                                return (
                                    integrations.analyticsWebProperties[id].accountId ===
                                    selectedIntegrations.analyticsAccounts.value
                                );
                            }

                            return true;
                        })
                        .map((id) => ({
                            value: id,
                            label: integrations.analyticsWebProperties[id].name,
                        }))}
                    darkBorder
                    disabled={isEditing}
                    validate={[required]}
                />
            </div>
            <div
                className={onboardingStyles.multiSelectWrapper}
                data-for="analytics-field-view"
                data-tip="This field isn't editible. You may remove this integration and add a new one."
                data-tip-disable={!isEditing}
            >
                <Field
                    component={MultiSelector}
                    className={onboardingStyles.multiSelect}
                    name="analyticsProfiles"
                    label={options.analyticsProfiles.humanReadable}
                    onChange={(e, curr, prev) => {
                        if (!curr || !curr.value) return;
                        const profile = integrations.analyticsProfiles[curr.value];

                        if (!selectedIntegrations || !selectedIntegrations.analyticsAccounts) {
                            const account = integrations.analyticsAccounts[profile.accountId];
                            change("analyticsAccounts", {
                                value: account.id,
                                label: account.name,
                            });
                        }

                        if (!selectedIntegrations || !selectedIntegrations.analyticsWebProperties) {
                            const property = integrations.analyticsWebProperties[profile.webPropertyId];
                            change("analyticsWebProperties", {
                                value: property.id,
                                label: property.name,
                            });
                        }
                    }}
                    options={Object.keys(integrations.analyticsProfiles)
                        .filter((id) => {
                            if (!selectedIntegrations) return true;

                            if (
                                selectedIntegrations.hasOwnProperty("analyticsWebProperties") &&
                                selectedIntegrations.analyticsWebProperties
                            ) {
                                return (
                                    integrations.analyticsProfiles[id].webPropertyId ===
                                    selectedIntegrations.analyticsWebProperties.value
                                );
                            }

                            if (
                                selectedIntegrations.hasOwnProperty("analyticsAccounts") &&
                                selectedIntegrations.analyticsAccounts
                            ) {
                                return (
                                    integrations.analyticsProfiles[id].accountId ===
                                    selectedIntegrations.analyticsAccounts.value
                                );
                            }

                            return true;
                        })
                        .map((id) => {
                            const disable = alreadyIntegrated.includes(id.toString());
                            return {
                                value: id,
                                label: `${integrations.analyticsProfiles[id].name}${disable ? " (In Use)" : ""}`,
                                currency: integrations.analyticsProfiles[id].currency,
                                disabled: disable,
                            };
                        })}
                    darkBorder
                    disabled={isEditing}
                    validate={[required]}
                />
            </div>
            <TooltipDark id="analytics-field-account" proximity={34} />
            <TooltipDark id="analytics-field-property" proximity={34} />
            <TooltipDark id="analytics-field-view" proximity={34} />
        </div>
    );
};

export default React.memo(
    ({
        change,
        changeConnection,
        exchangeAnalyticsCode,
        getAnalyticsAccounts,
        integrations,
        list,
        options,
        selected,
        selectedIntegrations,
        userId,
        integrationId,
        alreadyIntegrated = [],
    }) => {
        const [integrateLoading, toggleIntegrateLoading] = useState(false);

        return (
            <>
                <AnalyticsConnect
                    exchangeAnalyticsCode={exchangeAnalyticsCode}
                    component={({ onAdd, loading, label, platform, accountId }) =>
                        ConnectButton({
                            list,
                            selected,
                            changeConnection,
                            onAdd,
                            loading,
                            label,
                            platform,
                            accountId,
                            disabled: integrateLoading || !!integrationId,
                        })
                    }
                />

                {selected && (
                    <AnalyticsIntegrate
                        selectedIntegrations={selectedIntegrations}
                        integrations={integrations}
                        getAnalyticsAccounts={getAnalyticsAccounts}
                        options={options}
                        selected={selected}
                        userId={userId}
                        change={change}
                        isLoading={integrateLoading}
                        toggleLoading={toggleIntegrateLoading}
                        integrationId={integrationId}
                        alreadyIntegrated={alreadyIntegrated}
                    />
                )}
            </>
        );
    },
    isEqual,
);
