import React from "react";

export default ({ color = null }) => (
    <svg
        width="4px"
        height="20px"
        viewBox="0 0 4 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g
            id="⚙️-Settings"
            stroke="none"
            strokeWidth="1"
            fillRule="evenodd"
            fill={color}
        >
            <g
                id="5.3-Settings-(Slack)"
                transform="translate(-326.000000, -358.000000)"
            >
                <g id="Framework">
                    <g
                        id="Content"
                        transform="translate(262.000000, 136.000000)"
                    >
                        <g
                            id="Tip-Block"
                            transform="translate(10.000000, 176.000000)"
                        >
                            <g
                                id="Group"
                                transform="translate(32.000000, 32.000000)"
                            >
                                <path
                                    d="M24.0130603,29.8241444 C25.0848496,29.8241444 26,30.7731546 26,31.9120722 C26,33.02381 25.0849342,34 24.0130603,34 C22.8627401,34 22,33.02381 22,31.9120722 C22,30.7731546 22.8628247,29.8241444 24.0130603,29.8241444 Z M23.9084089,14 C24.9280416,14 25.7906972,14.5966385 25.7906972,15.6541046 C25.7906972,18.8808623 25.4247554,23.5175455 25.4247554,26.7442155 C25.4247554,27.58477 24.5358947,27.9372295 23.9084934,27.9372295 C23.0719584,27.9372295 22.3660263,27.58477 22.3659418,26.7442155 C22.3659418,23.5174578 22,18.8807746 22,15.6541046 C22,14.5965508 22.836535,14 23.9084089,14 Z"
                                    id="Icon-Alert"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
