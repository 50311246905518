import { KpiAPI } from "../apiKpi";

function ucFirst(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const arrayToObj = (id) => (array = []) => {
    return array.reduce((obj, next) => {
        return {
            ...obj,
            [next[id]]: next
        };
    }, {});
};

const formatAnalytics = (account) => {
    return Object.keys(account).reduce((obj, key) => {
        return {
            ...obj,
            ["analytics" + ucFirst(key)]: arrayToObj("id")(account[key].items)
        };
    }, {});
};

function getAccounts({ userId, connectionId }) {
    return KpiAPI({
        method: "GET",
        url: `/analytics/accounts`,
        query: {
            userId,
            connectionId
        }
    })
        .then(({ data }) => {
            return {
                connectionId,
                platform: "analytics",
                integrations: formatAnalytics(data)
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

const getConversionTypes = ({
    userId,
    connectionId,
    analyticsAccountId,
    analyticsViewId
}) => {
    return KpiAPI({
        method: "GET",
        url: `/analytics/sitewide/conversionType`,
        query: {
            userId,
            connectionId,
            analyticsAccountId,
            analyticsViewId
        }
    }).then(({ data = [] }) =>
        data.reduce(
            (obj, next) => ({
                ...obj,
                [next.id]: next
            }),
            {}
        )
    );
};

export default {
    getAccounts,
    getConversionTypes
};
