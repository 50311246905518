import { connect } from "react-redux";
import { operations as connectOperations } from "../../_ducks";
import IntegrateStep from "./index";
import { deleteSlackChannel, addSlackChannel, testSlackMessage, saveNotificationChannels } from "_redux/sites";
import { operations as SettingsOperations } from "features/Settings/_ducks";

import { makeSelectAccountId, makeSelectAccountName, makeSelectIntegrationIds } from "_redux/sites/selectors";
import { makeSelectUserConnections } from "_redux/users/selectors";
import { selectOnboardingCampaigns } from "features/Onboarding/ducks/selectors";
import { selectSettingsAvailableChannels } from "features/Settings/_ducks/selectors";
import { operations } from "features/Integrations/_ducks";

const { getSlackChannels } = SettingsOperations;
const { exchangeSlackCode: saveSlackToken } = connectOperations;
const { changeConnection } = operations;

const mapStateToProps = (state, { connection }) => {
    const { slack } = makeSelectUserConnections()(state);
    const campaigns = selectOnboardingCampaigns(state);
    const accountId = makeSelectAccountId()(state);
    const integrations = makeSelectIntegrationIds()(state);
    const availableChannels = selectSettingsAvailableChannels(state);
    const accountName = makeSelectAccountName()(state);

    const { slack: { [connection]: { channels = {} } = {} } = {} } = integrations;

    return {
        list: slack,
        selected: connection,
        campaigns,
        accountId,
        accountName,
        channels,
        availableChannels,
    };
};

const mapDispatchToProps = (dispatch) => ({
    saveNotificationChannels: ({ accountId }) => dispatch(saveNotificationChannels({ accountId })),
    testSlackMessage: ({ channel, accountId, accountName, connectionId }) =>
        dispatch(testSlackMessage({ channel, accountId, accountName, connectionId })),
    deleteSlackChannel: ({ channel, integrationId }) => dispatch(deleteSlackChannel({ channel, integrationId })),
    addSlackChannel: ({ channel, integrationId }) => dispatch(addSlackChannel({ channel, integrationId })),
    getSlackChannels: ({ accountId, connectionId }) => dispatch(getSlackChannels({ accountId, connectionId })),
    saveSlackAccessToken: (code) => {
        return dispatch(saveSlackToken(code)).then(({ data: id }) => {
            return dispatch(
                changeConnection({
                    platform: "slack",
                    connectionId: id,
                }),
            );
        });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrateStep);
