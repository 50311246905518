import React from "react";

export default () => (
    <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
    >
        <g
            id="🧭-Nav"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="0.1-New-Nav-(Hover)"
                transform="translate(-86.000000, -508.000000)"
                fillRule="nonzero"
            >
                <g id="Framework">
                    <g
                        id="Hover-1-(Tiny)-3-Copy-3"
                        transform="translate(58.969083, 396.000000)"
                    >
                        <g id="2" transform="translate(7.030917, 112.000000)">
                            <g
                                id="Icon-Slack-Copy"
                                transform="translate(20.000000, 0.000000)"
                            >
                                <g
                                    id="Group"
                                    transform="translate(0.000000, 10.428693)"
                                    fill="#E01E5A"
                                >
                                    <path
                                        d="M4.25806452,2.11782683 C4.25806452,3.25696095 3.32258065,4.18752122 2.17741935,4.18752122 C1.03225806,4.18752122 0.0967741935,3.25696095 0.0967741935,2.11782683 C0.0967741935,0.978692699 1.03225806,0.0481324278 2.17741935,0.0481324278 L4.25806452,0.0481324278 L4.25806452,2.11782683 Z"
                                        id="Path"
                                    ></path>
                                    <path
                                        d="M5.30645161,2.11782683 C5.30645161,0.978692699 6.24193548,0.0481324278 7.38709677,0.0481324278 C8.53225806,0.0481324278 9.46774194,0.978692699 9.46774194,2.11782683 L9.46774194,7.30008489 C9.46774194,8.43921902 8.53225806,9.36977929 7.38709677,9.36977929 C6.24193548,9.36977929 5.30645161,8.43921902 5.30645161,7.30008489 L5.30645161,2.11782683 Z"
                                        id="Path"
                                    ></path>
                                </g>
                                <g id="Group" fill="#36C5F0">
                                    <path
                                        d="M7.38709677,4.23565365 C6.24193548,4.23565365 5.30645161,3.30509338 5.30645161,2.16595925 C5.30645161,1.02682513 6.24193548,0.0962648557 7.38709677,0.0962648557 C8.53225806,0.0962648557 9.46774194,1.02682513 9.46774194,2.16595925 L9.46774194,4.23565365 L7.38709677,4.23565365 Z"
                                        id="Path"
                                    ></path>
                                    <path
                                        d="M7.38709677,5.27852292 C8.53225806,5.27852292 9.46774194,6.20908319 9.46774194,7.34821732 C9.46774194,8.48735144 8.53225806,9.41791171 7.38709677,9.41791171 L2.17741935,9.41791171 C1.03225806,9.41791171 0.0967741935,8.48735144 0.0967741935,7.34821732 C0.0967741935,6.20908319 1.03225806,5.27852292 2.17741935,5.27852292 L7.38709677,5.27852292 Z"
                                        id="Path"
                                    ></path>
                                </g>
                                <g
                                    id="Group"
                                    transform="translate(10.483871, 0.000000)"
                                    fill="#2EB67D"
                                >
                                    <path
                                        d="M5.25806452,7.34821732 C5.25806452,6.20908319 6.19354839,5.27852292 7.33870968,5.27852292 C8.48387097,5.27852292 9.41935484,6.20908319 9.41935484,7.34821732 C9.41935484,8.48735144 8.48387097,9.41791171 7.33870968,9.41791171 L5.25806452,9.41791171 L5.25806452,7.34821732 Z"
                                        id="Path"
                                    ></path>
                                    <path
                                        d="M4.20967742,7.34821732 C4.20967742,8.48735144 3.27419355,9.41791171 2.12903226,9.41791171 C0.983870968,9.41791171 0.0483870968,8.48735144 0.0483870968,7.34821732 L0.0483870968,2.16595925 C0.0483870968,1.02682513 0.983870968,0.0962648557 2.12903226,0.0962648557 C3.27419355,0.0962648557 4.20967742,1.02682513 4.20967742,2.16595925 L4.20967742,7.34821732 Z"
                                        id="Path"
                                    ></path>
                                </g>
                                <g
                                    id="Group"
                                    transform="translate(10.483871, 10.428693)"
                                    fill="#ECB22E"
                                >
                                    <path
                                        d="M2.12903226,5.23039049 C3.27419355,5.23039049 4.20967742,6.16095076 4.20967742,7.30008489 C4.20967742,8.43921902 3.27419355,9.36977929 2.12903226,9.36977929 C0.983870968,9.36977929 0.0483870968,8.43921902 0.0483870968,7.30008489 L0.0483870968,5.23039049 L2.12903226,5.23039049 Z"
                                        id="Path"
                                    ></path>
                                    <path
                                        d="M2.12903226,4.18752122 C0.983870968,4.18752122 0.0483870968,3.25696095 0.0483870968,2.11782683 C0.0483870968,0.978692699 0.983870968,0.0481324278 2.12903226,0.0481324278 L7.33870968,0.0481324278 C8.48387097,0.0481324278 9.41935484,0.978692699 9.41935484,2.11782683 C9.41935484,3.25696095 8.48387097,4.18752122 7.33870968,4.18752122 L2.12903226,4.18752122 Z"
                                        id="Path"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
