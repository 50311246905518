import { parseStringToFloat, toEnglishNumberFormat } from "lib/numbers/format";
import BingIcon from "assets/images/Integrations/Icon-Bing.svg";
import FacebookIcon from "assets/images/Integrations/Icon-Facebook.svg";
import TikTokIcon from "assets/images/Integrations/Icon-TikTok.svg";
import PinterestIcon from "assets/images/Integrations/Icon-Pinterest.svg";
import LinkedinIcon from "assets/images/Integrations/Icon-LinkedIn.svg";
import AdwordsIcon from "assets/images/Integrations/Icon-GoogleAds.svg";
import StackAdaptIcon from "assets/images/Integrations/Icon-StackAdapt.svg";
import { channelFriendlyName } from "lib/utility";

const clearBudgetFormatter = (obj = {}) =>
    Object.keys(obj).reduce((cache, key) => {
        return {
            ...cache,
            [key]: toEnglishNumberFormat(obj[key]),
        };
    }, {});

export const formatBudgetValues = ({ metrics, goals }) => {
    const activeGoals = Object.keys(metrics).filter(
        (key) => metrics[key].hasOwnProperty("active") && metrics[key].active,
    );

    const budgetVals = activeGoals.reduce((obj, key) => {
        const value = goals[key] && goals[key].hasOwnProperty("value") ? goals[key].value : 0;

        return {
            ...obj,
            [key]: toEnglishNumberFormat(value),
        };
    }, {});

    return clearBudgetFormatter(budgetVals);
};

export const formatGoals = (budgets) =>
    Object.keys(budgets).reduce(
        (cache, key) => ({
            ...cache,
            [key]: {
                value: parseStringToFloat(budgets[key]),
            },
        }),
        {},
    );

const ICONS_INFO = {
    bing: BingIcon,
    facebook: FacebookIcon,
    tiktok: TikTokIcon,
    adwords: AdwordsIcon,
    linkedin: LinkedinIcon,
    pinterest: PinterestIcon,
    stackAdapt: StackAdaptIcon,
};

export const getIcon = (platform) => {
    return ICONS_INFO[platform];
};

export const PLATFORM_OPTIONS = [
    "adwords",
    "adwordsDisplay",
    "adwordsSearch",
    "adwordsYouTubeVideos",
    "adwordsSearchPartners",
    "adwordsCross",
    "facebook",
    "tiktok",
    "pinterest",
    "linkedin",
    "bing",
    "stackAdapt",
].map((platform) => {
    return {
        key: `${platform}Budget`,
        platform,
        name: channelFriendlyName(platform),
    };
});

export const getInitialMetrics = ({ platformOptionsPermissions, metrics, goals }) =>
    platformOptionsPermissions.reduce((prev, { key, platform, name }) => {
        const { [key]: { active = null } = {} } = metrics;
        const { value } = goals[key] || {};
        const isActive = active || (active === null && !!value);
        return {
            ...prev,
            [key]: {
                name,
                active: isActive,
            },
        };
    }, {});

export const hasGoalChanged = ({ initialMetrics, selectedGoalMetrics }) => {
    const [initialGoalMetricsArray, selectedGoalMetricsArray] = [initialMetrics, selectedGoalMetrics].map(
        (metricData) =>
            Object.keys(metricData).filter((key) => {
                return metricData[key].active;
            }),
    );

    return (
        initialGoalMetricsArray.length !== selectedGoalMetricsArray.length ||
        !initialGoalMetricsArray.every((key) => selectedGoalMetricsArray.includes(key))
    );
};
