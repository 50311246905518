import React from "react";
import isEmpty from "lodash/fp/isEmpty";
import onboardStyles from "features/Onboarding/onboarding.module.scss";
import style from "./style.module.scss";
import MaterialUITable from "components/Table/MaterialUITable";
import {
    ADVANCED_METRICS_LIST_CELL_HEADERS,
    getMetricRows,
    generateNewAdvancedMetric,
} from "./helpers";
import { flatten } from "lodash";
import Button from "components/Button";
import { ReactComponent as IconAdd } from "assets/images/icons/Icon-Add-Small.svg";

const BigQueryAdvancedMetrics = ({
    bigQueryFields,
    bigQueryFieldsLoading,
    change,
    metrics = [],
}) => {
    if (bigQueryFieldsLoading || isEmpty(bigQueryFields)) return <></>;

    const onMetricPropChangeHandler = ({ metricId = "", newValues = {} }) => {
        change(
            "bigQueryAdvancedMetrics",
            metrics.map((metric) =>
                metric.id === metricId ? { ...metric, ...newValues } : metric
            )
        );
    };

    const rows = getMetricRows({
        fields: metrics,
        selectedMetrics: metrics.filter((metric) => metric.isSelected),
        onFieldChangeHandler: onMetricPropChangeHandler,
        isAdvancedMetric: true,
    });

    const onSelectRowHandler = (selectedRows) => {
        const selectedIds = flatten(selectedRows);
        const newRows = metrics.map((metric) => {
            const { id } = metric;
            const isSelected = selectedIds.includes(id);
            return { ...metric, isSelected };
        });
        change("bigQueryAdvancedMetrics", [...newRows]);
    };

    const onAddNewRow = () => {
        change("bigQueryAdvancedMetrics", [
            ...metrics,
            generateNewAdvancedMetric(),
        ]);
    };

    return (
        <div className={style.bigQueryMetricsWrapper}>
            <div className={onboardStyles.multiSelectWrapper}>
                <label>Advanced Metrics</label>
                <div className={style.tableHeader}>
                    <Button
                        small
                        icon={IconAdd}
                        onClick={onAddNewRow}
                        className={style.addMetric}
                    >
                        Add Metric
                    </Button>
                </div>
                <MaterialUITable
                    headCells={ADVANCED_METRICS_LIST_CELL_HEADERS}
                    showSelection
                    rows={rows}
                    selected={metrics
                        .filter((metric) => metric.isSelected)
                        .map((metric) => metric.id)}
                    setSelected={onSelectRowHandler}
                    dividers={false}
                    pagination={true}
                    defaultRowsPerPage={10}
                />
            </div>
        </div>
    );
};
export default BigQueryAdvancedMetrics;
