import React, { useEffect, useState, useMemo } from "react";
import isEmpty from "lodash/fp/isEmpty";
import { CardWarning } from "components/Warnings";
import onboardStyles from "features/Onboarding/onboarding.module.scss";
import Loading from "features/Integrations/Dependant/common/Loading";
import style from "./style.module.scss";
import MaterialUITable from "components/Table/MaterialUITable";
import { Field } from "redux-form";
import MultiSelector from "components/MultiSelector";
import { METRICS_LIST_CELL_HEADERS, getMetricRows } from "./helpers";
import { find, flatten, get, mapValues } from "lodash";

const required = (value) => (value ? undefined : "This field is required");

const Warning = () => {
    return (
        <CardWarning
            className={onboardStyles.warning}
            title="Google BigQuery Error"
            subtitle="We could not find any fields in your connection. Please choose another Google BigQuery connection."
        />
    );
};

const BigQueryMetrics = ({
    dataset,
    tableId,
    userId,
    connectionId,
    getBigQueryTableFields,
    bigQueryFields,
    bigQueryFieldsLoading,
    change,
    selectedMetrics,
}) => {
    const [fields, setFields] = useState([]);
    useEffect(() => {
        const [projectId, datasetId] = dataset.split(".");
        getBigQueryTableFields({
            userId,
            connectionId,
            projectId,
            datasetId,
            tableId,
        });
    }, [dataset, tableId]);

    useEffect(() => {
        setFields(bigQueryFields);
    }, [bigQueryFields]);

    const dateRangeFieldOptions = useMemo(
        () =>
            bigQueryFields.map((field) => {
                return {
                    value: field.name,
                    label: field.name,
                    type: field.type,
                };
            }),
        [bigQueryFields]
    );

    if (bigQueryFieldsLoading) return <Loading />;
    if (isEmpty(bigQueryFields)) return <Warning />;

    const onMetricPropChangeHandler = ({ metricId = "", newValues = {} }) => {
        const newFields = fields.map((field) => {
            return field.name === metricId ? { ...field, ...newValues } : field;
        });
        setFields(newFields);
        if (find(selectedMetrics, { id: metricId })) {
            change(
                "bigQueryBasicMetrics",
                selectedMetrics.map((metric) =>
                    metric.name === metricId
                        ? { ...metric, ...newValues }
                        : metric
                )
            );
        }
    };

    const rows = getMetricRows({
        fields,
        selectedMetrics,
        onFieldChangeHandler: onMetricPropChangeHandler,
    });

    const onSelectRowHandler = (selectedRows) => {
        const newSelectedRows = flatten(selectedRows).map((rowId) => {
            const row = find(rows, { id: rowId }) || {};
            return mapValues(row, (rowProp) => {
                return typeof rowProp === "string"
                    ? rowProp
                    : get(rowProp, ["value"], "");
            });
        });
        change("bigQueryBasicMetrics", [...newSelectedRows]);
    };

    return (
        <>
            <div className={onboardStyles.additionalIntegrationAccount}>
                <div className={onboardStyles.multiSelectWrapper}>
                    <Field
                        component={MultiSelector}
                        onChange={() => {}}
                        name="bigQueryDateRangeField"
                        placeholder="Select Data Source Date Range Dimension"
                        className={onboardStyles.multiSelect}
                        label="Date Range Dimension"
                        options={dateRangeFieldOptions}
                        darkBorder
                        validate={[required]}
                    />
                </div>
            </div>
            <div className={style.bigQueryMetricsWrapper}>
                <div className={onboardStyles.multiSelectWrapper}>
                    <label>Metrics</label>
                    <MaterialUITable
                        headCells={METRICS_LIST_CELL_HEADERS}
                        showSelection
                        rows={rows}
                        selected={selectedMetrics.map((metric) => metric.id)}
                        setSelected={onSelectRowHandler}
                        dividers={false}
                        pagination={true}
                        defaultRowsPerPage={10}
                    />
                </div>
            </div>
        </>
    );
};
export default BigQueryMetrics;
