import React from "react";
import onboardingStyles from "features/Onboarding/onboarding.module.scss";
import { Field } from "redux-form";
import MultiSelector from "components/MultiSelector";
import Connect from "features/Integrations/Dependant/ConnectButton";
import SearchConsoleConnect from "features/Integrations/Connections/SearchConsoleConnect/container";

const ConnectButton = ({
    changeConnection,
    label,
    list,
    loading,
    onAdd,
    platform,
    selected,
    disabled,
}) => {
    return (
        <div className={`${onboardingStyles.connectButtonWrapper} ${onboardingStyles.formInputs}`}>
            <Connect
                selected={selected}
                list={list}
                changeConnection={({ connectionId }) =>
                    changeConnection({ connectionId, platform })
                }
                loading={loading}
                onAdd={onAdd}
                label={label}
                disabled={disabled}
            />
        </div>
    );
};

export default ({
    options = [],
    isLoading,
    change,
    integrationId,
    changeConnection,
    connections,
    exchangeSearchConsoleCode,
    list,
}) => {
    const selected = connections?.searchConsole;
    return (
        <div
            className={`${onboardingStyles.additionalIntegrationFields} ${onboardingStyles.formInputs}`}
        >
            <div className={onboardingStyles.formInputs}>
                <SearchConsoleConnect
                    exchangeSearchConsoleCode={exchangeSearchConsoleCode}
                    component={({ onAdd, loading, label, platform, accountId }) =>
                        ConnectButton({
                            list,
                            selected,
                            changeConnection,
                            onAdd,
                            loading,
                            label,
                            platform,
                            accountId,
                            disabled: isLoading || !!integrationId,
                        })
                    }
                />
            </div>
            {selected && (
                <div>
                    <Field
                        component={MultiSelector}
                        name='searchConsoleAccount'
                        type='text'
                        label='Select Your Google Search Console Account'
                        className={onboardingStyles.multiSelect}
                        options={options}
                        darkBorder
                        isLoading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            )}
        </div>
    );
};
