import React from "react";
import Analytics from "assets/images/Integrations/JSX/Logo-Analytics-No-Border";
import Ads from "assets/images/Integrations/JSX/Logo-Ads";
import Facebook from "assets/images/Integrations/JSX/Logo-Facebook";
import Bing from "assets/images/Integrations/JSX/Logo-Bing";
import Linkedin from "assets/images/Integrations/JSX/Logo-Linkedin";
import Shopify from "assets/images/Integrations/JSX/Logo-Shopify";
import Klaviyo from "assets/images/Integrations/JSX/Logo-Klaviyo";
import TikTok from "assets/images/Integrations/JSX/Logo-TikTok";
import Pinterest from "assets/images/Integrations/JSX/Logo-Pinterest";
import SearchConsole from "assets/images/Integrations/JSX/Logo-Google";
import StackAdapt from "assets/images/Integrations/JSX/Logo-StackAdapt";
import { ReactComponent as AnalyticsV4 } from "assets/images/Integrations/Icon-GoogleAnalytics-v4.svg";
import GoogleIcon from "assets/images/Integrations/JSX/Logo-GoogleBusinessProfile";
import BigQuery from "assets/images/Integrations/JSX/Logo-BigQuery";
import Maropost from "assets/images/Integrations/JSX/Logo-Maropost";
import DataStudio from "assets/images/Integrations/JSX/Logo-DataStudio";
import Slack from "assets/images/Integrations/JSX/Logo-Slack";
import { isEmpty } from "lodash";

export const getPlatforms = (connectedIntegrations = []) => {
    return [
        {
            key: "analytics",
            platformName: "Universal Analytics",
            platformIntegration: "analytics",
            icon: Analytics,
            info: () => (
                <span>
                    While not required, Google Analytics is a <strong>recommended integration</strong> that is needed
                    for some features to function.
                </span>
            ),
        },
        {
            key: "adwords",
            platformName: "Google Ads",
            platformIntegration: "googleAdsIntegration",
            icon: Ads,
            info: () => (
                <span>
                    Connect your <strong>Google Ads</strong> account and see your Search, Display, Video and Shopping
                    campaigns in one place.
                </span>
            ),
        },
        {
            key: "facebook",
            platformName: "Meta Ads",
            platformIntegration: "facebookIntegration",
            icon: Facebook,
            info: () => (
                <span>
                    Connect your <strong>Meta Ads</strong> Account to collect campaign performance data.
                </span>
            ),
        },
        {
            key: "bing",
            platformName: "Microsoft Advertising",
            platformIntegration: "microsoftIntegration",
            icon: Bing,
            info: () => (
                <span>
                    Connect <strong>Microsoft Advertising</strong> and view Search campaign metrics.
                </span>
            ),
        },
        {
            key: "linkedin",
            platformName: "Linkedin Ads",
            platformIntegration: "linkedinIntegration",
            icon: Linkedin,
            info: () => (
                <span>
                    Connect <strong>LinkedIn</strong> Account and view Search campaign metrics.
                </span>
            ),
        },
        {
            key: "shopify",
            platformName: "Shopify",
            platformIntegration: "shopifyIntegration",
            icon: Shopify,
            info: () => (
                <span>
                    Connect your <strong>Shopify</strong> online store and receive real-time alerts directly into your
                    companies work-flow.
                </span>
            ),
        },
        {
            key: "tiktok",
            platformName: "TikTok Ads",
            platformIntegration: "tiktokIntegration",
            icon: TikTok,
            info: () => (
                <span>
                    Connect your <strong>TikTok Ads</strong> Account to collect campaign performance data.
                </span>
            ),
        },
        {
            key: "pinterest",
            platformName: "Pinterest Ads",
            platformIntegration: "pinterestIntegration",
            icon: Pinterest,
            info: () => (
                <span>
                    Connect your <strong>Pinterest</strong> online store and receive real-time alerts directly into your
                    companies work-flow.
                </span>
            ),
        },
        {
            key: "analyticsV4",
            platformName: "Google Analytics 4",
            platformIntegration: "analyticsV4Integration",
            icon: AnalyticsV4,
            info: () => (
                <span>
                    Connect <strong>Google Analytics 4</strong> and view metrics.
                </span>
            ),
        },
        {
            key: "googleBusinessProfile",
            platformName: "Google Business Profile",
            platformIntegration: "googleBusinessProfileIntegration",
            icon: GoogleIcon,
            info: () => <span>Connect your Google Business Profile locations.</span>,
        },
        {
            key: "klaviyo",
            platformName: "Klaviyo",
            platformIntegration: "klaviyoIntegration",
            icon: Klaviyo,
            info: () => (
                <span>
                    Connect <strong>Klaviyo</strong> Account to collect campaign performance data.
                </span>
            ),
        },
        {
            key: "searchConsole",
            platformName: "Search Console",
            platformIntegration: "searchConsole",
            icon: SearchConsole,
            info: () => (
                <span>
                    Connect <strong>Search Console</strong> Account to collect campaign performance data.
                </span>
            ),
        },
        {
            key: "maropost",
            platformName: "Maropost",
            platformIntegration: "maropost",
            icon: Maropost,
            info: () => (
                <span>
                    Connect <strong>Maropost</strong> Account to collect campaign performance data.
                </span>
            ),
        },
        {
            key: "organicFacebook",
            platformName: "Organic Facebook",
            platformIntegration: "organicFacebook",
            icon: Facebook,
            info: () => (
                <span>
                    Connect <strong>Organic Facebook</strong> Account to collect campaign performance data.
                </span>
            ),
        },
        {
            key: "slack",
            platformName: "Slack",
            platformIntegration: "slack",
            icon: Slack,
            info: () => (
                <span>
                    Connect <strong>Slack</strong> Account to receive real-time alerts within selected public channels.
                </span>
            ),
        },
        {
            key: "bigQuery",
            platformName: "Google BigQuery",
            platformIntegration: "bigQuery",
            icon: BigQuery,
            info: () => (
                <span>
                    Connect <strong>Google BigQuery</strong> Dataset to collect campaign performance data.
                </span>
            ),
        },
        {
            key: "dataStudio",
            platformName: "Google Data Studio",
            platformIntegration: "dataStudio",
            icon: DataStudio,
            info: () => (
                <span>
                    Connect <strong>Google Data Studio</strong> Account.
                </span>
            ),
        },
        {
            key: "stackAdapt",
            platformName: "StackAdapt",
            platformIntegration: "stackAdapt",
            icon: StackAdapt,
            info: () => (
                <span>
                    Connect <strong>StackAdapt</strong> Account to collect campaign performance data.
                </span>
            ),
        },
    ].filter(({ key }) => !connectedIntegrations.hasOwnProperty(key) || isEmpty(connectedIntegrations[key]));
};
