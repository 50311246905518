import React from "react";
import Input from "components/RenderInput";
import { Field } from "redux-form";
import style from "./style.module.scss";
import HelpIcon from "assets/images/icons/JSX/Icon-Help";
import isEqual from "lodash/isEqual";
import { Controller } from "react-hook-form";

export const checkRegex = (regex) => {
    if (!regex) return false;
    let isValid = true;
    try {
        new RegExp(regex);
    } catch (e) {
        isValid = false;
    }
    return isValid;
};

export const checkRegexMatched = (regex, label) => {
    if (!regex || !label) return false;

    const isValid = checkRegex(regex);
    const regexParse = isValid ? new RegExp(regex.toLowerCase()) : { test: () => false };

    return regexParse.test(label.toLowerCase());
};

export default React.memo(({ disabled, platform, className = "", placeholder = "Your Regex", darkBorder }) => {
    return (
        <div className={className}>
            <div className={style.regexFilterContainer}>
                <div className={style.infographic}>
                    <HelpIcon />
                    <a
                        rel="noopener"
                        target="_blank"
                        href="https://medium.com/factory-mind/regex-tutorial-a-simple-cheatsheet-by-examples-649dc1c3f285"
                    >
                        What is this?
                    </a>
                </div>
                <Field
                    disabled={disabled}
                    component={Input}
                    className={style.regexInput}
                    name={`regex${platform}`}
                    label="Regex Campaign Selection"
                    placeholder={placeholder}
                    prefix="Regex"
                    darkBorder={darkBorder}
                />
            </div>
        </div>
    );
}, isEqual);

export const NewRegexInput = ({
    disabled,
    control,
    platform,
    className = "",
    placeholder = "Your Regex",
    darkBorder,
}) => {
    return (
        <div className={className}>
            <div className={style.regexFilterContainer}>
                <div className={style.infographic}>
                    <HelpIcon />
                    <a
                        rel="noopener"
                        target="_blank"
                        href="https://medium.com/factory-mind/regex-tutorial-a-simple-cheatsheet-by-examples-649dc1c3f285"
                    >
                        What is this?
                    </a>
                </div>
                <Controller
                    name={`regex${platform}`}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <Input
                            input={field}
                            disabled={disabled}
                            className={style.regexInput}
                            label="Regex Campaign Selection"
                            placeholder={placeholder}
                            prefix="Regex"
                            darkBorder={darkBorder}
                        />
                    )}
                />
            </div>
        </div>
    );
};
