import React from "react";
import onboardingStyles from "features/Onboarding/onboarding.module.scss";
import { Field } from "redux-form";
import MultiSelector from "components/MultiSelector";
import ThreeDots from "components/Loading/ThreeDots";
import CheckboxSearch from "components/SearchBox/CheckboxSearchWithField";
import GoogleBusinessProfileConnect from "features/Integrations/Connections/GoogleBusinessProfileConnect/container";
import Connect from "features/Integrations/Dependant/ConnectButton";

const ConnectButton = ({ changeConnection, label, list, loading, onAdd, platform, selected, disabled }) => {
    return (
        <div className={`${onboardingStyles.connectButtonWrapper} ${onboardingStyles.formInputs}`}>
            <Connect
                selected={selected}
                list={list}
                changeConnection={({ connectionId }) => changeConnection({ connectionId, platform })}
                loading={loading}
                onAdd={onAdd}
                label={label}
                disabled={disabled}
            />
        </div>
    );
};

export default ({
    options = [],
    locationOptions = [],
    isLoading,
    isLoadingLocation,
    selectedGoogleBusinessProfileId,
    selectedLocations = {},
    change,
    integrationId,
    changeConnection,
    connections,
    exchangeGoogleBusinessProfileCode,
    list,
}) => {
    const selected = connections?.googleBusinessProfile;
    return (
        <div className={`${onboardingStyles.additionalIntegrationFields} ${onboardingStyles.formInputs}`}>
            <div className={onboardingStyles.formInputs}>
                <GoogleBusinessProfileConnect
                    exchangeGoogleBusinessProfileCode={exchangeGoogleBusinessProfileCode}
                    component={({ onAdd, loading, label, platform, accountId }) =>
                        ConnectButton({
                            list,
                            selected,
                            changeConnection,
                            onAdd,
                            loading,
                            label,
                            platform,
                            accountId,
                            disabled: isLoading || !!integrationId,
                        })
                    }
                />
            </div>
            {selected && (
                <div>
                    <Field
                        component={MultiSelector}
                        name="googleBusinessProfile"
                        type="text"
                        label="Select Your Google Business Profile Account"
                        className={onboardingStyles.multiSelect}
                        options={options}
                        darkBorder
                        isLoading={isLoading}
                    />
                    {isLoadingLocation && (
                        <div style={{ margin: "2rem auto", width: "24px" }}>
                            <ThreeDots color="#6a80a7" />
                        </div>
                    )}
                    {!isLoadingLocation &&
                        (integrationId || selectedGoogleBusinessProfileId) &&
                        locationOptions.length > 0 && (
                            <div style={{ marginTop: "1rem" }}>
                                <CheckboxSearch
                                    component={MultiSelector}
                                    type="text"
                                    label="Google Business Profile Locations"
                                    className={onboardingStyles.multiSelect}
                                    isLoading={isLoading}
                                    search={{
                                        placeholder: "Search Business Profile Locations",
                                        accessor: "label",
                                    }}
                                    options={locationOptions.map(({ label, value }) => {
                                        const cleanValue = value.replace(/\//gi, "_");
                                        return {
                                            checked: selectedLocations[cleanValue],
                                            label: label,
                                            name: `googleBusinessProfile-locations.${cleanValue}`,
                                            id: cleanValue,
                                        };
                                    })}
                                    onChangeAll={(options, isAllSelected) => {
                                        options.forEach((option) => {
                                            change(option.name, isAllSelected);
                                        });
                                    }}
                                    selectAllTxt="Select All Locations"
                                    searchTermInText
                                    hideSelectAll
                                    itemType="Locations"
                                />
                            </div>
                        )}
                </div>
            )}
        </div>
    );
};
