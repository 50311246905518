import React, { useState, useEffect, useCallback } from "react";
import RenderInput from "components/RenderInput";
import { SimpleDrawer, DrawerHeader } from "components/Drawer";
import Button from "components/Button";
import style from "./style.module.scss";
import v from "validate.js";
import EditIcon from "assets/images/icons/JSX/Icon-Edit";

const validate = (values) =>
    v(values, {
        accountName: {
            presence: {
                message: "^Required",
            },
            length: {
                minimum: 3,
                maximum: 50,
            },
        },
    });

export default ({ accountId, toggleDrawer: toggleOpen, isOpen, name, updateAccount }) => {
    const [inputValue, onInputChange] = useState("");
    const [error, errorOnChange] = useState(false);
    const [isLoading, toggleLoading] = useState(false);

    const closeDrawer = useCallback(() => {
        toggleOpen(false);
        onInputChange("");
    }, [toggleOpen]);

    useEffect(() => {
        onInputChange(name || "");
    }, [name]);

    return (
        <SimpleDrawer open={isOpen} docked={false} width={744} openSecondary onRequestChange={closeDrawer}>
            <DrawerHeader
                title="Rename Account"
                description="Change the name of this account"
                handleClose={closeDrawer}
                icon={() => <EditIcon className={style.editIcon} />}
                centered
            />
            <div style={{ padding: "2rem 2.5rem 3rem" }}>
                <RenderInput
                    name="renameAccount"
                    label="Account Name"
                    input={{
                        onChange: (e) => {
                            onInputChange(e.target.value);
                        },
                        value: inputValue,
                    }}
                    className={`${error && style.errorInput} ${style.input}`}
                />
                {error && <div className={style.error}>{error}</div>}
                <div className={style.renameButtons}>
                    <Button
                        onClick={async () => {
                            errorOnChange(false);
                            toggleLoading(true);

                            const valid = validate({
                                accountName: inputValue,
                            });

                            if (valid) {
                                toggleLoading(false);
                                return errorOnChange(valid.accountName[0]);
                            }

                            return updateAccount({
                                accountId,
                                name: inputValue,
                            })
                                .then(() => {
                                    toggleOpen(false);
                                })
                                .finally(() => {
                                    toggleLoading(false);
                                });
                        }}
                    >
                        {isLoading ? "Saving..." : "Save"}
                    </Button>
                    <Button disabled={isLoading} onClick={() => toggleOpen(false)} secondary>
                        Cancel
                    </Button>
                </div>
            </div>
        </SimpleDrawer>
    );
};
