import TikTokConnect from "features/Integrations/Connections/TikTok";
import React, {useState} from "react";
import onboardStyles from "features/Onboarding/onboarding.module.scss";
import {isEqual} from "lodash";
import SelectConnectAccounts from "features/Integrations/Dependant/common/SelectConnectAccounts";
import TikTokIntegrate from "features/Integrations/Dependant/TikTok/TikTokIntegrate";
import TikTokCampaigns from "features/Integrations/Dependant/TikTok/TikTokCampaigns";

export const TIKTOK_CAMPAIGNS = "tiktok-campaigns";

export default React.memo(
    ({
        userId,
        list,
        selected,
        changeConnection,
        saveTiktokAccessToken,
        getTiktokAccounts,
        getTiktokCampaigns,
        options,
        selectedIntegrations,
        integrations,
        campaigns,
        selectedCampaignLength,
        savedCampaigns,
        change,
        currentAndFuture = false,
        savedRegex,
        integrationId,
        alreadyIntegrated = [],
    }) => {
        const [isCampaignsLoading, toggleCampaignsLoading] = useState(false);
        const [isIntegrateLoading, toggleIntegrateLoading] = useState(false);

        return (
            <div className={onboardStyles.formInputs}>
                <TikTokConnect
                    exchangeCode={saveTiktokAccessToken}
                    component={({ onAdd, loading, label, platform }) =>
                        SelectConnectAccounts({
                            list,
                            selected,
                            changeConnection,
                            onAdd,
                            loading,
                            label,
                            platform,
                            disabled:
                                isCampaignsLoading ||
                                isIntegrateLoading ||
                                !!integrationId,
                        })
                    }
                />
                {selected && (
                    <div
                        className={`${onboardStyles.formInputs} ${onboardStyles.noMargin}`}
                    >
                        <TikTokIntegrate
                            selectedIntegrations={selectedIntegrations}
                            integrations={integrations}
                            getTikTokAccounts={getTiktokAccounts}
                            options={options}
                            selected={selected}
                            userId={userId}
                            change={change}
                            isLoading={isIntegrateLoading}
                            toggleLoading={toggleIntegrateLoading}
                            isEditing={!!integrationId}
                            alreadyIntegrated={alreadyIntegrated}
                        />
                    </div>
                )}
                {selectedIntegrations &&
                    selectedIntegrations.tiktok &&
                    integrations && (
                        <TikTokCampaigns
                            integrations={integrations}
                            options={options}
                            campaigns={campaigns}
                            savedCampaigns={savedCampaigns}
                            selectedCampaignLength={selectedCampaignLength}
                            connectionId={selected}
                            userId={userId}
                            regex={selectedIntegrations.regextiktok}
                            getTikTokCampaigns={getTiktokCampaigns}
                            selected={selectedIntegrations.tiktok.value}
                            change={change}
                            isLoading={isCampaignsLoading}
                            toggleLoading={toggleCampaignsLoading}
                            currentAndFuture={currentAndFuture}
                            savedRegex={savedRegex}
                        />
                    )}
            </div>
        );
    },
    isEqual
);
