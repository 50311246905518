import React, { useEffect, useState } from "react";
import OrganicFacebookConnect from "../../Connections/OrganicFacebookConnect/container";
import Connect from "../ConnectButton";
import { Field } from "redux-form";
import isEmpty from "lodash/fp/isEmpty";
import MultiSelector from "components/MultiSelector";
import onboardingStyles from "features/Onboarding/onboarding.module.scss";
import Loading from "components/Loading/ThreeDots";
import { CardWarning } from "components/Warnings";
import RenderInput from "components/RenderInput";
import { TooltipDark } from "components/Tooltip";
import { isEqual } from "lodash";

const required = (value) => (value ? undefined : "This field is required");

const ConnectButton = ({ onAdd, loading, label, platform, selected, list, changeConnection, disabled }) => {
    return (
        <Connect
            selected={selected}
            list={list}
            changeConnection={({ connectionId }) => changeConnection({ connectionId, platform })}
            loading={loading}
            onAdd={onAdd}
            label={label}
            disabled={disabled}
        />
    );
};

const OrganicFacebookIntegrate = ({
    options,
    selected,
    userId,
    getOrganicFacebookPages,
    integrations,
    isLoading,
    toggleLoading,
    selectedIntegrations,
    isEditing = false,
    alreadyIntegrated,
}) => {
    useEffect(() => {
        if (isEmpty(selected) || integrations) return;
        toggleLoading(true);
        getOrganicFacebookPages({ userId, connectionId: selected }).finally(() => toggleLoading(false));
    }, [selected, userId]);

    if (isLoading) return <Loading style={{ margin: "2rem auto 0 auto" }} color="#BCCFDC" />;
    if (!isEmpty(selected) && isEmpty(integrations) && !isLoading)
        return (
            <CardWarning
                className={onboardingStyles.warning}
                title="Organic Facebook Error"
                subtitle="We could not find any accounts in your connection. Please choose another Organic Facebook connection."
            />
        );
    if (!integrations) return null;

    return (
        <div className={onboardingStyles.additionalIntegrationAccount}>
            <Field
                component={RenderInput}
                className={onboardingStyles.renderInput}
                name="organicFacebookIntegrationName"
                label="Connection Name"
                darkBorder
                large
                type="text"
                placeholder={
                    selectedIntegrations?.organicFacebook?.label
                        ? `Default: ${selectedIntegrations?.organicFacebook?.label}`
                        : ""
                }
                tip="Name that will apearin the app forthis account."
            />
            <div
                className={onboardingStyles.multiSelectWrapper}
                data-for="organic-facebook-field-account"
                data-tip="This field isn't editible. You may remove this integration and add a new one."
                data-tip-disable={!isEditing}
            >
                <Field
                    component={MultiSelector}
                    name="organicFacebook"
                    className={onboardingStyles.multiSelect}
                    label={options["organicFacebook-page"].humanReadable}
                    options={Object.keys(integrations.organicFacebook || {}).map((id) => {
                        const disable = alreadyIntegrated.includes(id.toString());
                        const integration = integrations.organicFacebook[id];
                        return {
                            value: integration.id,
                            label: `${integration.name}${disable ? " (In Use)" : ""} - ${integration.id}`,
                            currency: integration.currency,
                            disabled: disable,
                            pageAccessToken: integration.accessToken,
                        };
                    })}
                    darkBorder
                    validate={[required]}
                    disabled={isEditing}
                />
                <TooltipDark id="organic-facebook-field-account" proximity={34} />
            </div>
        </div>
    );
};

export default React.memo(
    ({
        userId,
        list,
        selected,
        changeConnection,
        saveOrganicFacebookAccessToken,
        getOrganicFacebookPages,
        options,
        selectedIntegrations,
        integrations,
        campaigns,
        selectedCampaignLength,
        savedCampaigns,
        change,
        currentAndFuture = false,
        savedRegex,
        integrationId,
        alreadyIntegrated = [],
    }) => {
        const [isIntegrateLoading, toggleIntegrateLoading] = useState(false);

        return (
            <>
                <div className={onboardingStyles.formInputs}>
                    <OrganicFacebookConnect
                        saveOrganicFacebookAccessToken={saveOrganicFacebookAccessToken}
                        component={({ onAdd, loading, label, platform, accountId }) =>
                            ConnectButton({
                                list,
                                selected,
                                changeConnection,
                                onAdd,
                                loading,
                                label,
                                platform,
                                accountId,
                                disabled: isIntegrateLoading || !!integrationId,
                            })
                        }
                    />
                </div>
                {selected && (
                    <div className={`${onboardingStyles.formInputs} ${onboardingStyles.noMargin}`}>
                        <OrganicFacebookIntegrate
                            integrations={integrations}
                            getOrganicFacebookPages={getOrganicFacebookPages}
                            options={options}
                            selected={selected}
                            userId={userId}
                            change={change}
                            isLoading={isIntegrateLoading}
                            toggleLoading={toggleIntegrateLoading}
                            selectedIntegrations={selectedIntegrations}
                            isEditing={!!integrationId}
                            alreadyIntegrated={alreadyIntegrated}
                        />
                    </div>
                )}
            </>
        );
    },
    isEqual,
);
