import React, { useEffect, useState } from "react";
import AdwordsConnect from "features/Integrations/Connections/AdwordsConnect/container";
import Connect from "features/Integrations/Dependant/ConnectButton";
import { Field } from "redux-form";
import isEmpty from "lodash/fp/isEmpty";
import onboardingStyles from "features/Onboarding/onboarding.module.scss";
import MultiSelector from "components/MultiSelector";
import Loading from "components/Loading/ThreeDots";
import { CardWarning } from "components/Warnings";
import Ads from "assets/images/Integrations/JSX/Logo-Ads";
import CheckboxSearch from "components/SearchBox/CheckboxSearchWithField";
import RegexInput from "features/Integrations/Dependant/RegexInput/container";
import { checkRegex } from "features/Integrations/Dependant/RegexInput";
import { campaignFetchDataToReduxFormData, hasFormCampaigns } from "../util";
import RenderInput from "components/RenderInput";
import { TooltipDark } from "components/Tooltip";
import { isEqual } from "lodash";

const required = (value) => (value ? undefined : "This field is required");

const ConnectButton = ({
    onAdd,
    loading,
    label,
    platform,
    selected,
    list,
    changeConnection,
    error,
    disabled = false,
}) => {
    return (
        <>
            {error && (
                <div className={onboardingStyles.additionalIntegrationError}>
                    Something went wrong with your Google Ads connection. Make sure you have your adblocker turned off
                    and try again. If this problem persists please contact support.
                </div>
            )}
            <Connect
                selected={selected}
                list={list}
                changeConnection={({ connectionId }) => changeConnection({ connectionId, platform })}
                loading={loading}
                onAdd={onAdd}
                label={label}
                disabled={disabled}
            />
        </>
    );
};

const AdwordsIntegrate = ({
    options,
    selected,
    userId,
    getAdwordsAccounts,
    integrations,
    change,
    isLoading,
    toggleLoading,
    selectedIntegrations,
    isEditing,
    alreadyIntegrated,
}) => {
    useEffect(() => {
        if (isEmpty(selected) || integrations) return;
        toggleLoading(true);
        /**
         * Get Adwords accounts and change from here
         */
        getAdwordsAccounts({ userId, connectionId: selected }).finally(() => toggleLoading(false));
    }, [selected, userId]);

    if (isLoading) return <Loading style={{ margin: "2rem auto 0 auto" }} color="#BCCFDC" />;

    if (!isEmpty(selected) && isEmpty(integrations) && !isLoading)
        return (
            <CardWarning
                className={onboardingStyles.warning}
                title="Google Ads Error"
                subtitle="We could not find any accounts in your connection. Please choose another Google Ads connection."
            />
        );

    if (!integrations) return null;
    return (
        <div className={onboardingStyles.additionalIntegrationAccount}>
            <Field
                component={RenderInput}
                className={onboardingStyles.renderInput}
                name="adwordsIntegrationName"
                label="Connection Name"
                darkBorder
                large
                type="text"
                placeholder={
                    selectedIntegrations?.adwords?.label ? `Default: ${selectedIntegrations?.adwords?.label}` : ""
                }
                tip="Name that will apearin the app forthis Ads account."
            />
            <div
                className={onboardingStyles.multiSelectWrapper}
                data-for="adwords-field-account"
                data-tip="This field isn't editible. You may remove this integration and add a new one."
                data-tip-disable={!isEditing}
            >
                <Field
                    component={MultiSelector}
                    onChange={() => {
                        change("adwords-campaigns", { _all: true });
                    }}
                    name="adwords"
                    placeholder="Select Google Ads Account"
                    className={onboardingStyles.multiSelect}
                    label={options.adwords.humanReadable}
                    options={Object.keys(integrations.adwords).map((id) => {
                        const disable = alreadyIntegrated.includes(id.toString());
                        return {
                            value: integrations.adwords[id].id,
                            label: `${integrations.adwords[id].name}${disable ? " (In Use)" : ""}`,
                            currency: integrations.adwords[id].currency,
                            disabled: disable,
                        };
                    })}
                    darkBorder
                    validate={[required]}
                    disabled={isEditing}
                />
            </div>
            <TooltipDark id="adwords-field-account" proximity={34} />
        </div>
    );
};

const AdwordsCampaigns = ({
    options,
    selected,
    getAdwordsCampaigns,
    userId,
    regex,
    campaigns,
    selectedCampaignLength,
    savedCampaigns,
    connectionId,
    change,
    isLoading,
    toggleLoading,
    currentAndFuture = false,
    savedRegex,
}) => {
    const [regexEnabled, toggleRegex] = useState(false);

    useEffect(() => {
        const formCampaigns = hasFormCampaigns({
            selectedAccount: selected,
            reduxCampaigns: campaigns[selected],
            formCampaigns: selectedCampaignLength,
        });

        if (formCampaigns) {
            return change("adwords-campaigns", formCampaigns);
        }
        toggleLoading(true);
        getAdwordsCampaigns({
            userId,
            connectionId: connectionId,
            adwordsId: selected,
        })
            .then((data) => {
                const campaigns = campaignFetchDataToReduxFormData(data.payload.campaigns, savedCampaigns, savedRegex);
                change("adwords-campaigns", campaigns);
                return data;
            })
            .finally(() => toggleLoading(false));
    }, [selected, userId, connectionId, savedRegex]);

    useEffect(() => {
        if (savedRegex) {
            change("regexadwords", savedRegex);
        }
        toggleRegex(!currentAndFuture);
    }, [currentAndFuture]);

    if (isLoading) return <Loading style={{ margin: "2rem auto 0 auto" }} color="#BCCFDC" />;

    if (selected && isEmpty(campaigns[selected]) && !isLoading)
        return (
            <CardWarning
                className={onboardingStyles.warning}
                title="Google Ads Campaigns Warning"
                subtitle="We have connected your Google Ads account successfully, but did not find any campaigns. If you are currently only running video ad campaigns, there is a known limitation in the Google Ads API with regards to providing campaigns at this stage of the connection process."
            />
        );

    if (!campaigns || !campaigns[selected]) return null;

    const isValid = checkRegex(regex);
    const regexParse = isValid ? new RegExp(regex.toLowerCase()) : { test: () => false };

    const CampaignsSelected = () => (
        <p className={onboardingStyles.campaignsSelected}>
            <Ads />
            <strong>{currentAndFuture ? Object.keys(campaigns[selected]).length : selectedCampaignLength}</strong>{" "}
            Campaigns Selected
        </p>
    );

    return (
        <div>
            <p className={onboardingStyles.labelText}>{options["adwords-campaigns"].humanReadable}</p>
            <CheckboxSearch
                className={onboardingStyles.checkboxSearch}
                search={{
                    placeholder: "Search Google Ads Campaigns",
                    accessor: "label",
                }}
                options={Object.keys(campaigns[selected]).map((id) => {
                    const label = campaigns[selected][id].name;
                    const isRegexMatched = regexParse.test(label.toLowerCase());
                    const keyName = `${campaigns[selected][id].name}____${campaigns[selected][id].id}`;

                    return {
                        checked: isRegexMatched,
                        disabled: isRegexMatched,
                        label: label,
                        name: `adwords-campaigns.${keyName.replace(/[^\w\s]/gi, "")}`,
                        id: campaigns[selected][id].id,
                    };
                })}
                onChangeAll={(options, isAllSelected) => {
                    options.forEach((option) => {
                        change(option.name, isAllSelected);
                    });
                }}
                selectAllTxt="Select All Campaigns"
                searchTermInText
                selectedValueLength={selectedCampaignLength}
                hideSelectAll
                itemType="Campaigns"
                bottomBar={CampaignsSelected}
                selectAllCurrentAndFuture={
                    regex
                        ? false
                        : (value) => {
                              if (value === true) {
                                  change(`adwords-campaigns`, { _all: true });
                              } else {
                                  change(`adwords-campaigns`, {});
                              }
                          }
                }
                selectAllCurrentAndFutureDefault={currentAndFuture}
            />
            <RegexInput
                disabled={!regexEnabled}
                platform="adwords"
                regex={regex}
                className={onboardingStyles.connectRegex}
                placeholder={
                    regexEnabled ? "Type Selection" : 'To use regex, uncheck "Select All Current and Future Campaigns"'
                }
                darkBorder
            />
        </div>
    );
};

export default React.memo(
    ({
        userId,
        list,
        selected,
        changeConnection,
        exchangeAdwordsCode,
        getAdwordsAccounts,
        getAdwordsCampaigns,
        options,
        selectedIntegrations,
        savedCampaigns,
        integrations,
        campaigns,
        selectedCampaignLength,
        change,
        currentAndFuture,
        savedRegex,
        integrationId,
        alreadyIntegrated = [],
    }) => {
        const [campaignsLoading, toggleCampaignsLoading] = useState(false);
        const [integrateLoading, toggleIntegrateLoading] = useState(false);

        return (
            <>
                <div className={onboardingStyles.formInputs}>
                    <AdwordsConnect
                        exchangeAdwordsCode={exchangeAdwordsCode}
                        component={({ onAdd, loading, label, platform, accountId, error }) =>
                            ConnectButton({
                                list,
                                selected,
                                changeConnection,
                                onAdd,
                                loading,
                                label,
                                error,
                                platform,
                                accountId,
                                disabled: integrateLoading || campaignsLoading || !!integrationId,
                            })
                        }
                    />
                </div>
                {selected && (
                    <div className={`${onboardingStyles.formInputs} ${onboardingStyles.noMargin}`}>
                        <AdwordsIntegrate
                            selectedIntegrations={selectedIntegrations}
                            integrations={integrations}
                            getAdwordsAccounts={getAdwordsAccounts}
                            options={options}
                            selected={selected}
                            userId={userId}
                            change={change}
                            isLoading={integrateLoading}
                            toggleLoading={toggleIntegrateLoading}
                            isEditing={!!integrationId}
                            alreadyIntegrated={alreadyIntegrated}
                        />

                        {selectedIntegrations && selectedIntegrations.adwords && integrations && (
                            <AdwordsCampaigns
                                integrations={integrations}
                                options={options}
                                campaigns={campaigns}
                                savedCampaigns={savedCampaigns}
                                selectedCampaignLength={selectedCampaignLength}
                                connectionId={selected}
                                userId={userId}
                                regex={selectedIntegrations.regexadwords}
                                getAdwordsCampaigns={getAdwordsCampaigns}
                                selected={selectedIntegrations.adwords.value}
                                change={change}
                                isLoading={campaignsLoading}
                                toggleLoading={toggleCampaignsLoading}
                                currentAndFuture={currentAndFuture}
                                savedRegex={savedRegex}
                            />
                        )}
                    </div>
                )}
            </>
        );
    },
    isEqual,
);
