import React from "react";

export default ({ styles }) => (
    <svg width="45" height="41" viewBox="0 0 45 41" {...styles}>
        <g fill="none">
            <g transform="translate(16.25 29.09)">
                <path
                    fill="#669DF6"
                    d="M21.6666667,0 L5.93253968,0 C2.12281746,0 0,2.64675325 0,5.45454545 C0,8.05194805 1.80555556,10.9090909 5.93253968,10.9090909 L21.6666667,10.9090909 L21.6666667,0 Z"
                />
                <ellipse
                    cx="21.667"
                    cy="5.455"
                    fill="#1A73E8"
                    rx="5.417"
                    ry="5.455"
                />
            </g>
            <g transform="translate(0 14.545)">
                <path
                    fill="#669DF6"
                    d="M21.6666667,0 L5.93253968,0 C2.12281746,0 0,2.64675325 0,5.45454545 C0,8.05194805 1.80555556,10.9090909 5.93253968,10.9090909 L21.6666667,10.9090909 L21.6666667,0 Z"
                />
                <ellipse
                    cx="21.667"
                    cy="5.455"
                    fill="#1A73E8"
                    rx="5.417"
                    ry="5.455"
                />
            </g>
            <g transform="translate(16.25)">
                <path
                    fill="#669DF6"
                    d="M21.6666667,0 L5.93253968,0 C2.12281746,0 0,2.64675325 0,5.45454545 C0,8.05194805 1.80555556,10.9090909 5.93253968,10.9090909 L21.6666667,10.9090909 L21.6666667,0 Z"
                />
                <ellipse
                    cx="21.667"
                    cy="5.455"
                    fill="#1A73E8"
                    rx="5.417"
                    ry="5.455"
                />
            </g>
        </g>
    </svg>
);
