import React from "react";

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none">
            <path
                fill="#333333"
                stroke="#333333"
                strokeWidth="2"
                d="M8.00033346,1 C9.93383533,1 11.6838563,1.78273611 12.9504645,3.04873128 C14.2169136,4.31456747 15,6.0634964 15,7.99579488 C15,9.93038914 14.2167693,11.6816114 12.9499729,12.9491206 C11.6834661,14.2163401 9.93364204,15 8.00029641,15 C6.06767307,15 4.31748435,14.215997 3.05052374,12.9484174 C1.78371356,11.6809883 1,9.93007798 1,7.99579488 C1,6.06381647 1.78357629,4.31519425 3.05005539,3.04943363 C4.31712672,1.7830811 6.06752592,1 8.00033346,1 Z"
            />
            <path
                fill="#FFF"
                d="M7.74999561,10.5000029 C8.16357292,10.5000029 8.49999707,10.8364241 8.49999707,11.2500015 C8.49999707,11.6635788 8.16357292,12.0000029 7.74999561,12.0000029 C7.33641829,12.0000029 6.99999707,11.6635788 6.99999707,11.2500015 C6.99999707,10.8364241 7.33641829,10.5000029 7.74999561,10.5000029 Z M8,4 C9.37842992,4 10.5,5.12159937 10.5,6.5 C10.5,7.69971934 9.65478095,8.71631891 8.5,8.95020127 L8.5,9.75000439 C8.5,9.88819162 8.38817405,10.0000117 8.24998682,10.0000117 L7.25000439,10.0000117 C7.11181716,10.0000117 7,9.88819162 7,9.75000439 L7,8.50001465 C7,7.94874229 7.44873057,7.5 8,7.5 C8.55127529,7.5 9,7.05127529 9,6.5 C9,5.94873057 8.55127529,5.5 8,5.5 C7.44873057,5.5 7,5.94873057 7,6.5 L7,6.75001025 C7,6.88819748 6.88817698,7.00001758 6.74998975,7.00001758 L5.75000732,7.00001758 C5.61182009,7.00001758 5.5,6.88819748 5.5,6.75001025 L5.5,6.5 C5.5,5.12157593 6.62157593,4 8,4 Z"
            />
        </g>
    </svg>
);
