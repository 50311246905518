import React from "react";
import style from "./style.module.scss";

function Circle({ children, borderColor, className }) {
    return (
        <div
            className={`${style.circle} ${className}`}
            style={{ borderColor: borderColor || "#E2E9F1" }}
        >
            {children}
        </div>
    );
}

export default Circle;
