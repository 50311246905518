import style from "./style.module.scss";
import { TooltipFlexible } from "components/Tooltip";
import HelpIcon from "assets/images/icons/JSX/CircleHelpIcon";
import Button from "components/Button";
import React from "react";

const IntegrationCard = ({
    onClick,
    platform,
    icon: Icon,
    info: InfoElement,
}) => (
    <div onClick={onClick} className={style.integrationCard}>
        <TooltipFlexible className={style.help} message={<InfoElement />} thick>
            <HelpIcon className={style.helpIcon} />
        </TooltipFlexible>
        {Icon && <Icon />}
        <p>{platform}</p>
        <Button secondary medium>
            Connect
        </Button>
    </div>
);

export default IntegrationCard;
