import { connect } from "react-redux";
import IntegrateStep from "./index";
import { operations as connectOperations } from "features/Integrations/_ducks";
import { change, formValueSelector, getFormValues } from "redux-form";
import { actions, operations } from "features/Onboarding/ducks";
import { makeSelectGetIntegrationIdList, makeSelectIntegrationIds } from "_redux/sites/selectors";
import { makeSelectUserId, makeSelectUserConnections } from "_redux/users/selectors";
import {
    selectOnboardingCampaigns,
    makeSelectOnboardingIntegrations,
} from "features/Onboarding/ducks/selectors";
import { makeSelectIntegrationOpts } from "features/Integrations/_ducks/selectors";

const { saveOrganicFacebookAccessToken } = connectOperations;

const { onboardingPlatformConnection } = actions;

const { getOrganicFacebookPages } = operations;

const mapStateToProps = (state, { formName, connection, integrationId }) => {
    const userId = makeSelectUserId()(state);
    const { organicFacebook } = makeSelectUserConnections()(state);
    const allIntegrations = makeSelectOnboardingIntegrations()(state);
    const { organicFacebook: organicFacebookOptions } = makeSelectIntegrationOpts()(state);
    const { form } = state;

    const {
        [`${connection}_organicFacebook`]: organicFacebookIntegrations = false,
    } = allIntegrations;
    const alreadyIntegrated = makeSelectGetIntegrationIdList("organicFacebook")(state);

    return {
        userId,
        formName,
        list: organicFacebook,
        selected: connection,
        options: organicFacebookOptions,
        selectedIntegrations: getFormValues(formName)({ form }),
        integrations: organicFacebookIntegrations,
        integrationId,
        alreadyIntegrated: formName === "onboarding" ? [] : alreadyIntegrated,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getOrganicFacebookPages: ({ userId, connectionId }) =>
        dispatch(getOrganicFacebookPages({ userId, connectionId })),
    saveOrganicFacebookAccessToken: (code) => {
        return dispatch(saveOrganicFacebookAccessToken(code)).then(({ id }) => {
            dispatch(
                onboardingPlatformConnection({
                    platform: "organicFacebook",
                    connectionId: id,
                })
            );
        });
    },
    change: (formName, ...args) => dispatch(change(formName, ...args)),
});

const mergeProps = ({ formName, ...stateProps }, { change, ...dispatchProps }, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    change: (...args) => {
        return change(formName, ...args);
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IntegrateStep);
