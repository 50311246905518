import React, { useCallback } from "react";
import ShopifyConnect from "features/Integrations/Connections/ShopifyConnect/Manual";
import onboardingStyles from "features/Onboarding/onboarding.module.scss";
import { Field } from "redux-form";
import RenderInput from "components/RenderInput";
import style from "./style.module.scss";

const validateShopName = (value) => {
    if (value && (/\s/g.test(value) || value.match(/[A-Z]/))) {
        return (
            <span>
                Shop Name should not include any <strong>spaces</strong> or <strong>capital letters</strong> (e.g. if
                your Shopify shop URL is "https://my-shopify-shop.myshopify.com/", then "my-shopify-shop" is your shop
                name.
            </span>
        );
    }
    return undefined;
};

const required = (value) => (value ? undefined : "This field is required");
const currencyCheck = (value) => (value.length === 3 && !/\d/.test(value) ? undefined : "Currency code is not valid.");

const ConnectButton = () => {
    return (
        <>
            <Field
                name="shop"
                type="text"
                label="Shop Name"
                placeholder="Example: my-shopify-shop"
                component={RenderInput}
                darkBorder
                large
                validate={[required, validateShopName]}
                validateWhenActive
            />
            <Field
                name="clientId"
                type="text"
                label="API Key"
                component={RenderInput}
                validate={[required]}
                darkBorder
                large
            />
            <Field
                label="Admin API Access Token"
                name="secret"
                type="password"
                component={RenderInput}
                validate={[required]}
                darkBorder
                large
            />
            <Field
                label="Currency"
                name="currency"
                type="text"
                component={RenderInput}
                validate={[required, currencyCheck]}
                darkBorder
                large
                placeholder="i.e. USD"
            />
            <div className={style.ltvSettings}>
                <h4>LTV Settings:</h4>
                <p>
                    <span>Revenue generated from the last </span>
                    <Field
                        name="fromMonthsAgo"
                        type="text"
                        labelHidden
                        component={RenderInput}
                        darkBorder
                        placeholder="12"
                        className={style.input}
                    />
                    <span> months from customers who made a purchase between </span>
                    <Field
                        name="fromComparingMonthsAgo"
                        labelHidden
                        type="text"
                        component={RenderInput}
                        darkBorder
                        placeholder="12"
                        className={style.input}
                    />
                    <span> and </span>
                    <Field
                        name="toComparingMonthsAgo"
                        labelHidden
                        type="text"
                        component={RenderInput}
                        darkBorder
                        placeholder="24"
                        className={style.input}
                    />
                    <span> months ago.</span>
                </p>
            </div>
        </>
    );
};

export default ({}) => {
    // // temprory lock down
    // return (
    //     <div className={style.integrationText}>
    //         This integration is still on beta. Please <a href="mailto:support@hawke.ai">contact us</a> if you are interested to integrate Shopify.
    //     </div>
    // )
    const CustomComponent = useCallback(() => ConnectButton(), []);

    return (
        <div className={`${onboardingStyles.additionalIntegrationFields} ${onboardingStyles.formInputs}`}>
            <ShopifyConnect component={CustomComponent} />
        </div>
    );
};
