import { makeSelectIntegrationOpts } from "features/Integrations/_ducks/selectors";
import { selectOnboardingCampaigns } from "features/Onboarding/ducks/selectors";
import { connect } from "react-redux";
import { change, formValueSelector, getFormMeta, getFormValues } from "redux-form";
import { makeSelectIntegrationIds } from "_redux/sites/selectors";
import { operations } from "../../../Onboarding/ducks";
import IntegrateStep from "./index";

const { getStackAdaptCampaigns } = operations;

const formName = "stackAdapt";

const mapStateToProps = (state, { integrationId }) => {
    const campaigns = selectOnboardingCampaigns(state);
    const { stackAdapt: stackAdaptOptions } = makeSelectIntegrationOpts()(state);
    const integrations = makeSelectIntegrationIds()(state);
    const { form } = state;
    const selector = formValueSelector(formName);
    const selectedCampaigns = selector({ form }, "stackAdapt-campaigns") || {};
    const { isSandboxAccount, apiKey } = getFormValues(formName)({ form }) || {};
    const { stackAdapt: { [integrationId]: { campaigns: savedCampaigns = {}, regex: savedRegex = "" } = {} } = {} } =
        integrations || {};
    const regex = selector({ form }, "regexstackAdapt") || "";
    const formMetaData = getFormMeta("stackAdapt")({ form });
    const isFormActive = formMetaData?.apiKey?.active || formMetaData?.isSandboxAccount?.active;

    return {
        isSandboxAccount,
        apiKey,
        formName,
        options: stackAdaptOptions,
        selectedIntegrations: getFormValues(formName)({ form }),
        campaigns,
        savedCampaigns,
        selectedCampaignLength: Object.keys(selectedCampaigns).filter((campaign) => selectedCampaigns[campaign]).length,
        currentAndFuture: selectedCampaigns["_all"] && !regex,
        savedRegex,
        isEditing: !!integrationId,
        isFormActive,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getStackAdaptCampaigns: ({ isSandboxAccount, apiKey }) =>
        dispatch(getStackAdaptCampaigns({ isSandboxAccount, apiKey })),
    change: (formName, ...args) => dispatch(change(formName, ...args)),
});

const mergeProps = ({ formName, ...stateProps }, { change, ...dispatchProps }, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    change: (...args) => {
        return change(formName, ...args);
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IntegrateStep);
