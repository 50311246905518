import { connect } from "react-redux";
import IntegrateStep from "./index";
import { operations as connectOperations } from "features/Integrations/_ducks";

import { change, formValueSelector, getFormValues } from "redux-form";
import { actions, operations } from "features/Onboarding/ducks";
import { makeSelectGetIntegrationIdList, makeSelectIntegrationIds } from "_redux/sites/selectors";
import { makeSelectUserId, makeSelectUserConnections } from "_redux/users/selectors";
import { selectOnboardingCampaigns, makeSelectOnboardingIntegrations } from "features/Onboarding/ducks/selectors";
import { makeSelectIntegrationOpts } from "features/Integrations/_ducks/selectors";

const { exchangeBingCode } = connectOperations;

const { onboardingPlatformConnection } = actions;

const { getBingAccounts, getBingCampaigns } = operations;

const mapStateToProps = (state, { formName, connection, integrationId }) => {
    const userId = makeSelectUserId()(state);
    const { bing } = makeSelectUserConnections()(state);
    const allIntegrations = makeSelectOnboardingIntegrations()(state);
    const campaigns = selectOnboardingCampaigns(state);
    const { bing: bingOptions } = makeSelectIntegrationOpts()(state);
    const integrations = makeSelectIntegrationIds()(state);
    const { form } = state;
    const { bing: { [integrationId]: { campaigns: savedCampaigns = {}, regex: savedRegex = "" } = {} } = {} } =
        integrations || {};

    const { [`${connection}_bing`]: bingIntegrations = false } = allIntegrations;
    const selector = formValueSelector(formName);
    const selectedCampaigns = selector({ form }, "bing-campaigns") || {};
    const regex = selector({ form }, "regexbing") || "";
    const alreadyIntegrated = makeSelectGetIntegrationIdList("bing")(state);

    return {
        userId,
        formName,
        list: bing,
        selected: connection,
        options: bingOptions,
        selectedIntegrations: getFormValues(formName)({ form }),
        integrations: bingIntegrations,
        campaigns,
        savedCampaigns,
        selectedCampaignLength: Object.keys(selectedCampaigns).filter((campaign) => selectedCampaigns[campaign]).length,
        currentAndFuture: selectedCampaigns["_all"] && !regex,
        savedRegex,
        integrationId,
        alreadyIntegrated: formName === "onboarding" ? [] : alreadyIntegrated,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getBingCampaigns: ({ userId, connectionId, bingId }) =>
        dispatch(getBingCampaigns({ userId, connectionId, bingId })),
    getBingAccounts: ({ userId, connectionId }) => dispatch(getBingAccounts({ userId, connectionId })),
    exchangeBingCode: (code) => {
        return dispatch(exchangeBingCode(code)).then(({ id }) => {
            dispatch(
                onboardingPlatformConnection({
                    platform: "bing",
                    connectionId: id,
                }),
            );
        });
    },
    change: (formName, ...args) => dispatch(change(formName, ...args)),
});

const mergeProps = ({ formName, ...stateProps }, { change, ...dispatchProps }, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    change: (...args) => {
        return change(formName, ...args);
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IntegrateStep);
