import { createSelector } from "reselect";
import { ADWORDS_NETWORK_KEYS, filterNetworkCampaigns } from "lib/adwords/networks";
import defaultState from "./reducers";

export const selectConnectors = (state = {}) => state.connect || defaultState;

export const selectConnect = (state) => state.connect;

export const makeSelectCampaigns = () => createSelector(selectConnectors, ({ campaigns }) => campaigns || {});

export const makeSelectCampaignsLoading = () =>
    createSelector(selectConnectors, ({ campaignsLoading = false }) => campaignsLoading);

export const makeSelectSearchConsoleOptions = () =>
    createSelector(selectConnectors, ({ searchConsole }) => {
        return searchConsole;
    });

export const makeSelectGoogleBusinessProfileOptions = () =>
    createSelector(selectConnectors, ({ googleBusinessProfile }) => {
        return googleBusinessProfile || [];
    });

export const makeSelectGoogleBusinessProfilePermissionIssue = () =>
    createSelector(selectConnectors, ({ hasGoogleBusinessProfilePermissionIssue }) => {
        return hasGoogleBusinessProfilePermissionIssue;
    });

export const makeSelectGoogleBusinessProfileLocationsOptions = () =>
    createSelector(selectConnectors, ({ googleBusinessProfileLocations }) => {
        return googleBusinessProfileLocations || [];
    });

export const makeSelectSelectedConnections = () =>
    createSelector(selectConnectors, ({ connections }) => {
        return connections;
    });

export const makeSelectGetCampaign = (platform) =>
    createSelector(makeSelectCampaigns(), (campaigns) => {
        if (ADWORDS_NETWORK_KEYS.includes(platform)) {
            return filterNetworkCampaigns({ campaigns: campaigns["adwords"] || [], network: platform });
        }

        return campaigns[platform] || [];
    });

export const makeSelectIntegrationOpts = () => createSelector(selectConnectors, ({ integrations }) => integrations);
