import React, { useState, useEffect } from "react";
import GoogleIcon from "assets/images/Integrations/JSX/Logo-GoogleBusinessProfile";
import GoogleBusinessProfile from "features/Integrations/Dependant/GoogleBusinessProfile/container";
import Icon from "features/Onboarding/IntegrateStep/Icon";
import style from "../style.module.scss";
import { mixpanel, sendToSlack } from "components/Mixpanel";
import SubmitButtons from "../_Components/SubmitButtons";
import Warning from "components/AlertNotificationBar/Warning";

export default ({
    onCancel,
    handleSubmit,
    submitting,
    invalid,
    options,
    locationOptions,
    getGoogleBusinessProfileOptions,
    getGoogleBusinessProfileLocationsOptions,
    selectedGoogleBusinessProfileId,
    selectedLocations,
    integrationId,
    changeConnection,
    connections,
    selected,
    userId,
    form
}) => {
    const selectedTooManyLocations = Object.values(selectedLocations).filter((v) => v).length > 20;
    const selectedNoLocations = Object.values(selectedLocations).filter((v) => v).length === 0;
    const [isLoading, toggleLoading] = useState(false);
    const [isLoadingLocation, toggleLoadingLocation] = useState(false);

    useEffect(() => {
        toggleLoading(true);
        getGoogleBusinessProfileOptions({
            userId,
            connectionId: selected,
        }).finally(() => toggleLoading(false));
    }, [selected]);

    useEffect(() => {
        if (selectedGoogleBusinessProfileId || integrationId) {
            toggleLoadingLocation(true);
            getGoogleBusinessProfileLocationsOptions({
                userId,
                connectionId: selected,
                businessProfileId: selectedGoogleBusinessProfileId,
            }).finally(() => toggleLoadingLocation(false));
        }
    }, [selectedGoogleBusinessProfileId, integrationId, selected]);

    return (
        <div className={`${style.integration} ${style.narrow}`}>
            <Icon icon={GoogleIcon} />
            <h1>Connect Google Business Profile</h1>
            <p>Connect your Google Business Profile locations.</p>
            <GoogleBusinessProfile
                options={options}
                isLoading={isLoading}
                locationOptions={locationOptions}
                isLoadingLocation={isLoadingLocation}
                selectedGoogleBusinessProfileId={selectedGoogleBusinessProfileId}
                integrationId={integrationId}
                changeConnection={changeConnection}
                connections={connections}
                formName={form}
            />
            {selectedTooManyLocations && (
                <div style={{ marginTop: "1rem" }}>
                    <Warning>You cannot select more than 10 locations for this integration.</Warning>
                </div>
            )}
            {selected && selectedGoogleBusinessProfileId && !isLoadingLocation && !locationOptions.length && (
                <div style={{ marginTop: "1rem" }}>
                    <Warning>Could not find any locations connected to this account.</Warning>
                </div>
            )}

            <SubmitButtons
                invalid={invalid || submitting}
                disabled={isLoading || isLoadingLocation || selectedTooManyLocations || selectedNoLocations}
                onClick={async () => {
                    mixpanel.track("Save Integration", {
                        Platform: "googleBusinessProfile",
                    });
                    sendToSlack(`Save Integration: googleBusinessProfile`);
                    handleSubmit();
                }}
                onCancel={() => {
                    mixpanel.track("Save Integration", {
                        Platform: "googleBusinessProfile",
                        Canceled: true,
                    });
                    onCancel();
                }}
            />
        </div>
    );
};
