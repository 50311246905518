import { connect } from "react-redux";
import Component from "./";
import { selectSeenItById } from "_redux/users/selectors";
import { updateSeenIt } from "_redux/users";
import moment from "moment";

const mapStateToProps = (state, { it = "" }) => {
    const seenIt = selectSeenItById(state, it);

    return {
        seenIt: seenIt?.seen,
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleSeenIt: (it) => {
        dispatch(updateSeenIt({ it, seen: true, updated: moment.utc(new Date()).format() }));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
