import React from "react";
import style from "./style.module.scss";
import { ReactComponent as ArrowIcon } from "assets/images/icons/Icon-Anamoly-Arrow.svg";
import PropTypes from "prop-types";

function ChangeCompare({ title, previousValue, currentValue, warn }) {
    return (
        <div className={style.changeCompareWrapper}>
            <p className={style.title}>{title}</p>
            <div className={style.changeCompare}>
                <div className={style.valueCompare}>
                    <p>Previously</p>
                    <div>{previousValue}</div>
                </div>
                <ArrowIcon />
                <div className={style.valueCompare}>
                    <p>Now</p>
                    <div>{currentValue}</div>
                </div>
            </div>
            {warn}
        </div>
    );
}

export default ChangeCompare;

ChangeCompare.propTypes = {
    title: PropTypes.string,
    previousValue: PropTypes.node,
    currentValue: PropTypes.node,
};
