import React from "react";
import style from "./style.module.scss";
import AddButton from "features/ControlPanel/AddButton";
import SeenItCard from "components/SeenItCard";
import SeenIt from "features/SeenIt/container";
import { useUpdatedRef } from "lib/hooks";

const AddIntegrationButton = ({ onClick }) => {
    const [accountSelectRef, updateAccountSelectRef] = useUpdatedRef();

    return (
        <>
            <div className={style.addButtonContainer} ref={updateAccountSelectRef}>
                <AddButton type="Integration" onClick={onClick} className={style.addButton} />
            </div>
            <SeenIt it="add_account_integration">
                <SeenItCard
                    title="Add Integration"
                    message={"Easily bring all your marketing data together."}
                    targetElement={accountSelectRef}
                    position="right-top"
                    offset={15}
                    className={style.seenIt}
                />
            </SeenIt>
        </>
    );
};

export default AddIntegrationButton;
