import { toLower } from "lodash/string";

export const getStatusTextColor = (status) => {
    switch (toLower(status)) {
        case "paused":
            return "#6A80A7";
        default:
            return "#333333";
    }
};
