import { KpiAPI } from "lib/apiKpi";
import Creators from "./actions";
import types from "./types";
import { getAnalyticsConversionTypes } from "_redux/analytics/async";
import {
    updateSiteMeasurementSetting,
    updateSiteConversionGoalTypeSetting,
    updateFacebookAttribution,
    updatePinterestAttribution,
    changeAccount,
} from "_redux/sites";
import {
    makeSelectFacebookAttribution,
    makeSelectPinterestAttribution,
    makeSelectIntegrationIds,
    makeSelectGetIntegrationId,
    makeSelectAccountId,
} from "_redux/sites/selectors";
import { makeSelectUserId } from "_redux/users/selectors";
import { selectSettingsAnalyticsConversionGoals } from "features/Settings/_ducks/selectors";
import { isEmpty } from "lodash";
import { getSites } from "features/ControlPanel/ducks/operations";
import { toCapitalize } from "features/AlertsPage/Alert/helpers";

/**
 * Facebook
 */
export const getFacebookConversionTypes = (accountId) => async (dispatch, getState) => {
    await dispatch(Creators.facebookBreakDownLoading(true));
    return KpiAPI({
        method: "GET",
        url: `/facebook/breakdown`,
        query: {
            accountId,
        },
    }).then(({ data }) => {
        const {
            sites: { selectedSite: { facebook: { conversions: savedConversions = {} } = {} } = {} } = {},
        } = getState();

        // Filter down to checked only, treat all false as empty
        const filterSavedConverions = Object.keys(savedConversions).filter((key) => {
            return Boolean(savedConversions[key]);
        });

        const updatedConversions = Object.keys(data).reduce((cache, key) => {
            const dbKey = key.split(".").join("-");
            return {
                ...cache,
                [key]: {
                    name: data[key]?.name,
                    common: data[key]?.common,
                    active: filterSavedConverions.length > 0 ? Boolean(savedConversions[dbKey]) : data[key]?.active,
                    isDefault: data[key]?.active || false,
                },
            };
        }, {});

        dispatch({
            type: types.SETTINGS_FACEBOOK_BREAKDOWN,
            payload: updatedConversions,
        });
        dispatch(Creators.facebookBreakDownLoading(false));
    });
};

export const getFacebookAttribution = () => (dispatch, getState) => {
    const state = getState();
    const attribution = makeSelectFacebookAttribution()(state);

    dispatch({
        type: types.GET_FACEBOOK_ATTRIBUTION,
        attribution,
    });
};

export const getPinterestAttribution = () => (dispatch, getState) => {
    const state = getState();
    const attribution = makeSelectPinterestAttribution()(state);

    dispatch({
        type: types.GET_PINTEREST_ATTRIBUTION,
        attribution,
    });
};

export const saveFacebookConversion = (accountId, conversions) => {
    return KpiAPI({
        method: "PUT",
        url: `/facebook/breakdown`,
        data: {
            accountId,
            conversions,
        },
    }).then(({ data }) => {
        return data;
    });
};

/**
 * Adwords
 */
export const getAdwordsConversions = () => (dispatch, getState) => {
    const state = getState();
    const { sites: { selectedSite: { adwords: { conversions: savedConversions = {} } = {} } = {} } = {} } = state;
    const adwordsIntegrations = makeSelectGetIntegrationId("adwords")(state);

    const conversions = Object.keys(adwordsIntegrations).reduce((obj, integration) => {
        const conversionObj = [
            "Purchase",
            "Add to cart",
            "Begin checkout",
            "Subscribe",
            "Contact",
            "Submit lead form",
            "Book appointment",
            "Sign-up",
            "Request quote",
            "Get directions",
            "Outbound click",
            "Phone call leads",
            "Page view",
            "Other",
            "none",
        ].reduce((cache, conv) => {
            const conversionKey = conv.toLowerCase().replaceAll(" ", "_");
            return {
                ...cache,
                [`${integration}-${conversionKey}`]: {
                    active: savedConversions[integration]
                        ? Boolean(savedConversions[integration][conversionKey])
                        : false,
                    name: conv,
                    integrationId: integration,
                    id: conversionKey,
                },
            };
        }, {});

        return {
            ...obj,
            ...conversionObj,
        };
    }, {});

    dispatch({
        type: types.GET_ADWORDS_CONVERSIONS,
        conversions,
    });
};

export const saveAdwordsConversion = (accountId, conversions) => {
    return KpiAPI({
        method: "PUT",
        url: `/adwords/breakdown`,
        data: {
            accountId,
            conversions,
        },
    }).then(({ data }) => {
        return data;
    });
};

/**
 * AnalyticsV4
 */
export const getAnalyticsV4Conversions = () => async (dispatch, getState) => {
    const state = getState();
    const accountId = makeSelectAccountId()(state);

    await dispatch(Creators.analyticsV4BreakDownLoading(true));
    return KpiAPI({
        method: "GET",
        url: `/analyticsV4/breakdown`,
        query: {
            accountId,
        },
    }).then(({ data }) => {
        const {
            sites: { selectedSite: { analyticsV4: { conversions: savedConversions = {} } = {} } = {} } = {},
        } = getState();

        // Filter down to checked only, treat all false as empty
        const filterSavedConverions = Object.keys(savedConversions).filter((key) => {
            return Boolean(savedConversions[key]);
        });

        const updatedConversions = data.reduce(
            (cache, { apiName, description, integrationId }) => {
                const storedOptions = savedConversions[integrationId] || {};
                return {
                    ...cache,
                    [`${integrationId}-${apiName}`]: {
                        name: toCapitalize(apiName.replaceAll("sessionKeyEventRate:", "")),
                        tip: description,
                        active:
                            filterSavedConverions.length > 0 ? Boolean(storedOptions[apiName]) : data[apiName]?.active,
                        isDefault: data[apiName]?.active || false,
                        integrationId,
                        id: apiName,
                    },
                };
            },
            {
                ...Object.keys(savedConversions)
                    .filter((key) => savedConversions[key].none)
                    .reduce(
                        (subcache, key) => ({
                            ...subcache,
                            [`${key}-none`]: { active: true, id: "none", integrationId: key },
                        }),
                        {},
                    ),
            },
        );

        dispatch({
            type: types.SETTINGS_ANALYTICSV4_BREAKDOWN,
            payload: updatedConversions,
        });
        dispatch(Creators.analyticsV4BreakDownLoading(false));
    });
};

export const saveAnalyticsV4Conversion = (accountId, conversions) => {
    return KpiAPI({
        method: "PUT",
        url: `/analyticsV4/breakdown`,
        data: {
            accountId,
            conversions,
        },
    }).then(({ data }) => {
        return data;
    });
};

/**
 * Linkedin
 */
export const getLinkedinConversionTypes = () => async (dispatch, getState) => {
    await dispatch(Creators.linkedinBreakDownLoading(true));
    return KpiAPI({
        method: "GET",
        url: `/linkedin/breakdown`,
    }).then(({ data }) => {
        const {
            sites: { selectedSite: { linkedin: { conversions: savedConversions = {} } = {} } = {} } = {},
        } = getState();

        let updatedConversions = data;

        if (!isEmpty(savedConversions)) {
            updatedConversions = Object.keys(data).reduce((cache, key) => {
                return {
                    ...cache,
                    [key]: {
                        name: data[key]?.name,
                        common: data[key]?.common,
                        active: Boolean(savedConversions[key]),
                    },
                };
            }, {});
        }

        dispatch({
            type: types.SETTINGS_LINKEDIN_BREAKDOWN,
            payload: updatedConversions,
        });
        dispatch(Creators.linkedinBreakDownLoading(false));
    });
};

export const saveLinkedinConversion = (accountId, conversions) => {
    return KpiAPI({
        method: "PUT",
        url: `/linkedin/breakdown`,
        data: {
            accountId,
            conversions,
        },
    }).then(({ data }) => {
        return data;
    });
};

/**
 * Analytics
 */
const updateGoalSetting = ({ accountId, data }) => {
    const payload = {
        accountId,
        data,
    };

    return KpiAPI({
        method: "PUT",
        url: `/goals`,
        data: payload,
    }).then(({ data }) => data);
};

export const getConversionTypes = () => (dispatch, getState) => {
    const goalConversionTypes = getState().sites.selectedSite.goals.conversion_goal_types || [];
    const { analytics: analyticsAccounts = {} } = makeSelectIntegrationIds()(getState());

    dispatch(Creators.getConversionTypesPending({ pending: true }));
    dispatch(Creators.getConversionTypesRejected({ error: false, payload: {} }));
    //grab existing types too
    getAnalyticsConversionTypes()
        .then((data) => {
            const checkSelected = [
                ...data,
                ...Object.keys(analyticsAccounts).map((analyticsViewId) => ({ analyticsViewId, id: "none" })),
            ].map((element) => {
                return {
                    ...element,
                    label: element.name,
                    checked: goalConversionTypes.some((types) => {
                        return types.id === element.id && types.analyticsViewId === element.analyticsViewId;
                    }),
                };
            });

            dispatch(Creators.getConversionTypesFulfilled({ payload: checkSelected }));
            dispatch(Creators.setInitialConversionTypes({ payload: checkSelected }));
            dispatch(Creators.getConversionTypesPending({ pending: false }));
            return checkSelected;
        })
        .catch((error) => {
            dispatch(
                Creators.getConversionTypesRejected({
                    error: true,
                    payload: error,
                }),
            );
            dispatch(Creators.getConversionTypesPending({ pending: false }));
        });
};

const setConversionTypes = (goals) => (dispatch, getState) => {
    const state = getState();
    const { analytics: analyticsAccounts = {} } = makeSelectIntegrationIds()(state);
    const conversionGoalTypes = selectSettingsAnalyticsConversionGoals(state);

    // Get all possible goals and auto-populate with the current values
    const allPossibleGoals = [
        ...conversionGoalTypes.filter(({ id }) => id !== "none"),
        ...Object.keys(analyticsAccounts).map((analyticsViewId) => {
            const isGoalAlreadyChecked = conversionGoalTypes.find(
                ({ analyticsViewId: analyticsId, id }) => analyticsId === analyticsViewId && id === "none",
            )?.checked;

            return { analyticsViewId, id: "none", checked: isGoalAlreadyChecked };
        }),
    ];

    // Convert goals into db-friendly array
    const goalConversionTypes = allPossibleGoals.map((element) => {
        const { analyticsViewId, id } = element;
        if (goals.hasOwnProperty(`${analyticsViewId}-${id}`)) {
            return {
                ...element,
                checked: goals[`${analyticsViewId}-${id}`],
            };
        }

        return element;
    });

    // Determine what to pass to the db
    const isSelectedAll = !goalConversionTypes.find((element) => !element.checked);
    const filteredConversions = goalConversionTypes
        .filter((element) => element.checked)
        .map(({ checked, ...element }) => element);

    // REDUX: update site
    dispatch(
        updateSiteConversionGoalTypeSetting({
            conversionGoalTypes: isSelectedAll ? [] : filteredConversions,
        }),
    );

    // REDUX: update settings state
    dispatch(Creators.getConversionTypesFulfilled({ payload: goalConversionTypes }));
};

/**
 * Shared
 */
const saveConversionSettings = () => (dispatch, getState) => {
    const {
        sites: {
            selectedSite: { goals: { conversion_goal_types = [] } = {}, _id: accountId, integrations = {} } = {},
        } = {},
        kpi: {
            settings: {
                goals: {
                    conversionTypes = [],
                    facebookBreakdown = {},
                    facebookAttribution = {},
                    pinterestAttribution = {},
                    linkedinBreakdown = {},
                    adwordsBreakdown = {},
                    analyticsV4Breakdown = {},
                } = [],
            } = [],
        } = {},
    } = getState();

    let facebookSelection = facebookBreakdown;

    // If none are selected, choose defaults
    if (Object.keys(facebookBreakdown).every((key) => !facebookBreakdown[key].active)) {
        facebookSelection = Object.keys(facebookBreakdown).reduce((cache, key) => {
            const type = facebookBreakdown[key] || {};
            const { isDefault = false } = type;

            return {
                ...cache,
                [key]: {
                    ...type,
                    active: isDefault,
                },
            };
        }, {});
    }

    const facebookForDb = Object.keys(facebookSelection).reduce((cache, key) => {
        const isActive = Boolean(facebookSelection[key].active);
        const conversion = isActive ? { [key.split(".").join("-")]: isActive } : {};

        return {
            ...cache,
            ...conversion,
        };
    }, {});

    const linkedinForDb = Object.keys(linkedinBreakdown).reduce((cache, key) => {
        return {
            ...cache,
            [key]: Boolean(linkedinBreakdown[key].active),
        };
    }, {});

    const adwordsForDb = Object.keys(adwordsBreakdown).reduce((cache, key) => {
        const { id, integrationId, active = false } = adwordsBreakdown[key];

        const conversion = active ? { [id]: Boolean(active) } : {};

        return {
            ...cache,
            [integrationId]: {
                ...(cache[integrationId] || {}),
                ...conversion,
            },
        };
    }, {});

    const analyticsV4ForDb = Object.keys(analyticsV4Breakdown).reduce((cache, key) => {
        const { id, integrationId, active = false } = analyticsV4Breakdown[key];

        const conversion = active ? { [id]: Boolean(active) } : {};

        return {
            ...cache,
            [integrationId]: {
                ...(cache[integrationId] || {}),
                ...conversion,
            },
        };
    }, {});
    console.log({ analyticsV4ForDb, analyticsV4Breakdown });
    // Update init

    if (integrations.analytics) {
        dispatch(Creators.setInitialConversionTypes({ payload: conversionTypes }));
    }
    if (integrations.facebook) {
        dispatch(Creators.setInitialFacebookAttribution(facebookAttribution));
        dispatch(Creators.setInitialFacebookBreakdown(facebookBreakdown));
        dispatch(Creators.setFacebookBreakdown(facebookSelection));
    }
    if (integrations.pinterest) {
        dispatch(Creators.setInitialFacebookAttribution(pinterestAttribution));
    }
    if (integrations.linkedin) {
        dispatch(Creators.setInitialLinkedinBreakdown(linkedinBreakdown));
    }
    if (integrations.adwords) {
        dispatch(Creators.setInitialAdwordsBreakdown(adwordsBreakdown));
    }
    if (integrations.analyticsV4) {
        dispatch(Creators.setInitialAnalyticsV4Breakdown(analyticsV4Breakdown));
    }

    //Send to database
    return Promise.all([
        ...(integrations.analyticsV4 ? [saveAnalyticsV4Conversion(accountId, analyticsV4ForDb)] : []),
        ...(integrations.adwords ? [saveAdwordsConversion(accountId, adwordsForDb)] : []),
        ...(integrations.facebook ? [saveFacebookConversion(accountId, facebookForDb)] : []),
        ...(integrations.linkedin ? [saveLinkedinConversion(accountId, linkedinForDb)] : []),
        ...(integrations.facebook
            ? [dispatch(updateFacebookAttribution({ accountId, attribution: facebookAttribution }))]
            : []),
        ...(integrations.pinterest
            ? [dispatch(updatePinterestAttribution({ accountId, attribution: pinterestAttribution }))]
            : []),
        dispatch(Creators.setConversionsTouched(false)),
        ...(integrations.analytics ? [updateGoalSetting({ accountId, data: { conversion_goal_types } })] : []),
    ]);
};

const updateConversionMeasurement = (measurement, accountId) => (dispatch, getState) => {
    const payload = {
        conversion_measurement: measurement,
    };

    dispatch(updateSiteMeasurementSetting({ measurement: measurement }));
    return updateGoalSetting({ accountId, data: payload });
};

const updateConversionType = (conversionType) => (dispatch, getState) => {
    const payload = {
        selected_types: conversionType,
    };

    //dispatch(updateSiteConversionTypeSetting({ conversionType }));
    return updateGoalSetting({ data: payload });
};

const getSlackChannels = ({ accountId, connectionId }) => ({
    type: types.GET_SLACK_CHANNELS,
    promise: KpiAPI({
        method: "GET",
        url: `/slack/channels`,
        query: {
            accountId,
            connectionId,
        },
        bypassCache: true,
    }).then(({ data: { channels } }) => channels),
});

/**
 * Campaign Groups
 */
export const addCampaignGroup = ({ campaigns = {}, name = "", regex = {} }) => async (dispatch, getState) => {
    const state = getState();
    const accountId = makeSelectAccountId()(state);
    const userId = makeSelectUserId()(state);

    return await KpiAPI({
        url: `/account/${accountId}/campaignGroups`,
        method: "POST",
        data: {
            accountId,
            campaigns,
            regex,
            name,
            createdBy: userId,
        },
    }).then(async ({ data }) => {
        const { id: groupId, created, createdBy } = data;
        return Promise.all([
            dispatch(
                Creators.updateCampaignGroup({ groupId, updates: { name, campaigns, regex, created, createdBy } }),
            ),
            dispatch(changeAccount(accountId)),
            dispatch(getSites(userId)),
        ]);
    });
};

export const editCampaignGroup = ({ campaigns = {}, regex = {}, name = "", groupId }) => async (dispatch, getState) => {
    const state = getState();
    const accountId = makeSelectAccountId()(state);

    return await KpiAPI({
        url: `/account/${accountId}/campaignGroups/${groupId}`,
        method: "PUT",
        data: {
            accountId,
            groupId,
            campaigns,
            regex,
            name,
        },
    }).then(async ({ data }) => {
        const { lastUpdated = "" } = data;
        return dispatch(Creators.updateCampaignGroup({ groupId, updates: { name, campaigns, regex, lastUpdated } }));
    });
};

export const getCampaignGroupsRequest = () => (dispatch, getState) => {
    const state = getState();
    const accountId = makeSelectAccountId()(state);

    return dispatch({
        type: types.GET_CAMPAIGN_GROUPS,
        promise: KpiAPI({
            method: "GET",
            url: `/account/${accountId}/campaignGroups`,
        }),
    });
};

export const deleteCampaignGroupRequest = (groupId) => async (dispatch, getState) => {
    const state = getState();
    const accountId = makeSelectAccountId()(state);

    dispatch({
        type: types.DELETE_CAMPAIGN_GROUP,
        groupId,
    });

    return KpiAPI({
        url: `/account/${accountId}/campaignGroups/${groupId}`,
        method: "DELETE",
    });
};

export const getChangeLogs = ({ accountId, startDate, endDate }) => {
    return {
        type: types.GET_ACCOUNT_CHANGE_LOGS,
        promise: KpiAPI({
            method: "GET",
            url: `/account/${accountId}/changelog`,
            query: {
                startDate: startDate,
                endDate: endDate,
            },
        }).then(({ data } = {}) => (isEmpty(data) ? [] : data)),
    };
};

export const addAnnotation = ({ accountId, title, description, date, platform, type, userId }) => {
    const data = {
        title,
        description,
        date,
        platform,
        type,
        userId,
    };
    return {
        type: types.ADD_ACCOUNT_CHANGE_LOGS,
        meta: data,
        promise: KpiAPI({
            method: "POST",
            url: `/account/${accountId}/changelog`,
            data,
        }).then(({ data } = {}) => data),
    };
};

export const updateAnnotation = ({ id, accountId, title, description, date, oldDate, platform, type, userId }) => {
    const data = {
        id,
        title,
        description,
        date,
        oldDate,
        platform,
        type,
        userId,
    };
    return {
        type: types.UPDATE_ACCOUNT_CHANGE_LOGS,
        meta: data,
        promise: KpiAPI({
            method: "PUT",
            url: `/account/${accountId}/changelog`,
            data,
        }).then(({ data } = {}) => data),
    };
};

export const deleteAnnotation = ({ id, accountId, date }) => {
    return {
        type: types.DELETE_ACCOUNT_CHANGE_LOGS,
        meta: { date, id },
        promise: KpiAPI({
            method: "DELETE",
            url: `/account/${accountId}/changelog`,
            data: { id, date },
        }).then(({ data } = {}) => data),
    };
};

export default {
    getSlackChannels,
    updateGoalSetting,
    getConversionTypes,
    setConversionTypes,
    updateConversionMeasurement,
    updateConversionType,
    saveConversionSettings,
    getChangeLogs,
    updateAnnotation,
    addAnnotation,
};
