import React from "react";
import style from "./style.module.scss";
import Button from "components/Button";
import Wrapper from "../Wrapper";
import { ReactComponent as Checkmark } from "assets/images/icons/Icon-Checkmark.svg";
import { ReactComponent as Alert } from "assets/images/icons/Icon-Alert-BigBig.svg";
import StepContainer from "features/Dashboard/CampaignGroups/EditCampaign/StepContainer";
import Circle from "../Circle";

function FinalStatus({ isSubmitFailed, onCloseModalHandler, onButtonClick, title, message }) {
    const Icon = isSubmitFailed ? Alert : Checkmark;
    const buttonLabel = isSubmitFailed ? "Try Again" : "Close";

    const onClickHandle = () => {
        if (isSubmitFailed) {
            return onButtonClick();
        }
        onCloseModalHandler();
    };

    return (
        <StepContainer title={title} onCloseHandle={onCloseModalHandler}>
            <Wrapper message={message}>
                <div className={`${style.action} ${isSubmitFailed ? style.failed : style.success}`}>
                    <Circle borderColor={isSubmitFailed ? "#FB1427" : "#00B178"}>
                        <Icon />
                    </Circle>
                    <Button onClick={onClickHandle}>{buttonLabel}</Button>
                </div>
            </Wrapper>
        </StepContainer>
    );
}

export default FinalStatus;
