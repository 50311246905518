import React from "react";
import styles from "../style.module.scss";
import classNames from "classnames";
import { CardWarning } from "components/Warnings";
import isEqual from "lodash/isEqual";

const integrationNames = {
    analytics: "Analytics",
    adwords: "Google Ads",
    facebook: "Meta",
    bing: "Microsoft Ads",
};

export default React.memo(({ integrations = {}, className }) => {
    const integrationKeys = Object.keys(integrations).filter((key) => integrations[key] && integrations[key].currency);

    const hasMismatch = [...new Set(integrationKeys.map((key) => integrations[key].currency))].length > 1;

    return (
        hasMismatch && (
            <CardWarning
                className={classNames(styles.currencyCheck, { [className]: className })}
                title="Mismatched Currency - Heads Up!"
                subtitle={
                    <div>
                        <p>
                            We have detected a currency mismatch in your selected integrations, which means certain
                            features will not be available.
                            <br />
                            <br />
                        </p>
                        {integrationKeys.map((integration) => {
                            const { currency, name } = integrations[integration];

                            return (
                                <p>
                                    <span>
                                        <b>"{currency}"</b> is the detected {integrationNames[integration]} currency for
                                        the following integration:{" "}
                                    </span>
                                    {name}
                                </p>
                            );
                        })}
                    </div>
                }
            />
        )
    );
}, isEqual);
