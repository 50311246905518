import React from "react";

class SlackConnect extends React.Component {
    state = {
        loading: false,
        platform: "slack",
        label: "Slack"
    };

    onAdd = async (event) => {
        this.setState({ loading: true });

        // Add Client ID + Secret to DB

        this.setState({ loading: false });
    };

    render() {
        const { loading, platform, label } = this.state;

        const { component: Component } = this.props;

        return (
            <Component
                platform={platform}
                loading={loading}
                onAdd={this.onAdd}
                label={label}
            />
        );
    }
}

export default SlackConnect;
