import React from "react";
import style from "./style.module.scss";

export default ({ platforms = [] }) => {
    return (
        <div className={style.platformTooltip}>
            <span>
                <strong>Platforms</strong>
            </span>
            {platforms.map(({ name = "", icon = "", count = 0 }) => {
                return (
                    <div key={name}>
                        <img src={icon} alt={`${name} icon`} />
                        <div>
                            <strong>{count}</strong>
                            <span>{count === 1 ? "Campaign" : "Campaigns"}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
