import React from "react";
import classNames from "classnames";
import sharedStyles from "../sharedStyles.module.scss";
import { calculateGrowth, toPrettyNumber } from "lib/metricCalculations";
import { GrowthNumber } from "lib/utility";

export default ({
    actual = 0,
    showGrowth,
    metric,
    isCardNumber,
    previousPeriod = 0,
    isSmallCardNumber,
    tipPosition,
    platform,
    className = "",
}) => {
    const validActual = isFinite(actual) ? actual : 0;
    const growth = calculateGrowth(actual, previousPeriod);

    return (
        <div
            className={classNames(sharedStyles.numberFormat, {
                [className]: className,
            })}
        >
            <p className={isCardNumber ? sharedStyles.actualBigNumberFormat : sharedStyles.actualNumberFormat}>
                {toPrettyNumber(validActual, `${platform}-${metric}`)}{" "}
            </p>
            {!isNaN(growth) && showGrowth && (
                <div
                    className={`${sharedStyles.comparison} ${(isCardNumber || isSmallCardNumber) &&
                        sharedStyles.fullComparison} ${isSmallCardNumber && sharedStyles.smallCardFullComparison}`}
                >
                    {(isCardNumber || isSmallCardNumber) && (
                        <span className={sharedStyles.comparisonNum}>
                            {toPrettyNumber(previousPeriod, `${platform}-${metric}`)}
                        </span>
                    )}
                    <GrowthNumber
                        number={growth}
                        metric={metric}
                        styles={{
                            margin: isCardNumber ? "0" : isSmallCardNumber ? "0" : "0.125rem 0 0",
                        }}
                        dataTip={
                            !isCardNumber && !isSmallCardNumber
                                ? `Comparing to: ${toPrettyNumber(previousPeriod, `${platform}-${metric}`)}`
                                : null
                        }
                        tipPosition={tipPosition}
                        tipId={`${metric}-growthNumber-${isSmallCardNumber}`}
                    />
                </div>
            )}
            {isCardNumber && showGrowth && isFinite(growth) && (
                <p className={sharedStyles.comparisionText}>vs. Comparison</p>
            )}
        </div>
    );
};
