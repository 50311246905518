import React from "react";

export default () => (
    <svg
        width="14px"
        height="16px"
        viewBox="0 0 14 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="Design-Library"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="0.1-Icons"
                transform="translate(-163.000000, -1088.000000)"
                fillRule="nonzero"
            >
                <g id="Framework" transform="translate(140.000000, 60.000000)">
                    <g
                        id="Features-Copy-3"
                        transform="translate(0.000000, 880.000000)"
                    >
                        <g
                            id="0-copy"
                            transform="translate(2.000000, 128.000000)"
                        >
                            <g
                                id="Icon-GoogleAnalytics-Grey"
                                transform="translate(21.000000, 20.000000)"
                            >
                                <path
                                    d="M10.25,1.90909091 L10.25,13.9090909 C10.25,15.2518182 11.1607143,16 12.125,16 C13.0178571,16 14,15.3636364 14,13.9090909 L14,2 C14,0.769090909 13.1071429,0 12.125,0 C11.1428571,0 10.25,0.848181818 10.25,1.90909091 Z"
                                    id="Path"
                                    fill="#D5E0EC"
                                ></path>
                                <path
                                    d="M5.125,8.12844037 L5.125,13.9426606 C5.125,15.2638303 6.03571429,16 7,16 C7.89285714,16 8.875,15.3738532 8.875,13.9426606 L8.875,8.21788991 C8.875,7.00674312 7.98214286,6.25 7,6.25 C6.01785714,6.25 5.125,7.08456422 5.125,8.12844037 Z"
                                    id="Path"
                                    fill="#BCCFDC"
                                ></path>
                                <circle
                                    id="Oval"
                                    fill="#BCCFDC"
                                    cx="1.875"
                                    cy="14.125"
                                    r="1.875"
                                ></circle>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
