import Connect from "features/Integrations/Dependant/ConnectButton";
import React from "react";

const SelectConnectAccounts = ({
    onAdd,
    loading,
    label,
    platform,
    selected,
    list,
    changeConnection,
    disabled,
}) => {
    return (
        <Connect
            selected={selected}
            list={list}
            changeConnection={({ connectionId }) =>
                changeConnection({ connectionId, platform })
            }
            loading={loading}
            onAdd={onAdd}
            label={label}
            disabled={disabled}
        />
    );
};

export default SelectConnectAccounts;
