import React, { useEffect } from "react";
import isEmpty from "lodash/fp/isEmpty";
import { CardWarning } from "components/Warnings";
import onboardStyles from "features/Onboarding/onboarding.module.scss";
import { Field } from "redux-form";
import MultiSelector from "components/MultiSelector";
import { TooltipDark } from "components/Tooltip";
import Loading from "features/Integrations/Dependant/common/Loading";
import RenderInput from "components/RenderInput";

const required = (value) => (value ? undefined : "This field is required");
const currencyCheck = (value) => (value.length === 3 && !/\d/.test(value) ? undefined : "Currency code is not valid.");

const Warning = () => {
    return (
        <CardWarning
            className={onboardStyles.warning}
            title="Google BigQuery Error"
            subtitle="We could not find any dataset in your connection. Please choose another Google BigQuery connection."
        />
    );
};

const BigQueryIntegrate = ({
    options,
    connectionId,
    userId,
    getBigQueryDatasets,
    integrations,
    isLoading,
    toggleLoading,
    isEditing,
}) => {
    useEffect(() => {
        if (isEmpty(connectionId) || integrations) return;
        toggleLoading(true);
        getBigQueryDatasets({
            userId,
            connectionId,
        }).finally(() => toggleLoading(false));
    }, [connectionId, userId]);

    if (isLoading) return <Loading />;

    if (!isEmpty(connectionId) && isEmpty(integrations)) return <Warning />;

    if (!integrations) return null;

    const datasetOptions = Object.keys(integrations.bigQuery).map((id) => {
        const { [id]: integrationProject = {} } = integrations.bigQuery;
        return {
            value: integrationProject.id,
            label: integrationProject.id,
        };
    });

    return (
        <div className={onboardStyles.additionalIntegrationAccount}>
            <Field
                component={RenderInput}
                className={onboardStyles.renderInput}
                name="integrationName"
                label="Connection Name"
                darkBorder
                large
                type="text"
                placeholder=""
                tip="Name that will appearin the app forthis connection."
                validate={[required]}
            />
            <Field
                label="Currency"
                name="currency"
                type="text"
                component={RenderInput}
                validate={[required, currencyCheck]}
                darkBorder
                large
                placeholder="i.e. USD"
            />
            <div
                className={onboardStyles.multiSelectWrapper}
                data-for="bigQuery-field-dataset"
                data-tip="This field isn't editable. You may remove this integration and add a new one."
                data-tip-disable={!isEditing}
            >
                <Field
                    component={MultiSelector}
                    onChange={() => {}}
                    name="bigQuery"
                    placeholder="Select Google BigQuery Dataset"
                    className={onboardStyles.multiSelect}
                    label={options.bigQuery.humanReadable}
                    options={datasetOptions}
                    darkBorder
                    validate={[required]}
                    disabled={isEditing}
                />
            </div>
            <TooltipDark id="bigQuery-field-dataset" proximity={34} />
        </div>
    );
};
export default BigQueryIntegrate;
