import React from "react";
import style from "./style.module.scss";
import Dialog from "components/Dialog";
import alert from "assets/images/icons/Icon-Alert.svg";

export default ({ actions, isOpen = false, onClose, children }) => (
    <Dialog actions={actions} open={isOpen} onRequestClose={onClose}>
        <div className={style.dialog}>
            <div className={style.alert}>
                <img src={alert} alt="Alert icon" />
            </div>
            <div className={style.dialogText}>{children}</div>
        </div>
    </Dialog>
);
