import { connect } from "react-redux";
import Component from "./index";

const mapStateToProps = ({
    sites: {
        selectedSite: { _id: accountId }
    }
}) => ({
    accountId
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
