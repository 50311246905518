import { lowerCase, startCase } from "lodash/string";

const ADWORDS_AUTHENTICATION_SCOPE =
    "https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email";
const ADWORDS_CAMPAIGN_STATUSES = {
    UNSPECIFIED: "Unspecified",
    UNKNOWN: "Unknown",
    ENABLED: "Enabled",
    PAUSED: "Paused",
    REMOVED: "Removed",
};
const FACEBOOK_AUTHENTICATION_SCOPE =
    "ads_management, ads_read, email, business_management, read_insights, pages_read_engagement, pages_read_user_content, pages_show_list";

const ORGANIC_FACEBOOK_AUTHENTICATION_SCOPE =
    "ads_management, ads_read, email, business_management, read_insights, pages_read_engagement, pages_read_user_content, pages_show_list";

const FACEBOOK_CAMPAIGN_STATUSES = {
    ACTIVE: "Active",
    PAUSED: "Paused",
    DELETED: "Deleted",
    ARCHIVED: "Archived",
};
const LINKEDIN_AUTHENTICATION_SCOPE = "rw_ads,r_ads,r_ads_reporting,r_organization_social,r_emailaddress";
const LINKEDIN_CAMPAIGN_STATUSES = {
    ACTIVE: "Active",
    PAUSED: "Paused",
    ARCHIVED: "Archived",
    COMPLETED: "Completed",
    CANCELED: "Canceled",
    DRAFT: "Draft",
    PENDING_DELETION: "Pending Deletion",
    REMOVED: "Removed",
};
// TIKTOK_AUTHENTICATION_SCOPE is submitted and need approvals from TikTok Applications Management [https://ads.tiktok.com/marketing_api/apps]
const TIKTOK_CAMPAIGN_STATUSES = {
    ENABLE: "Enable",
    DISABLE: "Paused",
    DELETE: "Delete",
};
const PINTEREST_AUTHENTICATION_SCOPE = "ads:write,ads:read,user_accounts:read,boards:read,pins:read";
const PINTEREST_CAMPAIGN_STATUSES = {
    ACTIVE: "Active",
    PAUSED: "Paused",
    ARCHIVED: "Archived",
};
const BING_AUTHENTICATION_SCOPE = "bingads.manage";

const BING_CAMPAIGN_STATUSES = { Active: "Active", Paused: "Paused" };

// StackAdapt uses API_KEY, not need authentication scopes
const STACK_ADAPT_CAMPAIGN_STATUSES = {
    APPROVED: "Approved",
    DRAFT: "Draft",
    ENDED: "Ended",
    ERROR: "Error",
    LIVE: "Live",
    PAUSED: "Paused",
    PENDING: "Pending",
    REJECTED: "Rejected",
    RESUMED: "Resumed",
};

const PLATFORM_PAUSE_CAMPAIGN_STATUS = {
    adwords: "PAUSED",
    bing: "Paused",
    facebook: "PAUSED",
    linkedin: "PAUSED",
    pinterest: "PAUSED",
    tiktok: "DISABLE",
    stackAdapt: "PAUSED",
};

const getPlatformStatus = (platform) => {
    switch (platform) {
        case "adwords":
            return ADWORDS_CAMPAIGN_STATUSES;
        case "facebook":
            return FACEBOOK_CAMPAIGN_STATUSES;
        case "linkedin":
            return LINKEDIN_CAMPAIGN_STATUSES;
        case "tiktok":
            return TIKTOK_CAMPAIGN_STATUSES;
        case "pinterest":
            return PINTEREST_CAMPAIGN_STATUSES;
        case "bing":
            return BING_CAMPAIGN_STATUSES;
        case "stackAdapt":
            return STACK_ADAPT_CAMPAIGN_STATUSES;
        default:
            return {};
    }
};

const formatStatus = (status) => {
    return startCase(lowerCase(status));
};

const getPlatformPauseStatus = (platform) => PLATFORM_PAUSE_CAMPAIGN_STATUS[platform];

export {
    ADWORDS_AUTHENTICATION_SCOPE,
    ADWORDS_CAMPAIGN_STATUSES,
    FACEBOOK_AUTHENTICATION_SCOPE,
    FACEBOOK_CAMPAIGN_STATUSES,
    LINKEDIN_AUTHENTICATION_SCOPE,
    LINKEDIN_CAMPAIGN_STATUSES,
    TIKTOK_CAMPAIGN_STATUSES,
    PINTEREST_AUTHENTICATION_SCOPE,
    PINTEREST_CAMPAIGN_STATUSES,
    BING_AUTHENTICATION_SCOPE,
    BING_CAMPAIGN_STATUSES,
    STACK_ADAPT_CAMPAIGN_STATUSES,
    getPlatformStatus,
    formatStatus,
    ORGANIC_FACEBOOK_AUTHENTICATION_SCOPE,
    getPlatformPauseStatus,
};
