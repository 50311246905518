import React from "react";
import KlaviyoIcon from "assets/images/Integrations/JSX/Logo-Klaviyo";
import KlaviyoIntegrate from "features/Integrations/Dependant/Klaviyo/container";
import Icon from "features/Onboarding/IntegrateStep/Icon";
import style from "../style.module.scss";
import { mixpanel, sendToSlack } from "components/Mixpanel";
import SubmitButtons from "../_Components/SubmitButtons";

export default ({ onCancel, handleSubmit, submitting, disabled, invalid, reset, userId, integrationId }) => {
    return (
        <div className={`${style.integration} ${style.narrow}`}>
            <Icon icon={KlaviyoIcon} />
            <h1>Integrate Klaviyo</h1>
            <p>
                Follow the instructions in the{" "}
                <a
                    className={style.link}
                    rel="noopener noreferrer"
                    href="https://hawke.ai/support/resources/klaviyo-integration/"
                    target="_blank"
                >
                    Resource Section
                </a>{" "}
                to connect your Klaviyo account.
            </p>
            <KlaviyoIntegrate integrationId={integrationId} />

            <SubmitButtons
                invalid={invalid || submitting}
                disabled={disabled}
                onClick={async () => {
                    mixpanel.track("Save Integration", {
                        Platform: "klaviyo",
                    });
                    sendToSlack(`Save Integration: klaviyo`);
                    handleSubmit();
                }}
                onCancel={() => {
                    mixpanel.track("Save Integration", {
                        Platform: "klaviyo",
                        Canceled: true,
                    });
                    reset();
                    onCancel();
                }}
            />
        </div>
    );
};
