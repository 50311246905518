import React from "react";
import EditableCell from "../EditableCell";
import Status from "features/Dashboard/CampaignGroups/Status";

const StatusCell = ({ id, status = "", platform = "", disable, disableMessage, onEditHandle = () => {} }) => {
    if (!status) return null;
    return (
        <EditableCell
            id={id}
            iconPosition="right"
            dataTip="Edit Status"
            onEditButtonClick={onEditHandle}
            disable={disable}
            disableMessage={disableMessage}
        >
            <Status status={status} platform={platform} />
        </EditableCell>
    );
};

export default StatusCell;
