import { connect } from "react-redux";
import Component from ".";
import { operations } from "../ducks";
import { makeSelectAccounts } from "../ducks/selectors";

const { updateName } = operations;

const mapStateToProps = (state, { accountId }) => {
    const accounts = makeSelectAccounts()(state);
    const name = accounts[accountId]?.name;

    return {
        accountId,
        name,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateAccount: ({ accountId, name }) => dispatch(updateName({ accountId, name })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
