import { makeSelectIntegrationOpts } from "features/Integrations/_ducks/selectors";
import { selectOnboardingCampaigns } from "features/Onboarding/ducks/selectors";
import { connect } from "react-redux";
import { change, formValueSelector, getFormMeta, getFormValues } from "redux-form";
import { makeSelectIntegrationIds } from "_redux/sites/selectors";
import { operations } from "../../../Onboarding/ducks";
import IntegrateStep from "./index";

const { getKlaviyoCampaigns } = operations;

const formName = "klaviyo";

const mapStateToProps = (state, { integrationId }) => {
    const campaigns = selectOnboardingCampaigns(state);
    const { klaviyo: klaviyoOptions } = makeSelectIntegrationOpts()(state);
    const integrations = makeSelectIntegrationIds()(state);
    const { form } = state;
    const selector = formValueSelector(formName);
    const selectedCampaigns = selector({ form }, "klaviyo-campaigns") || {};
    const { apiKey, timezone } = getFormValues(formName)({ form });
    const { klaviyo: { [apiKey]: { campaigns: savedCampaigns = {}, regex: savedRegex = "" } = {} } = {} } =
        integrations || {};
    const regex = selector({ form }, "regexklaviyo") || "";
    const formMetaData = getFormMeta(formName)({ form });
    const isFormActive = formMetaData?.apiKey?.active;

    return {
        apiKey,
        timezone,
        formName,
        options: klaviyoOptions,
        selectedIntegrations: getFormValues(formName)({ form }),
        campaigns,
        savedCampaigns,
        selectedCampaignLength: Object.keys(selectedCampaigns).filter((campaign) => selectedCampaigns[campaign]).length,
        currentAndFuture: selectedCampaigns["_all"] && !regex,
        savedRegex,
        isEditing: !!integrationId,
        isFormActive,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getKlaviyoCampaigns: ({ apiKey }) => dispatch(getKlaviyoCampaigns({ apiKey })),
    change: (formName, ...args) => dispatch(change(formName, ...args)),
});

const mergeProps = ({ formName, ...stateProps }, { change, ...dispatchProps }, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    change: (...args) => {
        return change(formName, ...args);
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IntegrateStep);
