import React from "react";
import style from "./style.module.scss";
import Circle from "../Circle";
import { Icon } from "components/Icon/PlatformIcon";
import classNames from "classnames";

const PlatformsIcon = ({ platforms }) => {
    const platformsLength = platforms.length;

    return (
        <>
            <div className={style.loading}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className={style.platformCircleContainer}>
                {platforms.map((platform, index) => (
                    <div key={`${platform}_${index}`} style={{ transform: `translateX(${-24 * index}px)` }}>
                        <Circle
                            className={classNames({
                                [style.platformCircle]: platformsLength === 1,
                                [style.platformCircleMany]: platformsLength > 1,
                            })}
                        >
                            <Icon platform={platform} />
                        </Circle>
                    </div>
                ))}
            </div>
        </>
    );
};

export default PlatformsIcon;
