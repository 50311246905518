import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import compose from "lodash/fp/flowRight";
import Component from "./";
import v from "validate.js";
import { makeSelectIntegrationIds } from "_redux/sites/selectors";
import { makeSelectUserId } from "_redux/users/selectors";
import moment from "moment";

const validate = (values) => {
    const isValid = v(values, {
        clientId: {
            presence: { allowEmpty: false, message: "^API Key is required" },
        },
        secret: {
            presence: {
                allowEmpty: false,
                message: "^Admin API Access Token is required",
            },
        },
        shop: {
            presence: { allowEmpty: false, message: "^Shop Name is required" },
        },
        currency: {
            presence: { allowEmpty: false, message: "^Currency Code is required" },
        },
    });

    return isValid;
};

const mapStateToProps = (state, { integrationId }) => {
    const integrations = makeSelectIntegrationIds()(state);
    const {
        shopify: {
            [integrationId]: {
                clientId,
                secret,
                shop,
                currency,
                ltvSettings: { fromMonthsAgo, fromComparingMonthsAgo, toComparingMonthsAgo } = {},
            } = {},
        } = {},
    } = integrations;
    return {
        initialValues: {
            clientId,
            secret,
            shop,
            currency,
            fromMonthsAgo,
            fromComparingMonthsAgo,
            toComparingMonthsAgo,
        },
        userId: makeSelectUserId()(state),
    };
};

const mapDispatchToProps = () => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: "shopify",
        enableReinitialize: true,
        validate,
        onSubmit: async (
            { clientId, secret, shop, currency, fromMonthsAgo, toComparingMonthsAgo, fromComparingMonthsAgo },
            _,
            { updateIntegration, onCancel, accountId, userId },
        ) => {
            await updateIntegration({
                integrations: {
                    shopify: {
                        value: {
                            [clientId]: {
                                clientId,
                                secret,
                                shop,
                                currency,
                                userId,
                                created_at: moment().toISOString(),
                                ltvSettings: {
                                    fromMonthsAgo,
                                    fromComparingMonthsAgo,
                                    toComparingMonthsAgo,
                                },
                            },
                        },
                    },
                },
                accountId,
            });
            onCancel();
        },
    }),
)(Component);
