import React, { useEffect, useState } from "react";
import moment from "moment";
import { history } from "_redux";
import isEmpty from "lodash/isEmpty";
import { Permissions, usePermissions } from "features/Permissions";
import MaterialUITable from "components/Table/MaterialUITable";
import TableWrapper from "components/Table/MaterialUITable/TableWrapper";
import { platforms as platformMeta } from "lib/paidPlatforms";
import { SectionHeading, InfoTooltip } from "components/SectionHeading";
import Button from "components/Button";
import MonthPicker from "components/DatePicker/MonthPicker";
import ToggleMulti from "components/ToggleMulti";
import { AdPlatformZeroState } from "components/PlatformNeeded";
import ZeroState from "components/ZeroStates/Icon";
import DownloadIcon from "assets/images/icons/JSX/Icon-Download";
import { ReactComponent as CampaignGroupIcon } from "assets/images/icons/Icon-CampaignGroup-Light-Grey.svg";
import UpgradeIcon from "assets/images/icons/JSX/Icon-Upgrade-Circle";
import { mixpanel } from "components/Mixpanel";
import {
    getStatus,
    countGroupTypes,
    getDataToExport,
    headCells,
    filterCampaignGroups,
    campaignGroupsSorter,
    PAID_PLATFORMS,
    getActiveCampaignAmount,
    getIconPlatform,
    EXPORTABLE_METRICS,
    TABLE_FIELD_MAPPING,
} from "./helpers";
import EditCampaignModal from "features/Dashboard/CampaignGroups/EditCampaign/container";
import AdditionalMenu from "features/Dashboard/CampaignGroups/AdditionalMenu";
import { ExportDropdown } from "components/DropDown";
import { useHandleOutsideClickHook } from "lib/handleOutsideClick";
import useExportPdf from "lib/exportPdf/useExportPdf";
import DidYouKnow from "components/Loading/DidYouKnow";
import MainPanel from "components/MainPanel";
import CompareToMonthPicker from "components/DatePicker/CompareToMonthPicker";
import { subtractDateAndFormat } from "lib/dateUtils";
import { fetchCampaignGroupsPerformance } from "../_ducks/operations";
import { TooltipDark, TooltipBox } from "components/Tooltip";
import FormattedNumber from "features/Reports/CampaignPerformance/FormattedNumber";
import { channelFriendlyName } from "lib/utility";
import UpgradeTip from "./UpgradeTip";
import SubTable from "./SubTable";
import style from "./style.module.scss";
import PlatformsTooltip from "./PlatformsTooltip";
import { renderToString } from "react-dom/server";

const General = ({
    accountId,
    campaignGroups,
    campaignGroupsPending,
    accountCurrencies,
    extraCampaignGroupsInfoLoading,
    hasPaidPlatforms,
    hasCampaignGroups,
    getCampaignGroupsPerformance,
    getExtraCampaignGroupsInfo,
    togglePlanOptions,
    setEditingCampaign,
    setShowEditCampaignModal,
}) => {
    const [comparedCampaignGroups, setComparedCampaignGroups] = useState({});
    const [comparedCampaignGroupsPending, setComparedCampaignGroupsPending] = useState(false);
    const [dateRange, toggleDateRange] = useState({
        start: subtractDateAndFormat(moment(), 7),
        end: subtractDateAndFormat(moment(), 1),
    });
    const [comparedDateRange, toggleComparedDateRange] = useState({
        compareToStart: subtractDateAndFormat(moment(), 14),
        compareToEnd: subtractDateAndFormat(moment(), 8),
    });

    const [filter, toggleFilter] = useState("all");
    const [filterStatus, toggleFilterStatus] = useState("active");

    const campaignGroupsPermitted = usePermissions({
        actions: ["ACTION_CAMPAIGN_GROUPS"],
    });

    useEffect(() => {
        accountId &&
            getCampaignGroupsPerformance({
                accountId,
                startDate: moment(dateRange.start).format("YYYY-MM-DD"),
                endDate: moment(dateRange.end).format("YYYY-MM-DD"),
            });
    }, [accountId, dateRange]);

    useEffect(() => {
        setComparedCampaignGroupsPending(true);
        accountId &&
            fetchCampaignGroupsPerformance({
                accountId,
                startDate: moment(comparedDateRange.compareToStart).format("YYYY-MM-DD"),
                endDate: moment(comparedDateRange.compareToEnd).format("YYYY-MM-DD"),
            }).then((data) => {
                setComparedCampaignGroups(data);
                setComparedCampaignGroupsPending(false);
            });
    }, [accountId, comparedDateRange]);

    useEffect(() => {
        accountId && getExtraCampaignGroupsInfo(accountId);
    }, [accountId]);

    const dataToExport = getDataToExport(campaignGroups);
    const hasBlendedCurrencies = accountCurrencies.length > 1;

    const editCampaignHandle = (campaignGroupId, platform, campaignId) => {
        setEditingCampaign(campaignGroupId, platform, campaignId);
        setShowEditCampaignModal(true);
    };

    // Export data
    const [isOpenExport, toggleIsOpenExport] = useState(false);
    const exportButtonRef = useHandleOutsideClickHook(() => toggleIsOpenExport(false));
    const [isExporting, exportPdfRef, generatePdf] = useExportPdf();

    const exportPdf = async () => {
        await generatePdf(`campaign-groups-${accountId}`);
    };
    const { platforms: platformCounts, groups: groupCounts } = countGroupTypes(campaignGroups);
    const filteredCampaignGroups = filterCampaignGroups({
        campaignGroups,
        filter,
    });

    return (
        <MainPanel fullWidthAndPadding>
            <SectionHeading
                title="Campaign Groups"
                className={style.sectionHeading}
                subtitle={
                    <span className={style.dashboardSubtitle}>
                        <strong>Date Range:</strong> {moment(dateRange.start).format("MMM DD, YYYY")} -{" "}
                        {moment(dateRange.end).format("MMM DD, YYYY")}
                        <span className={style.dot}></span>
                        <strong>Platforms:</strong> {platformCounts}
                        <span className={style.dot}></span>
                        <strong>Campaign Groups:</strong> {groupCounts}
                    </span>
                }
                style={{ marginBottom: "1rem" }}
                tooltip={() => (
                    <InfoTooltip
                        title="Campaign Groups"
                        description={`Performance metrics for ad platforms and campaign groups. Campaign groups can be added and modified in Client Account Settings.`}
                    />
                )}
            >
                <Permissions actions={"ACTION_CAMPAIGN_GROUPS"}>
                    <AdditionalMenu accountId={accountId} />
                </Permissions>
            </SectionHeading>
            {!extraCampaignGroupsInfoLoading && !campaignGroupsPending ? (
                <TableWrapper className={style.campaignTableWrapper}>
                    <div className={style.tableTopBar}>
                        <ToggleMulti
                            className={style.filterCampaigns}
                            options={[
                                {
                                    id: "all",
                                    label: "All",
                                    onClick: () => toggleFilter("all"),
                                },
                                {
                                    id: "platforms",
                                    label: "Platforms",
                                    onClick: () => toggleFilter("platforms"),
                                },
                                {
                                    id: "groups",
                                    label: campaignGroupsPermitted ? (
                                        "Campaign Groups"
                                    ) : (
                                        <div className={style.promoToggle}>
                                            <span className={style.promoToggleButton}>
                                                <UpgradeIcon /> Campaign Groups
                                            </span>
                                            <UpgradeTip
                                                title="Get this Feature!"
                                                message="Enhance Budget Monitoring is exclusively available to Pro users only. Upgrade to a Pro plan to gain access to this feature and more!"
                                                onClick={() =>
                                                    togglePlanOptions({
                                                        isOpen: true,
                                                    })
                                                }
                                            />
                                        </div>
                                    ),
                                    onClick: () => toggleFilter("groups"),
                                    disabled: !campaignGroupsPermitted,
                                },
                            ]}
                            active={filter}
                        />
                        <ToggleMulti
                            className={style.filterCampaigns}
                            options={[
                                {
                                    id: "all",
                                    label: "All",
                                    onClick: () => toggleFilterStatus("all"),
                                },
                                {
                                    id: "active",
                                    label: (
                                        <div>
                                            <span
                                                className={style.dot}
                                                style={{
                                                    backgroundColor: getStatus("active").color,
                                                }}
                                            ></span>
                                            Active
                                        </div>
                                    ),
                                    onClick: () => toggleFilterStatus("active"),
                                },
                            ]}
                            active={filterStatus}
                        />
                        <div className={style.dateContainer}>
                            <div className={style.shareContainer}>
                                <div ref={exportButtonRef}>
                                    <Button
                                        icon={DownloadIcon}
                                        onClick={() => {
                                            mixpanel.track("Dashboard - Campaign Export");
                                            toggleIsOpenExport(!isOpenExport);
                                        }}
                                        download
                                        data-for="cammpaign-group-export"
                                        data-tip="Download table data."
                                        disabled={
                                            !hasPaidPlatforms || campaignGroupsPending || extraCampaignGroupsInfoLoading
                                        }
                                    >
                                        Export
                                    </Button>
                                </div>
                                <TooltipDark id="cammpaign-group-export" proximity={2} delay />
                                <ExportDropdown
                                    isOpen={isOpenExport}
                                    exportPdf={exportPdf}
                                    fileName={`campaign-groups-${accountId}`}
                                    csvData={dataToExport}
                                    isExportingPdf={isExporting}
                                />
                            </div>

                            <MonthPicker
                                className={style.dateTimePicker}
                                presets={["month", "week", "day", "this month"]}
                                maxDaysSelected={365}
                                dateRange={{
                                    start: moment(dateRange.start, "YYYY-MM-DD").toDate(),
                                    end: moment(dateRange.end, "YYYY-MM-DD").toDate(),
                                }}
                                onDateSelect={({ dateRange }) => {
                                    mixpanel.track("Dashboard - Campaign Date Change");
                                    toggleDateRange(dateRange);
                                }}
                            />

                            <CompareToMonthPicker
                                className={style.dateTimePicker}
                                maxDaysSelected={365}
                                dateRange={{
                                    start: moment(dateRange.start, "YYYY-MM-DD").toDate(),
                                    end: moment(dateRange.end, "YYYY-MM-DD").toDate(),
                                    compareToStart: comparedDateRange.compareToStart
                                        ? moment(comparedDateRange.compareToStart, "YYYY-MM-DD").toDate()
                                        : undefined,
                                    compareToEnd: comparedDateRange.compareToEnd
                                        ? moment(comparedDateRange.compareToEnd, "YYYY-MM-DD").toDate()
                                        : undefined,
                                }}
                                onDateSelect={({ dateRange }) => {
                                    mixpanel.track("Dashboard - Campaign Date Change");
                                    toggleComparedDateRange(dateRange);
                                }}
                            />
                        </div>
                    </div>
                    {!isEmpty(filteredCampaignGroups) && (
                        <div ref={exportPdfRef}>
                            <MaterialUITable
                                rowClass={style.rowClass}
                                allowScroll={true}
                                headCells={headCells}
                                rows={campaignGroupsSorter(filteredCampaignGroups, campaignGroups).map((key) => {
                                    const { info: { name } = {}, campaigns = [], platforms = [], performance = {} } =
                                        campaignGroups[key] || {};

                                    const { campaigns: cammpaignCompare = [], performance: performanceCompare = {} } =
                                        comparedCampaignGroups[key] || {};

                                    const isPlatform = PAID_PLATFORMS.includes(name);
                                    const activeCampaignNumber = getActiveCampaignAmount(campaigns);
                                    const formatName = isPlatform ? channelFriendlyName(name) : name;
                                    const campaignCount = campaigns.reduce(
                                        (obj, { platform }) => ({
                                            ...obj,
                                            [platform]: (obj[platform] || 0) + 1,
                                        }),
                                        {},
                                    );
                                    const icon = getIconPlatform({
                                        platformMeta,
                                        isPlatform,
                                        formatName,
                                        name,
                                    });

                                    const platformsTip = renderToString(
                                        <PlatformsTooltip
                                            platforms={platforms.map((platform) => ({
                                                name: platformMeta[platform]?.name,
                                                icon: platformMeta[platform]?.colorIcon,
                                                count: campaignCount[platform],
                                            }))}
                                        />,
                                    );

                                    return {
                                        id: key,
                                        name: {
                                            content: (
                                                <span className={style.campaignName}>
                                                    <span
                                                        className={style.icon}
                                                        data-tip={platformsTip}
                                                        data-html
                                                        data-for={`group-platforms-${key}`}
                                                    >
                                                        {icon}
                                                    </span>{" "}
                                                    {formatName}
                                                    <TooltipBox
                                                        effect="solid"
                                                        position="top"
                                                        id={`group-platforms-${key}`}
                                                        hasArrow
                                                    />
                                                </span>
                                            ),
                                            value: formatName,
                                            hasDropDown: true,
                                        },
                                        status: {
                                            content: (
                                                <p className={style.activeCampaignAmount}>
                                                    {activeCampaignNumber} Active
                                                </p>
                                            ),
                                            value: activeCampaignNumber,
                                            isCellLoading:
                                                campaignGroupsPending ||
                                                extraCampaignGroupsInfoLoading ||
                                                comparedCampaignGroupsPending,
                                        },
                                        budget: {
                                            content: "",
                                            value: 0,
                                        },
                                        ...EXPORTABLE_METRICS.reduce((cache, metric) => {
                                            const actual = performance[metric];
                                            const actualCompare = performanceCompare[metric];

                                            return {
                                                ...cache,
                                                [TABLE_FIELD_MAPPING[metric] || metric]: {
                                                    content: (
                                                        <FormattedNumber
                                                            actual={actual}
                                                            showGrowth={comparedDateRange.compareToStart}
                                                            previousPeriodGrowth={
                                                                ((actual - actualCompare) * 100) / actualCompare
                                                            }
                                                            metric={metric}
                                                            previousPeriod={actualCompare}
                                                            platform={key}
                                                        />
                                                    ),
                                                    value: actual,
                                                    oldValue: actualCompare,
                                                    isCellLoading:
                                                        campaignGroupsPending ||
                                                        extraCampaignGroupsInfoLoading ||
                                                        comparedCampaignGroupsPending,
                                                },
                                            };
                                        }, {}),
                                        _collapsedComponent: (props) => (
                                            <SubTable
                                                filterStatus={filterStatus}
                                                showGrowth={comparedDateRange.compareToStart}
                                                cammpaignsCompare={cammpaignCompare}
                                                campaigns={campaigns}
                                                accountId={accountId}
                                                hasBlendedCurrencies={hasBlendedCurrencies}
                                                extraCampaignGroupsInfoLoading={extraCampaignGroupsInfoLoading}
                                                campaignGroupsPending={
                                                    campaignGroupsPending || comparedCampaignGroupsPending
                                                }
                                                groupId={key}
                                                editCampaignHandle={editCampaignHandle}
                                                {...props}
                                            />
                                        ),
                                    };
                                })}
                            />
                        </div>
                    )}
                    {!hasPaidPlatforms && <AdPlatformZeroState accountId={accountId} />}
                    {!hasCampaignGroups && (
                        <ZeroState
                            icon={CampaignGroupIcon}
                            title="Add Campaign Groups"
                            message="We couldn't find any campaign groups in this account."
                            containerClass={style.zeroState}
                        >
                            <Button
                                medium
                                onClick={() => {
                                    mixpanel.track("Dashboard - Zero State Add Campaign Group");
                                    history.push(`/dashboard/${accountId}/settings/campaign-groups`);
                                }}
                            >
                                Add Campaign Group
                            </Button>
                        </ZeroState>
                    )}
                </TableWrapper>
            ) : (
                <DidYouKnow />
            )}
            <EditCampaignModal />
        </MainPanel>
    );
};

export default General;
