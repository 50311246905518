import { makeSelectIntegrationOpts } from "features/Integrations/_ducks/selectors";
import { selectOnboardingCampaigns } from "features/Onboarding/ducks/selectors";
import { connect } from "react-redux";
import { change, formValueSelector, getFormMeta, getFormValues } from "redux-form";
import { makeSelectIntegrationIds } from "_redux/sites/selectors";
import { operations } from "../../../Onboarding/ducks";
import IntegrateStep from "./index";

const { getMaropostCampaigns } = operations;

const formName = "maropost";

const mapStateToProps = (state, {integrationId}) => {
    const campaigns = selectOnboardingCampaigns(state);
    const { maropost: maropostOptions } = makeSelectIntegrationOpts()(state);
    const integrations = makeSelectIntegrationIds()(state);
    const { form } = state;
    const selector = formValueSelector(formName);
    const selectedCampaigns = selector({ form }, "maropost-campaigns") || {};
    const { isSandboxAccount, apiKey, adAccountId } = getFormValues(formName)({ form });
    const { maropost: { [adAccountId]: { campaigns: savedCampaigns = {}, regex: savedRegex = "" } = {} } = {} } =
        integrations || {};
    const regex = selector({ form }, "regexmaropost") || "";
    const formMetaData = getFormMeta("maropost")({ form });
    const isFormActive =
        formMetaData?.apiKey?.active || formMetaData?.adAccountId?.active || formMetaData?.isSandboxAccount?.active;

    return {
        isSandboxAccount,
        apiKey,
        adAccountId,
        formName,
        options: maropostOptions,
        selectedIntegrations: getFormValues(formName)({ form }),
        campaigns,
        savedCampaigns,
        selectedCampaignLength: Object.keys(selectedCampaigns).filter((campaign) => selectedCampaigns[campaign]).length,
        currentAndFuture: selectedCampaigns["_all"] && !regex,
        savedRegex,
        isEditing: !!integrationId,
        isFormActive,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getMaropostCampaigns: ({ isSandboxAccount, apiKey, adAccountId }) =>
        dispatch(getMaropostCampaigns({ isSandboxAccount, apiKey, adAccountId })),
    change: (formName, ...args) => dispatch(change(formName, ...args)),
});

const mergeProps = ({ formName, ...stateProps }, { change, ...dispatchProps }, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    change: (...args) => {
        return change(formName, ...args);
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IntegrateStep);
