import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import moment from "moment";
import { handle } from "redux-pack";
import { COMPARISONS } from "features/Analysis/helpers";
import { get } from "lodash";

export const defaultState = {
    settings: {
        start: moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD"),
        end: moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
        compareToEnd: null,
        compareToStart: null,
        compare: COMPARISONS.NONE,
    },
    reportData: {},
    breadcrumb: {
        platform: "",
        network: "",
        campaign: "",
        campaignNetwork: [],
        adGroup: "",
        ad: "",
        campaignGroupPlatform: "",
    },
    campaigns: [],
    adGroupReports: {},
    adReports: {},
    adGroups: [],
    ads: [],
    reportLoading: false,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.REPORT_CLEAR]: (state) => {
            return { ...defaultState, reportLoading: state.reportLoading };
        },
        [types.REPORT_SETTINGS]: (state, action) => {
            const oldSettings = state.settings;
            return {
                ...state,
                settings: { ...oldSettings, ...action.payload },
            };
        },
        [types.REPORT_BREADCRUMB]: (state, action) => {
            const oldBreadcrumb = state.breadcrumb;
            return {
                ...state,
                breadcrumb: { ...oldBreadcrumb, ...action.payload },
            };
        },
        [types.SET_CAMPAIGNS]: (state, action) => {
            return {
                ...state,
                campaigns: action.payload || [],
            };
        },
        [types.GET_REPORT_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    reportLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const reportData = isCancelledReq ? prevState.reportData : { ...action.payload };
                    return {
                        ...prevState,
                        reportData,
                        adGroupReportsLoadingError: false,
                        reportLoading: isCancelledReq ? true : false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    reportLoadingError: true,
                    reportLoading: false,
                }),
            });
        },
        [types.GET_AD_GROUP_REPORTS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    adGroupReportsLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { meta: { platform } = {}, payload } = action;
                    const adGroupReports = isCancelledReq
                        ? prevState.adGroupReports
                        : { ...prevState.adGroupReports, [platform]: payload };

                    return {
                        ...prevState,
                        adGroupReports,
                        adGroupReportsLoadingError: false,
                        adGroupReportsLoading: isCancelledReq ? true : false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    adGroupReportsLoadingError: true,
                    adGroupReportsLoading: false,
                }),
            });
        },
        [types.GET_AD_REPORTS]: (state, action) => {
            const { meta: { platform } = {}, payload } = action;
            const platformLoadingKey = `${platform}AdReportsLoading`;

            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    [platformLoadingKey]: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const adReports = isCancelledReq
                        ? prevState.adReports
                        : { ...prevState.adReports, [platform]: payload };
                    return {
                        ...prevState,
                        adReports,
                        adReportsLoadingError: false,
                        [platformLoadingKey]: isCancelledReq ? true : false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    adReportsLoadingError: true,
                    [platformLoadingKey]: false,
                }),
            });
        },
        [types.SET_ADGROUPS]: (state, action) => {
            return {
                ...state,
                adGroups: action.payload || [],
            };
        },
        [types.SET_ADS]: (state, action) => {
            return {
                ...state,
                ads: action.payload || [],
            };
        },
        [types.RESET_AD_REPORTS]: (state, action) => {
            return {
                ...state,
                adGroupReports: {},
                adReports: {},
                adGroups: [],
                ads: [],
            };
        },
        [types.FETCH_LISTS_ADGROUPS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    adGroupsLoading: true,
                }),
                success: (prevState) => {
                    const { data } = action.payload;
                    const dataFormatted = data.isCancelledReq ? {} : data;

                    return {
                        ...prevState,
                        adGroupsLoading: false,
                        adGroups: {
                            ...prevState.adGroups,
                            ...Object.keys(dataFormatted).reduce(
                                (cache, key) => ({
                                    ...cache,
                                    [key]: dataFormatted[key].map(({ name, id } = {}) => ({
                                        label: `${name} - ${id}`,
                                        value: id,
                                    })),
                                }),
                                {},
                            ),
                        },
                    };
                },
                finish: (prevState) => ({
                    ...prevState,
                    adGroupsLoading: false,
                }),
            }),
    }),
);
