import { connect } from "react-redux";
import { destroy, reduxForm } from "redux-form";
import Component from "features/Dashboard/CampaignGroups/EditCampaign/index";
import { selectEditingCampaignInfo, selectShowEditCampaignModal } from "features/Dashboard/_ducks/selectors";
import { setEditingCampaign, setShowEditCampaignModal } from "features/Dashboard/_ducks/actions";
import { makeSelectAccountId, makeSelectIntegrationIds } from "_redux/sites/selectors";
import { makeSelectType, userIdSelector } from "_redux/users/selectors";
import { operations } from "../../_ducks";
import { EDIT_CAMPAIGN_NAME, getFormInitialValues } from "./helpers";

const { updatePlatformCampaign } = operations;

const makeMapStateToProps = () => {
    const accountIdSelector = makeSelectAccountId();
    const integrationIdsSelector = makeSelectIntegrationIds();
    return (state) => {
        const editingCampaignInfo = selectEditingCampaignInfo(state);
        const integrationIds = integrationIdsSelector(state);
        const userType = makeSelectType()(state) || "";

        return {
            initialValues: getFormInitialValues(editingCampaignInfo),
            showEditingCampaignModal: selectShowEditCampaignModal(state),
            accountId: accountIdSelector(state),
            userId: userIdSelector(state),
            editingCampaignInfo,
            integrations: integrationIds,
            showUpgrade: userType.includes("appSumo"),
        };
    };
};

const mapDispatchToProps = (dispatch) => ({
    updatePlatformCampaign: (params) => dispatch(updatePlatformCampaign(params)),
    onCloseModalHandle: () => {
        dispatch(setShowEditCampaignModal(false));
        dispatch(setEditingCampaign("", "", 0));
        dispatch(destroy(EDIT_CAMPAIGN_NAME));
    },
});

const form = reduxForm({
    form: EDIT_CAMPAIGN_NAME,
    enableReinitialize: true,
    asyncBlurFields: [],
})(Component);

export default connect(makeMapStateToProps, mapDispatchToProps)(form);
