import React from "react";
import ArrowButton from "components/Button/ArrowButton";
import ThreeDots from "components/Loading/ThreeDots";
import style from "../style.module.scss";
import classNames from "classnames";

const DropDownButton = ({ title, dropDownIsOpen, toggleDropDown, isLoading }) => {
    const classes = classNames(style.selectionArrow, {
        [style.open]: dropDownIsOpen,
    });

    return (
        <div onClick={toggleDropDown} className={classes}>
            {isLoading ? (
                <ThreeDots color="#1b264f" className={style.iconLoading} />
            ) : (
                <>
                    <h1 className={style.title}>{title}</h1>
                    <ArrowButton className={style.selectionArrowButton} />
                </>
            )}
        </div>
    );
};

export default DropDownButton;
