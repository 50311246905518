import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import BingIcon from "assets/images/icons/JSX/Icon-Bing";
import AdwordsIcon from "assets/images/icons/JSX/Icon-Adwords";
import FacebookIcon from "assets/images/icons/JSX/Icon-Facebook";
import TikTokIcon from "assets/images/icons/JSX/Icon-TikTok";
import PinterestIcon from "assets/images/icons/JSX/Icon-Pinterest";
import BingColorIcon from "assets/images/Integrations/Icon-Bing.svg";
import AdwordsColorIcon from "assets/images/Integrations/Icon-GoogleAds.svg";
import FacebookColorIcon from "assets/images/Integrations/Icon-Facebook.svg";
import TikTokColorIcon from "assets/images/Integrations/Icon-TikTok.svg";
import PinterestColorIcon from "assets/images/Integrations/Icon-Pinterest.svg";
import LinkedinColorIcon from "assets/images/Integrations/Icon-LinkedIn.svg";
import BasicBudgetingIcon from "assets/images/icons/Icon-BudgetMonitoring.svg";
import Checkmark from "assets/images/Form/JSX/checkmark";
import WarningIcon from "assets/images/icons/JSX/Icon-Alert";
import { withDates } from "lib/tableSchema";
import moment from "moment";
import { getTrend } from "lib/metricCalculations";
import PermissionsContext from "features/Permissions";
import LoadingCircles from "components/Loading";
import PlatformBudget from "./PlatformBudget";
import { SectionHeading } from "components/SectionHeading";

export const platformBar = ({ platform }) =>
    ({
        bing: {
            name: "Microsoft Advertising",
            style: style.bing,
            icon: BingIcon,
            colorIcon: BingColorIcon,
        },
        adwords: {
            name: "Google Ads",
            style: style.adwords,
            icon: AdwordsIcon,
            colorIcon: AdwordsColorIcon,
        },
        facebook: {
            name: "Meta Ads",
            style: style.facebook,
            icon: FacebookIcon,
            colorIcon: FacebookColorIcon,
        },
        tiktok: {
            name: "TikTok Ads",
            style: style.tiktok,
            icon: TikTokIcon,
            colorIcon: TikTokColorIcon,
        },
        pinterest: {
            name: "Pinterest Ads",
            style: style.pinterest,
            icon: PinterestIcon,
            colorIcon: PinterestColorIcon,
        },
        linkedin: {
            name: "LinkedIn Ads",
            style: style.linkedin,
            icon: LinkedinColorIcon,
            colorIcon: LinkedinColorIcon,
        },
        total: {
            name: "Combined Platform Budgets",
            style: style.linkedin,
            icon: BasicBudgetingIcon,
            colorIcon: BasicBudgetingIcon,
        },
    }[platform]);

export const trendOpts = (trend, difference, hasOverageOverall) => {
    if (trend < 0 && difference > 0) {
        if (hasOverageOverall) {
            return {
                color: "#FB1427",
                icon: () => (
                    <div className={`${style.redIcon} ${style.icon}`}>
                        <WarningIcon />
                    </div>
                ),
            };
        }

        return {
            color: "#FF9D00",
            icon: () => (
                <div className={`${style.warningIcon} ${style.icon}`}>
                    <WarningIcon />
                </div>
            ),
        };
    }
    if (trend < 0 && difference < 0) {
        return {
            color: "#FF9D00",
            icon: () => (
                <div className={`${style.warningIcon} ${style.icon}`}>
                    <WarningIcon />
                </div>
            ),
        };
    }
    return {
        color: "#00B178",
        icon: () => (
            <div className={`${style.checkMark} ${style.icon}`}>
                <Checkmark />
            </div>
        ),
    };
};

const thresholdTrend = (spend = 0, monthlyBudget = 0, min, max) => {
    if (spend === 0 && monthlyBudget === 0) {
        return -1;
    }

    if (spend < min || spend > max) {
        return -1;
    }

    return 0;
};

const calcRange = ({ baseline, comparison, percent, min, max }) => {
    //+ - threshold from expected spend

    const difference = baseline * percent;

    return {
        min: Math.max(min, comparison - difference),
        max: Math.min(max, comparison + difference),
        difference,
    };
};

export default ({
    accountId,
    date,
    getBudget,
    budgetLoading,
    budget = {},
    toggleDrawer,
    getAnomaliesByDate,
    anomalyCount,
    thresholdPercent,
    integrations,
}) => {
    const {
        budget: monthlyBudget = {
            adwords: 0,
            facebook: 0,
            tiktok: 0,
            pinterest: 0,
            bing: 0,
            linkedin: 0,
            total: 0,
        },
        ...platforms
    } = budget;

    const currencies = []
        .concat([
            ...new Set(
                ["adwords", "bing", "linkedin", "facebook", "tiktok", "pinterest"].reduce((cache, platform) => {
                    return [...cache, ...Object.values(integrations[platform] || {}).map(({ currency }) => currency)];
                }, []),
            ),
        ])
        .filter((v) => v);

    const {
        date: { daysIntoMonth, daysInMonth },
    } = withDates({ start: date, end: date }, {});

    const monthDecimalPercent = daysIntoMonth / daysInMonth;

    const platformData = ["total", "adwords", "facebook", "bing", "linkedin", "tiktok", "pinterest"]
        .filter((platform) => platforms[platform])
        .map((platform) => {
            const spend = parseFloat(platforms[platform].cost, 10);
            const expectedSpend = monthlyBudget[platform] * monthDecimalPercent;

            const overallOverage = Math.max(spend - monthlyBudget[platform], 0);
            const difference = spend - expectedSpend;
            const platformDetails = platformBar({ platform });

            const { min, max } = calcRange({
                baseline: monthlyBudget[platform],
                comparison: expectedSpend,
                percent: thresholdPercent,
                min: 0,
                max: monthlyBudget[platform],
            });

            const trend = thresholdTrend(spend, monthlyBudget[platform], min, max);

            const hasOverageOverall = overallOverage > 0;
            const hasPartialShortage = spend < expectedSpend && trend < 0;
            const hasPartialOverage = spend > expectedSpend && trend < 0;

            const totalWidthBaseline = Math.max(spend, monthlyBudget[platform]);
            const calculatedBarWidth = hasOverageOverall ? 100 : (spend / totalWidthBaseline) * 100;

            const calculatedExpectedBarWidth = (expectedSpend / totalWidthBaseline) * 99;
            const calculatedDifference = Math.abs(calculatedExpectedBarWidth - calculatedBarWidth);

            // const calculatedBudgetWidth = (monthlyBudget[platform]/totalWidthBaseline) * 99;
            const overageWidth = (overallOverage / totalWidthBaseline) * 99;
            const hasCaps = calculatedBarWidth + overageWidth >= 99;

            const trendOptions = trendOpts(trend, difference, hasOverageOverall);
            const normalTrend = getTrend(spend, expectedSpend);

            const value = {
                "0": {
                    symbol: null,
                    value: null,
                },
                "1": {
                    value: spend - expectedSpend,
                    symbol: "+",
                },
                "-1": {
                    value: expectedSpend - spend,
                    symbol: "-",
                },
            }[normalTrend];

            return {
                spend,
                calculatedBarWidth,
                calculatedExpectedBarWidth,
                calculatedDifference,
                platformDetails,
                expectedSpend,
                trendOptions,
                value,
                platform,
                hasCaps,
                hasOverageOverall,
                hasPartialOverage,
                hasPartialShortage,
                monthlyBudget: monthlyBudget[platform],
                anomalyCount: anomalyCount[platform],
            };
        })
        .sort((a, b) => b.spend - a.spend);

    useEffect(() => {
        getBudget({ accountId, date });
        getAnomaliesByDate({
            accountId,
            startDate: moment(date)
                .startOf("month")
                .format("YYYY-MM-DD"),
            endDate: moment(date)
                .endOf("month")
                .format("YYYY-MM-DD"),
        });
    }, [accountId, date]);

    if (budgetLoading) {
        return <LoadingCircles title="" message="" />;
    }

    const dateRangeString =
        daysIntoMonth === 1
            ? moment(date).format("MMM D, YYYY")
            : `${moment(date).format("MMM")} 1 - ${daysIntoMonth}, ${moment(date).format("YYYY")}`;

    const totalProps = platformData.find(({ platform }) => {
        return platform === "total";
    });

    return (
        <PermissionsContext.Consumer>
            {({ isAllowed }) => (
                <>
                    {totalProps && currencies.length === 1 && (
                        <PlatformBudget
                            budgetLoading={budgetLoading}
                            date={date}
                            dateRangeString={dateRangeString}
                            toggleDrawer={toggleDrawer}
                            accountId={accountId}
                            {...totalProps}
                        />
                    )}
                    <SectionHeading title="Platform Budgets" smallHeader />
                    {platformData
                        .filter(({ platform }) => {
                            return platform !== "total" && isAllowed(platform);
                        })
                        .map((props) => {
                            return (
                                <PlatformBudget
                                    key={props.platform}
                                    budgetLoading={budgetLoading}
                                    date={date}
                                    dateRangeString={dateRangeString}
                                    toggleDrawer={toggleDrawer}
                                    accountId={accountId}
                                    {...props}
                                />
                            );
                        })}
                </>
            )}
        </PermissionsContext.Consumer>
    );
};
