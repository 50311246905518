import React from "react";
import ShopifyIcon from "assets/images/Integrations/JSX/Logo-Shopify";
import ShopifyIntegrate from "features/Integrations/Dependant/Shopify/container";
import Icon from "features/Onboarding/IntegrateStep/Icon";
import style from "../style.module.scss";
import { mixpanel, sendToSlack } from "components/Mixpanel";
import SubmitButtons from "../_Components/SubmitButtons";
import moment from "moment";

export default ({ onCancel, handleSubmit, submitting, disabled, invalid, reset, userId }) => {
    return (
        <div className={`${style.integration} ${style.narrow}`}>
            <Icon icon={ShopifyIcon} />
            <h1>Integrate Shopify</h1>
            <p>
                Follow the instructions in the{" "}
                <a
                    className={style.link}
                    rel="noopener noreferrer"
                    href="https://hawke.ai/support/resources/shopify-integration/"
                    target="_blank"
                >
                    Resource Section
                </a>{" "}
                to connect your Shopify online store and view active store metrics.
            </p>
            <ShopifyIntegrate />

            <SubmitButtons
                invalid={invalid || submitting}
                disabled={disabled}
                onClick={async () => {
                    mixpanel.track("Save Integration", {
                        Platform: "shopify",
                    });
                    sendToSlack(`Save Integration: shopify`);
                    handleSubmit();
                }}
                onCancel={() => {
                    mixpanel.track("Save Integration", {
                        Platform: "shopify",
                        Canceled: true,
                    });
                    reset();
                    onCancel();
                }}
            />
        </div>
    );
};
