import _ from "lodash/fp";
import { KpiAPI, userRequest } from "../../lib/apiKpi";
import moment from "moment";

export const analyticsRequest = ({
    level = "sitewide",
    dimensions,
    metrics,
    segmentFilter,
    startDate,
    endDate,
    route,
    filter = null,
    channel,
}) => {
    const startDateFormatted = moment(startDate).format("YYYY-MM-DD");
    const endDateFormatted = moment(endDate).format("YYYY-MM-DD");
    const segment = segmentFilter === Object(segmentFilter) ? JSON.stringify(segmentFilter) : segmentFilter;

    return userRequest().then(({ accountId }) => {
        return KpiAPI({
            method: "GET",
            url: `/analytics/${level}`,
            query: {
                accountId,
                route: route,
                start_date: startDateFormatted,
                end_date: endDateFormatted,
                dimensions: dimensions,
                goals: filter,
                segment,
                channel,
                metrics,
            },
        }).then((result) => {
            return {
                dimensions: dimensions.split(","),
                data: result.data,
            };
        });
    });
};

export const getAnalyticsConversionTypes = ({ accountId } = {}) => {
    return userRequest({ accountId }).then(({ analytics = {}, userId, parentUserId }) => {
        return Promise.all(
            Object.keys(analytics).map(async (integrationId) => {
                const {
                    integrationName,
                    connectionId,
                    analyticsAccounts: { value: analyticsAccountId } = {},
                } = analytics[integrationId];
                return KpiAPI({
                    method: "GET",
                    url: `/analytics/sitewide/conversionType`,
                    query: {
                        userId: parentUserId || userId,
                        accountId,
                        connectionId,
                        analyticsAccountId,
                        analyticsViewId: integrationId,
                    },
                }).then((result) => {
                    return result.data.map((item) => ({ ...item, integrationName }));
                });
            }),
        ).then((data) => {
            return [].concat(...data);
        });
    });
};

export const getTopPages = ({ segmentFilter, startDate, endDate, channel }) => {
    return userRequest().then(({ accountId }) => {
        const startDateFormatted = moment(startDate).format("YYYY-MM-DD");
        const endDateFormatted = moment(endDate).format("YYYY-MM-DD");

        let query = {
            accountId,
            start_date: startDateFormatted,
            end_date: endDateFormatted,
        };

        if (segmentFilter) {
            query.segment = typeof segmentFilter === "string" ? segmentFilter : JSON.stringify(segmentFilter);
        }

        if (channel) {
            query.channel = channel;
        }

        return KpiAPI({
            method: "GET",
            url: `/pages/top`,
            query,
        }).then((result) => {
            return result.data;
        });
    });
};
