import React from "react";
import qs from "querystring";
import openWindow from "../openWindow";
import { BING_AUTHENTICATION_SCOPE } from "lib/campaignUtils";

const { NODE_ENV, REACT_APP_DOMAIN } = process.env;
const redirectUrl =
    NODE_ENV === "development"
        ? `${REACT_APP_DOMAIN}/dashboard/account/connect`
        : `https://app.morphio.ai/hawke/redirect`; // redirects to https://app.hawke.ai/dashboard/account/connect

class BingConnect extends React.Component {
    state = {
        loading: false,
        platform: "bing",
        label: "Microsoft Advertising",
    };

    csrfCode() {
        return Math.random()
            .toString(36)
            .substring(7);
    }

    login = async (cb, secret) => {
        return openWindow(this.buildLoginUrl(secret), secret).then(cb);
    };

    exchangeCode = async (data) => {
        await this.props.exchangeBingCode({
            code: data.code,
            redirectUrl: redirectUrl,
        });
        this.setState({ loading: false });
    };

    buildLoginUrl = (secret) => {
        const url = "https://login.live.com/oauth20_authorize.srf";
        const params = qs.stringify({
            client_id: process.env.REACT_APP_BING_CLIENT_ID,
            scope: BING_AUTHENTICATION_SCOPE,
            response_type: "code",
            redirect_uri: redirectUrl,
            state: secret,
        });

        return url + "?" + params;
    };

    onAdd = async (event, toggled) => {
        const secret = this.csrfCode();

        this.setState({ loading: true });
        await this.login(this.exchangeCode, secret);
        this.setState({ loading: false });
    };

    render() {
        const { loading, platform, label } = this.state;
        const { component: Component } = this.props;
        return <Component platform={platform} loading={loading} onAdd={this.onAdd} label={label} />;
    }
}

export default BingConnect;
