import React from "react";
import styleMobile from "./styleMobile.module.scss";
import PinStar from "../PinStar";
import ArrowDown from "assets/images/Top_Panel/JSX/Icon-Arrow-Down";

export const NameContentMobile = ({ accountId, togglePinnedView, pinned, dispatch, selectView, name, isNew, userRole }) => {
    return (
        <div className={styleMobile.account}>
            <div className={styleMobile.accountHeader}>
                <div className={styleMobile.accountHeaderName}>
                    <div
                        className={styleMobile.favourite}
                        onClick={() =>
                            dispatch(
                                togglePinnedView({
                                    accountId,
                                    pinned: !pinned,
                                })
                            )
                        }
                    >
                        <PinStar className={styleMobile.star} pinned={pinned} />
                    </div>
                    <h3 onClick={() => selectView({ accountId })}>{name}</h3>
                    {isNew && <span className={styleMobile.new}>New</span>}
                </div>
                <div className={styleMobile.iconArrow} onClick={() => selectView({ accountId, userRole })}>
                    <ArrowDown />
                </div>
            </div>
        </div>
    );
};
