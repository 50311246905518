import React from "react";
import { ReactComponent as AnalyticsV4Icon } from "assets/images/Integrations/Icon-GoogleAnalytics-v4.svg";
import AnalyticsV4Integrate from "features/Integrations/Dependant/AnalyticsV4/container";
import Icon from "features/Onboarding/IntegrateStep/Icon";
import IntegrationWrapper from "features/Integrations/Independant/IntegrationWrapper/container";
import style from "../style.module.scss";
import moment from "moment";

export default ({
    onCancel,
    changeConnection,
    connections,
    updateIntegration,
    accountId,
    integrationId,
    userId,
    hasIntegrations,
}) => {
    return (
        <div className={`${style.integration} ${style.narrow}`}>
            <Icon icon={AnalyticsV4Icon} />
            <h1>Connect Google Analytics 4</h1>
            <p style={{ maxWidth: "27.5rem" }}>
                While not required, Google Analytics 4 is a <strong>recommended integration</strong> that is needed for
                some features to function.
            </p>

            <IntegrationWrapper
                onCancel={onCancel}
                onSave={async (
                    {
                        analyticsV4Accounts = {
                            value: null,
                        },
                        analyticsV4IntegrationName = "",
                        analyticsV4Properties = {
                            value: null,
                        },
                    },
                    _,
                    { connection: connectionId },
                ) => {
                    await updateIntegration({
                        integrations: {
                            analyticsV4: {
                                value: {
                                    [analyticsV4Properties.value.split("/")[1]]: {
                                        integrationName: analyticsV4IntegrationName || analyticsV4Properties.label,
                                        integrationId: analyticsV4Properties.value.split("/")[1],
                                        analyticsV4Accounts,
                                        analyticsV4Properties,
                                        connectionId,
                                        currency: analyticsV4Properties.currency,
                                        userId,
                                        created_at: moment().toISOString(),
                                    },
                                },
                            },
                        },
                        accountId,
                    });

                    onCancel();
                }}
                platform="analyticsV4"
                changeConnection={changeConnection}
                connection={connections?.analyticsV4}
                integration={AnalyticsV4Integrate}
                integrationId={integrationId}
                disabled={!connections?.analyticsV4 || !hasIntegrations}
            />
        </div>
    );
};
