import React from "react";
import styleMobile from "./styleMobile.module.scss";

import { history } from "_redux";
import ThreeDots from "components/Loading/ThreeDots";
import { BudgetBar } from "features/Budgets/BasicBudgeting/BudgetTracker/PlatformBudget";
import { platformBar } from "features/Budgets/BasicBudgeting/BudgetTracker";
import { toPrettyNumber } from "lib/metricCalculations";
import { TooltipHorizontalFloat } from "components/Tooltip";
import classNames from "classnames";
import AddButton from "../AddButton";
import { MixCurrency } from ".";

export const BudgetsContentMobile = ({
    spend,
    toExpectedBudgetPercent,
    currency,
    calculatedDifference,
    accountId,
    isLoading,
    monthlyBudget,
    accountCurrencies,
    hasPartialOverage,
    calculatedExpectedBarWidth,
    calculatedBarWidth,
    hasCaps,
    hasOverageOverall,
    hasPartialShortage,
}) => {
    return accountId === "total" || accountCurrencies.length > 1 ? (
        <MixCurrency />
    ) : isLoading ? (
        <div className={styleMobile.loadingDots}>
            <ThreeDots color="#1b264f" />
        </div>
    ) : monthlyBudget ? (
        <div
            className={styleMobile.budgetBarContainer}
            onClick={() => history.push(`/dashboard/${accountId}/financials`)}
        >
            <div className={styleMobile.barWrapper}>
                <BudgetBar
                    platform={"total"}
                    hasPartialOverage={hasPartialOverage}
                    calculatedExpectedBarWidth={calculatedExpectedBarWidth}
                    calculatedBarWidth={calculatedBarWidth}
                    hasCaps={hasCaps}
                    hasOverageOverall={hasOverageOverall}
                    size="super-small"
                    platformDetails={platformBar({
                        platform: "total",
                    })}
                    hasPartialShortage={hasPartialShortage}
                    spend={spend}
                    calculatedDifference={calculatedDifference}
                />
            </div>
            <TooltipHorizontalFloat id={`${accountId}-total`} html className={styleMobile.budgetBarTooltip} />
            <div className={styleMobile.budgetBarInfo}>
                <div className={styleMobile.budgetBarInfoSection}>
                    <span className={styleMobile.budgetSummaryMonthly}>
                        {toPrettyNumber(monthlyBudget, "spend", accountId, null, currency)}
                    </span>
                    <span className={styleMobile.spendDesc}>Budget</span>
                </div>
                <div className={styleMobile.budgetBarInfoSection}>
                    <span className={styleMobile.spend}>
                        {toPrettyNumber(spend, "spend", accountId, null, currency)}
                    </span>
                    <span className={styleMobile.spendDesc}>Spend To Date</span>
                </div>
                <div className={styleMobile.budgetBarInfoSection}>
                    <span
                        className={classNames(styleMobile.spend, {
                            [styleMobile.red]: toExpectedBudgetPercent > 0,
                        })}
                    >
                        {toPrettyNumber(toExpectedBudgetPercent, "percent", accountId, null, currency)}
                    </span>
                    <span className={styleMobile.spendDesc}>To Expected Spend</span>
                </div>
            </div>
        </div>
    ) : (
        <div>
            <AddButton type="Platform Budgets" onClick={() => history.push(`/dashboard/${accountId}/financials`)} />
        </div>
    );
};
