import { useState, useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { isString } from "lodash";

const PDF_MARGIN = 30;

const setElementDimensions = (element, overflowTag) => {
    const overflowElement =
        overflowTag && element.getElementsByTagName(overflowTag)
            ? element.getElementsByTagName(overflowTag)[0]
            : undefined;
    if (!overflowElement) {
        return;
    }

    const overflowWidth = overflowTag === "svg" ? overflowElement.getAttribute("width") : overflowElement.offsetWidth;
    const overflowHeight =
        overflowTag === "svg" ? overflowElement.getAttribute("height") : overflowElement.offsetHeight;

    if (overflowWidth > element.offsetWidth) {
        element.style.width = "fit-content";
    }
    if (overflowHeight > element.offsetHeight) {
        element.style.height = "fit-content";
    }
};

const loadImages = (element) => {
    const images = element.getElementsByTagName("img");
    const imagePromises = [];
    for (let img of images) {
        if (!img.complete) {
            imagePromises.push(
                new Promise((resolve) => {
                    img.onload = resolve;
                    img.onerror = resolve; // resolve anyway, to not block the process
                }),
            );
        }
    }
    return Promise.all(imagePromises);
};

function useExportPdf() {
    const exportPdfRef = useRef();
    const [isExporting, setIsExporting] = useState(false);

    const generatePdf = async (fileName = "export", overflowTag = "table") => {
        setIsExporting(true);

        const element = exportPdfRef.current;
        if (!element) {
            setIsExporting(false);
            return;
        }

        // Ensure all images are loaded
        await loadImages(element);

        // Handle scrollable children elements
        setElementDimensions(element, overflowTag);
        // Create canvas
        const canvas = await html2canvas(element, { useCORS: true });
        const data = canvas.toDataURL("image/png");

        const componentWidth = element.offsetWidth;
        const componentHeight = element.offsetHeight;
        element.style.width = "";
        element.style.height = "";

        // Create PDF
        const orientation = componentWidth >= componentHeight ? "l" : "p";
        const pdf = new jsPDF({ orientation, unit: "px" });
        pdf.internal.pageSize.width = componentWidth + PDF_MARGIN * 2;
        pdf.internal.pageSize.height = componentHeight + PDF_MARGIN * 2;
        pdf.addImage(data, "PNG", PDF_MARGIN, PDF_MARGIN, componentWidth, componentHeight);
        pdf.save(`${isString(fileName) ? fileName : "export"}.pdf`);

        setIsExporting(false);
    };

    return [isExporting, exportPdfRef, generatePdf];
}

export default useExportPdf;
