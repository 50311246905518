import React from "react";
import TikTokIcon from "assets/images/Integrations/JSX/Logo-TikTok";
import TikTokIntegration from "features/Integrations/Dependant/TikTok/container";
import Icon from "features/Onboarding/IntegrateStep/Icon";
import IntegrationWrapper from "features/Integrations/Independant/IntegrationWrapper/container";
import style from "../style.module.scss";
import moment from "moment";
import { TIKTOK_CAMPAIGNS } from "features/Integrations/Dependant/TikTok";

const convertCampaignsToSave = (campaignsSelected) => {
    return Object.keys(campaignsSelected)
        .filter((key) => {
            return campaignsSelected[key];
        })
        .reduce((obj, key) => {
            const [name, value] = key.split("____");
            return {
                ...obj,
                [value]: {
                    id: value,
                    name: name,
                },
            };
        }, {});
};

export default ({
    onCancel,
    changeConnection,
    connections,
    updateIntegration,
    accountId,
    integrationId,
    userId,
    hasIntegrations,
}) => {
    // onSubmit in redux-form
    const onSaveConnection = async (
        {
            [TIKTOK_CAMPAIGNS]: selectedCampaigns,
            tiktok: integration = {
                value: null,
            },
            tiktokIntegrationName = "",
            ...rest
        },
        _,
        { platform, connection: connectionId },
    ) => {
        const allCurrentAndFuture = selectedCampaigns["_all"];
        const campaignsSelected = allCurrentAndFuture ? {} : selectedCampaigns;
        const campaignsToSave = convertCampaignsToSave(campaignsSelected);

        await updateIntegration({
            integrations: {
                tiktok: {
                    value: {
                        [integration.value]: {
                            integrationName: tiktokIntegrationName || integration.label,
                            integrationId: integration.value,
                            campaigns: campaignsToSave,
                            regex: rest[`regex${platform}`],
                            connectionId,
                            currency: integration.currency,
                            userId,
                            created_at: moment().toISOString(),
                        },
                    },
                },
            },
            accountId,
        });

        onCancel();
    };
    return (
        <div className={`${style.integration} ${style.narrow}`}>
            <Icon icon={TikTokIcon} />
            <h1>Connect TikTok Ads</h1>
            <p>
                Please ensure you already logged into{" "}
                <a href="https://ads.tiktok.com/i18n/login" target="_blank">
                    TikTok Ads Manager
                </a>{" "}
                prior to integration.
            </p>
            <IntegrationWrapper
                onCancel={onCancel}
                onSave={onSaveConnection}
                platform="tiktok"
                integration={TikTokIntegration}
                changeConnection={changeConnection}
                connection={connections?.tiktok}
                integrationId={integrationId}
                disabled={!connections?.tiktok || !hasIntegrations}
            />
        </div>
    );
};
