import React, { useEffect, useState, useCallback } from "react";
import { useUpdatedRef } from "lib/hooks";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import style from "./style.module.scss";
import Button from "components/Button";
import classNames from "classnames";
import { mainPanelRef } from "components/MainPanel";
import throttle from "lodash/throttle";
import { mixpanel } from "components/Mixpanel";

/**
 * "Seen it" card design for seen it functionality
 * @param {props} props
 * @param {string} props.className classname for custom overrides
 * @param {function} props.handleSeenIt function passed down from "seen it" feature to handle dismissal
 * @param {string} props.title title of "seen it" card
 * @param {string} props.message message of "seen it" card
 * @param {string} props.position specifies the position of the card (see list of options in cardPos below)
 * @param {element} props.targetElement the DOM node being pointed to
 * @param {number} props.offset distance between the card and the targetElement
 * @param {string} props.it the id of the "seen it" instance
 */

const cardPos = (
    cardPos,
    { left = 0, top = 0, height = 0, width = 0 },
    { width: cardWidth = 0, height: cardHeight = 0 },
    offset,
    mainPanelElement
) => {
    const { offsetTop: mainPanelTop = 0, scrollTop: mainPanelScrollTop = 0 } = mainPanelElement;
    const topWithMainPanel = top - mainPanelTop + mainPanelScrollTop;
    const topAll_top = topWithMainPanel - cardHeight - offset;
    const bottomAll_top = topWithMainPanel + height + offset;
    const horizontalTop_top = topWithMainPanel - 30 + height / 2;
    const horizontalCenter_top = topWithMainPanel + height / 2 - cardHeight / 2;
    const horizontalBottom_top =
        height < 60
            ? topWithMainPanel - cardHeight + 30 + height / 2
            : topWithMainPanel + height - cardHeight;

    const verticalRight_left =
        width < 60
            ? left - cardWidth + 30 + width / 2
            : left + width - cardWidth;
    const verticalLeft_left = width < 60 ? left - 30 + width / 2 : left;
    const horizontalCenter_left = left + width / 2 - cardWidth / 2;
    const rightAll_left = left + width + offset;
    const leftAll_left = left - cardWidth - offset;

    return {
        "top-right": {
            top: topAll_top,
            left: verticalRight_left,
        },
        "top-left": { top: topAll_top, left: verticalLeft_left },
        "top-center": { top: topAll_top, left: horizontalCenter_left },
        "bottom-right": {
            top: bottomAll_top,
            left: verticalRight_left,
        },
        "bottom-left": { top: bottomAll_top, left: verticalLeft_left },
        "bottom-center": { top: bottomAll_top, left: horizontalCenter_left },
        "right-top": {
            top: height < 60 ? horizontalTop_top : topWithMainPanel,
            left: rightAll_left,
        },
        "right-bottom": {
            top: horizontalBottom_top,
            left: rightAll_left,
        },
        "right-center": { top: horizontalCenter_top, left: rightAll_left },
        "left-top": {
            top: height < 60 ? horizontalTop_top : topWithMainPanel,
            left: leftAll_left,
        },
        "left-bottom": {
            top: horizontalBottom_top,
            left: leftAll_left,
        },
        "left-center": { top: horizontalCenter_top, left: leftAll_left },
    }[cardPos];
};

const arrowPos = (
    cardPos,
    { width: cardWidth = 0, height: cardHeight = 0 }
) => {
    const arrowOvehang = "-0.375rem";
    const arrowDistance = "1.25rem";

    return {
        "top-right": { bottom: arrowOvehang, right: arrowDistance },
        "top-left": { bottom: arrowOvehang, left: arrowDistance },
        "top-center": { bottom: arrowOvehang, left: cardWidth / 2 - 10 },
        "bottom-right": { top: arrowOvehang, right: arrowDistance },
        "bottom-center": { top: arrowOvehang, left: cardWidth / 2 - 10 },
        "bottom-left": { top: arrowOvehang, left: arrowDistance },
        "right-top": { top: arrowDistance, left: arrowOvehang },
        "right-bottom": { bottom: arrowDistance, left: arrowOvehang },
        "right-center": { top: cardHeight / 2 - 10, left: arrowOvehang },
        "left-top": { top: arrowDistance, right: arrowOvehang },
        "left-bottom": { bottom: arrowDistance, right: arrowOvehang },
        "left-center": { top: cardHeight / 2 - 10, right: arrowOvehang },
    }[cardPos];
};

const defaultTransform = (cardPos) => {
    if (cardPos.includes("bottom-")) {
        return "translateY(0.375rem)";
    }
    if (cardPos.includes("top-")) {
        return "translateY(-0.375rem)";
    }
    if (cardPos.includes("right-")) {
        return "translateX(0.375rem)";
    }
    if (cardPos.includes("left-")) {
        return "translateX(-0.375rem)";
    }
};

const SeenItCard = ({
    className,
    handleSeenIt,
    title,
    message,
    position = "top-right",
    offset = 14,
    it,
    targetElement,
    width = "18rem",
}) => {
    const [cardStyles, setCardStyles] = useState({
        top: 0,
        left: 0,
        opacity: 0,
        transform: defaultTransform(position),
    });

    const [cardRef, updateCardRef] = useUpdatedRef();

    const { offsetWidth: cardWidth = 0, offsetHeight: cardHeight = 0 } =
        cardRef || {};
    const arrowStyles = arrowPos(position, {
        width: cardWidth,
        height: cardHeight,
    });

    const handleResize = useCallback(
        throttle(
            () => {
                const positionTarget = targetElement?.getBoundingClientRect();

                const targetInfo = {
                    left: positionTarget?.left,
                    top: positionTarget?.top,
                    height: targetElement?.offsetHeight,
                    width: targetElement?.offsetWidth,
                };

                setCardStyles({
                    ...cardPos(
                        position,
                        targetInfo,
                        { width: cardWidth, height: cardHeight },
                        offset,
                        mainPanelRef?.current || {}
                    ),
                    opacity: 1,
                    transform: "translate(0,0)",
                });
            },
            500,
            { leading: true }
        ),
        [cardWidth, cardHeight, position, targetElement]
    );

    const handleGotItClick = useCallback(() => {
        mixpanel.track("Seen It", { it });
        setCardStyles({
            ...cardStyles,
            opacity: 0,
            transition: "opacity 0.5s ease-in-out",
        });
        setTimeout(handleSeenIt, 500);
    }, [cardStyles, handleSeenIt, it]);

    useEffect(() => {
        if (!targetElement) {
            return;
        }

        const positionTarget = targetElement?.getBoundingClientRect();

        const targetInfo = {
            left: positionTarget?.left,
            top: positionTarget?.top,
            height: targetElement?.offsetHeight,
            width: targetElement?.offsetWidth,
        };

        setCardStyles({
            ...cardPos(
                position,
                targetInfo,
                { width: cardWidth, height: cardHeight },
                offset,
                mainPanelRef?.current || {}
            ),
            opacity: 1,
            transform: "translate(0,0)",
        });
    }, [targetElement, position, offset, cardWidth, cardHeight]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);

    const cardClasses = classNames(style.seenItCard, {
        [className]: className,
    });

    if (!mainPanelRef.current) {
        return null;
    }

    return ReactDOM.createPortal(
        <div
            ref={updateCardRef}
            className={cardClasses}
            style={{ ...cardStyles, width }}
        >
            <span style={arrowStyles} className={style.arrow}></span>
            {title && <h3>{title}</h3>}
            {message && <p>{message}</p>}
            <Button
                small
                hollowWhite
                className={style.gotIt}
                onClick={handleGotItClick}
            >
                Got it!
            </Button>
        </div>,
        mainPanelRef.current
    );
};

SeenItCard.propTypes = {
    className: PropTypes.string,
    handleSeenIt: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string,
    position: PropTypes.string,
    targetElement: PropTypes.instanceOf(Element),
    offset: PropTypes.number,
    it: PropTypes.string,
};

export default SeenItCard;
