import React from "react";
import { TooltipFlexible, TooltipHorizontalFloat } from "components/Tooltip";
import AnomalyPill from "components/AnomalyCountPill";
import HelpIcon from "assets/images/icons/JSX/CircleHelpIcon";
import OverspentBG from "assets/images/Dashboard/Bar-Red.png";
import OverBG from "assets/images/Dashboard/Bar-Orange.png";
import UnderBG from "assets/images/Dashboard/Bar-Grey.png";
import BudgetBarToolTip from "./PlatformBudgetTooltip";
import { toPrettyNumber } from "lib/metricCalculations";
import IconFinancial from "assets/images/Menu/JSX/Financial";
import generalStyles from "components/generalStyles.module.scss";

import Button from "components/Button";
import style from "./style.module.scss";
import moment from "moment";
import Avatar from "components/Avatar";

const barSizeOpts = {
    "super-small": {
        styleClass: style.superSmallBar,
        radius: "4px",
        estimatedFlagBarBottom: "-6px",
    },
    small: {
        styleClass: style.smallBar,
        radius: "5px",
        estimatedFlagBarBottom: "-8px",
    },
    large: {
        styleClass: style.largeBar,
        radius: "6px",
        estimatedFlagBarBottom: "-8px",
    },
    default: {
        styleClass: style.largeBar,
        radius: "6px",
        estimatedFlagBarBottom: "-8px",
    },
};

export const BudgetBar = ({
    platform,
    hasPartialOverage,
    calculatedExpectedBarWidth,
    calculatedBarWidth,
    hasCaps,
    hasOverageOverall,
    platformDetails,
    hasPartialShortage,
    spend,
    calculatedDifference,
    size = "large",
}) => {
    const borderRadius = barSizeOpts[size]?.radius;
    return (
        <div className={`${style.budgetBar} ${barSizeOpts[size]?.styleClass}`} key={platform}>
            <div className={style.breakDownBar} style={{ width: "100%" }}>
                <div
                    style={{
                        width: `${hasPartialOverage ? calculatedExpectedBarWidth : calculatedBarWidth}%`,
                        borderRadius:
                            hasCaps && !hasOverageOverall
                                ? `${borderRadius} ${borderRadius} ${borderRadius} ${borderRadius}`
                                : `${borderRadius} 0 0 ${borderRadius}`,
                    }}
                    className={`${platformDetails.style} ${style.platformBar}`}
                    key={platform}
                ></div>
                {hasPartialShortage && (
                    <div
                        style={{
                            borderRadius: spend === 0 ? `${borderRadius} 0 0 ${borderRadius}` : "0",
                            backgroundColor: "Transparent",
                            backgroundImage: `url(${UnderBG})`,
                            width: `${calculatedDifference}%`,
                        }}
                        className={`${platformDetails.style} ${style.platformBar}`}
                        key={platform + "-difference"}
                    ></div>
                )}
                {hasPartialOverage && (
                    <div
                        style={{
                            width: `calc(${calculatedDifference}% - 1px)`,
                            borderRadius: hasCaps ? `0 ${borderRadius} ${borderRadius} 0` : "0 0 0 0",
                            backgroundImage: `url(${hasOverageOverall ? OverspentBG : OverBG})`,
                            marginLeft: "1px",
                        }}
                        className={`${style.platformBar} ${style.overage}`}
                    ></div>
                )}
            </div>
            <div
                style={{
                    width: `${calculatedExpectedBarWidth}%`,
                    bottom: barSizeOpts[size]?.estimatedFlagBarBottom,
                }}
                className={style.estimatedFlagBar}
            ></div>
        </div>
    );
};

export default ({
    budgetLoading,
    monthlyBudget,
    date,
    dateRangeString,
    toggleDrawer,
    spend,
    calculatedBarWidth,
    calculatedExpectedBarWidth,
    calculatedDifference,
    platformDetails,
    expectedSpend,
    trendOptions,
    value,
    platform,
    hasCaps,
    hasOverageOverall,
    hasPartialOverage,
    hasPartialShortage,
    anomalyCount,
    accountId,
}) => {
    if (!budgetLoading && monthlyBudget === 0) {
        return (
            <div className={`${style.budgetContainer} ${platform === "total" && style.budgetTotalContainer}`}>
                <div className={style.budgetHeader}>
                    <p className={style.platformName}>
                        <img src={platformDetails.colorIcon} alt={platformDetails.name} />
                        {platformDetails.name}
                    </p>
                    <AnomalyPill
                        tipId={`anomalyTip-${platform}`}
                        anomalyUrl={anomalyCount.url}
                        className={style.budgetAnomaly}
                        length={0}
                    />
                </div>
                <div className={style.budgetBarWrapper}>
                    <div className={style.noBudgetBarContainer}>
                        <div className={style.noBudgetContentContainer}>
                            <Avatar icon={<IconFinancial />} className={style.avatar} />
                            <div>
                                <h4>
                                    {platformDetails.name} budget for {moment(date).format("MMMM YYYY")} has not been
                                    set.
                                </h4>
                                <p>
                                    Once your budget is set, we will monitor your media spend on a daily basis and alert
                                    you if it is not on track.
                                </p>
                            </div>
                        </div>
                        <Button onClick={() => toggleDrawer(true)} medium>
                            Set Budget
                        </Button>
                    </div>
                    <div className={`${style.budgetBarInfo} ${style.noBudgetInfo}`}>
                        <div className={style.budgetBarInfoSection}>
                            <span className={style.budgetSummaryMonthly}>
                                {toPrettyNumber(0, `${platform}-cost`, platform === "total" ? accountId : null)}
                            </span>
                            <span className={style.spendDesc}>{moment(date).format("MMMM")} Budget</span>
                        </div>
                        <div className={style.expectedBarHoverSpendToDate}>
                            <div className={style.spendToDateNumberContainer}>
                                <p className={style.barHoverSpend}>
                                    {toPrettyNumber(0, `${platform}-cost`, platform === "total" ? accountId : null)}
                                </p>
                            </div>
                            <p className={style.barHoverSubtitle}>Spend to Date</p>
                        </div>
                        <div className={style.expectedBarHoverExpectedSpend}>
                            <p className={style.barHoverSpend}>
                                {toPrettyNumber(0, `${platform}-cost`, platform === "total" ? accountId : null)}
                            </p>
                            <p className={style.barHoverSubtitle}>Expected Spend</p>
                        </div>

                        <div className={style.budgetBarInfoSection}>
                            <p style={{ color: trendOptions.color }} className={style.spend}>
                                {`${toPrettyNumber(0, `${platform}-cost`, platform === "total" ? accountId : null)}`}
                            </p>
                            <span className={style.spendDesc}>Spend Difference</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const hasOverage = () => {
        if (!hasPartialOverage) return null;

        if (hasOverageOverall) {
            return "red";
        } else {
            return "yellow";
        }
    };

    const borderRadius = "6px";

    return (
        <div className={`${style.budgetContainer} ${platform === "total" && style.budgetTotalContainer}`}>
            <div className={style.budgetHeader}>
                <p className={style.platformName}>
                    <img src={platformDetails.colorIcon} alt={platformDetails.name} />
                    {platformDetails.name}
                </p>
                <AnomalyPill
                    tipId={`anomalyTip-${platform}`}
                    anomalyUrl={anomalyCount.url}
                    className={style.budgetAnomaly}
                    length={anomalyCount.count}
                />
            </div>
            <div className={style.budgetBarWrapper}>
                <div
                    data-html={true}
                    data-tip={BudgetBarToolTip({
                        expected: toPrettyNumber(
                            expectedSpend,
                            `${platform}-cost`,
                            platform === "total" ? accountId : null,
                        ),
                        actual: toPrettyNumber(
                            Math.abs(spend),
                            `${platform}-cost`,
                            platform === "total" ? accountId : null,
                        ),
                        overage: toPrettyNumber(
                            value.value,
                            `${platform}-cost`,
                            platform === "total" ? accountId : null,
                        ),
                        hasOverage: hasOverage(),
                        date: dateRangeString,
                    })}
                    data-for={platform}
                    className={style.budgetBarContainer}
                >
                    <BudgetBar
                        platform={platform}
                        hasPartialOverage={hasPartialOverage}
                        calculatedExpectedBarWidth={calculatedExpectedBarWidth}
                        calculatedBarWidth={calculatedBarWidth}
                        hasCaps={hasCaps}
                        hasOverageOverall={hasOverageOverall}
                        borderRadius={borderRadius}
                        platformDetails={platformDetails}
                        hasPartialShortage={hasPartialShortage}
                        spend={spend}
                        calculatedDifference={calculatedDifference}
                        size={platform === "total" ? "large" : "small"}
                    />
                </div>
                <TooltipHorizontalFloat id={platform} html className={style.budgetBarTooltip} />
                <div className={style.budgetBarInfo}>
                    <div className={style.budgetBarInfoSection}>
                        <span className={style.budgetSummaryMonthly}>
                            {toPrettyNumber(monthlyBudget, `${platform}-cost`, platform === "total" ? accountId : null)}
                        </span>
                        <span className={style.spendDesc}>
                            {moment(date).format("MMMM")} Budget
                            <TooltipFlexible
                                width="14rem"
                                className={style.tooltip}
                                message={
                                    <span className={style.message}>
                                        Planned media spend based on input provided in Budget Settings.
                                    </span>
                                }
                                arrowPosition="center"
                            >
                                <HelpIcon />
                            </TooltipFlexible>
                        </span>
                    </div>
                    <div className={style.expectedBarHoverSpendToDate}>
                        <div className={style.spendToDateNumberContainer}>
                            <p className={style.barHoverSpend}>
                                {toPrettyNumber(spend, `${platform}-cost`, platform === "total" ? accountId : null)}
                            </p>
                        </div>
                        <span className={style.barHoverSubtitle}>
                            Spend to Date
                            <TooltipFlexible
                                width="14rem"
                                className={style.tooltip}
                                message={
                                    <span className={style.message}>
                                        Actual <strong>{platformDetails.name}</strong> media spend from{" "}
                                        <strong>{dateRangeString}</strong>.
                                    </span>
                                }
                                arrowPosition="center"
                            >
                                <HelpIcon />
                            </TooltipFlexible>
                        </span>
                    </div>
                    <div className={style.expectedBarHoverExpectedSpend}>
                        <p className={style.barHoverSpend}>
                            {toPrettyNumber(expectedSpend, `${platform}-cost`, platform === "total" ? accountId : null)}
                        </p>
                        <span className={style.barHoverSubtitle}>
                            Expected Spend
                            <TooltipFlexible
                                width="14rem"
                                className={style.tooltip}
                                message={
                                    <span className={style.message}>
                                        Expected <strong>{platformDetails.name}</strong> media spend from{" "}
                                        <strong>{dateRangeString}</strong> based on our analysis of historical data.
                                    </span>
                                }
                                arrowPosition="center"
                            >
                                <HelpIcon />
                            </TooltipFlexible>
                        </span>
                    </div>
                    <div className={`${style.budgetBarInfoSection} ${style.difference}`}>
                        <p className={style.spend}>
                            {toPrettyNumber(value.value, `${platform}-cost`, platform === "total" ? accountId : null)}
                            <span
                                className={value.value === 0 ? generalStyles.zeroGrowth : generalStyles.negativeGrowth}
                                style={{ marginLeft: "0.375rem", transform: "translateY(-0.0625rem)" }}
                            >
                                {value.symbol && value.symbol}
                                {toPrettyNumber((value.value / monthlyBudget) * 100, "percent")}
                            </span>
                        </p>
                        <span className={style.spendDesc}>
                            Spend Difference
                            <TooltipFlexible
                                width="14rem"
                                className={style.tooltip}
                                message={
                                    <span className={style.message}>
                                        Difference in actual vs. expected media spend from{" "}
                                        <strong>{dateRangeString}</strong>.
                                    </span>
                                }
                                arrowPosition="center"
                            >
                                <HelpIcon />
                            </TooltipFlexible>
                        </span>
                    </div>
                    <div>
                        <trendOptions.icon />
                    </div>
                </div>
            </div>
        </div>
    );
};
