import { connect } from "react-redux";
import IntegrateStep from "./index";
import { operations as connectOperations } from "features/Integrations/_ducks";
import { change, getFormValues } from "redux-form";
import { actions, operations } from "features/Onboarding/ducks";
import { makeSelectGetIntegrationIdList } from "_redux/sites/selectors";
import { makeSelectUserId, makeSelectUserConnections } from "_redux/users/selectors";
import { makeSelectOnboardingIntegrations } from "features/Onboarding/ducks/selectors";
import { makeSelectIntegrationOpts } from "features/Integrations/_ducks/selectors";

const { exchangeAnalyticsV4Code } = connectOperations;
const { onboardingPlatformConnection } = actions;
const { getAnalyticsV4Accounts } = operations;

const mapStateToProps = (state, { formName, connection, integrationId }) => {
    const { form } = state;
    const alreadyIntegrated = makeSelectGetIntegrationIdList("analyticsV4")(state);
    const userId = makeSelectUserId()(state);
    const { analyticsV4 } = makeSelectUserConnections()(state);
    const allIntegrations = makeSelectOnboardingIntegrations()(state);
    const { analyticsV4: analyticsV4Options } = makeSelectIntegrationOpts()(state);

    const { [`${connection}_analyticsV4`]: analyticsV4Integrations = false } = allIntegrations;
    const formValues = getFormValues(formName)({ form });

    return {
        formName,
        userId,
        list: analyticsV4,
        selected: connection,
        options: analyticsV4Options,
        selectedIntegrations: formValues,
        integrations: analyticsV4Integrations,
        integrationId,
        alreadyIntegrated: formName === "onboarding" ? [] : alreadyIntegrated,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getAnalyticsV4Accounts: ({ userId, connectionId }) => dispatch(getAnalyticsV4Accounts({ userId, connectionId })),
    exchangeAnalyticsV4Code: (code) => {
        return dispatch(exchangeAnalyticsV4Code(code)).then(({ id }) => {
            dispatch(
                onboardingPlatformConnection({
                    platform: "analyticsV4",
                    connectionId: id,
                }),
            );
        });
    },
    change: (formName, ...args) => dispatch(change(formName, ...args)),
});

const mergeProps = ({ formName, ...stateProps }, { change, ...dispatchProps }, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    change: (...args) => {
        return change(formName, ...args);
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IntegrateStep);
