import React from "react";
import styleMobile from "./styleMobile.module.scss";
import ThreeDots from "components/Loading/ThreeDots";
import { toPrettyNumber } from "lib/metricCalculations";
import classNames from "classnames";
import { channelFriendlyName, getTrendRgbaColor } from "lib/utility";
import { Icon } from "components/Icon/PlatformIcon";

export const BudgetPlatformsContentMobile = ({
    isLoading,
    platform,
    spend,
    accountId,
    currencyPerPlatform,
    currency,
    budget,
    trend,
}) => {
    return (
        <div className={classNames(`${styleMobile.group} ${styleMobile.metricsGroup}`)}>
            {isLoading && (
                <div className={styleMobile.loadingDots}>
                    <ThreeDots color="#1b264f" />
                </div>
            )}
            {!isLoading && (
                <>
                    <div className={styleMobile.metricTitle}>
                        <span className={styleMobile.metricName}>Media Spend</span>
                        <span className={styleMobile.platformIcon}>
                            <Icon platform={platform} /> {channelFriendlyName(platform)}
                        </span>
                    </div>
                    <div className={styleMobile.metricNumber}>
                        <span className={styleMobile.recentValue}>
                            {toPrettyNumber(spend, "spend", accountId, null, currencyPerPlatform[platform] || currency)}
                        </span>

                        <div className={styleMobile.submetrics}>
                            {spend > 0 && !budget && <div className={styleMobile.noBudgetCircle}></div>}
                            Budget:
                            <strong>
                                <div
                                    style={{
                                        color: trend ? `rgb(${getTrendRgbaColor(trend)})` : "",
                                    }}
                                >
                                    {toPrettyNumber(budget, "budget", accountId, null, currency)}
                                </div>
                            </strong>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};