import React from "react";
import BigQueryIcon from "assets/images/Integrations/JSX/Logo-BigQuery";
import BigQueryIntegration from "features/Integrations/Dependant/BigQuery/container";
import Icon from "features/Onboarding/IntegrateStep/Icon";
import IntegrationWrapper from "features/Integrations/Independant/IntegrationWrapper/container";
import style from "../style.module.scss";
import moment from "moment";
import {
    formatStoredIntegrationKey,
    formatAdvancedMetricsToSave,
} from "lib/bigQuery/helpers";

export default ({
    onCancel,
    changeConnection,
    connections,
    updateIntegration,
    accountId,
    integrationId,
    userId,
    hasIntegrations,
}) => {
    // onSubmit in redux-form
    const onSaveConnection = async (
        formValues,
        _,
        { platform, connection: connectionId }
    ) => {
        const {
            integrationName,
            bigQuery: integration = {
                value: null,
            },
            bigQueryTable,
            bigQueryDateRangeField = {},
            bigQueryBasicMetrics = [],
            bigQueryAdvancedMetrics = [],
            currency,
        } = formValues;
        await updateIntegration({
            integrations: {
                bigQuery: {
                    value: {
                        [`${integration.value}.${bigQueryTable.value}`]: {
                            integrationName,
                            integrationId: integration.value,
                            bigQueryTable,
                            bigQueryDateRangeField,
                            bigQueryBasicMetrics,
                            bigQueryAdvancedMetrics: formatAdvancedMetricsToSave(
                                bigQueryAdvancedMetrics
                            ),
                            connectionId,
                            currency,
                            userId,
                            created_at: moment().toISOString(),
                        },
                    },
                },
            },
            accountId,
        });
        onCancel();
    };

    return (
        <div className={`${style.integration} ${style.narrow}`}>
            <Icon icon={BigQueryIcon} />
            <h1>Connect Google BigQuery</h1>
            <p>Connect your Google BigQuery dataset.</p>
            <IntegrationWrapper
                onCancel={onCancel}
                onSave={onSaveConnection}
                platform="bigQuery"
                integration={BigQueryIntegration}
                changeConnection={changeConnection}
                connection={connections?.bigQuery}
                integrationId={formatStoredIntegrationKey(integrationId)}
                disabled={!connections?.bigQuery || !hasIntegrations}
            />
        </div>
    );
};
