import React from "react";
import style from "./style.module.scss";
import { CloseDialog } from "components/Dialog";

function StepContainer({ title = "", subtitle, children, onCloseHandle }) {
    return (
        <div className={style.stepWrapper}>
            {onCloseHandle && <CloseDialog closeActions={onCloseHandle} />}
            {title && <p className={style.title}>{title}</p>}
            {subtitle && <p className={style.subTitle}>{subtitle}</p>}
            {children}
        </div>
    );
}

export default StepContainer;
