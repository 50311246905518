import { round } from "lodash/math";
import { toDecimalJs } from "lib/metricCalculations";
import get from "lodash/get";
import { pickBy } from "lodash/object";
import { identity } from "lodash/util";
import React from "react";
import { getStatus, PLATFORM_STATUSES } from "../helpers";
import { getPlatformStatus } from "lib/campaignUtils";
import getSymbolFromCurrency from "currency-symbol-map";
import { isEmpty, startCase } from "lodash";
import { parseNumberField } from "lib/utility";

export const EDIT_STEP_INDEX = 0;

export const REVIEW_STEP_INDEX = 1;

export const SAVING_STEP_INDEX = 2;

export const DANGEROUS_CHANGE_PERCENT = 20;

export const DEFAULT_ERROR_MESSAGE = "Update Campaign failed";

export const MISS_PERMISSION_ERROR_MESSAGE = (
    <p>
        This connection doesn't have permissions. Please re-connect the integration{" "}
        <a href="/dashboard/user/connections" target="_self">
            here.
        </a>
    </p>
);

export const EDIT_CAMPAIGN_NAME = "editCampaign";

export const getBudgetChangePercent = (newBudget, oldBudget) => {
    const formattedOldBudget = toDecimalJs(oldBudget).toNumber();
    if (!formattedOldBudget) return 0;
    const changePercent = (100 * (toDecimalJs(newBudget).toNumber() - formattedOldBudget)) / oldBudget;
    return round(changePercent, 2);
};

export const getBudgetWarn = (changePercent) => {
    const budgetSuffix = changePercent > 0 ? "more" : "less";
    return `Your new budget is ${Math.abs(changePercent)}% ${budgetSuffix} than your current budget.`;
};

export const getErrorMessageFromRes = (res) => {
    const { error, payload } = res || {};
    if (error) {
        const data = get(payload, ["response", "data"]);

        return getErrorMessage(data);
    }
    return "";
};

export const getErrorMessage = (data = {}) => {
    if (isEmpty(data)) return "";
    const { message, status } = data;
    const messageFormatted = isEmpty(message) ? DEFAULT_ERROR_MESSAGE : message;

    return status === 403 ? MISS_PERMISSION_ERROR_MESSAGE : messageFormatted;
};

export const prepareUpdateCampaignData = (
    formData,
    editingCampaignInfo,
    integrations,
    initialValues,
    accountId,
    userId,
) => {
    const { campaignId, integrationId, platform, budgetType = "" } = editingCampaignInfo || {};

    let formattedIntegrationId =
        platform === "facebook" && !integrationId.includes("act_") ? `act_${integrationId}` : integrationId;
    const budgetField = `${budgetType}Budget`;

    const connectionId = get(integrations, [platform, formattedIntegrationId, "connectionId"]);
    const oldBudget = get(initialValues, [budgetField]);
    const oldStatus = get(initialValues, ["status", "value"]);
    const newBudget = get(formData, [budgetField]);
    const newStatus = get(formData, ["status", "value"]);
    const data = pickBy(
        {
            budget: newBudget !== oldBudget && toDecimalJs(newBudget).toNumber(),
            status: newStatus !== oldStatus && newStatus,
        },
        identity,
    );

    return {
        accountId,
        campaignId,
        integrationId: formattedIntegrationId,
        userId,
        platform,
        connectionId,
        data,
    };
};

const getDisabledBudgetCause = ({ readOnly, budgetType, platform }) => {
    if (platform === "stackAdapt") {
        return "This field is not editable.";
    }

    if (readOnly) {
        return "This campaign is not editable.";
    }

    if (!Boolean(budgetType) || budgetType === "unlimited") {
        return "We only support edits to Daily or Lifetime budgets.";
    }

    return "This field is not editable";
};

const isDisabledBudget = ({ platform, readOnly, budgetType }) => {
    const { active = true } = PLATFORM_STATUSES[platform] || {};
    return (
        !active || platform === "stackAdapt" || Boolean(readOnly) || !Boolean(budgetType) || budgetType === "unlimited"
    );
};

const getStackAdaptStatusOptions = (currentStatus) => {
    const allStatuses = getPlatformStatus("stackAdapt");
    const newAvailableStatus = [currentStatus, currentStatus === "PAUSED" ? "RESUMED" : "PAUSED"];

    return Object.keys(allStatuses)
        .filter((statusKey) => newAvailableStatus.includes(statusKey))
        .map((statusKey) => ({
            value: statusKey,
            label: allStatuses[statusKey],
        }));
};

const getStatusOptions = (platform) => {
    const platformStatus = getPlatformStatus(platform);
    return Object.keys(platformStatus)
        .sort((a, b) => getStatus(a, platform).order - getStatus(b, platform).order)
        .map((statusKey) => ({
            value: statusKey,
            label: platformStatus[statusKey],
        }));
};

export const getCampaignUpdateInfo = ({ platform, readOnly, budgetType, currency, status }) => {
    const { active = true } = PLATFORM_STATUSES[platform] || {};
    const isStatusDisabled = Boolean(readOnly) || !active;
    const isBudgetDisabled = isDisabledBudget({ platform, readOnly, budgetType });
    const disableCause = getDisabledBudgetCause({ readOnly, budgetType, platform });
    const symbol = getSymbolFromCurrency(currency);

    return {
        isStatusDisabled,
        isBudgetDisabled,
        statusOptions: platform === "stackAdapt" ? getStackAdaptStatusOptions(status) : getStatusOptions(platform),
        disableCause,
        symbol,
    };
};

const getInitBudgets = (editingCampaignInfo = {}) => {
    const { budget = 0, budgetType = "", platform = "", budgets = [] } = editingCampaignInfo || {};

    if (platform === "stackAdapt") {
        return budgets.reduce((cache, currentBudget) => {
            return { ...cache, [`${currentBudget.type}Budget`]: parseNumberField(currentBudget.budget) };
        }, {});
    }

    return {
        dailyBudget: budgetType === "daily" ? parseNumberField(budget) : undefined,
        lifetimeBudget: budgetType === "lifetime" ? parseNumberField(budget) : undefined,
        unlimitedBudget: budgetType === "unlimited" ? 0 : undefined,
    };
};

export const getFormInitialValues = (editingCampaignInfo = {}) => {
    const { status = "" } = editingCampaignInfo || {};
    const budgets = getInitBudgets(editingCampaignInfo) || {};

    return {
        ...budgets,
        status: {
            value: status,
            label: startCase(status.toLowerCase()),
        },
    };
};
