import React from "react";
import { ReactComponent as IconArrow } from "assets/images/icons/Icon-Arrow-Metrics.svg";
import { ReactComponent as IconArrowDown } from "assets/images/icons/Icon-Arrow-Metrics-Down.svg";
import MetricPropSelector from "./MetricPropSelector";
import style from "./style.module.scss";
import { find, get } from "lodash";
import RenderInput from "components/RenderInput";
import TextArea from "components/RenderInput/TextArea";
import { customAlphabet } from "nanoid";

export const METRICS_LIST_CELL_HEADERS = [
    {
        id: "name",
        label: "Metric",
        padding: "1.25rem 0.875rem 1.125rem",
        hasDivider: false,
        align: "left",
        freeze: true,
        width: 350,
    },
    {
        id: "format",
        label: "Format",
        padding: "1.25rem 0.875rem 1.125rem",
        hasDivider: false,
        align: "left",
        width: 210,
    },
    {
        id: "aggregation",
        label: "Aggregation",
        padding: "1.25rem 0.875rem 1.125rem",
        hasDivider: false,
        align: "left",
        width: 250,
    },
    {
        id: "performanceType",
        label: "Performance Type",
        padding: "1.25rem 0.875rem 1.125rem",
        hasDivider: false,
        align: "left",
        width: 250,
    },
];

export const ADVANCED_METRICS_LIST_CELL_HEADERS = [
    ...METRICS_LIST_CELL_HEADERS,
    {
        id: "query",
        label: "Query",
        padding: "1.25rem 0.875rem 1.125rem",
        hasDivider: false,
        align: "left",
    },
];

export const METRIC_FORMAT_OPTIONS = {
    string: {
        value: "string",
        label: <span className={style.selectionOption}>String (abc)</span>,
        labelText: "String (abc)",
    },
    number: {
        value: "number",
        label: <span className={style.selectionOption}>Number (#)</span>,
        labelText: "Number (#)",
    },
    percentage: {
        value: "percentage",
        label: <span className={style.selectionOption}>Percentage (%)</span>,
        labelText: "Percentage (%)",
    },
    currency: {
        value: "currency",
        label: <span className={style.selectionOption}>Currency ($)</span>,
        labelText: "Currency ($)",
    },
};

export const METRIC_AGGREGATION_OPTIONS = {
    count: {
        value: "count",
        label: <span className={style.selectionOption}>Count</span>,
        labelText: "Count",
    },
    sum: {
        value: "sum",
        label: <span className={style.selectionOption}>Sum</span>,
        labelText: "Sum",
    },
    average: {
        value: "average",
        label: <span className={style.selectionOption}>Average</span>,
        labelText: "Average",
    },
};

export const METRIC_PERFORMANCE_OPTIONS = {
    higherIsBetter: {
        value: "higherIsBetter",
        label: (
            <span className={style.selectionOption}>
                <span>
                    <IconArrow />
                </span>{" "}
                Higher is Better
            </span>
        ),
        icon: () => <IconArrow />,
        labelText: "Higher is Better",
    },
    lowerIsBetter: {
        value: "lowerIsBetter",
        label: (
            <span className={style.selectionOption}>
                <span>
                    <IconArrowDown />
                </span>{" "}
                Lower is Better
            </span>
        ),
        icon: () => <IconArrowDown />,
        labelText: "Lower is Better",
    },
};

const enableAggregationPerformanceType = (field = {}) => {
    const { format = "" } = field;
    return format !== "string";
};

export const getMetricRows = ({
    fields = [],
    selectedMetrics = [],
    onFieldChangeHandler,
    isAdvancedMetric = false,
}) => {
    return fields.map((field) => {
        const { name: fieldId } = field || {};
        const isSelected = find(selectedMetrics, { id: fieldId });
        const validField = isSelected ? isSelected : field;
        const {
            id,
            name,
            type,
            format = "",
            aggregation = "",
            performanceType = "",
            query = "",
        } = validField || {};
        const metricId = id || name;
        const needAggregationPerformanceType = enableAggregationPerformanceType(
            validField
        );
        return {
            id: metricId,
            type,
            value: name,
            name: isAdvancedMetric
                ? {
                      value: name,
                      content: (
                          <RenderInput
                              className={style.inputField}
                              input={{
                                  value: name,
                                  onChange: (event) => {
                                      onFieldChangeHandler({
                                          metricId,
                                          newValues: {
                                              name: event.target.value,
                                          },
                                      });
                                  },
                              }}
                              placeholder="Metric Name"
                          />
                      ),
                  }
                : {
                      value: name,
                      content: (
                          <span className={style.tableCellName}>{name}</span>
                      ),
                  },
            format: {
                value: format,
                content: (
                    <MetricPropSelector
                        options={Object.values(METRIC_FORMAT_OPTIONS)}
                        placeholder="Select Format"
                        selectorValue={format}
                        selectorValueLabel={get(METRIC_FORMAT_OPTIONS, [
                            format,
                            "labelText",
                        ])}
                        onChangeHandler={(value) =>
                            onFieldChangeHandler({
                                metricId,
                                newValues: { format: value },
                            })
                        }
                    />
                ),
            },
            aggregation: {
                value: aggregation,
                content: (
                    <MetricPropSelector
                        options={Object.values(METRIC_AGGREGATION_OPTIONS)}
                        placeholder="Select Aggregation"
                        selectorValue={aggregation}
                        selectorValueLabel={get(METRIC_AGGREGATION_OPTIONS, [
                            aggregation,
                            "labelText",
                        ])}
                        onChangeHandler={(value) =>
                            onFieldChangeHandler({
                                metricId,
                                newValues: { aggregation: value },
                            })
                        }
                        isDisabled={!needAggregationPerformanceType}
                    />
                ),
            },
            performanceType: {
                value: performanceType,
                content: (
                    <MetricPropSelector
                        options={Object.values(METRIC_PERFORMANCE_OPTIONS)}
                        placeholder="Select Performance Type"
                        selectorValue={performanceType}
                        selectorValueLabel={get(METRIC_PERFORMANCE_OPTIONS, [
                            performanceType,
                            "labelText",
                        ])}
                        onChangeHandler={(value) =>
                            onFieldChangeHandler({
                                metricId,
                                newValues: { performanceType: value },
                            })
                        }
                        isDisabled={!needAggregationPerformanceType}
                    />
                ),
            },
            query: isAdvancedMetric
                ? {
                      value: query,
                      content: (
                          <TextArea
                              input={{
                                  value: query,
                                  onChange: (event) => {
                                      onFieldChangeHandler({
                                          metricId,
                                          newValues: {
                                              query: event.target.value,
                                          },
                                      });
                                  },
                              }}
                              minRows={1}
                              placeholder="Add Query..."
                          />
                      ),
                  }
                : {},
        };
    });
};

const nanoid = customAlphabet("1234567890abcdef", 10);

const INITIAL_ADVANCED_METRIC = {
    name: "",
    value: "",
    format: "",
    type: "",
    aggregation: "",
    performanceType: "",
    query: "",
    isSelected: false,
};

export const generateNewAdvancedMetric = () => ({
    id: `advancedMetric_${nanoid()}`,
    ...INITIAL_ADVANCED_METRIC,
});
