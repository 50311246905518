import React, { useState, useEffect, useRef } from "react";
import Checkbox from "../../Checkbox";
import style from "./style.module.scss";
import isEmpty from "lodash/fp/isEmpty";
import SearchInput from "components/SearchBox";
import PropTypes from "prop-types";

/**
 *
 *  @param {object} props
 *  @param {Array<object>} props.options
 *  @param {string} props.className
 *  @param {object} props.search
 *  @param {function} props.onChangeAll
 *  @param {boolean} props.selectAllByDefault
 *  @param {boolean} props.disableSelectAll
 *  @param {string} props.selectAllTxt
 *  @param {JSX.Element} props.bottomBar
 *  @param {boolean} props.reverse
 *  @param {boolean} props.largeSelect
 *  @param {boolean} props.hideSelectAll
 *  @param {object} props.filters
 *  @param {JSX.Element | Array.<JSX.Element>} props.children
 *
 *  @returns {JSX.Element}
 */

function CheckboxSearchWrapper({
    options = [],
    className,
    search = {},
    bottomBar,
    reverse = false,
    largeSelect = false,
    onChangeAll,
    sortFn,
    filterFn,
    children,
    hideSelectAll,
    disableSelectAll,
    selectAllTxt,
    selectAllByDefault,
    filters,
    searchTermInText = false,
    currentAndFuture = false,
    searchTerm,
    changeSearchTerm,
    clearTxt = "Clear All",
    onClearAll,
    toggleFocus,
    isLoadingData,
}) {
    const [filteredOpts, changeFilteredOpts] = useState(options);
    const hasSearch = !isEmpty(search) && search.hasOwnProperty("accessor");
    const searchRef = useRef();

    useEffect(() => {
        if (toggleFocus) {
            // Focus the search on open
            setTimeout(() => searchRef.current && searchRef.current.focus(), 100);
        }
    }, [toggleFocus]);

    return (
        <>
            <div className={`${style.Wrapper} ${hasSearch && style.Search} ${className} ${reverse && style.reverse}`}>
                <SearchInput
                    foundListContainerStyle={style.ScrollContainer}
                    pathOfFilterKey={search?.accessor}
                    list={options}
                    sortFn={sortFn || null}
                    filterFn={filterFn || null}
                    filters={filters}
                    isLarge={largeSelect}
                    onChange={changeSearchTerm}
                    disabled={currentAndFuture}
                    changeFilteredOpts={changeFilteredOpts}
                    ref={searchRef}
                    isLoadingData={isLoadingData}
                    {...search}
                >
                    {({ foundList }) => {
                        return typeof children === "function" ? children({ foundList }) : null;
                    }}
                </SearchInput>
                <ChangeAll
                    selectAllByDefault={selectAllByDefault}
                    disableSelectAll={disableSelectAll}
                    currentAndFuture={currentAndFuture}
                    hideSelectAll={hideSelectAll}
                    selectAllTxt={selectAllTxt}
                    onChangeAll={onChangeAll}
                    bottomBar={bottomBar}
                    reverse={reverse}
                    options={filteredOpts}
                    searchTerm={searchTerm}
                    searchTermInText={searchTermInText}
                />
                {onClearAll && <ClearAll bottomBar={bottomBar} onClearAll={onClearAll} clearTxt={clearTxt} />}
            </div>
        </>
    );
}

function ClearAll({ bottomBar: BottomBar, clearTxt, onClearAll }) {
    return (
        <div className={style.BottomBar}>
            <div className={style.clearAll} onClick={onClearAll}>
                <span>{clearTxt}</span>
            </div>
            {typeof BottomBar === "function" && (
                <div className={style.BottomContents}>
                    <BottomBar />
                </div>
            )}
        </div>
    );
}

function ChangeAll({
    hideSelectAll,
    disableSelectAll,
    currentAndFuture,
    selectAllTxt,
    reverse,
    onChangeAll,
    options,
    selectAllByDefault,
    bottomBar: BottomBar,
    searchTerm = "",
    searchTermInText,
}) {
    const canSelectAll = options.some(({ disabled }) => !disabled);
    const [selectAll, toggleSelected] = useState(Boolean(selectAllByDefault));
    const selectedList = options.filter(({ checked }) => checked || currentAndFuture);
    const isAllSelected = options.filter(({ disabled }) => !disabled).every(({ checked }) => checked);
    const truncateSearchTerm = searchTerm.length > 50 ? `${searchTerm.substring(0, 50)}...` : searchTerm;
    const label = selectAllTxt
        ? `${selectAllTxt} ${searchTerm && searchTermInText ? `Searched "${truncateSearchTerm}"` : ""}`
        : `Select All ${searchTerm && searchTermInText ? `Searched "${truncateSearchTerm}"` : ""}`;

    useEffect(() => {
        toggleSelected(isAllSelected);
    }, [isAllSelected]);
    const selectAllInput = {
        onChange: () => {
            return onChangeAll(
                options
                    .filter(({ disabled }) => !disabled)
                    .map((option) => {
                        return option;
                    }),
                !selectAll,
            );
        },
        checked: selectAll,
    };

    return (
        ((typeof onChangeAll === "function" && canSelectAll) || typeof BottomBar === "function") && (
            <div className={style.BottomBar}>
                {typeof BottomBar === "function" && (
                    <div className={style.BottomContents}>
                        <BottomBar />
                    </div>
                )}
                {!hideSelectAll && (
                    <div className={style.totalSelected}>
                        <strong>{isAllSelected ? "All" : selectedList.length}</strong> Selected
                    </div>
                )}
                {typeof onChangeAll === "function" && canSelectAll && (
                    <div className={`${style.SelectAll} ${disableSelectAll || currentAndFuture ? style.fade : ""}`}>
                        <Checkbox
                            disabled={disableSelectAll}
                            autoChecked={currentAndFuture}
                            className={style.Checkbox}
                            label={label}
                            flipped={reverse}
                            input={selectAllInput}
                        />
                    </div>
                )}
            </div>
        )
    );
}

CheckboxSearchWrapper.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    search: PropTypes.object,
    onChangeAll: PropTypes.func,
    selectAllByDefault: PropTypes.bool,
    disableSelectAll: PropTypes.bool,
    selectAllTxt: PropTypes.string,
    bottomBar: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    reverse: PropTypes.bool,
    largeSelect: PropTypes.bool,
    hideSelectAll: PropTypes.bool,
    children: PropTypes.func,
    filters: PropTypes.object,
    selectAllCurrentAndFuture: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default CheckboxSearchWrapper;
