import React from "react";

export default () => (
    <svg fill="#333333" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            d="M14,3 L14,9 C14,9.5 13.5,10 13,10 L13,12 L14,12 C15,12 16,11 16,10 L16,2 C16,1.00481604 15.0024882,0 14,0 L6,0 C5,0 4,1 4,2 L4,3 L6,3 C6,2.5 6.5,2 7.00019094,2 L13,2 C13.5,2 14,2.5 14,3 Z M9.99238211,4 C11,4 12,5.00175309 12,6 L12,14 C12,15 10.9953956,16 10,16 L2,16 C1,16 0,15.0030823 0,14 L0,6 C0,4.99986267 1,4 2,4 L9.99238211,4 Z M8.99893558,6 L3.00106443,6 C2.44725688,6 2,6.44819181 2,7.00106443 L2,7.00106443 L2,12.9989356 C2,13.5527431 2.44819181,14 3.00106443,14 L3.00106443,14 L8.99893558,14 C9.55274312,14 10,13.5518082 10,12.9989356 L10,12.9989356 L10,7.00106443 C10,6.44725687 9.55180819,6 8.99893558,6 L8.99893558,6 Z"
            transform="matrix(-1 0 0 1 16 0)"
        />
    </svg>
);
