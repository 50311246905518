import React from "react";
import qs from "querystring";
import openWindow from "../openWindow";
import { LINKEDIN_AUTHENTICATION_SCOPE } from "lib/campaignUtils";

const { REACT_APP_DOMAIN, REACT_APP_LINKEDIN_CLIENT_ID } = process.env;

const redirectUrl = `${REACT_APP_DOMAIN}/dashboard/account/connect`;

class LinkedinConnect extends React.Component {
    state = {
        loading: false,
        platform: "linkedin",
        label: "LinkedIn Ads"
    };

    csrfCode() {
        return Math.random()
            .toString(36)
            .substring(7);
    }

    login = async (cb, secret) => {
        return openWindow(this.buildLoginUrl(secret), secret).then(cb);
    };

    exchangeCode = async (data) => {
        await this.props.exchangeCode({
            code: data.code,
            redirectUrl: redirectUrl
        });
        this.setState({ loading: false });
    };

    buildLoginUrl = (secret) => {
        // state string for crsf attacks?

        const url = "https://www.linkedin.com/oauth/v2/authorization";
        const params = qs.stringify({
            client_id: REACT_APP_LINKEDIN_CLIENT_ID,
            scope: LINKEDIN_AUTHENTICATION_SCOPE,
            response_type: "code",
            redirect_uri: redirectUrl,
            state: secret
        });

        return `${url}?${params}`;
    };

    onAdd = async (event, toggled) => {
        const secret = this.csrfCode();
        this.setState({ loading: true });
        await this.login(this.exchangeCode, secret);
        this.setState({ loading: false });
    };

    render() {
        const { loading, platform, label } = this.state;
        const { component: Component } = this.props;
        return (
            <Component
                platform={platform}
                loading={loading}
                onAdd={this.onAdd}
                label={label}
            />
        );
    }
}

export default LinkedinConnect;
