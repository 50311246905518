import React from "react";
import SelectField from "components/DropDown/SelectField";
import style from "./style.module.scss";

const MetricPropSelector = ({
    options,
    placeholder,
    selectorValue,
    selectorValueLabel,
    onChangeHandler,
    isDisabled,
}) => {
    return (
        <SelectField
            className={`${style.inputField} ${style.selectField}`}
            optionClassName={style.option}
            input={{
                value: selectorValue,
                onChange: (value) => {
                    onChangeHandler(value);
                },
            }}
            valueLabel={selectorValueLabel}
            options={options}
            placeholder={placeholder}
            isDisabled={isDisabled}
        />
    );
};

export default MetricPropSelector;
