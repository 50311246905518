import React from "react";
import { find, get, isEmpty, toLower } from "lodash";
import { channelFriendlyName, metricFriendlyName } from "lib/utility";
import { toPrettyNumber } from "lib/metricCalculations";
import { ReactComponent as CampaignGroupIconColor } from "assets/images/icons/Icon-CampaignGroup.svg";
import style from "./style.module.scss";

export const PAID_PLATFORMS = ["adwords", "linkedin", "bing", "facebook", "tiktok", "pinterest", "stackAdapt"];

export const PLATFORM_STATUSES = {
    adwords: {
        active: true,
        message: "",
    },
    facebook: {
        active: true,
        message: "",
    },
    tiktok: {
        active: true,
        message: "",
    },
    pinterest: {
        active: true,
        message: "",
    },
    linkedin: {
        active: true,
        message: "",
    },
    bing: {
        active: true,
        message: "",
    },
    stackAdapt: {
        active: true,
        message: "",
    },
};

const ACTIVE_STATUSES = ["enabled", "enable", "active", "live"];

const COMPLETE_STATUSES = ["completed", "ended"];

const PAUSED_STATUSES = ["draft", "paused", "approved"];

const INACTIVE_STATUSES = ["inactive", "disabled", "rejected", "error"];

export const EXPORTABLE_METRICS = [
    "cost",
    "impressions",
    "cpm",
    "clicks",
    "ctr",
    "cpc",
    "conversions",
    "conversionRate",
    "cpa",
    "revenue",
    "roas",
    "addToCart",
    "costPerAddToCart",
];

export const TABLE_FIELD_MAPPING = {
    cost: "spend",
    conversionRate: "conversionsRate",
};

export const infoWrapper = (info) => `<span style="display:block;max-width:14rem;">${info}</span>`;

export const headCells = [
    {
        id: "name",
        label: "Campaign Group",
        sortable: true,
        headPadding: "1rem 0.625rem 1rem 1.25rem",
        padding: "1.125rem 0.875rem 1rem 1.25rem",
        subPadding: "0.375rem 0.875rem 0.25rem 1.25rem",
        width: "394",
        freeze: true,
        hasDivider: true,
    },
    {
        id: "status",
        label: "Status",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "2.2rem 0.875rem",
        width: "130",
        hasDivider: true,
    },
    {
        id: "budget",
        label: "Campaign Budget",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "2.2rem 0.875rem",
        width: "130",
        align: "right",
        hasDivider: true,
    },
    {
        id: "spend",
        label: "Spend",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "0.625rem 0.875rem 0.5rem",
        width: "120",
        align: "right",
        info: infoWrapper("Media spend from the connect ad platform."),
        hasDivider: true,
    },
    {
        id: "impressions",
        label: "Impressions",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "0.625rem 0.875rem 0.5rem",
        width: "130",
        align: "right",
        info: infoWrapper("Total number of ad impressions."),
        hasDivider: true,
    },
    {
        id: "cpm",
        label: "CPM",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "0.625rem 0.875rem 0.5rem",
        width: "115",
        align: "right",
        info: infoWrapper("(Cost per mille) Cost per one-thousand ad impressions."),
        hasDivider: true,
    },
    {
        id: "clicks",
        label: "Ad Clicks",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "0.625rem 0.875rem 0.5rem",
        width: "115",
        align: "right",
        info: infoWrapper("Total number of ad clicks."),
        hasDivider: true,
    },
    {
        id: "ctr",
        label: "CTR",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "0.625rem 0.875rem 0.5rem",
        width: "115",
        align: "right",
        info: infoWrapper("(Click-through rate) Number of ad clicks divided by number of impressions."),
        hasDivider: true,
    },
    {
        id: "cpc",
        label: "CPC",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "0.625rem 0.875rem 0.5rem",
        width: "115",
        align: "right",
        info: infoWrapper("(Cost per click) Media spend divided by the number of ad clicks."),
        hasDivider: true,
    },
    {
        id: "conversions",
        label: "Conv.",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "0.625rem 0.875rem 0.5rem",
        width: "115",
        align: "right",
        info: infoWrapper("Number of conversions from the connected ad platform."),
        hasDivider: true,
    },
    {
        id: "conversionsRate",
        label: "Conv. Rate",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "0.625rem 0.875rem 0.5rem",
        width: "130",
        align: "right",
        info: infoWrapper("Number of conversions divided by number of ad clicks."),
        hasDivider: true,
    },
    {
        id: "cpa",
        label: "CPA",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "0.625rem 0.875rem 0.5rem",
        width: "115",
        align: "right",
        info: infoWrapper(
            "(Cost per acquisition) Media spend divided by the number of conversions from the connected ad platform.",
        ),
    },
    {
        id: "revenue",
        label: "Revenue",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "0.625rem 0.875rem 0.5rem",
        width: "115",
        align: "right",
        info: infoWrapper("Total revenue from the connected ad platform."),
        hasDivider: true,
    },
    {
        id: "roas",
        label: "ROAS",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "0.625rem 0.875rem 0.5rem",
        width: "115",
        align: "right",
        info: infoWrapper("(Return on ad spend) Total revenue divided by media spend."),
        hasDivider: true,
    },
    {
        id: "addToCart",
        label: "Add to Cart",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "0.625rem 0.875rem 0.5rem",
        width: "115",
        align: "right",
        info: infoWrapper("Total number of add to carts."),
        hasDivider: true,
    },
    {
        id: "costPerAddToCart",
        label: "Cost per Add to Cart",
        sortable: true,
        padding: "1.125rem 0.875rem 1rem",
        subPadding: "0.625rem 0.875rem 0.5rem",
        width: "130",
        align: "right",
        info: infoWrapper("Media spend divided by the number of add to carts."),
        hasDivider: true,
    },
];

export const getStatus = (status, platform) => {
    const statusName = toLower(status);

    if (ACTIVE_STATUSES.includes(statusName)) {
        return {
            color: "#00b178",
            order: 0,
        };
    }
    if (PAUSED_STATUSES.includes(statusName)) {
        return {
            color: "#A0B5C4",
            order: 2,
        };
    }
    if (COMPLETE_STATUSES.includes(statusName)) {
        return {
            color: "#050c42",
            order: 3,
        };
    }
    if (INACTIVE_STATUSES.includes(statusName)) {
        return {
            color: "#FB1427",
            order: 4,
        };
    }
    if (platform === "stackAdapt" && ["resumed", "pending"].includes(statusName)) {
        return {
            color: "#ffbc00",
            order: 1,
        };
    }

    return {
        color: "#FB1427",
        order: 10,
    };
};

export const getActiveCampaignAmount = (campaigns) => {
    if (isEmpty(campaigns)) return 0;
    return campaigns.reduce((cache, { status }) => cache + ACTIVE_STATUSES.includes(toLower(status)), 0);
};

const matchPlatformPerformance = ({ groupKey, allGroupsPerformance, allCampaigns }) => {
    const campaignsPeformance = get(allGroupsPerformance, [groupKey, "campaigns"]);
    const mappedCampaigns = Object.values(allCampaigns[groupKey]).map((campaign) => {
        const { campaignId } = campaign || {};
        const campaignPerformance =
            find(campaignsPeformance, (campaignPer) => campaignPer.campaignId === campaignId) || {};
        return { ...campaign, ...campaignPerformance };
    });
    return {
        ...allGroupsPerformance[groupKey],
        campaigns: mappedCampaigns,
        platforms: [groupKey],
    };
};

const matchCustomGroupPerformance = ({ groupKey, allGroupsPerformance, allCampaigns }) => {
    const customGroupCampaignPlatforms = get(allCampaigns, [groupKey, "campaigns"]) || {};
    const campaignsPeformance = get(allGroupsPerformance, [groupKey, "campaigns"]);
    const mappedCampaigns = Object.keys(customGroupCampaignPlatforms).reduce((cache, platform) => {
        const campaignIds = customGroupCampaignPlatforms[platform];
        const campaigns = campaignIds.map((campaignId) => {
            const campaign = get(allCampaigns, [platform, campaignId]) || {};
            const campaignPerformance =
                find(campaignsPeformance, (campaignPer) => campaignPer.campaignId === campaignId) || {};
            return { ...campaign, ...campaignPerformance };
        });
        return [...cache, ...campaigns];
    }, []);
    return {
        ...allGroupsPerformance[groupKey],
        campaigns: mappedCampaigns,
        platforms: Object.keys(customGroupCampaignPlatforms),
    };
};

export const matchCampaignsPeformance = ({ allGroupsPerformance, allCampaigns }) => {
    if (isEmpty(allCampaigns)) return [];
    return Object.keys(allCampaigns).reduce((cache, groupKey) => {
        const isPlatform = PAID_PLATFORMS.includes(groupKey);
        const matchedData = isPlatform
            ? matchPlatformPerformance({
                  groupKey,
                  allGroupsPerformance,
                  allCampaigns,
              })
            : matchCustomGroupPerformance({
                  groupKey,
                  allGroupsPerformance,
                  allCampaigns,
              });
        return { ...cache, [groupKey]: matchedData };
    }, {});
};

export const countGroupTypes = (campaignGroups = {}) =>
    Object.keys(campaignGroups).reduce(
        (obj, groupName) => {
            const isPlatform = PAID_PLATFORMS.includes(groupName);
            return {
                platforms: isPlatform ? obj.platforms + 1 : obj.platforms,
                groups: !isPlatform ? obj.groups + 1 : obj.groups,
            };
        },
        {
            platforms: 0,
            groups: 0,
        },
    );

export const getDataToExport = (campaignGroups) => {
    return [].concat(
        ...Object.values(campaignGroups).map((campaignGroup) => {
            const { info: { name } = {}, performance = {}, campaigns = [], platforms = [] } = campaignGroup || {};
            return [
                {
                    Name: PAID_PLATFORMS.includes(name) ? channelFriendlyName(name) : name,
                    Status: "-",
                    Type: "-",
                    Platforms: platforms.map((p) => channelFriendlyName(p)).join(", "),
                    ...EXPORTABLE_METRICS.reduce(
                        (cache, metric) => ({
                            ...cache,
                            [metricFriendlyName(metric)]: isNaN(performance[metric])
                                ? "-"
                                : toPrettyNumber(performance[metric], metric),
                        }),
                        {},
                    ),
                },
                {},
                ...campaigns.map((campaignItem) => {
                    const { campaign, status = "-", type = "-", platform, performance = {} } = campaignItem || {};
                    return {
                        Name: campaign,
                        Platforms: channelFriendlyName(platform),
                        Status: status,
                        Type: type,
                        ...EXPORTABLE_METRICS.reduce(
                            (cache, metric) => ({
                                ...cache,
                                [metricFriendlyName(metric)]: isNaN(performance[metric])
                                    ? "-"
                                    : toPrettyNumber(performance[metric], metric),
                            }),
                            {},
                        ),
                    };
                }),
                {},
                {},
                {},
            ];
        }),
    );
};

export const campaignGroupsSorter = (filteredCampaignGroups, campaignGroups) =>
    filteredCampaignGroups.sort((a, b) => {
        const { info: { name: nameA } = {} } = campaignGroups[a];
        const { info: { name: nameB } = {} } = campaignGroups[b];

        const isAPlatform = PAID_PLATFORMS.includes(nameA);
        const isBPlatform = PAID_PLATFORMS.includes(nameB);

        const formatNameA = isAPlatform ? channelFriendlyName(nameA) : nameA;
        const formatNameB = isBPlatform ? channelFriendlyName(nameB) : nameB;

        if (isBPlatform && !isAPlatform) {
            return 1;
        }

        if (!isBPlatform && isAPlatform) {
            return -1;
        }

        return formatNameA > formatNameB ? 1 : formatNameA < formatNameB ? -1 : 0;
    });

export const getIconPlatform = ({ platformMeta, isPlatform, formatName, name }) =>
    isPlatform ? <img src={platformMeta[name]?.colorIcon} alt={`${formatName} icon`} /> : <CampaignGroupIconColor />;

export const getPlatformsTip = ({ platforms = [] }) => {
    return `<div className="${style.platformTooltip}">
            <span><strong>Platforms</strong></span>
            ${platforms
                .map(
                    ({ name = "", icon = "", count = 0 }) => `<div>
                <img src="${icon}" alt="${name} icon" />
                <strong>${count}</strong>
                <span>${count === 1 ? "Campaign" : "Campaigns"}</span>
            </div>`,
                )
                .join("")}
        </div>`;
};

export const filterCampaignGroups = ({ campaignGroups = {}, filter }) =>
    Object.keys(campaignGroups).filter((groupId) => {
        const isPlatform = PAID_PLATFORMS.includes(groupId);

        if (filter === "platforms" && !isPlatform) {
            return false;
        }

        if (filter === "groups" && isPlatform) {
            return false;
        }

        return true;
    });
