import React, { useMemo, useState } from "react";
import style from "./style.module.scss";
import CurrencyCheck from "features/Settings/Integrate/CurrencyCheck/OnboardingCurrencyMismatch";
import { getPlatforms } from "./helpers";
import IntegrationCard from "./IntegrationCard";
import Tabs from "components/Tabs";
import Integrations from "features/Settings/Integrate/Integrations/container";
import { useDispatch, useSelector } from "react-redux";
import { operations } from "features/Integrations/_ducks";
import { actions as siteActions } from "../ducks";
import { getSite } from "_redux/sites";
import { makeSelectAccountId } from "_redux/sites/selectors";
import { isOnDefaultDomain } from "components/Branding";
import { TipCard } from "components/Warnings";
import IconTip from "assets/images/icons/JSX/Icon-Tip";

const { changeConnection } = operations;
const { updateAccountIntegrations } = siteActions;

const MainTab = ({ integrations = {}, changeIntegration }) => {
    if (!isOnDefaultDomain()) {
        return (
            <TipCard icon={IconTip} title={"Integrations"}>
                You're currently using our white-labeled version, which is streamlined for simplicity. For a full
                experience with integrations, kindly switch to the original app domain. It's tailored with all the
                features just for you!
            </TipCard>
        );
    }
    return (
        <div className={` ${style.additionalIntegrationsWrapper}`}>
            <h1>Add Integrations</h1>
            <p>
                Easily bring all your marketing data together.
                <br />
                <small>These are read-only integrations. No changes will be made in your account.</small>
            </p>
            <div className={style.integrationCollection}>
                {getPlatforms(integrations).map(({ platformName, info, icon, key }) => (
                    <IntegrationCard
                        key={key}
                        onClick={() => changeIntegration(key)}
                        platform={platformName}
                        icon={icon}
                        info={info}
                    />
                ))}
            </div>

            <div className={style.currencyCheck}>
                <CurrencyCheck />
            </div>
        </div>
    );
};

const AddIntegrations = ({ account: { id: accountId, integrations = {} } = {}, closeIntegrationModal }) => {
    const accountIdSelector = useMemo(makeSelectAccountId);
    const selectedSiteId = useSelector((state) => accountIdSelector(state));
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedIntegration, setSelectedIntegration] = useState({
        integration: null,
        integrationId: null,
    });

    const dispatch = useDispatch();

    const changeConnectionHandle = ({ connectionId, platform }) => {
        if (accountId) {
            dispatch(
                changeConnection({
                    accountId,
                    platform,
                    connectionId,
                }),
            );
        }
    };

    const onUpdateIntegrationSuccess = (connectedIntegrations) => {
        dispatch(updateAccountIntegrations(accountId, connectedIntegrations));
        closeIntegrationModal();
        if (accountId === selectedSiteId) {
            dispatch(getSite(accountId));
        }
    };

    return (
        <Tabs tabType={tabIndex} onChange={setTabIndex} className={style.tabContainer}>
            <MainTab
                integrations={integrations}
                changeIntegration={(platform) => {
                    setSelectedIntegration((preState) => ({
                        ...preState,
                        integration: platform,
                    }));
                    setTabIndex(1);
                }}
            />
            <div className={style.integrationSelected}>
                <Integrations
                    changeConnection={changeConnectionHandle}
                    onCancel={() => {
                        setTabIndex(0);
                    }}
                    integration={selectedIntegration.integration}
                    integrationId={selectedIntegration.integrationId}
                    currentIntegrations={integrations}
                    onUpdateIntegrationSuccess={onUpdateIntegrationSuccess}
                />
            </div>
        </Tabs>
    );
};

export default AddIntegrations;
