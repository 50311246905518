import React from "react";
import qs from "querystring";
import { PINTEREST_AUTHENTICATION_SCOPE } from "lib/campaignUtils"; 
import openWindow from "../openWindow";
const { REACT_APP_DOMAIN, REACT_APP_PINTEREST_APP_ID } = process.env;

const redirectUrl = `${REACT_APP_DOMAIN}/dashboard/account/connect`;

class PinterestConnect extends React.Component {
    state = {
        loading: false,
        platform: "pinterest",
        label: "Pinterest Ads"
    };

    login = async (callback) => {
        const secret = Math.random().toString(36).substring(7);
        return openWindow(this.buildLoginUrl(secret), secret).then((data) => {
            return callback(data);
        });
    };

    exchangeCode = async (data) => {
        await this.props.exchangeCode({
            code: data.code,
            redirectUrl: redirectUrl
        });
        this.setState({ loading: false });
    };

    buildLoginUrl = (secret) => {
        const url = "https://www.pinterest.com/oauth";
        const params = qs.stringify({
            client_id: REACT_APP_PINTEREST_APP_ID,
            response_type: "code",
            redirect_uri: redirectUrl,
            scope: PINTEREST_AUTHENTICATION_SCOPE,
            state: secret
        });

        return `${url}?${params}`;
    };

    onAdd = async (event, toggled) => {
        this.setState({ loading: true });
        await this.login(this.exchangeCode);
    };

    render() {
        const { loading, platform, label } = this.state;
        const { component: Component } = this.props;
        return (
            <Component
                platform={platform}
                loading={loading}
                onAdd={this.onAdd}
                label={label}
            />
        );
    }
}

export default PinterestConnect;
