import Linkedin from "assets/images/Integrations/JSX/Logo-Linkedin";
import CheckboxSearch from "components/SearchBox/CheckboxSearchWithField";
import Loading from "components/Loading/ThreeDots";
import Connect from "features/Integrations/Dependant/ConnectButton";
import LinkedinConnect from "features/Integrations/Connections/LinkedinConnect";
import isEmpty from "lodash/fp/isEmpty";
import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import { CardWarning } from "components/Warnings";
import MultiSelector from "components/MultiSelector";
import onboardingStyles from "features/Onboarding/onboarding.module.scss";
import RegexInput from "features/Integrations/Dependant/RegexInput/container";
import { checkRegex } from "features/Integrations/Dependant/RegexInput";
import { campaignFetchDataToReduxFormData, hasFormCampaigns } from "../util";
import RenderInput from "components/RenderInput";
import { TooltipDark } from "components/Tooltip";
import { isEqual } from "lodash";

const required = (value) => (value ? undefined : "This field is required");

const ConnectButton = ({ onAdd, loading, label, platform, selected, list, changeConnection, disabled }) => {
    return (
        <Connect
            selected={selected}
            list={list}
            changeConnection={({ connectionId }) => changeConnection({ connectionId, platform })}
            loading={loading}
            onAdd={onAdd}
            label={label}
            disabled={disabled}
        />
    );
};

const LinkedinIntegrate = ({
    options,
    selected,
    userId,
    getLinkedinAccounts,
    integrations,
    change,
    isLoading,
    toggleLoading,
    selectedIntegrations,
    alreadyIntegrated,
    isEditing = false,
}) => {
    useEffect(() => {
        if (isEmpty(selected) || integrations) return;
        toggleLoading(true);
        getLinkedinAccounts({ userId, connectionId: selected }).finally(() => toggleLoading(false));
    }, [selected, userId]);

    if (isLoading) return <Loading style={{ margin: "2rem auto 0 auto" }} color="#BCCFDC" />;
    if (!isEmpty(selected) && isEmpty(integrations) && !isLoading)
        return (
            <CardWarning
                className={onboardingStyles.warning}
                title="LinkedIn Error"
                subtitle="We could not find any accounts in your connection. Please choose another LinkedIn connection."
            />
        );
    if (!integrations) return null;
    return (
        <div className={onboardingStyles.additionalIntegrationAccount}>
            <Field
                component={RenderInput}
                className={onboardingStyles.renderInput}
                name="linkedinIntegrationName"
                label="Connection Name"
                darkBorder
                large
                type="text"
                placeholder={
                    selectedIntegrations?.linkedin?.label ? `Default: ${selectedIntegrations?.linkedin?.label}` : ""
                }
                tip="Name that will apearin the app forthis Ads account."
            />
            <div
                className={onboardingStyles.multiSelectWrapper}
                data-for="linkedin-field-account"
                data-tip="This field isn't editible. You may remove this integration and add a new one."
                data-tip-disable={!isEditing}
            >
                <Field
                    component={MultiSelector}
                    name="linkedin"
                    label={options.linkedin.humanReadable}
                    onChange={() => {
                        change("linkedin-campaigns", { _all: true });
                    }}
                    className={onboardingStyles.multiSelect}
                    options={Object.keys(integrations.linkedin).map((id) => {
                        const disable = alreadyIntegrated.includes(id.toString());
                        const integration = integrations.linkedin[id];
                        return {
                            value: integration.id,
                            label: `${integration.name}${disable ? " (In Use)" : ""} - ${integration.id}`,
                            currency: integration.currency,
                            disabled: disable,
                        };
                    })}
                    darkBorder
                    validate={[required]}
                    disabled={isEditing}
                />
            </div>
            <TooltipDark id="linkedin-field-account" proximity={34} />
        </div>
    );
};

const LinkedinCampaigns = ({
    options,
    selected,
    getLinkedinCampaigns,
    userId,
    campaigns,
    selectedCampaignLength,
    connectionId,
    regex,
    savedCampaigns,
    change,
    isLoading,
    toggleLoading,
    currentAndFuture = false,
    savedRegex,
}) => {
    const [regexEnabled, toggleRegex] = useState(false);

    useEffect(() => {
        const formCampaigns = hasFormCampaigns({
            selectedAccount: selected,
            reduxCampaigns: campaigns[selected],
            formCampaigns: selectedCampaignLength,
        });

        if (formCampaigns) {
            return change("linkedin-campaigns", formCampaigns);
        }

        toggleLoading(true);

        getLinkedinCampaigns({
            userId,
            connectionId: connectionId,
            linkedinId: selected,
        })
            .then((data) => {
                const campaigns = campaignFetchDataToReduxFormData(data.payload.campaigns, savedCampaigns, savedRegex);
                change("linkedin-campaigns", campaigns);
                return data;
            })
            .finally(() => toggleLoading(false));
    }, [selected, userId, connectionId, savedRegex]);

    useEffect(() => {
        if (savedRegex) {
            change("regexlinkedin", savedRegex);
        }
        toggleRegex(!currentAndFuture);
    }, [currentAndFuture]);

    if (isLoading) return <Loading style={{ margin: "2rem auto 0 auto" }} color="#BCCFDC" />;
    if (selected && isEmpty(campaigns[selected]) && !isLoading)
        return (
            <CardWarning
                className={onboardingStyles.warning}
                title="Linkedin Campaign Warning"
                subtitle="We could not find any campaigns in your account."
            />
        );
    if (!campaigns || !campaigns[selected]) return null;

    const isValid = checkRegex(regex);
    const regexParse = isValid ? new RegExp(regex.toLowerCase()) : { test: () => false };

    const CampaignsSelected = () => (
        <p className={onboardingStyles.campaignsSelected}>
            <Linkedin />
            <strong>{currentAndFuture ? Object.keys(campaigns[selected]).length : selectedCampaignLength}</strong>{" "}
            Campaigns Selected
        </p>
    );

    return (
        <div>
            <p className={onboardingStyles.labelText}>{options["linkedin-campaigns"].humanReadable}</p>
            <CheckboxSearch
                className={onboardingStyles.checkboxSearch}
                search={{
                    placeholder: "All campaigns are selected by default",
                    accessor: "label",
                }}
                options={Object.keys(campaigns[selected]).map((id, index) => {
                    const label = campaigns[selected][id].name;
                    const isMatched = regexParse.test(label.toLowerCase());
                    const keyName = `${campaigns[selected][id].name}____${campaigns[selected][id].id}`;

                    return {
                        checked: isMatched,
                        disabled: isMatched,
                        label: label,
                        name: `linkedin-campaigns.${keyName.replace(/[^\w\s]/gi, "")}`,
                        id: campaigns[selected][id].id,
                    };
                })}
                onChangeAll={(options, isAllSelected) => {
                    options.forEach((option) => {
                        change(option.name, isAllSelected);
                    });
                }}
                hideSelectAll
                selectAllTxt="Select All Campaigns"
                searchTermInText
                selectedValueLength={selectedCampaignLength}
                bottomBar={CampaignsSelected}
                itemType="Campaigns"
                selectAllCurrentAndFuture={
                    regex
                        ? false
                        : (value) => {
                              if (value === true) {
                                  change(`linkedin-campaigns`, { _all: true });
                              } else {
                                  change(`linkedin-campaigns`, {});
                              }
                          }
                }
                selectAllCurrentAndFutureDefault={currentAndFuture}
            />
            <RegexInput
                disabled={!regexEnabled}
                platform="linkedin"
                className={onboardingStyles.connectRegex}
                regex={regex}
                placeholder={
                    regexEnabled ? "Type Selection" : 'To use regex, uncheck "Select All Current and Future Campaigns"'
                }
            />
        </div>
    );
};

export default React.memo(
    ({
        userId,
        list,
        selected,
        changeConnection,
        saveLinkedinAccessToken,
        getLinkedinAccounts,
        getLinkedinCampaigns,
        options,
        selectedIntegrations,
        integrations,
        campaigns,
        selectedCampaignLength,
        savedCampaigns,
        change,
        currentAndFuture = false,
        savedRegex,
        integrationId,
        alreadyIntegrated = [],
    }) => {
        const [isCampaignsLoading, toggleCampaignsLoading] = useState(false);
        const [isIntegrateLoading, toggleIntegrateLoading] = useState(false);

        return (
            <>
                <div className={onboardingStyles.formInputs}>
                    <LinkedinConnect
                        exchangeCode={saveLinkedinAccessToken}
                        component={({ onAdd, loading, label, platform }) =>
                            ConnectButton({
                                list,
                                selected,
                                changeConnection,
                                onAdd,
                                loading,
                                label,
                                platform,
                                disabled: isCampaignsLoading || isIntegrateLoading || !!integrationId,
                            })
                        }
                    />
                </div>
                {selected && (
                    <div className={`${onboardingStyles.formInputs} ${onboardingStyles.noMargin}`}>
                        <LinkedinIntegrate
                            selectedIntegrations={selectedIntegrations}
                            integrations={integrations}
                            getLinkedinAccounts={getLinkedinAccounts}
                            options={options}
                            selected={selected}
                            userId={userId}
                            isLoading={isIntegrateLoading}
                            toggleLoading={toggleIntegrateLoading}
                            change={change}
                            isEditing={!!integrationId}
                            alreadyIntegrated={alreadyIntegrated}
                        />
                        {selectedIntegrations && selectedIntegrations.linkedin && integrations && (
                            <LinkedinCampaigns
                                integrations={integrations}
                                options={options}
                                campaigns={campaigns}
                                selectedCampaignLength={selectedCampaignLength}
                                connectionId={selected}
                                userId={userId}
                                savedCampaigns={savedCampaigns}
                                regex={selectedIntegrations.regexlinkedin}
                                getLinkedinCampaigns={getLinkedinCampaigns}
                                selected={selectedIntegrations.linkedin.value}
                                change={change}
                                isLoading={isCampaignsLoading}
                                toggleLoading={toggleCampaignsLoading}
                                currentAndFuture={currentAndFuture}
                                savedRegex={savedRegex}
                            />
                        )}
                    </div>
                )}
            </>
        );
    },
    isEqual,
);
