import React, { useState } from "react";
import BigQueryConnect from "features/Integrations/Connections/BigQueryConnect/container";
import SelectConnectAccounts from "features/Integrations/Dependant/common/SelectConnectAccounts";
import onboardStyles from "features/Onboarding/onboarding.module.scss";
import BigQueryIntegrate from "./BigQueryIntegrate";
import BigQueryTables from "./BigQueryTables";
import BigQueryMetrics from "./BigQueryMetrics";
import BigQueryAdvancedMetrics from "./BigQueryAdvancedMetrics";
import { get, isEmpty, isEqual } from "lodash";
import { generateNewAdvancedMetric } from "./helpers";

export default React.memo(
    ({
        change,
        changeConnection,
        exchangeBigQueryCode,
        getBigQueryDatasets,
        getBigQueryTables,
        integrations,
        list,
        options,
        connectionId,
        selectedIntegrations,
        userId,
        integrationId,
        alreadyIntegrated = [],
        bigQueryTables,
        bigQueryTablesLoading,
        getBigQueryTableFields,
        bigQueryFields,
        bigQueryFieldsLoading,
    }) => {
        const [isIntegrateLoading, toggleIntegrateLoading] = useState(false);
        const dataset = get(selectedIntegrations, ["bigQuery", "value"], "");
        const selectedLatestTableId = get(
            selectedIntegrations,
            ["bigQueryTable", "latestTable"],
            ""
        );
        const selectedBasicMetrics = get(
            selectedIntegrations,
            ["bigQueryBasicMetrics"],
            []
        );
        const advancedMetrics = get(
            selectedIntegrations,
            ["bigQueryAdvancedMetrics"],
            []
        );

        return (
            <div className={onboardStyles.formInputs}>
                <BigQueryConnect
                    exchangeBigQueryCode={exchangeBigQueryCode}
                    component={({
                        onAdd,
                        loading,
                        label,
                        platform,
                        accountId,
                    }) =>
                        SelectConnectAccounts({
                            list,
                            selected: connectionId,
                            changeConnection,
                            onAdd,
                            loading,
                            label,
                            platform,
                            disabled: isIntegrateLoading || !!integrationId,
                        })
                    }
                />
                {connectionId && (
                    <div
                        className={`${onboardStyles.formInputs} ${onboardStyles.noMargin}`}
                    >
                        <BigQueryIntegrate
                            integrations={integrations}
                            getBigQueryDatasets={getBigQueryDatasets}
                            options={options}
                            connectionId={connectionId}
                            userId={userId}
                            isLoading={isIntegrateLoading}
                            toggleLoading={toggleIntegrateLoading}
                            isEditing={!!integrationId}
                        />
                        {!!dataset && (
                            <BigQueryTables
                                connectionId={connectionId}
                                userId={userId}
                                selectedIntegrations={selectedIntegrations}
                                isEditing={!!integrationId}
                                bigQueryTables={bigQueryTables}
                                bigQueryTablesLoading={bigQueryTablesLoading}
                                getBigQueryTables={getBigQueryTables}
                                dataset={dataset}
                                alreadyIntegrated={alreadyIntegrated}
                            />
                        )}
                        {!!selectedLatestTableId && (
                            <>
                                <BigQueryMetrics
                                    connectionId={connectionId}
                                    userId={userId}
                                    bigQueryFields={bigQueryFields}
                                    bigQueryFieldsLoading={
                                        bigQueryFieldsLoading
                                    }
                                    getBigQueryTableFields={
                                        getBigQueryTableFields
                                    }
                                    dataset={dataset}
                                    tableId={selectedLatestTableId}
                                    change={change}
                                    selectedMetrics={selectedBasicMetrics}
                                />
                                <BigQueryAdvancedMetrics
                                    bigQueryFields={bigQueryFields}
                                    bigQueryFieldsLoading={
                                        bigQueryFieldsLoading
                                    }
                                    change={change}
                                    metrics={
                                        isEmpty(advancedMetrics)
                                            ? [generateNewAdvancedMetric()]
                                            : advancedMetrics
                                    }
                                />
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    },
    isEqual
);
