import React from "react";
import style from "./style.module.scss";
import { formatStatus } from "lib/campaignUtils";
import { getStatusTextColor } from "./helpers";
import { getStatus } from "features/Dashboard/CampaignGroups/helpers";

function Status({ status, platform, className, normalTextColor = false }) {
    return (
        <div className={`${style.statusWrapper} ${className}`}>
            <span className={style.dot} style={{ backgroundColor: getStatus(status, platform).color }}></span>
            <p className={style.status} style={{ color: normalTextColor ? "#333333" : getStatusTextColor(status) }}>
                {formatStatus(status)}
            </p>
        </div>
    );
}

export default Status;
