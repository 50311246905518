import Component from "./";
import { connect } from "react-redux";
import { getCampaignGroupsPerformance } from "features/Dashboard/_ducks/operations";
import { operations } from "../_ducks/";
import {
    makeSelectAccountCurrencies,
    makeSelectAccountId,
    makeSelectCampaignGroups,
    makeSelectHasPlatforms,
} from "_redux/sites/selectors";
import {
    makeSelectCampaignGroupsPerformance,
    makeSelectCampaignGroupsPerformancePending,
    selectExtraCampaignGroupsInfo,
    selectExtraCampaignGroupsInfoLoading,
} from "features/Dashboard/_ducks/selectors";
import { actions as planOptionActions } from "features/PlanOptions/_ducks";
import { PAID_PLATFORMS, matchCampaignsPeformance } from "./helpers";

import {
    setShowEditCampaignModal,
    setEditingCampaign,
} from "features/Dashboard/_ducks/actions";

const { getExtraCampaignGroupsInfo } = operations;
const { togglePlanOptions } = planOptionActions;

const makeMapStateToProps = () => {
    const accountIdSelector = makeSelectAccountId();
    const campaignGroupsPerformanceSelector = makeSelectCampaignGroupsPerformance();
    const campaignGroupsPerformancePendingSelector = makeSelectCampaignGroupsPerformancePending();
    const accountCurrenciesSelector = makeSelectAccountCurrencies(
        PAID_PLATFORMS
    );
    const integrationsSelector = makeSelectHasPlatforms();
    const accountCampaignGroupsSelector = makeSelectCampaignGroups();

    return (state) => {
        const integrations = integrationsSelector(state);
        const accountCampaignGroups = accountCampaignGroupsSelector(state);
        const campaignGroups = campaignGroupsPerformanceSelector(state);
        const extraCampaignGroupsInfo = selectExtraCampaignGroupsInfo(state);

        return {
            accountId: accountIdSelector(state),
            campaignGroups: matchCampaignsPeformance({
                allGroupsPerformance: campaignGroups,
                allCampaigns: extraCampaignGroupsInfo,
            }),
            campaignGroupsPending: campaignGroupsPerformancePendingSelector(
                state
            ),
            accountCurrencies: accountCurrenciesSelector(state),
            extraCampaignGroupsInfoLoading: selectExtraCampaignGroupsInfoLoading(
                state
            ),
            hasPaidPlatforms: PAID_PLATFORMS.find(
                (platform) => integrations[platform]
            ),
            hasCampaignGroups: Object.keys(accountCampaignGroups).length > 0,
        };
    };
};

const mapDispatchToProps = {
    getCampaignGroupsPerformance,
    getExtraCampaignGroupsInfo,
    togglePlanOptions,
    setShowEditCampaignModal,
    setEditingCampaign,
};

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
