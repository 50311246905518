import React from "react";

export default ({ fill = "#6A80A7" }) => (
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill={fill}
    >
        <g id="7.1.2-Budgets-(Past)" transform="translate(-1278.000000, -549.000000)" fillRule="nonzero">
            <g id="Content-Copy" transform="translate(180.000000, 104.000000)">
                <g id="Table-(Edit)-Copy" transform="translate(0.000000, 146.000000)">
                    <g id="Table-4-Copy" transform="translate(0.000000, 222.000000)">
                        <g id="Row-1" transform="translate(88.000000, 69.000000)">
                            <path
                                d="M1024,12 L1024,16 C1024,17 1023.5,18 1023,18 C1023,18 1023,18.3333333 1023,19 C1023,19.6666667 1023.33333,20 1024,20 C1025,20 1026,18.5 1026,17 L1026,11.25 C1026,9.75 1024.25,8 1022.75,8 C1019.9041,8 1017.98743,8 1017,8 C1015.51885,8 1014,9 1014,10 C1014,10.6666667 1014.33962,11 1015.01885,11 C1015.25883,11 1015.58588,11 1016,11 C1016,10.5 1017,10 1018,10 L1022,10 C1023,10 1024,11 1024,12 Z M1018.5,12 C1020.433,12 1022,13.5670034 1022,15.5 L1022,20.5 C1022,22.4329966 1020.433,24 1018.5,24 L1013.5,24 C1011.567,24 1010,22.4329966 1010,20.5 L1010,15.5 C1010,13.5670034 1011.567,12 1013.5,12 L1018.5,12 Z M1018,14 L1014,14 C1012.89543,14 1012,14.8954305 1012,16 L1012,16 L1012,20 C1012,21.1045695 1012.89543,22 1014,22 L1014,22 L1018,22 C1019.10457,22 1020,21.1045695 1020,20 L1020,20 L1020,16 C1020,14.8954305 1019.10457,14 1018,14 L1018,14 Z"
                                id="Icon-Copy"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
