import style from "./style.module.scss";
import PlatformIcon from "components/Icon/PlatformIcon";
import React from "react";
import { formatStatus } from "lib/campaignUtils";

function CampaignTitle({ platform, type, name }) {
    return (
        <div className={style.titleWrapper}>
            <PlatformIcon
                platform={platform}
                className={style.icon}
                backgroundColor="white"
            />
            <div className={style.nameWrapper}>
                <p className={style.name}>{name}</p>
                {type && (
                    <p className={style.type}>
                        Type: <span>{formatStatus(type)}</span>
                    </p>
                )}
            </div>
        </div>
    );
}

export default CampaignTitle;
