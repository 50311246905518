import React from "react";
import style from "./style.module.scss";
import { Icon } from "components/Icon/PlatformIcon";
import { ReactComponent as IconInfo } from "assets/images/icons/Icon-Info-Outline.svg";
import Wrapper from "../Wrapper";
import Circle from "../Circle";
import StepContainer from "features/Dashboard/CampaignGroups/EditCampaign/StepContainer";
import classNames from "classnames";
import { BrandLogo } from "components/Branding";
import ThreeDots from "components/Loading/ThreeDots";
import PlatformsIcon from "./PlatformsIcon";

function Loading({ platforms, loadingTitle, loadingMessage, showPlatforms = true }) {
    return (
        <StepContainer title={loadingTitle}>
            <Wrapper message={loadingMessage}>
                <div className={classNames(style.loadingWrapper, { [style.hidePlatforms]: !showPlatforms })}>
                    <div className={style.hawkeCircleContainer}>
                        <Circle className={style.hawkeCircle}>
                            <BrandLogo />
                        </Circle>
                    </div>
                    {showPlatforms ? <PlatformsIcon platforms={platforms} /> : <ThreeDots color={"#6A80A7"} />}
                </div>
                <p className={style.warm}>
                    <IconInfo />
                    Don’t refresh your browser during this process.
                </p>
            </Wrapper>
        </StepContainer>
    );
}

export default Loading;
