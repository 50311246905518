import {connect} from "react-redux";
import {change, formValueSelector, getFormValues} from "redux-form";
import {operations as connectOperations} from "../../_ducks";
import {actions, operations} from "../../../Onboarding/ducks";
import IntegrateStep from "./index";
import {makeSelectGetIntegrationIdList, makeSelectIntegrationIds,} from "_redux/sites/selectors";
import {makeSelectUserConnections, makeSelectUserId,} from "_redux/users/selectors";
import {makeSelectOnboardingIntegrations, selectOnboardingCampaigns,} from "features/Onboarding/ducks/selectors";
import {makeSelectIntegrationOpts} from "features/Integrations/_ducks/selectors";

const { exchangePinterestCode: savePinterestToken } = connectOperations;

const { onboardingPlatformConnection } = actions;

const { getPinterestAccounts, getPinterestCampaigns } = operations;

const mapStateToProps = (state, { formName, connection, integrationId }) => {
    const userId = makeSelectUserId()(state);
    const { pinterest } = makeSelectUserConnections()(state);
    const allIntegrations = makeSelectOnboardingIntegrations()(state);
    const campaigns = selectOnboardingCampaigns(state);
    const { pinterest: pinterestOptions } = makeSelectIntegrationOpts()(state);
    const integrations = makeSelectIntegrationIds()(state);
    const { form } = state;
    const {
        pinterest: {
            [integrationId]: {
                campaigns: savedCampaigns = {},
                regex: savedRegex = "",
            } = {},
        } = {},
    } = integrations || {};

    const {
        [`${connection}_pinterest`]: pinterestIntegrations = false,
    } = allIntegrations;

    const selector = formValueSelector(formName);
    const selectedCampaigns = selector({ form }, "pinterest-campaigns") || {};
    const regex = selector({ form }, "regexpinterest") || "";
    const alreadyIntegrated = makeSelectGetIntegrationIdList("pinterest")(
        state
    );

    return {
        userId,
        formName,
        list: pinterest,
        selected: connection,
        options: pinterestOptions,
        selectedIntegrations: getFormValues(formName)({ form }),
        integrations: pinterestIntegrations,
        campaigns,
        savedCampaigns,
        selectedCampaignLength: Object.keys(selectedCampaigns).filter(
            (campaign) => selectedCampaigns[campaign]
        ).length,
        currentAndFuture: selectedCampaigns["_all"] && !regex,
        savedRegex,
        integrationId,
        alreadyIntegrated: formName === "onboarding" ? [] : alreadyIntegrated,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getPinterestCampaigns: ({ userId, connectionId, advertiserId }) =>
        dispatch(getPinterestCampaigns({ userId, connectionId, advertiserId })),
    getPinterestAccounts: ({ userId, connectionId }) =>
        dispatch(getPinterestAccounts({ userId, connectionId })),
    savePinterestAccessToken: (code) => {
        return dispatch(savePinterestToken(code)).then(({ id }) => {
            dispatch(
                onboardingPlatformConnection({
                    platform: "pinterest",
                    connectionId: id,
                })
            );
        });
    },
    change: (formName, ...args) => dispatch(change(formName, ...args)),
});

const mergeProps = (
    { formName, ...stateProps },
    { change, ...dispatchProps },
    ownProps
) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    change: (...args) => {
        return change(formName, ...args);
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(IntegrateStep);
