import style from "./style.module.scss";
import React from "react";
import { ReactComponent as IconInfo } from "assets/images/icons/Icon-Info-Outline.svg";

function ChangeWarn({ title, warn }) {
    if (!title && !warn) return null;
    return (
        <div className={style.changeWarn}>
            <IconInfo />
            {title && <p className={style.title}>{title}</p>}
            {warn && <p className={style.warnMessage}>{warn}</p>}
        </div>
    );
}

export default ChangeWarn;
