import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import compose from "lodash/fp/flowRight";
import Component from ".";
import operations from "features/Integrations/_ducks/operations";
import { makeSelectGoogleBusinessProfileAccount } from "_redux/sites/selectors";
import {
    makeSelectGoogleBusinessProfileOptions,
    makeSelectGoogleBusinessProfileLocationsOptions,
    makeSelectGoogleBusinessProfilePermissionIssue,
} from "features/Integrations/_ducks/selectors";
import { makeSelectUserId } from "_redux/users/selectors";
import moment from "moment";

const { getGoogleBusinessProfileOptions, getGoogleBusinessProfileLocationsOptions } = operations;

const mapStateToProps = (state, { integrationId, connections }) => {
    const googleBusinessProfileAccount = makeSelectGoogleBusinessProfileAccount()(state);
    const googleBusinessProfilePermissionIssue = makeSelectGoogleBusinessProfilePermissionIssue()(state);
    const googleBusinessProfileOptions = makeSelectGoogleBusinessProfileOptions()(state);
    const googleBusinessProfileLocationsOptions = makeSelectGoogleBusinessProfileLocationsOptions()(state);
    const {
        form: {
            googleBusinessProfile: {
                values: {
                    googleBusinessProfile: selectedGoogleBusinessProfile = {},
                    "googleBusinessProfile-locations": selectedLocations = {},
                } = {},
            } = {},
        } = {},
    } = state;
    const { [integrationId]: { integrationId: googleBusinessProfile, integrationName, locationIds } = {} } =
        googleBusinessProfileAccount || {};
    return {
        selected: connections?.googleBusinessProfile,
        selectedGoogleBusinessProfileId: selectedGoogleBusinessProfile?.value,
        integrationId: googleBusinessProfile,
        initialValues: {
            googleBusinessProfile: {
                value: googleBusinessProfile,
                label: integrationName,
            },
            "googleBusinessProfile-locations": locationIds,
        },
        options: googleBusinessProfileOptions.map(({ accountName, name } = {}) => {
            return {
                label: accountName,
                value: name,
            };
        }),
        locationOptions: googleBusinessProfileLocationsOptions.map(
            ({ locationName, address: { addressLines = [], locality, regionCode } = {}, name } = {}) => {
                return {
                    label: `${locationName} - ${addressLines.join(", ")}, ${locality}, ${regionCode}`,
                    value: name,
                };
            },
        ),
        selectedLocations: selectedLocations,
        hasPermissionIssue: googleBusinessProfilePermissionIssue,
        userId: makeSelectUserId()(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    getGoogleBusinessProfileOptions: ({ userId, connectionId }) =>
        dispatch(getGoogleBusinessProfileOptions({ userId, connectionId })),
    getGoogleBusinessProfileLocationsOptions: ({ userId, connectionId, businessProfileId }) =>
        dispatch(getGoogleBusinessProfileLocationsOptions({ userId, connectionId, businessProfileId })),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: "googleBusinessProfile",
        enableReinitialize: true,
        onSubmit: async (item, _, { updateIntegration, onCancel, accountId, userId, selected: connectionId } = {}) => {
            if (!item.googleBusinessProfile) {
                // Disconnection
                await updateIntegration({
                    integrations: {
                        googleBusinessProfile: {
                            value: null,
                        },
                    },
                    accountId,
                });
            } else {
                const { googleBusinessProfile, "googleBusinessProfile-locations": locations = {} } = item || {};
                await updateIntegration({
                    integrations: {
                        googleBusinessProfile: {
                            value: {
                                [googleBusinessProfile.value.replace(/\//g, "_")]: {
                                    integrationId: googleBusinessProfile.value,
                                    integrationName: googleBusinessProfile.label,
                                    locationIds: locations,
                                    userId,
                                    created_at: moment().toISOString(),
                                    connectionId,
                                },
                            },
                        },
                    },
                    accountId,
                });
            }
            onCancel();
        },
    }),
)(Component);
