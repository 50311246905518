import React from "react";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
import { Field, getFormValues } from "redux-form";
import { toLower } from "lodash/string";
import { parseNumberInputField } from "lib/metricCalculations";
import DisableToolTip from "./DisableTooltip";
import { EDIT_CAMPAIGN_NAME } from "../helpers";

function BudgetField({ isDisabled, disableCause, symbol, campaign, budgetType }) {
    const fieldId = `${budgetType}Budget`;
    const { [fieldId]: currentBudget } = useSelector(getFormValues(EDIT_CAMPAIGN_NAME)) || {};

    if (currentBudget === undefined) {
        return null;
    }

    return (
        <div className={style.campaignBudget}>
            <label htmlFor={fieldId} className={style.labelInput}>
                <span>Campaign Budget {budgetType ? `(${toLower(budgetType)})` : ""}</span>
                {(isDisabled || !disableCause) && <DisableToolTip id={`budget-${campaign}`} dataTip={disableCause} />}
            </label>
            <div className={`${style.budgetInput} ${isDisabled ? style.disable : ""}`}>
                <p>{symbol}</p>
                <Field
                    id={fieldId}
                    name={fieldId}
                    component="input"
                    normalize={parseNumberInputField}
                    disabled={isDisabled}
                />
            </div>
        </div>
    );
}

export default BudgetField;
