import React, { useState } from "react";
import onboardingStyles from "features/Onboarding/onboarding.module.scss";
import { Field } from "redux-form";
import RenderInput from "components/RenderInput";
import Checkbox from "components/Checkbox";
import style from "./style.module.scss";
import MaropostCampaigns from "features/Integrations/Dependant/Maropost/MaropostCampaigns";
import { isEqual } from "lodash";

const required = (value) => (value ? undefined : "This field is required");

export default React.memo(
    ({
        userId,
        getMaropostCampaigns,
        options,
        selectedIntegrations,
        campaigns,
        selectedCampaignLength,
        savedCampaigns,
        change,
        currentAndFuture = false,
        savedRegex,
        alreadyIntegrated = [],
        isSandboxAccount,
        apiKey,
        adAccountId,
        isEditing,
        isFormActive,
        ...props
    }) => {
        const [isCampaignsLoading, toggleCampaignsLoading] = useState(false);
        const label = selectedIntegrations?.adAccountId;
        const placeholder = label ? `Default: ${label}` : "";

        return (
            <div className={`${onboardingStyles.additionalIntegrationFields} ${onboardingStyles.formInputs}`}>
                <Field
                    name="integrationName"
                    type="text"
                    label="Integration Name"
                    placeholder={placeholder || "Example: maropost integration name"}
                    component={RenderInput}
                    darkBorder
                    large
                    validateWhenActive
                />
                <div
                    className={style.mainFormContainer}
                >
                    <div className={style.checkboxContainer}>
                        <label className={style.label}>Sandbox Account</label>
                        <Field        
                            name="isSandboxAccount"
                            type="checkbox"
                            hideLabel={true}
                            component={Checkbox}
                            disabled={isEditing}
                        />
                    </div>

                    <Field
                        name="apiKey"
                        type="password"
                        autoComplete="new-password"
                        label="API Key"
                        placeholder="Example: maropost api key"
                        className={style.input}
                        component={RenderInput}
                        darkBorder
                        large
                        validate={[required]}
                        validateWhenActive
                        disabled={isEditing}
                    />

                    <Field
                        name="adAccountId"
                        type="text"
                        label="Account Id"
                        placeholder="Example: 123456"
                        className={style.input}
                        component={RenderInput}
                        validate={[required]}
                        darkBorder
                        large
                        disabled={isEditing}
                    />
                </div>

                {apiKey && adAccountId && !isFormActive &&(
                    <MaropostCampaigns
                        options={options}
                        campaigns={campaigns}
                        savedCampaigns={savedCampaigns}
                        selectedCampaignLength={selectedCampaignLength}
                        userId={userId}
                        regex={selectedIntegrations.regexmaropost}
                        getMaropostCampaigns={getMaropostCampaigns}
                        selected={selectedIntegrations.adAccountId}
                        change={change}
                        isLoading={isCampaignsLoading}
                        toggleLoading={toggleCampaignsLoading}
                        currentAndFuture={currentAndFuture}
                        savedRegex={savedRegex}
                        isSandboxAccount={isSandboxAccount}
                        apiKey={apiKey}
                        adAccountId={adAccountId}
                    />
                )}
            </div>
        );
    },
    isEqual
);
