import { KpiAPI } from "lib/apiKpi";
import { MorphioAPI } from "lib/api";
import { getProfile } from "lib/auth";
import types from "./types";

export const getCampaigns = ({ accountId, scope, id, abortController }) => (dispatch, getState) => {
    return dispatch({
        type: types.FETCH_CAMPAIGNS,
        promise: KpiAPI({
            method: "GET",
            url: `/account/${accountId}/campaigns`,
            query: {
                scope,
                [`${scope}Id`]: id,
            },
            abortController,
        }),
    });
};

export const deletePlatformsDataCache = (accountId, platforms) => {
    return {
        type: types.DELETE_PLATFORM_CACHE_DATA,
        promise: KpiAPI({
            method: "DELETE",
            url: `/account/${accountId}/data`,
            data: {
                platforms,
            },
        }),
    };
};

const getSearchConsoleAccounts = ({ userId, connectionId }) => (dispatch, getState) => {
    return dispatch({
        type: types.GET_SEARCHCONSOLE_ACCOUNTS,
        promise: KpiAPI({
            method: "GET",
            url: `/searchConsole/accounts`,
            query: {
                userId,
                connectionId,
            },
        }),
    });
};

const getGoogleBusinessProfileOptions = ({ connectionId, userId }) => (dispatch, getState) => {
    return dispatch({
        type: types.GET_GOOGLE_BUSINESS_PROFILE_ACCOUNTS,
        promise: KpiAPI({
            method: "GET",
            url: `/googleBusinessProfile/accounts`,
            query: {
                connectionId,
                userId,
            },
        }),
    });
};

const getGoogleBusinessProfileLocationsOptions = ({ connectionId, userId, businessProfileId }) => (
    dispatch,
    getState,
) => {
    return dispatch({
        type: types.GET_GOOGLE_BUSINESS_PROFILE_LOCATIONS,
        promise: KpiAPI({
            method: "GET",
            url: `/googleBusinessProfile/locations`,
            query: {
                businessProfileId,
                connectionId,
                userId,
            },
        }),
    });
};

const exchangeLinkedInCode = ({ code, redirectUrl, connectionId = null }) => (dispatch, getState) => {
    const userId = getProfile()?.sub;
    const { sites: { selectedSite: { _id: accountId } = {} } = {} } = getState();
    return KpiAPI({
        method: "GET",
        url: `/linkedin/authorize`,
        query: {
            code,
            userId,
            accountId,
            redirectUrl,
            connectionId,
        },
    })
        .then(({ data }) => {
            dispatch({
                type: "AUTH_CONNECTION",
                payload: { accountId, platform: "linkedin", ...data },
            });
            return data;
        })
        .catch((err) => console.log("Something went wrong with exchanging Linkedin code: ", err));
};

const exchangeTikTokCode = ({ code, connectionId = null }) => (dispatch, getState) => {
    const userId = getProfile()?.sub;
    const { sites: { selectedSite: { _id: accountId } = {} } = {} } = getState();

    return KpiAPI({
        method: "GET",
        url: `/tiktok/authorize`,
        query: {
            code,
            userId,
            accountId,
            connectionId,
        },
    })
        .then(({ data }) => {
            dispatch({
                type: "AUTH_CONNECTION",
                payload: { accountId, platform: "tiktok", ...data },
            });
            return data;
        })
        .catch((err) => console.log("Something went wrong with exchanging TikTok code: ", err));
};

const exchangePinterestCode = ({ code, connectionId = null }) => (dispatch, getState) => {
    const userId = getProfile()?.sub;
    const { sites: { selectedSite: { _id: accountId } = {} } = {} } = getState();

    return KpiAPI({
        method: "GET",
        url: `/pinterest/authorize`,
        query: {
            code,
            userId,
            accountId,
            connectionId,
        },
    })
        .then(({ data }) => data)
        .catch((err) => console.log("Something went wrong with exchanging Pinterest code: ", err));
};

const exchangeBingCode = ({ code, redirectUrl, connectionId = null }) => (dispatch, getState) => {
    const userId = getProfile()?.sub;
    const { sites: { selectedSite: { _id: accountId } = {} } = {} } = getState();

    return KpiAPI({
        method: "GET",
        url: `/bing/authorize`,
        query: {
            code,
            userId,
            accountId,
            redirectUrl,
            connectionId,
        },
    })
        .then(({ data }) => {
            dispatch({
                type: "AUTH_CONNECTION",
                payload: { accountId, platform: "bing", ...data },
            });
            return data;
        })
        .catch((err) => console.log("Something went wrong with exchanging Bing code: ", err));
};

const postShopifyCreds = ({ userId, accountId, clientId, secret }) => (dispatch, getState) => {
    return KpiAPI({
        method: "POST",
        url: `/shopify/credentials`,
        data: {
            userId,
            accountId,
            clientId,
            secret,
        },
    });
};

const exchangeShopifyCode = ({ code, redirectUrl, connectionId = null, shop, hmac, state, timestamp }) => (
    dispatch,
    getState,
) => {
    const userId = getProfile()?.sub;
    const { sites: { selectedSite: { _id: accountId } = {} } = {} } = getState();

    return KpiAPI({
        method: "POST",
        url: `/shopify/authorize`,
        data: {
            code,
            userId,
            hmac,
            timestamp,
            accountId,
            redirectUrl,
            connectionId,
            state,
            shop,
        },
    }).then(({ data }) => data);
};

const exchangeSlackCode = ({ code, redirectUrl, connectionId }) => (dispatch, getState) => {
    const userId = getProfile()?.sub;
    const { sites: { selectedSite: { _id: accountId } = {} } = {} } = getState();

    return KpiAPI({
        method: "GET",
        url: `/slack/authorize`,
        query: {
            code,
            userId,
            accountId,
            redirectUrl,
            connectionId,
        },
    })
        .then(({ data }) => {
            dispatch({
                type: "AUTH_CONNECTION",
                payload: { accountId, platform: "slack", ...data },
            });
            return data;
        })
        .catch((err) => console.log("Something went wrong with connecting Slack: ", err));
};

const exchangeAnalyticsCode = (code, connectionId = null) => async (dispatch, getState) => {
    const userId = getProfile()?.sub;
    const { sites: { selectedSite: { _id: accountId } = {} } = {} } = getState();

    return await KpiAPI({
        method: "GET",
        url: `/analytics/authorize`,
        query: {
            code,
            userId,
            accountId,
            connectionId,
        },
    }).then(({ data }) => {
        dispatch({
            type: "AUTH_CONNECTION",
            payload: { accountId, platform: "analytics", ...data },
        });
        return data;
    });
};

const exchangeAnalyticsV4Code = (code, connectionId = null) => async (dispatch, getState) => {
    const userId = getProfile()?.sub;
    const { sites: { selectedSite: { _id: accountId } = {} } = {} } = getState();
    return await KpiAPI({
        method: "GET",
        url: `/analyticsV4/authorize`,
        query: {
            code,
            userId,
            accountId,
            connectionId,
        },
    }).then(({ data }) => {
        dispatch({
            type: "AUTH_CONNECTION",
            payload: { accountId, platform: "analyticsV4", ...data },
        });
        return data;
    });
};

const exchangeGoogleBusinessProfileCode = (code, connectionId = null) => async (dispatch, getState) => {
    const userId = getProfile()?.sub;
    const { sites: { selectedSite: { _id: accountId } = {} } = {} } = getState();

    return await KpiAPI({
        method: "GET",
        url: `/googleBusinessProfile/authorize`,
        query: {
            code,
            userId,
            accountId,
            connectionId,
        },
    }).then(({ data }) => data);
};

const exchangeSearchConsoleCode = (code, connectionId = null) => async (dispatch, getState) => {
    const userId = getProfile()?.sub;
    const { sites: { selectedSite: { _id: accountId } = {} } = {} } = getState();

    return await KpiAPI({
        method: "GET",
        url: `/searchConsole/authorize`,
        query: {
            code,
            userId,
            accountId,
            connectionId,
        },
    }).then(({ data }) => data);
};

const exchangeAdwordsCode = (code, connectionId = null) => (dispatch, getState) => {
    const userId = getProfile()?.sub;
    const { sites: { selectedSite: { _id: accountId } = {} } = {} } = getState();

    return KpiAPI({
        method: "GET",
        url: `/adwords/authorize`,
        query: {
            code,
            userId,
            accountId,
            connectionId,
        },
    })
        .then(({ data }) => {
            dispatch({
                type: "AUTH_CONNECTION",
                payload: { accountId, platform: "adwords", ...data },
            });
            return data;
        })
        .catch((err) => console.log("Something went wrong with getting Adwords: ", err));
};

const exchangeBigQueryCode = (code, connectionId = null) => (dispatch, getState) => {
    const userId = getProfile()?.sub;
    const { sites: { selectedSite: { _id: accountId } = {} } = {} } = getState();

    return KpiAPI({
        method: "GET",
        url: `/bigQuery/authorize`,
        query: {
            code,
            userId,
            accountId,
            connectionId,
        },
    })
        .then(({ data }) => {
            dispatch({
                type: "AUTH_CONNECTION",
                payload: { accountId, platform: "bigQuery", ...data },
            });
        })
        .catch((err) => console.log("Something went wrong with exchanging Google BigQuery code: ", err));
};

const saveFacebookAccessToken = (token, connectionId = null) => (dispatch, getState) => {
    // send access token to the sever to save long lived access token.

    const userId = getProfile()?.sub;
    const { sites: { selectedSite: { _id: accountId } = {} } = {} } = getState();

    return KpiAPI({
        method: "GET",
        url: `/facebook/authorize`,
        query: {
            userId,
            accountId,
            connectionId,
            token,
        },
    })
        .then(({ data }) => {
            dispatch({
                type: "AUTH_CONNECTION",
                payload: { accountId, platform: "facebook", ...data },
            });
            return data;
        })
        .catch((err) => console.log("Something went wrong with saving Meta token: ", err));
};

const saveOrganicFacebookAccessToken = (token, connectionId = null) => (dispatch, getState) => {
    // send access token to the sever to save long lived access token.

    const userId = getProfile()?.sub;
    const { sites: { selectedSite: { _id: accountId } = {} } = {} } = getState();

    return KpiAPI({
        method: "GET",
        url: `/organicFacebook/authorize`,
        query: {
            userId,
            accountId,
            connectionId,
            token,
        },
    })
        .then(({ data }) => {
            dispatch({
                type: "AUTH_CONNECTION",
                payload: { accountId, platform: "organicFacebook", ...data },
            });
            return data;
        })
        .catch((err) => console.log("Something went wrong with saving Organic Facebook token: ", err));
};

const saveRegex = ({ platform, accountId, regex }) => ({
    type: types.SAVE_REGEX,
    promise: KpiAPI({
        method: "PUT",
        url: `/account/${accountId}/regex`,
        data: {
            data: {
                [platform]: regex,
            },
        },
    }),
});

const changeConnection = ({ platform, accountId, connectionId }) => (dispatch, getState) => {
    return dispatch({
        type: types.CHANGE_ACCOUNT_CONNECTION,
        meta: {
            platform,
            accountId,
            connectionId,
        },
        promise: Promise.resolve(),
    });
};

export const clearIntegrations = () => (dispatch, getState) => {
    return dispatch({
        type: types.CLEAR_REDUCER,
        promise: Promise.resolve(),
    });
};

const updateConnectionName = ({ platform, userId, connectionId, name }) => (dispatch, getState) => {
    return dispatch({
        type: types.UPDATE_CONNECTION_NAME,
        promise: MorphioAPI({
            method: "put",
            url: `/user/connection`,
            data: {
                connectionId,
                platform,
                userId,
                data: {
                    name,
                },
            },
        }),
    });
};

export default {
    getCampaigns,
    exchangeBingCode,
    exchangeSlackCode,
    exchangeAnalyticsCode,
    exchangeAnalyticsV4Code,
    exchangeAdwordsCode,
    exchangeShopifyCode,
    saveFacebookAccessToken,
    saveOrganicFacebookAccessToken,
    changeConnection,
    updateConnectionName,
    exchangeLinkedInCode,
    exchangeTikTokCode,
    saveRegex,
    postShopifyCreds,
    deletePlatformsDataCache,
    getSearchConsoleAccounts,
    getGoogleBusinessProfileOptions,
    getGoogleBusinessProfileLocationsOptions,
    exchangeGoogleBusinessProfileCode,
    exchangeSearchConsoleCode,
    exchangePinterestCode,
    clearIntegrations,
    exchangeBigQueryCode,
};
