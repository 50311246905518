import React from "react";
import style from "./styles.module.scss";
import LinearProgress from "material-ui/LinearProgress";
import Container from "components/DropDown/Container";
import { SimpleListItem } from "components/DropDown/ListItems";
import ArrowDown from "assets/images/Top_Panel/JSX/Icon-Arrow-Down";
import Checkmark from "assets/images/icons/JSX/Icon-Checkmark";
import Close from "assets/images/icons/JSX/Icon-Close-Small";
import Plus from "assets/images/Sub_Menu/JSX/Plus-Open";
import handleOutsideClick from "lib/handleOutsideClick";
import IconPopUp from "assets/images/icons/JSX/Icon-PopUp";
import isEqual from "lodash/isEqual";

import { withState, compose } from "recompose";

const LoadingState = ({ label }) => (
    <div className={style.loading}>
        <p>{label}</p>
        <div className={style.loadingBar}>
            <LinearProgress color="#0671EE" />
        </div>
    </div>
);

const dropDownState = compose(
    withState("isOpen", "toggleOpen", false),
    handleOutsideClick(({ toggleOpen }, event) => {
        const element = document.querySelector(".styles_connectDropdownContainer__2zR1U");
        if (element && !element.contains(event.target)) {
            toggleOpen(false);
        }
    }),
);

function capitalizeFirstLetter(string) {
    if (!string) return "Unnamed Connection";
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default dropDownState(
    React.memo(
        ({
            onAdd,
            label,
            selected,
            loading = false,
            list = {},
            isOpen,
            toggleOpen,
            setWrapperRef,
            changeConnection,
            connectButton: ConnectButton,
            disabled,
        } = {}) => {
            const { name: selectedName = "" } = list[selected] || {};

            return (
                <div ref={setWrapperRef}>
                    <div style={{ position: "relative" }}>
                        <div
                            onClick={(e) => {
                                if (!disabled) {
                                    toggleOpen(!isOpen);
                                }
                            }}
                            className={`${style.connectDropdownContainer} ${isOpen && style.openContainer} ${disabled &&
                                style.disabled}`}
                        >
                            {typeof selected === "string" && selected ? (
                                <span className={style.selected}>
                                    <Checkmark /> {capitalizeFirstLetter(selectedName)}
                                </span>
                            ) : (
                                <span>Select Your {label} Account</span>
                            )}
                            <div className={style.rightIcons}>{loading ? <LoadingState /> : <ArrowDown />}</div>
                        </div>
                        <Container className={style.connectDropdown} isOpen={isOpen}>
                            {Object.keys(list).length > 0 ? (
                                Object.keys(list).map((account, index) => [
                                    <SimpleListItem
                                        className={`${style.option} ${
                                            selectedName === list[account].name ? style.current : ""
                                        }`}
                                        key={index}
                                        onClick={() => {
                                            toggleOpen(false);
                                            changeConnection({
                                                connectionId: account,
                                            });
                                        }}
                                        label={capitalizeFirstLetter(list[account].name)}
                                    />,
                                ])
                            ) : (
                                <SimpleListItem className={style.noAccounts} label="No Accounts Connected" />
                            )}
                            {ConnectButton ? (
                                <ConnectButton
                                    onClick={() => {
                                        toggleOpen(false);
                                        onAdd();
                                    }}
                                />
                            ) : (
                                <div
                                    onClick={() => {
                                        toggleOpen(false);
                                        onAdd();
                                    }}
                                    className={style.connectNewAccount}
                                >
                                    <Plus />
                                    Connect New Account
                                </div>
                            )}
                        </Container>
                        <div
                            id="close"
                            onClick={() => {
                                if (!disabled) {
                                    changeConnection({ connectionId: null });
                                }
                            }}
                            className={`${style.closeIcon} ${disabled && style.disabled}`}
                        >
                            {typeof selected === "string" && selected && !loading && <Close />}
                        </div>
                    </div>
                    {(typeof selected !== "string" || !selected) && (
                        <div className={style.popUpMessage}>
                            <IconPopUp />
                            <p>
                                Please <strong>disable pop-up and ad blockers</strong> to connect and authenticate{" "}
                                {label}.
                            </p>
                        </div>
                    )}
                </div>
            );
        },
        isEqual,
    ),
);
