import PinterestConnect from "features/Integrations/Connections/PinterestConntect";
import React, {useState} from "react";
import onboardStyles from "features/Onboarding/onboarding.module.scss";
import {isEqual} from "lodash";
import SelectConnectAccounts from "features/Integrations/Dependant/common/SelectConnectAccounts";
import PinterestIntegrate from "features/Integrations/Dependant/Pinterest/PinterestIntegrate";
import PinterestCampaigns from "features/Integrations/Dependant/Pinterest/PinterestCampaigns";

export default React.memo(
    ({
        userId,
        list,
        selected,
        changeConnection,
        savePinterestAccessToken,
        getPinterestAccounts,
        getPinterestCampaigns,
        options,
        selectedIntegrations,
        integrations,
        campaigns,
        selectedCampaignLength,
        savedCampaigns,
        change,
        currentAndFuture = false,
        savedRegex,
        integrationId,
        alreadyIntegrated = [],
    }) => {
        const [isCampaignsLoading, toggleCampaignsLoading] = useState(false);
        const [isIntegrateLoading, toggleIntegrateLoading] = useState(false);

        return (
            <div className={onboardStyles.formInputs}>
                <PinterestConnect
                    exchangeCode={savePinterestAccessToken}
                    component={({ onAdd, loading, label, platform }) =>
                        SelectConnectAccounts({
                            list,
                            selected,
                            changeConnection,
                            onAdd,
                            loading,
                            label,
                            platform,
                            disabled:
                                isCampaignsLoading ||
                                isIntegrateLoading ||
                                !!integrationId,
                        })
                    }
                />
                {selected && (
                    <div
                        className={`${onboardStyles.formInputs} ${onboardStyles.noMargin}`}
                    >
                        <PinterestIntegrate
                            selectedIntegrations={selectedIntegrations}
                            integrations={integrations}
                            getPinterestAccounts={getPinterestAccounts}
                            options={options}
                            selected={selected}
                            userId={userId}
                            change={change}
                            isLoading={isIntegrateLoading}
                            toggleLoading={toggleIntegrateLoading}
                            isEditing={!!integrationId}
                            alreadyIntegrated={alreadyIntegrated}
                        />
                    </div>
                )}
                {selectedIntegrations &&
                    selectedIntegrations.pinterest &&
                    integrations && (
                        <PinterestCampaigns
                            integrations={integrations}
                            options={options}
                            campaigns={campaigns}
                            savedCampaigns={savedCampaigns}
                            selectedCampaignLength={selectedCampaignLength}
                            connectionId={selected}
                            userId={userId}
                            regex={selectedIntegrations.regexpinterest}
                            getPinterestCampaigns={getPinterestCampaigns}
                            selected={selectedIntegrations.pinterest.value}
                            change={change}
                            isLoading={isCampaignsLoading}
                            toggleLoading={toggleCampaignsLoading}
                            currentAndFuture={currentAndFuture}
                            savedRegex={savedRegex}
                        />
                    )}
            </div>
        );
    },
    isEqual
);
