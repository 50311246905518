import React from "react";

export default () => (
    <svg
        width="8px"
        height="8px"
        viewBox="0 0 8 8"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M5,5 L5,7.25910423 C5,7.66808986 4.55387666,7.99997209 4.00013221,8 C3.44627444,8 3,7.66808986 3,7.25913213 L3,5 L0.740839961,5 C0.331882238,5 0,4.55368779 0,3.99986779 C0,3.44612334 0.331882238,3 0.740839961,3 L3,3 L3,0.740839961 C3,0.331882238 3.44631221,9.51359389e-17 4.00013221,6.12242443e-17 C4.55387666,2.73171757e-17 5,0.331882238 5,0.740839961 L5,3 L7.25910423,3 C7.66808986,3 7.99997209,3.44612334 8,3.99986779 C8,4.55372556 7.66808986,5 7.25913213,5 L5,5 Z"
            id="path-10"
        ></path>
    </svg>
);
