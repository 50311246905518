import React from "react";

const SeenIt = ({ children, seenIt, it, handleSeenIt }) => {
    if (!it || seenIt) {
        return null;
    }

    return React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
            handleSeenIt: () => handleSeenIt(it),
            it,
        });
    });
};

export default SeenIt;
