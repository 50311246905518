const GET_CHANNEL_DATA = "GET_CHANNEL_DATA";
const CLEAR_CHANNEL_DATA = "CLEAR_CHANNEL_DATA";
const PAGES_REPORT = "PAGES_REPORT";
const KEYWORDS_REPORT = "KEYWORDS_REPORT";
const PRODUCTS_REPORT = "PRODUCTS_REPORT";

export default {
    GET_CHANNEL_DATA,
    CLEAR_CHANNEL_DATA,
    KEYWORDS_REPORT,
    PAGES_REPORT,
    PRODUCTS_REPORT
};
