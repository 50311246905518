import React from "react";
import qs from "querystring";
import openWindow from "../openWindow";
const { REACT_APP_DOMAIN, REACT_APP_SLACK_CLIENT_ID } = process.env;

const redirectUrl = `${REACT_APP_DOMAIN}/dashboard/account/connect`;

class SlackConnect extends React.Component {
    state = {
        loading: false,
        platform: "slack",
        label: "Slack",
    };

    csrfCode() {
        return Math.random()
            .toString(36)
            .substring(7);
    }

    login = async (cb, secret) => {
        return openWindow(this.buildLoginUrl(secret), secret).then(cb);
    };

    exchangeCode = async (data) => {
        await this.props.exchangeCode({
            code: data.code,
            redirectUrl: redirectUrl,
        });
        this.setState({ loading: false });
    };

    buildLoginUrl = (secret) => {
        //Create unique identifier for state?
        const url = "https://slack.com/oauth/v2/authorize";
        const params = qs.stringify({
            client_id: REACT_APP_SLACK_CLIENT_ID,
            scope: "chat:write,chat:write.public,channels:read",
            response_type: "code",
            redirect_uri: redirectUrl,
            state: secret,
        });

        return url + "?" + params;
    };

    onAdd = async (event) => {
        const secret = this.csrfCode();

        this.setState({ loading: true });
        await this.login(this.exchangeCode, secret);
        this.setState({ loading: false });
    };

    render() {
        const { loading, platform, label } = this.state;

        const { component: Component } = this.props;

        return <Component platform={platform} loading={loading} onAdd={this.onAdd} label={label} />;
    }
}

export default SlackConnect;
