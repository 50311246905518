import React, { useEffect, useState } from "react";
import AnalyticsV4Connect from "features/Integrations/Connections/AnalyticsV4Connect/container";
import Connect from "features/Integrations/Dependant/ConnectButton";
import { Field } from "redux-form";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import style from "./style.module.scss";
import onboardingStyles from "features/Onboarding/onboarding.module.scss";
import MultiSelector from "components/MultiSelector";
import Loading from "components/Loading/ThreeDots";
import { CardWarning } from "components/Warnings";
import RenderInput from "components/RenderInput";
import { TooltipDark } from "components/Tooltip";

const required = (value) => (value ? undefined : "This field is required");

const ConnectButton = ({ changeConnection, label, list, loading, onAdd, platform, selected, disabled }) => {
    return (
        <div className={`${style.connectButtonWrapper} ${onboardingStyles.formInputs}`}>
            <Connect
                selected={selected}
                list={list}
                changeConnection={({ connectionId }) => changeConnection({ connectionId, platform })}
                loading={loading}
                onAdd={onAdd}
                label={label}
                disabled={disabled}
            />
        </div>
    );
};

const AnalyticsV4Integrate = ({
    change,
    getAnalyticsV4Accounts,
    integrations,
    options,
    selected,
    selectedIntegrations,
    userId,
    isLoading,
    toggleLoading,
    integrationId,
    alreadyIntegrated,
    ...p
}) => {
    useEffect(() => {
        if (isEmpty(selected) || integrations) return;
        toggleLoading(true);
        getAnalyticsV4Accounts({ userId, connectionId: selected }).finally(() => toggleLoading(false));
    }, [selected, userId]);

    const isEditing = !!integrationId;

    if (isLoading) return <Loading style={{ margin: "2rem auto 0 auto" }} color="#BCCFDC" />;

    if (!isEmpty(selected) && isEmpty(integrations) && !isLoading) {
        return (
            <CardWarning
                className={onboardingStyles.warning}
                title="Google Analytics 4 Error"
                subtitle="We could not find any accounts in your connection. Please choose another Analytics V4 connection."
            />
        );
    }

    if (!integrations) return null;

    return (
        <div className={`${style.analyticsIntegrateWrapper} ${onboardingStyles.formInputs}`}>
            <Field
                component={RenderInput}
                className={onboardingStyles.renderInput}
                name="analyticsV4IntegrationName"
                label="Connection Name"
                darkBorder
                large
                type="text"
                placeholder={
                    selectedIntegrations?.analyticsV4Properties?.label
                        ? `Default: ${selectedIntegrations?.analyticsV4Properties?.label}`
                        : ""
                }
                tip="Name that will apearin the app forthis Ads account."
            />
            <div
                className={onboardingStyles.multiSelectWrapper}
                data-for="analyticsV4-field-account"
                data-tip="This field isn't editible. You may remove this connection and add a new one."
                data-tip-disable={!isEditing}
            >
                <Field
                    component={MultiSelector}
                    className={onboardingStyles.multiSelect}
                    name="analyticsV4Accounts"
                    label={options.analyticsV4Accounts.humanReadable}
                    onChange={(e, curr, prev) => {
                        // Reset property and view
                        if (prev && !isEqual(prev, curr)) {
                            change("analyticsV4Properties", null);
                        }
                    }}
                    options={Object.values(integrations.analyticsV4Accounts).map((analyticsV4Account) => {
                        return {
                            value: analyticsV4Account.id,
                            label: `${analyticsV4Account.name} - ${analyticsV4Account.id.replace("accounts/", "")}`,
                        };
                    })}
                    disabled={isEditing}
                    darkBorder
                    validate={[required]}
                />
            </div>
            <div
                className={onboardingStyles.multiSelectWrapper}
                data-for="analyticsV4-field-view"
                data-tip="This field isn't editible. You may remove this integration and add a new one."
                data-tip-disable={!isEditing}
            >
                <Field
                    component={MultiSelector}
                    className={onboardingStyles.multiSelect}
                    name="analyticsV4Properties"
                    label={options.analyticsV4Properties.humanReadable}
                    onChange={(e, curr, prev) => {
                        if (!curr || !curr.value) return;
                        const profile = integrations.analyticsV4Properties[curr.value];
                        if (!selectedIntegrations || !selectedIntegrations.analyticsV4Accounts) {
                            const account = integrations.analyticsV4Accounts[profile.parent];
                            change("analyticsV4Accounts", {
                                value: account.id,
                                label: account.name,
                            });
                        }
                    }}
                    options={Object.keys(integrations.analyticsV4Properties)
                        .filter((id) => {
                            if (!selectedIntegrations) return true;

                            if (
                                selectedIntegrations.hasOwnProperty("analyticsV4Accounts") &&
                                selectedIntegrations.analyticsV4Accounts
                            ) {
                                return (
                                    integrations.analyticsV4Properties[id].parent ===
                                    selectedIntegrations.analyticsV4Accounts.value
                                );
                            }

                            return true;
                        })
                        .map((id) => {
                            const disable = alreadyIntegrated.includes(id.toString());
                            return {
                                value: integrations.analyticsV4Properties[id].id,
                                label: `${integrations.analyticsV4Properties[id].name}${
                                    disable ? " (In Use)" : ""
                                } - ${integrations.analyticsV4Properties[id].id.replace("properties/", "")}`,
                                currency: integrations.analyticsV4Properties[id].currency,
                                disabled: disable,
                            };
                        })}
                    darkBorder
                    disabled={isEditing}
                    validate={[required]}
                />
            </div>
            <TooltipDark id="analyticsV4-field-account" proximity={34} />
            <TooltipDark id="analyticsV4-field-property" proximity={34} />
            <TooltipDark id="analyticsV4-field-view" proximity={34} />
        </div>
    );
};

export default React.memo(
    ({
        change,
        changeConnection,
        exchangeAnalyticsV4Code,
        getAnalyticsV4Accounts,
        integrations,
        list,
        options,
        selected,
        selectedIntegrations,
        userId,
        integrationId,
        alreadyIntegrated = [],
    }) => {
        const [integrateLoading, toggleIntegrateLoading] = useState(false);

        return (
            <>
                <AnalyticsV4Connect
                    exchangeAnalyticsV4Code={exchangeAnalyticsV4Code}
                    component={({ onAdd, loading, label, platform, accountId }) =>
                        ConnectButton({
                            list,
                            selected,
                            changeConnection,
                            onAdd,
                            loading,
                            label,
                            platform,
                            accountId,
                            disabled: integrateLoading || !!integrationId,
                        })
                    }
                />

                {selected && (
                    <AnalyticsV4Integrate
                        selectedIntegrations={selectedIntegrations}
                        integrations={integrations}
                        getAnalyticsV4Accounts={getAnalyticsV4Accounts}
                        options={options}
                        selected={selected}
                        userId={userId}
                        change={change}
                        isLoading={integrateLoading}
                        toggleLoading={toggleIntegrateLoading}
                        integrationId={integrationId}
                        alreadyIntegrated={alreadyIntegrated}
                    />
                )}
            </>
        );
    },
    isEqual,
);
