import React, { useReducer, useEffect, useCallback } from "react";
import styles from "../sharedStyles.module.scss";
import moment from "moment";
import DayPicker, { DateUtils, LocaleUtils } from "react-day-picker";
import Presets from "./Presets";
import DateSelector from "./DateSelector";
import { useHandleOutsideClickHook } from "lib/handleOutsideClick";
import { cannotSelectAfter, cannotSelectAfterMonth } from "components/DatePicker/MonthPicker";
import { getDateReadable } from "./helpers";

const reducer = (state, action) => {
    switch (action.type) {
        case "HANDLE_PRESET_CLICK":
            return {
                ...state,
                from: action.start,
                to: action.end,
                appliedFrom: action.start,
                appliedTo: action.end,
                isPresetOpen: false,
                label: action.label,
            };
        case "CANCEL_DATE_SELECTION":
            return {
                ...state,
                from: action.start,
                to: action.end,
                appliedFrom: action.start,
                appliedTo: action.end,
                enteredTo: action.end,
                isOpen: false,
                isPresetOpen: false,
                startDateReadable: moment(action.start).format("MMM D, YYYY"),
                endDateReadable: moment(action.end).format("MMM D, YYYY"),
            };
        case "APPLY_DATE_SELECTION":
            return {
                ...state,
                appliedFrom: action.start,
                appliedTo: action.end,
                isOpen: false,
            };
        case "HANDLE_START_DATE_SELECTION":
            return {
                ...state,
                from: action.day,
                to: null,
                enteredTo: null,
                startDateReadable: moment(action.day).format("MMM D, YYYY"),
            };
        case "HANDLE_END_DATE_SELECTION":
            return {
                ...state,
                to: action.day,
                enteredTo: action.day,
                endDateReadable: moment(action.day).format("MMM D, YYYY"),
            };

        case "UPDATE_DATE_SELECTION":
            return {
                ...state,
                from: action.start,
                to: action.end,
                appliedFrom: action.start,
                appliedTo: action.end,
                enteredTo: action.end,
            };
        case "UPDATE_ENTERED_TO":
            return {
                ...state,
                enteredTo: action.day,
            };
        case "UPDATE_INPUT_START_DATE":
            return {
                ...state,
                startDateReadable: action.day,
            };
        case "UPDATE_INPUT_END_DATE":
            return {
                ...state,
                endDateReadable: action.day,
            };
        case "HANDLE_INPUT_CHANGE":
            return {
                ...state,
                from: action.start,
                to: action.end,
                enteredTo: action.end,
            };
        case "TOGGLE_PRESETS":
            return {
                ...state,
                isPresetOpen: action.isPresetOpen,
            };
        case "TOGGLE_OPEN":
            return {
                ...state,
                isOpen: action.isOpen,
            };
        case "CLEAR_DATE":
            return {
                ...state,
                from: null,
                to: null,
                appliedFrom: null,
                appliedTo: null,
                enteredTo: null,
                isOpen: false,
                isPresetOpen: false,
            };
        case "UPDATE_ERRORS":
            return {
                ...state,
                error: {
                    start: action.start,
                    end: action.end,
                },
            };
        default:
            return state;
    }
};

const formatWeekdayShort = (day) =>
    ({
        0: "Su",
        1: "Mo",
        2: "Tu",
        3: "We",
        4: "Th",
        5: "Fr",
        6: "Sa",
    }[day]);

const containsDay = (contains) => (day) => {
    return moment(contains).isSame(day, "day");
};

const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
};

export default ({
    className = null,
    dateRange,
    onDateSelect,
    onDateClear,
    alignLeft,
    offleft = 0,
    rangeClass,
    onDayClick = () => null,
    maxDaysSelected,
    maxMonthsSelected,
    disabledDays = { after: new Date() },
    additionalOptions = [],
    defaultActive,
    isPresetOpen = false,
}) => {
    const initialState = {
        isOpen: false,
        from: null,
        to: null,
        appliedFrom: null,
        appliedTo: null,
        enteredTo: null, // Keep track of the last day for mouseEnter.
        isPresetOpen: isPresetOpen,
        startDateReadable: getDateReadable(dateRange.compareToStart),
        endDateReadable: getDateReadable(dateRange.compareToEnd),
        label: "Compare To",
        error: { start: "", end: "" },
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const clearDate = useCallback(() => {
        onDateClear({
            dateRange: {
                ...dateRange,
                compareToStart: null,
                compareToEnd: null,
            },
        });
        dispatch({ type: "CLEAR_DATE" });
    }, [onDateClear, dateRange]);

    useEffect(() => {
        dispatch({ type: "UPDATE_DATE_SELECTION", start: dateRange.compareToStart, end: dateRange.compareToEnd });
    }, [dateRange.compareToStart, dateRange.compareToEnd]);

    const setWrapperRef = useHandleOutsideClickHook(() => {
        dispatch({ type: "CANCEL_DATE_SELECTION", start: dateRange.compareToStart, end: dateRange.compareToEnd });
    });

    const onCancel = useCallback(() => {
        dispatch({ type: "CANCEL_DATE_SELECTION", start: dateRange.compareToStart, end: dateRange.compareToEnd });
    }, [dateRange]);

    const handleDayClick = (day, modifiers = {}) => {
        if (modifiers.disabled) {
            return;
        }

        dispatch({ type: "UPDATE_ERRORS", error: { start: "", end: "" } });

        onDayClick(day, modifiers, state.from, state.to);

        if (isSelectingFirstDay(state.from, state.to, day)) {
            // Select First Day
            // onFirstDayClick(day, modifiers, from, to);
            dispatch({ type: "HANDLE_START_DATE_SELECTION", day });
        } else {
            // Select Second Day
            // onLastDayClick(day, modifiers, from, to);
            dispatch({ type: "HANDLE_END_DATE_SELECTION", day });
        }
    };

    const handleDayMouseEnter = (day) => {
        if (!isSelectingFirstDay(state.from, state.to, day)) {
            dispatch({ type: "UPDATE_ENTERED_TO", day });
        }
    };

    const handlePresetClick = (start, end, label) => {
        onDateSelect({
            dateRange: {
                ...dateRange,
                compareToStart: start,
                compareToEnd: end,
            },
        });
        dispatch({ type: "HANDLE_PRESET_CLICK", start, end, label });
    };

    const onApply = useCallback(() => {
        const end = state.to || state.from; // Set end to the start date if not selected
        onDateSelect({
            dateRange: {
                ...dateRange,
                compareToStart: state.from,
                compareToEnd: end,
            },
        });

        dispatch({ type: "APPLY_DATE_SELECTION", start: state.from, end });
    }, [onDateSelect, dateRange, state.from, state.to]);

    const onInputChange = useCallback(({ start, end } = {}, error) => {
        if (error) return;
        dispatch({ type: "HANDLE_INPUT_CHANGE", start: moment(start).toDate(), end: moment(end).toDate() });
    }, []);

    const selectedDays = [state.from, { from: state.from, to: state.enteredTo }];

    return (
        <div
            ref={setWrapperRef}
            className={`dateRangeCompareContainer ${styles.calendarContainer} ${
                styles.compareCalendar
            } ${state.isOpen && styles.calendarOpen} ${className && className}`}
        >
            <Presets
                from={state.appliedFrom}
                to={state.appliedTo}
                label={state.label}
                dateRange={dateRange}
                toggleOpen={(isOpen) => dispatch({ type: "TOGGLE_OPEN", isOpen })}
                clearDate={onDateClear ? clearDate : null}
                togglePresetOpen={(isPresetOpen) => dispatch({ type: "TOGGLE_PRESETS", isPresetOpen })}
                isOpen={state.isOpen}
                isPresetOpen={state.isPresetOpen}
                handlePresetClick={handlePresetClick}
                additionalOptions={additionalOptions}
                defaultActive={defaultActive}
            />
            <div className={styles.dayPickerWrapper} style={{ left: offleft }}>
                <DayPicker
                    initialMonth={state.to ? state.to : new Date()}
                    localeUtils={{ ...LocaleUtils, formatWeekdayShort }}
                    className={`dateRange ${state.isOpen && "dateRangeOpen"} ${alignLeft &&
                        styles.rangePickerAlignLeft} ${rangeClass && rangeClass}`}
                    numberOfMonths={2}
                    toMonth={new Date()}
                    selectedDays={selectedDays}
                    disabledDays={
                        maxDaysSelected &&
                        moment(cannotSelectAfter(maxDaysSelected, state.from)).isBefore(dateRange.start) &&
                        !state.to
                            ? { after: cannotSelectAfter(maxDaysSelected, state.from) }
                            : maxMonthsSelected &&
                              moment(cannotSelectAfter(maxMonthsSelected, state.from)).isBefore(dateRange.start) &&
                              !state.to
                            ? { after: cannotSelectAfterMonth(maxMonthsSelected, state.from) }
                            : dateRange.start
                            ? { after: dateRange.start }
                            : disabledDays
                    }
                    modifiers={{
                        compareFrom: containsDay(dateRange.start),
                        compareTo: containsDay(dateRange.end),
                        compareEnteredTo: {
                            after: dateRange.start,
                            before: moment(dateRange.end)
                                .add(1, "day")
                                .toDate(),
                        },
                        from: containsDay(state.from),
                        to: containsDay(state.to),
                        enteredTo: containsDay(state.enteredTo),
                    }}
                    onDayClick={handleDayClick}
                    onDayMouseEnter={handleDayMouseEnter}
                    modifiersStyles={{
                        today: {
                            fontWeight: "800",
                        },
                        disabled: {
                            color: "#BCCFDC",
                        },
                    }}
                />
                <DateSelector
                    isOpen={state.isOpen}
                    maxDaysSelected={maxDaysSelected}
                    onInputChange={onInputChange}
                    onCancel={onCancel}
                    onApply={onApply}
                    from={state.from}
                    to={state.to}
                    initialStart=""
                    initialEnd=""
                    className={styles.dateSelector}
                    startDateReadable={state.startDateReadable}
                    endDateReadable={state.endDateReadable}
                    actualStart={dateRange.start}
                    actualEnd={dateRange.end}
                    updateStartDate={(day) => dispatch({ type: "UPDATE_INPUT_START_DATE", day })}
                    updateEndDate={(day) => dispatch({ type: "UPDATE_INPUT_END_DATE", day })}
                    error={state.error}
                    updateErrors={(error) => dispatch({ type: "UPDATE_ERRORS", ...error })}
                />
            </div>
        </div>
    );
};
