import { api as analyticsApi } from "lib/analytics";
import { api as analyticsV4Api } from "lib/analyticsV4";
import { api as adwordsApi } from "lib/adwords";
import { api as bingApi } from "lib/bing";
import { api as facebookApi } from "lib/facebook";
import { api as organicFacebookApi } from "lib/organicFacebook";
import { api as linkedinApi } from "lib/linkedin";
import { api as tiktokApi } from "lib/tiktok";
import { api as pinterestApi } from "lib/pinterest";
import { api as maropostApi } from "lib/maropost";
import { api as klaviyoApi } from "lib/klaviyo";
import { api as bigQueryApi } from "lib/bigQuery";
import { api as stackAdaptApi } from "lib/stackAdapt";
import { KpiAPI } from "lib/apiKpi";
import types from "./types";
import { MorphioAPI } from "lib/api";

function getAnalyticsAccounts({ userId, connectionId }) {
    return {
        type: types.GET_CONNECTION_ACCOUNTS,
        platform: "analytics",
        promise: analyticsApi.getAccounts({ userId, connectionId }),
    };
}

function getAnalyticsV4Accounts({ userId, connectionId }) {
    return {
        type: types.GET_CONNECTION_ACCOUNTS,
        platform: "analyticsV4",
        promise: analyticsV4Api.getAccounts({ userId, connectionId }),
    };
}

function getAdwordsAccounts({ userId, connectionId }) {
    return {
        type: types.GET_CONNECTION_ACCOUNTS,
        promise: adwordsApi.getAccounts({ userId, connectionId }),
    };
}

function getAdwordsCampaigns({ userId, connectionId, adwordsId }) {
    return {
        type: types.GET_CONNECTION_CAMPAIGNS,
        promise: adwordsApi.getCampaigns({ userId, connectionId, adwordsId }).then(({ campaigns, adwordsId }) => ({
            integrationId: adwordsId,
            campaigns: campaigns.adwords,
        })),
    };
}

function getLinkedinAccounts({ userId, connectionId }) {
    return {
        type: types.GET_CONNECTION_ACCOUNTS,
        promise: linkedinApi.getAccounts({ userId, connectionId }),
    };
}

function getLinkedinCampaigns({ userId, connectionId, linkedinId }) {
    return {
        type: types.GET_CONNECTION_CAMPAIGNS,
        promise: linkedinApi.getCampaigns({ userId, connectionId, linkedinId }).then(({ campaigns, linkedinId }) => ({
            integrationId: linkedinId,
            campaigns: campaigns.linkedin,
        })),
    };
}

function getTiktokAccounts({ userId, connectionId }) {
    return {
        type: types.GET_CONNECTION_ACCOUNTS,
        promise: tiktokApi.getAccounts({ userId, connectionId }),
    };
}

function getPinterestAccounts({ userId, connectionId }) {
    return {
        type: types.GET_CONNECTION_ACCOUNTS,
        promise: pinterestApi.getAccounts({ userId, connectionId }),
    };
}

function getBigQueryDatasets({ userId, connectionId }) {
    return {
        type: types.GET_CONNECTION_ACCOUNTS,
        promise: bigQueryApi.getDatasets({ userId, connectionId }),
    };
}

function getBigQueryTables({ userId, connectionId, projectId, datasetId }) {
    return {
        type: types.GET_BIGQUERY_TABLES,
        promise: bigQueryApi.getTables({ userId, connectionId, projectId, datasetId }),
    };
}

function getBigQueryTableFields({ userId, connectionId, projectId, datasetId, tableId }) {
    return {
        type: types.GET_BIGQUERY_FIELDS,
        promise: bigQueryApi.getTableFields({ userId, connectionId, projectId, datasetId, tableId }),
    };
}

function getTiktokCampaigns({ userId, connectionId, advertiserId }) {
    return {
        type: types.GET_CONNECTION_CAMPAIGNS,
        promise: tiktokApi.getCampaigns({ userId, connectionId, advertiserId }).then(({ campaigns }) => ({
            integrationId: advertiserId,
            campaigns: campaigns.tiktok,
        })),
    };
}

function getPinterestCampaigns({ userId, connectionId, advertiserId }) {
    return {
        type: types.GET_CONNECTION_CAMPAIGNS,
        promise: pinterestApi.getCampaigns({ userId, connectionId, advertiserId }).then(({ campaigns }) => ({
            integrationId: advertiserId,
            campaigns: campaigns.pinterest,
        })),
    };
}

function getMaropostCampaigns({ isSandboxAccount, apiKey, adAccountId }) {
    return {
        type: types.GET_CONNECTION_CAMPAIGNS,
        promise: maropostApi
            .getCampaigns({ isSandboxAccount, apiKey, adAccountId })
            .then(({ campaigns }) => ({
                integrationId: adAccountId,
                campaigns: campaigns.maropost,
            }))
            .catch(() => ({
                integrationId: adAccountId,
                campaigns: undefined,
            })),
    };
}

function getKlaviyoCampaigns({ apiKey }) {
    return {
        type: types.GET_CONNECTION_CAMPAIGNS,
        promise: klaviyoApi
            .getCampaigns({ apiKey })
            .then(({ campaigns }) => ({
                integrationId: apiKey,
                campaigns: campaigns.klaviyo,
            }))
            .catch(() => ({
                integrationId: apiKey,
                campaigns: undefined,
            })),
    };
}

function getBingAccounts({ userId, connectionId }) {
    return {
        type: types.GET_CONNECTION_ACCOUNTS,
        promise: bingApi.getAccounts({ userId, connectionId }),
    };
}

function getBingCampaigns({ userId, connectionId, bingId }) {
    return {
        type: types.GET_CONNECTION_CAMPAIGNS,
        promise: bingApi.getCampaigns({ userId, connectionId, bingId }).then(({ campaigns, bingId }) => ({
            integrationId: bingId,
            campaigns: campaigns.bing,
        })),
    };
}

function getFacebookAccounts({ userId, connectionId }) {
    return {
        type: types.GET_CONNECTION_ACCOUNTS,
        promise: facebookApi.getAccounts({ userId, connectionId }),
    };
}

function getOrganicFacebookPages({ userId, connectionId }) {
    return {
        type: types.GET_CONNECTION_ACCOUNTS,
        promise: organicFacebookApi.getPages({ userId, connectionId }),
    };
}

function getFacebookCampaigns({ userId, connectionId, facebookId }) {
    return {
        type: types.GET_CONNECTION_CAMPAIGNS,
        promise: facebookApi.getCampaigns({ userId, connectionId, facebookId }).then(({ campaigns, facebookId }) => ({
            integrationId: facebookId,
            campaigns: campaigns.facebook,
        })),
    };
}

function getConversionTypes({ userId, connectionId, analyticsAccountId, analyticsViewId }) {
    return {
        type: types.GET_CONVERSION_TYPES,
        promise: analyticsApi.getConversionTypes({
            userId,
            connectionId,
            analyticsAccountId,
            analyticsViewId,
        }),
    };
}

function getStackAdaptCampaigns({ isSandboxAccount, apiKey }) {
    return {
        type: types.GET_CONNECTION_CAMPAIGNS,
        promise: stackAdaptApi
            .getCampaigns({ isSandboxAccount, apiKey })
            .then(({ campaigns }) => ({
                integrationId: apiKey,
                campaigns: campaigns.stackAdapt,
            }))
            .catch(() => ({
                integrationId: apiKey,
                campaigns: undefined,
            })),
    };
}

const formatKeys = {
    analyticsProfiles: "analytics_view",
    analyticsAccounts: "analytics",
    analyticsWebProperties: "analytics_properties",
    analytics: "analytics",
    analyticsV4: "analyticsV4",
    bing: "bing",
    facebook: "facebook",
    adwords: "adwords",
    linkedin: "linkedin",
    shopify: "shopify",
    searchConsole: "searchConsole",
    googleBusinessProfile: "googleBusinessProfile",
    slack: "slack",
    tiktok: "tiktok",
    pinterest: "pinterest",
    maropost: "maropost",
    klaviyo: "klaviyo",
    bigQuery: "bigQuery",
    organicFacebook: "organicFacebook",
    stackAdapt: "stackAdapt",
};

function updateIntegrations({ accountId, integrations = {} }) {
    const promise = MorphioAPI({
        method: "PUT",
        url: `/account/${accountId}/integrations`,
        data: {
            integrations: Object.keys(integrations)
                .filter((platform) => platform)
                .reduce(
                    (obj, key) => ({
                        ...obj,
                        [formatKeys[key]]: integrations[key] || null,
                    }),
                    {},
                ),
        },
    }).then((data) => {
        return data;
    });

    return {
        type: types.UPDATE_INTEGRATIONS,
        promise,
    };
}

const campaignKeys = {
    "adwords-campaigns": "adwords",
    "facebook-campaigns": "facebook",
    "bing-campaigns": "bing",
    "linkedin-campaigns": "linkedin",
};

const updateGoalSetting = ({ accountId, data }) => {
    const payload = {
        accountId,
        data,
    };

    return KpiAPI({
        method: "PUT",
        url: `/goals`,
        data: payload,
    }).then(({ data }) => data);
};

function updateConversionTypes({ conversionTypes, accountId }) {
    const options = {
        conversion_goal_types: conversionTypes,
    };

    return {
        type: types.UPDATE_INTEGRATIONS,
        promise: updateGoalSetting({ accountId, data: options }),
    };
}

export default {
    getConversionTypes,
    getFacebookAccounts,
    getFacebookCampaigns,
    getBingAccounts,
    getBingCampaigns,
    getAnalyticsAccounts,
    getAnalyticsV4Accounts,
    getAdwordsAccounts,
    getAdwordsCampaigns,
    updateIntegrations,
    updateConversionTypes,
    getLinkedinAccounts,
    getLinkedinCampaigns,
    getTiktokAccounts,
    getPinterestAccounts,
    getTiktokCampaigns,
    getPinterestCampaigns,
    getMaropostCampaigns,
    getKlaviyoCampaigns,
    getBigQueryDatasets,
    getBigQueryTables,
    getBigQueryTableFields,
    getOrganicFacebookPages,
    getStackAdaptCampaigns,
};
