import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import IconButton from "material-ui/IconButton";
import CloseButton from "material-ui/svg-icons/navigation/close";
import MorphBot from "assets/images/MorphBot.svg";
import Drawer from "material-ui/Drawer";
import { TooltipDark } from "components/Tooltip";
import WatchButton from "components/YoutubeVideo/WatchButton";

const buttonContainerStyles = {
    padding: 0,
    width: "initial",
    height: "initial",
    zIndex: 100,
    marginTop: "5px",
    marginRight: "0.75rem",
};

const buttonStyles = {
    color: "#ffffff",
};

export const DrawerHeader = ({
    title,
    description,
    handleClose,
    additionalContent = null,
    closeTip = "Close",
    lightText = false,
    icon: Icon,
    centered = false,
    videoId = "",
    videoTitle = "Watch Video",
    className,
}) => (
    <div
        className={classNames(style.drawerHeader, {
            [style.lightText]: lightText,
            [style.centered]: centered,
            [className]: className,
        })}
    >
        {Icon && (
            <div className={style.icon}>
                <Icon />
            </div>
        )}
        <h2>{title}</h2>
        {description && <p>{description}</p>}
        {additionalContent && additionalContent}
        {videoId && <WatchButton className={style.videoButton} videoId={videoId} title={videoTitle} />}
        <div
            className={`Close-Drawer ${style.close}`}
            onClick={() => handleClose()}
            data-tip={closeTip}
            data-for="drawerClose"
        >
            <div>
                <span>close</span>
            </div>
        </div>
        <TooltipDark id="drawerClose" position="left" delay />
    </div>
);

export const SuggestionDrawerHeader = ({ title, description, handleClose }) => (
    <>
        <div className={`Close-Drawer Close-Suggestion ${style.close}`} onClick={() => handleClose()}>
            <div>
                <span>close</span>
            </div>
        </div>
        <div className={style.suggestionDrawerHeader}>
            <div className={style.morph}>
                <img src={MorphBot} alt="Morph Bot" />
            </div>
            <h2>{title}</h2>
            {description && <p>{description}</p>}
        </div>
    </>
);

export const SimpleDrawer = ({ children, noScroll = false, containerClassName, ...props }) => (
    <Drawer
        containerStyle={{
            transitionDuration: "550ms",
            overflow: "hidden",
        }}
        overlayStyle={{ background: "rgba(68, 76, 89, 0.8)" }}
        containerClassName={`${style.simpleDrawer} ${containerClassName && containerClassName}`}
        {...props}
    >
        <div className={!noScroll ? style.drawerScroll : ""}>{children}</div>
    </Drawer>
);

export default ({ hide, children }) => (
    <div className={style.drawer}>
        <IconButton onClick={hide} style={buttonContainerStyles} className={style.closeButtonWrapper}>
            <CloseButton style={buttonStyles} className={style.closeButton} />
        </IconButton>
        {children}
    </div>
);
