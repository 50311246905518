import React from "react";

export default ({ color = "#ffffff" }) => (
    <svg
        width="14px"
        height="20px"
        viewBox="0 0 14 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="⚙️-Settings"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="5.3-Settings-(Slack)"
                transform="translate(-321.000000, -358.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
            >
                <g id="Framework">
                    <g
                        id="Content"
                        transform="translate(262.000000, 136.000000)"
                    >
                        <g
                            id="Tip-Block"
                            transform="translate(10.000000, 176.000000)"
                        >
                            <g
                                id="Group"
                                transform="translate(32.000000, 32.000000)"
                            >
                                <path
                                    d="M17.1061864,19.612919 C16.8383173,21.5507707 17.0154003,23.0745846 18.3597999,24.5035056 C19.5424671,25.7268968 19.3722153,28.1530055 19.3722153,29.4098361 C19.3722153,30.2477231 19.7598081,30.8888889 20.5349938,31.3333333 C20.5349938,33.3333333 21.7284247,34 22.8605507,34 L25.1861076,34 C26.3182336,34 27.5116646,33.3333333 27.5116646,31.3333333 C28.2868502,30.8888889 28.6744431,30.2477231 28.6744431,29.4098361 C28.6744431,28.1530055 28.5920486,25.624132 29.7241745,24.5035056 C30.9624373,23.2801143 31,22.1673599 31,20.5329092 C30.9949459,16.9067776 27.8158958,14 23.9949707,14 C20.2448035,14 17.5357878,16.7061414 17.1061864,19.612919 Z M24.6952818,31.7777778 L23.3539238,31.7777778 C23.0789711,31.7777778 22.8605507,31.3468328 22.8605507,30.8016615 L22.8605507,30.6666667 L25.1861076,30.6666667 L25.1860852,30.8016615 C25.1886549,31.3416407 24.9702345,31.7777778 24.6952818,31.7777778 Z M25.3672344,29.5555556 L22.6845184,29.5555556 C22.134613,29.5555556 21.6977722,29.1246106 21.6977722,28.5794393 L21.6977722,28.4444444 L26.3488861,28.4444444 L26.3488412,28.5794393 C26.3539805,29.1194185 25.9171398,29.5555556 25.3672344,29.5555556 Z M27.4474285,23.5934959 C26.3911635,24.6521155 26.3488861,25.0058839 26.3488861,26.5957665 L26.3488861,26.962963 L21.6977722,26.962963 L21.6977722,26.5957665 C21.6977722,25.0879172 21.6101746,24.6950853 20.5539096,23.5544325 C19.6247141,22.5778461 19.2054366,21.3160966 19.3722153,20.0113772 C19.6700344,18.0582045 21.5545817,16.2222222 24.0205236,16.2222222 C26.6016223,16.2222222 28.6744431,18.2183647 28.6744431,20.6207671 C28.6744431,21.7184502 28.2495545,22.7770698 27.4474285,23.5934959 Z"
                                    id="Icon-Tip"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
