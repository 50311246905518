import React from "react";

export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="16"
        viewBox="0 0 20 16"
    >
        <path
            fill="#FB1427"
            fillRule="evenodd"
            d="M33.5,12 C34.8807119,12 36,13.1192881 36,14.5 L36,25.5 C36,26.8807119 34.8807119,28 33.5,28 L18.5,28 C17.1192881,28 16,26.8807119 16,25.5 L16,14.5 C16,13.1192881 17.1192881,12 18.5,12 L33.5,12 Z M34,16 L18,16 L18,24.5 C18,25.3284271 18.6715729,26 19.5,26 L19.5,26 L32.5,26 C33.3284271,26 34,25.3284271 34,24.5 L34,24.5 L34,16 Z M27.4142136,18.1715729 L28.8284271,19.5857864 L27.414,21 L28.8284271,22.4142136 L27.4142136,23.8284271 L26,22.414 L24.5857864,23.8284271 L23.1715729,22.4142136 L24.586,21 L23.1715729,19.5857864 L24.5857864,18.1715729 L26,19.586 L27.4142136,18.1715729 Z M18.75,13.25 C18.3357864,13.25 18,13.5857864 18,14 C18,14.4142136 18.3357864,14.75 18.75,14.75 C19.1642136,14.75 19.5,14.4142136 19.5,14 C19.5,13.5857864 19.1642136,13.25 18.75,13.25 Z M20.75,13.25 C20.3357864,13.25 20,13.5857864 20,14 C20,14.4142136 20.3357864,14.75 20.75,14.75 C21.1642136,14.75 21.5,14.4142136 21.5,14 C21.5,13.5857864 21.1642136,13.25 20.75,13.25 Z M22.75,13.25 C22.3357864,13.25 22,13.5857864 22,14 C22,14.4142136 22.3357864,14.75 22.75,14.75 C23.1642136,14.75 23.5,14.4142136 23.5,14 C23.5,13.5857864 23.1642136,13.25 22.75,13.25 Z"
            transform="translate(-16 -12)"
        />
    </svg>
);
