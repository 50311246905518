import { connect } from "react-redux";
import Component from "./index";
import { getFormValues } from "redux-form";
import { makeSelectIntegrationOpts } from "features/Integrations/_ducks/selectors";
import { makeSelectOnboardingIntegrations, makeSelectOnboardingConnections } from "features/Onboarding/ducks/selectors";
import { makeSelectIntegrationIds } from "_redux/sites/selectors";
import { createSelector } from "reselect";

const makeSelectAllIntegrations = createSelector(
    [makeSelectOnboardingIntegrations(), makeSelectIntegrationOpts(), makeSelectOnboardingConnections()],
    (loadedIntegrations, options, connections) => {
        return Object.keys(options)
            .filter((key) => connections[key])
            .reduce((obj, platform) => {
                const connectionId = connections[platform];
                const int = loadedIntegrations[`${connectionId}_${platform}`] || {};
                return {
                    ...obj,
                    ...Object.keys(int).reduce((obj, key) => {
                        return { ...obj, [key]: int[key] };
                    }, {}),
                };
            }, {});
    },
);

const mapStateToProps = (state) => {
    const selectedIntegrations = makeSelectIntegrationIds()(state);

    // Get selected loadedIntegrations
    const allInt = makeSelectAllIntegrations(state);

    const integrations = Object.keys(allInt)
        .filter((key) => {
            // remove keys that arn't needed
            return key !== "analyticsAccounts" && key !== "analyticsWebProperties";
        })
        .map((key) => {
            //Get analytics portion that has currency
            const integration = key === "analyticsProfiles" ? "analytics" : key;

            const formIntegration = getFormValues("onboarding")(state) || {};
            const formIntegrationSelected = formIntegration[key]?.value || undefined;

            // Grab yet another naming convention thats different :(
            const currentIntegrationId = integration === "analytics" ? "analytics_view" : integration;
            const currentIntegration = selectedIntegrations[currentIntegrationId];

            //If form doesnt exist then just use the existing integration
            const integrationId = formIntegrationSelected || currentIntegration;
            //Scale through to all the info.... this hurts me

            const integrationInfo = allInt[key][integrationId];

            return {
                platform: integration,
                info: integrationInfo,
            };
        })
        .reduce((obj, next) => {
            //turn back into obj
            return {
                ...obj,
                [next.platform]: next.info,
            };
        }, {});

    return {
        integrations: integrations,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
