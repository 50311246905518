import { connect } from "react-redux";
import { getFormMeta, reduxForm } from "redux-form";
import compose from "lodash/fp/flowRight";
import Component from "./";
import v from "validate.js";
import { makeSelectIntegrationIds } from "_redux/sites/selectors";
import { makeSelectUserId } from "_redux/users/selectors";
import moment from "moment";
import { selectOnboarding } from "features/Onboarding/ducks/selectors";
import { v4 as uuidv4 } from "uuid";

const convertCampaignsToSave = (campaignsSelected) => {
    return Object.keys(campaignsSelected)
        .filter((key) => {
            return campaignsSelected[key];
        })
        .reduce((obj, key) => {
            const [name, value] = key.split("____");
            return {
                ...obj,
                [value]: {
                    id: value,
                    name: name,
                },
            };
        }, {});
};

const validate = (values) => {
    const isValid = v(values, {
        isSandboxAccount: {
            presence: { allowEmpty: false, message: "^Sandbox Account checkbox is required" },
        },
        apiKey: {
            presence: { allowEmpty: false, message: "^API Key is required" },
        },
    });

    return isValid;
};

const mapStateToProps = (state, { integrationId }) => {
    const integrations = makeSelectIntegrationIds()(state);
    const { form } = state;
    const formMetaData = getFormMeta("stackAdapt")({ form });
    const isFormActive = formMetaData?.apiKey?.active || formMetaData?.isSandboxAccount?.active;

    const {
        stackAdapt: {
            [integrationId]: {
                apiKey,
                isSandboxAccount = false,
                "stackAdapt-campaigns": stackAdaptCampaigns = { _all: true },
                regex: regexstackAdapt = "",
                integrationName,
            } = {},
        } = {},
    } = integrations;

    return {
        initialValues: {
            isSandboxAccount,
            "stackAdapt-campaigns": stackAdaptCampaigns,
            regexstackAdapt,
            apiKey,
            integrationName,
        },
        isNoCampaigns: selectOnboarding(state).campaignsLoading || isFormActive,
        userId: makeSelectUserId()(state),
    };
};

export default compose(
    connect(mapStateToProps),
    reduxForm({
        form: "stackAdapt",
        enableReinitialize: true,
        validate,
        onSubmit: async (
            { "stackAdapt-campaigns": selectedCampaigns, isSandboxAccount, integrationName = "", apiKey, ...rest },
            _,
            { updateIntegration, onCancel, accountId, userId, platform, dispatch, integrationId },
        ) => {
            const allCurrentAndFuture = selectedCampaigns["_all"];
            const campaignsSelected = allCurrentAndFuture ? {} : selectedCampaigns;
            const campaignsToSave = convertCampaignsToSave(campaignsSelected);
            const validIntegrationId = integrationId || uuidv4();

            await updateIntegration({
                integrations: {
                    stackAdapt: {
                        value: {
                            [validIntegrationId]: {
                                connectionId: validIntegrationId,
                                integrationId: validIntegrationId,
                                integrationName: integrationName || apiKey,
                                isSandboxAccount,
                                campaigns: campaignsToSave,
                                apiKey,
                                userId,
                                regex: rest[`regexstackAdapt`],
                                created_at: moment().toISOString(),
                            },
                        },
                    },
                },
                accountId,
            });
            onCancel();
        },
    }),
)(Component);
