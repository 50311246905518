import React from "react";
import style from "./style.module.scss";
import StarIcon from "assets/images/icons/JSX/Icon-Star";
import { noop } from "lib/utility";

export default ({ onClick = noop, disabled = false, pinned, className } = {}) => (
    <div onClick={!disabled && onClick} className={`${style.pinStar} ${pinned && style.pinStarActive} ${className}`}>
        <StarIcon />
    </div>
);
