import { connect } from "react-redux";
import IntegrateStep from "./index";
import { change } from "redux-form";
import { makeSelectUserConnections } from "_redux/users/selectors";
import { operations as connectOperations } from "features/Integrations/_ducks";
import { actions } from "features/Onboarding/ducks";

const { onboardingPlatformConnection } = actions;
const { exchangeGoogleBusinessProfileCode } = connectOperations;

const mapStateToProps = (state) => {
    const { googleBusinessProfile } = makeSelectUserConnections()(state);

    return {
        list: googleBusinessProfile,
    };
};

const mapDispatchToProps = (dispatch) => ({
    exchangeGoogleBusinessProfileCode: (code) => {
        return dispatch(exchangeGoogleBusinessProfileCode(code)).then(({ id }) => {
            dispatch(
                onboardingPlatformConnection({
                    platform: "googleBusinessProfile",
                    connectionId: id,
                }),
            );
        });
    },
    change: (formName, ...args) => dispatch(change(formName, ...args)),
});

const mergeProps = ({ ...stateProps }, { change, ...dispatchProps }, { formName, ...ownProps }) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    change: (...args) => change(formName, ...args),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IntegrateStep);
