import React, { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.scss";
import styleMobile from "./styleMobile.module.scss";
import {
    makeSelectAccounts,
    makeSelectAccountsMetrics,
    makeSelectAccountsPerformance,
    makeSelectAccountsPerformanceLoading,
    makeSelectIntegrationIds,
    selectFilteredAccountArray,
    selectOverviewUi,
    selectSearch,
    selectSortByDataType,
} from "features/ControlPanel/ducks/selectors";
import {
    makeSelectCustomMetrics,
    makeSelectUserAccountFavourites,
    makeSelectUserActiveFeatures,
    makeSelectUserId,
    selectSavedMetrics,
} from "_redux/users/selectors";
import { operations } from "features/ControlPanel/ducks";
import { accountSort } from "lib/accountTags";
import WarningDialog from "components/Dialog/WarningDialog";
import Button from "components/Button";
import ZeroState from "components/ZeroStates/Icon";
import AccountRename from "../AccountRename/container";
import AccountDuplicate from "../AccountDuplicate/container";
import TableWrapper from "components/Table/MaterialUITable/TableWrapper";
import MaterialUITable from "components/Table/MaterialUITable";
import { history } from "_redux";
import { usePermissions } from "features/Permissions";
import { TooltipDark, TooltipHorizontalFloat } from "components/Tooltip";
import PinStar from "features/ControlPanel/PinStar";
import AccountMenu from "../AccountMenu";
import AccountTags, { ManageTags } from "../AccountTags";
import { divide, isEmpty, isEqual } from "lodash";
import ThreeDots from "components/Loading/ThreeDots";
import moment from "moment";
import classNames from "classnames";
import { Icon } from "components/Icon/PlatformIcon";
import {
    capitalize,
    channelFriendlyName,
    getPlatformMainKey,
    getTrend,
    GrowthNumber,
    metricFriendlyName,
    parseNumberField,
} from "lib/utility";
import { calculateGrowth, getRelatedMetrics, getUnit, toPrettyNumber } from "lib/metricCalculations";
import { makeSelectAccountId } from "_redux/sites/selectors";
import { BudgetBar } from "features/Budgets/BasicBudgeting/BudgetTracker/PlatformBudget";
import { platformBar } from "features/Budgets/BasicBudgeting/BudgetTracker";
import { getIcon } from "features/SettingsDrawer/BudgetSettings/helpers";
import { withDates } from "lib/tableSchema";
import { getSites, saveBudgetData, saveGoalData, toggleAlertSettingData } from "../ducks/operations";
import IconEdit from "assets/images/icons/JSX/Icon-Edit";
import { CloseDialog, DialogVFour } from "components/Dialog";
import AddIntegrations from "features/ControlPanel/AddIntegrations";
import { setSelectedSite } from "_redux/sites";
import AddButton from "features/ControlPanel/AddButton";
import AddIntegrationButton from "./AddIntegrationButton";
import { isNewAccount } from "lib/accountTags";
import { MetricStatsContentMobile } from "./MetricStatsContentMobile";
import { BudgetPlatformsContentMobile } from "./BudgetPlatformsContentMobile";
import { NameContentMobile } from "./NameContentMobile";
import { BudgetsContentMobile } from "./BudgetsContentMobile";
import ToggleMulti from "components/ToggleMulti";
import AddIcon from "assets/images/Dashboard/JSX/Add";
import { actions } from "../ducks/";

const { setShowAddAccountModal } = actions;
const { togglePinnedView } = operations;
const { removeView } = operations;

const zeroStateMessage = (search, tags, views, dispatch) => {
    if (views.length === 0) {
        return (
            <>
                <span>Take the first step; set up your first account now!</span>
                <br />
                <Button
                    className={style.addAccountButton}
                    icon={AddIcon}
                    onClick={() => dispatch(setShowAddAccountModal(true))}
                >
                    Add Account
                </Button>
            </>
        );
    }

    if (search && tags.length === 0) {
        return "There aren't any accounts that match your search";
    }

    return "There aren't any accounts that match your filters";
};

export const infoWrapper = (info) => `<span style="display:block;max-width:14rem;">${info}</span>`;

export const paidPlatforms = ["adwords", "facebook", "bing", "linkedin", "tiktok", "pinterest", "stackAdapt"];

const headCells = ({ metrics = [], viewType, filteredAccounts, allAccounts, month }) => [
    {
        id: "name",
        label: `Account Name (${filteredAccounts.length} of ${allAccounts.length})`,
        sortable: true,
        headPadding: "1rem 0.625rem 1rem 1.25rem",
        padding: "1.125rem 0.875rem 1rem 1.25rem",
        subPadding: "0.375rem 0.875rem 0.25rem 1.25rem",
        width: "420px",
        freeze: true,
        hasDivider: true,
    },
    ...(["budget"].includes(viewType)
        ? [
              {
                  id: "budgets",
                  label: "Media Spend Tracker",
                  sortable: true,
                  padding: "1.125rem 0.875rem 1rem",
                  subPadding: "0.625rem 0.875rem 0.5rem",
                  width: "346",
                  align: "center",
                  info: infoWrapper(
                      "Tracking monthly media spend using the sum of platform budgets set in Budget Monitoring.",
                  ),
                  hasDivider: true,
              },
          ]
        : []),
    ...(["budget"].includes(viewType)
        ? [
              ...paidPlatforms.map((platformKey, index) => {
                  return {
                      id: platformKey,
                      label: (
                          <>
                              <span className={style.metricTitle}>
                                  <span className={style.metricName}>Media Spend</span>
                                  <span className={style.platformIcon}>
                                      <Icon platform={platformKey} /> {channelFriendlyName(platformKey)}
                                  </span>
                              </span>
                          </>
                      ),
                      sortable: true,
                      padding: "1.125rem 0.875rem 1rem",
                      subPadding: "0.625rem 0.875rem 0.5rem",
                      width: "140",
                      align: "right",
                      hasDivider: index + 1 !== metrics.length,
                  };
              }),
          ]
        : []),

    ...(viewType === "metrics"
        ? (metrics || []).map((metric, index) => {
              const [platformKey, ...metricTemp] = metric.split("-");
              const metricKey = metricTemp.join("-");
              return {
                  id: metric,
                  label: (
                      <>
                          <span
                              className={style.metricTitle}
                              data-tip={capitalize(metricFriendlyName(metricKey, platformKey))}
                              data-for={metric}
                          >
                              <span className={style.metricName}>
                                  {capitalize(metricFriendlyName(metricKey, platformKey))}
                              </span>
                              <span className={style.platformIcon}>
                                  <Icon platform={platformKey} /> {channelFriendlyName(platformKey)}
                              </span>
                          </span>
                          <TooltipDark id={metric} proximity={6} position="bottom" />
                      </>
                  ),
                  sortable: true,
                  padding: "1.125rem 0.875rem 1rem",
                  subPadding: "0.625rem 0.875rem 0.5rem",
                  width: "140",
                  align: "right",
                  hasDivider: index + 1 !== metrics.length,
              };
          })
        : []),

    ...(viewType === "alerts"
        ? [
              {
                  id: "budgetPacingAlerts",
                  label: "Budget Pacing",
                  sortable: true,
                  padding: "1.125rem 0.875rem 1rem",
                  subPadding: "0.625rem 0.875rem 0.5rem",
                  width: "160",
                  align: "center",
                  info: infoWrapper(
                      "Tracking monthly media spend using the sum of platform budgets set in Budget Monitoring.",
                  ),
                  hasDivider: true,
              },
              {
                  id: "budgetProtectionAlerts",
                  label: "Budget Protection",
                  sortable: true,
                  padding: "1.125rem 0.875rem 1rem",
                  subPadding: "0.625rem 0.875rem 0.5rem",
                  width: "160",
                  align: "center",
                  info: infoWrapper(
                      "Tracking monthly media spend using the sum of platform budgets set in Budget Monitoring.",
                  ),
                  hasDivider: true,
              },
              {
                  id: "goalPacingAlerts",
                  label: "Goal Pacing",
                  sortable: true,
                  padding: "1.125rem 0.875rem 1rem",
                  subPadding: "0.625rem 0.875rem 0.5rem",
                  width: "160",
                  align: "center",
                  info: infoWrapper(
                      "Tracking monthly media spend using the sum of platform budgets set in Budget Monitoring.",
                  ),
                  hasDivider: true,
              },
              {
                  id: "goalProtectionAlerts",
                  label: "Goal Protection",
                  sortable: true,
                  padding: "1.125rem 0.875rem 1rem",
                  subPadding: "0.625rem 0.875rem 0.5rem",
                  width: "160",
                  align: "center",
                  info: infoWrapper(
                      "Tracking monthly media spend using the sum of platform budgets set in Budget Monitoring.",
                  ),
                  hasDivider: true,
              },
              {
                  id: "recepientAlerts",
                  label: "Alert Recepients",
                  sortable: true,
                  padding: "1.125rem 0.875rem 1rem",
                  subPadding: "0.625rem 0.875rem 0.5rem",
                  width: "346",
                  align: "center",
                  info: infoWrapper(
                      "Tracking monthly media spend using the sum of platform budgets set in Budget Monitoring.",
                  ),
                  hasDivider: true,
              },
          ]
        : []),
];

/**
 * Gather all currencies within accounts
 * @param {} param0
 * @returns
 */
const getCurrencies = ({ enhancedViews = {} }) => {
    const getCurrency = (integrations) =>
        paidPlatforms.reduce((cache, platform) => {
            return [...cache, ...Object.values(integrations[platform] || {}).map(({ currency }) => currency)];
        }, []);
    return []
        .concat([
            ...new Set([].concat(...enhancedViews.map(({ integrations = {} } = {}) => getCurrency(integrations)))),
        ])
        .filter((v) => v);
};

/**
 * Aggregate metrics between accounts
 * @param {*} param0
 * @returns
 */
const clean = (a) => (a && !isNaN(a) ? a : 0);
const cleanSum = (a, b) => clean(a) + clean(b);
const calculateMetricValue = ({
    key,
    relatedMetrics,
    platformKey,
    enhancedViews,
    customMetricKey,
    totalType = "total",
    metricKey,
}) => {
    const [metricA, metricB, factor = 1] = relatedMetrics;

    const aggregate = (key, metric, totalType = "total") => {
        const enhancedViewsValues = enhancedViews.map(({ performance } = {}) => {
            const { [`${platformKey}-${metric}`]: { [key]: value } = {} } = performance?.metrics?.data || {};
            return value;
        });

        const enhancedViewsFiltered = enhancedViewsValues.filter((value) => value !== undefined);

        const total = enhancedViewsFiltered.reduce((sum, value) => {
            return cleanSum(sum, value);
        }, 0);

        return totalType === "total" ? total : divide(total, enhancedViews.length);
    };

    // for custom metrics
    if (platformKey === "custom") {
        const agg = aggregate(key, [metricKey], totalType);
        if (customMetricKey === "stable") {
            return agg / enhancedViews.length;
        }
        return agg;
    }

    if (metricB && totalType === "total") {
        // has more than one related metrics
        return (aggregate(key, metricA) * factor) / aggregate(key, metricB);
    }

    // has only one related metric
    return aggregate(key, [metricKey], totalType);
};

/**
 * Calculate the total performances
 * @param {} param0
 * @returns
 */
const calculateTotals = ({ enhancedViews = {}, selectedMetrics, customMetrics, month, totalType }) => {
    return Object.values(enhancedViews).reduce(
        (cache, enhancedView = {}) => {
            const { goals = {}, integrations = {} } = enhancedView;
            const start = moment(month)
                .startOf("month")
                .format("YYYY-MM-DD");
            const end = moment(month)
                .endOf("month")
                .format("YYYY-MM-DD");

            const platforms = paidPlatforms
                .filter((platform) => integrations[platform])
                .map((platform) => {
                    const monthlyTarget = goals?.values?.[start]?.[`${platform}Budget`]?.value || 0;
                    const relatedMetrics = getRelatedMetrics("cost");
                    const recentValue = calculateMetricValue({
                        key: "actual",
                        relatedMetrics,
                        platformKey: platform,
                        enhancedViews: [enhancedView],
                    });

                    // get expected spend
                    const isInBudgetGroupRange = moment().isBetween(start, end, "day", []);
                    const daysInBudgetGroup = moment(end).diff(start, "days") + 1;
                    const daysFromStartOfBudgetGroup =
                        moment()
                            .add(-1, "days")
                            .diff(start, "days") + 1;
                    const daysIntoBudgetGroup = isInBudgetGroupRange ? daysFromStartOfBudgetGroup : daysInBudgetGroup;
                    const percentDaysIntoBudgetGroup = daysIntoBudgetGroup / daysInBudgetGroup;

                    const goalToDateTarget = monthlyTarget * percentDaysIntoBudgetGroup;

                    return {
                        recentValue: monthlyTarget ? recentValue : 0,
                        monthlyTarget,
                        goalToDateTarget,
                        platform,
                    };
                });

            return {
                ...cache,
                budgetIssues: {
                    platforms,
                    totals: {
                        recentValue: cleanSum(
                            cache?.budgetIssues?.totals?.recentValue,
                            platforms.reduce((a, b) => a + b.recentValue || 0, 0),
                        ),
                        monthlyTarget: cleanSum(
                            cache?.budgetIssues?.totals?.monthlyTarget,
                            platforms.reduce((a, b) => a + b.monthlyTarget || 0, 0),
                        ),
                        goalToDateTarget: cleanSum(
                            cache?.budgetIssues?.totals?.goalToDateTarget,
                            platforms.reduce((a, b) => a + b.goalToDateTarget || 0, 0),
                        ),
                    },
                },
            };
        },
        {
            budgetIssues: {
                platforms: [],
                totals: {
                    date: moment().add(-1, "days"),
                    deviation: 0.1, // 10% for totals
                },
            },
            metrics: {
                data: [...paidPlatforms.map((platform) => `${platform}-cost`), ...selectedMetrics].reduce(
                    (cache, metric) => {
                        const [platformKey, metricKey] = metric.split("-");
                        const customMetricKey = customMetrics[metric]?.changeInMonth;
                        // get related metrics
                        const relatedMetrics = getRelatedMetrics(metricKey);

                        // calculate the new value
                        const newActual = calculateMetricValue({
                            key: "actual",
                            relatedMetrics,
                            platformKey,
                            enhancedViews,
                            customMetricKey,
                            totalType,
                            metricKey,
                        });
                        const newOneMonth = calculateMetricValue({
                            key: "oneMonth",
                            relatedMetrics,
                            platformKey,
                            enhancedViews,
                            customMetricKey,
                        });

                        return {
                            ...cache,
                            [metric]: {
                                actual: newActual,
                                oneMonth: newOneMonth,
                                oneMonthTrend: (newActual - newOneMonth) / newOneMonth,
                            },
                        };
                    },
                    {},
                ),
            },
        },
    );
};

export default React.memo(({ viewType, month, isMobile, userRole }) => {
    const dispatch = useDispatch();
    const [totalType, toggleTotalType] = useState("total");
    const [showAddIntegrationModal, setShowAddIntegrationModal] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const saveBudget = useCallback(
        (props) => {
            return dispatch(saveBudgetData(props));
        },
        [dispatch],
    );
    const saveGoal = useCallback(
        (props) => {
            return dispatch(saveGoalData(props));
        },
        [dispatch],
    );

    const [updatingAccount, toggleUpdatingAccount] = useState({});
    const userIdSelector = useMemo(makeSelectUserId);
    const userId = useSelector((state) => userIdSelector(state));
    const userActiveFeatures = useSelector((state) => makeSelectUserActiveFeatures()(state));

    const toggleAlertSetting = useCallback(
        (props) => {
            toggleUpdatingAccount(props);
            return dispatch(toggleAlertSettingData(props)).then(() => {
                toggleUpdatingAccount({});
                return dispatch(getSites(userId));
            });
        },
        [dispatch],
    );
    const accountsSelector = useMemo(makeSelectAccounts);
    const accounts = useSelector((state) => accountsSelector(state));
    const views = useSelector((state) => selectFilteredAccountArray(state));
    const allAccounts = Object.keys(accounts).map((accountId) => ({
        id: accountId,
        ...accounts[accountId],
    }));
    const userAccountFavouritesSelector = useMemo(makeSelectUserAccountFavourites);
    const favourite = useSelector((state) => userAccountFavouritesSelector(state));
    const accountsPerformanceSelector = useMemo(makeSelectAccountsPerformance);
    const accountsMetricsSelector = useMemo(makeSelectAccountsMetrics);
    const accountsPerformance = useSelector((state) => accountsPerformanceSelector(state));
    const accountsMetrics = useSelector((state) => accountsMetricsSelector(state));
    const { sort = "default", sortOrder = "asc", tags = [] } = useSelector((state) => selectOverviewUi(state));
    const orderByDataType = useSelector((state) => selectSortByDataType(state)) || "actual";
    const currentSearch = useSelector((state) => selectSearch(state));
    const accountIdSelector = useMemo(makeSelectAccountId);
    const accountId = useSelector((state) => accountIdSelector(state));
    const selectedMetrics = useSelector((state) => selectSavedMetrics(state));
    const search = useSelector((state) => selectSearch(state));
    const customMetricsSelector = useMemo(makeSelectCustomMetrics);
    const customMetrics = useSelector((state) => customMetricsSelector(state));
    const accountsPerformanceLoadingSelector = useMemo(makeSelectAccountsPerformanceLoading);
    const isLoading = useSelector((state) => accountsPerformanceLoadingSelector(state));
    const isAuthorized = usePermissions({
        actions: "ACTIONS_CONTROLPANEL_CREATE_ACCOUNT",
    });

    const [prompt, handlePrompt] = useState({
        isOpen: false,
        accountId: null,
    });
    const [isRenameAccountOpen, toggleRenameAccount] = useState("");
    const [isDuplicateAccountOpen, toggleDuplicateAccount] = useState("");

    const enhancedViews = views.map((view) => ({
        ...view,
        performance: viewType === "metrics" ? accountsMetrics[view.id] : accountsPerformance[view.id],
        pinned: favourite[view.id],
    }));

    const currencies = getCurrencies({ enhancedViews });
    const totalPerformance = calculateTotals({
        enhancedViews,
        selectedMetrics,
        customMetrics,
        month,
        totalType,
    });

    const noAccountsFound = enhancedViews.length === 0;

    const onAddIntegrationButtonClick = (accountId) => {
        setShowAddIntegrationModal(true);
        const selectedSite = enhancedViews.find(({ id }) => id === accountId);
        setSelectedAccount(selectedSite);
        dispatch(setSelectedSite(selectedSite));
    };

    if (isMobile) {
        return (
            <>
                <div className={styleMobile.accountTotal}>
                    Accounts <div className={styleMobile.accountNumber}>{enhancedViews.length}</div>
                </div>
                <div className={styleMobile.accountListContainer}>
                    {accountSort({
                        accounts: enhancedViews,
                        sort,
                        sortOrder,
                    }).map((props) => {
                        const { budgets, name, ...rest } = getAccount({
                            isLoading,
                            selectedMetrics,
                            isAuthorized,
                            dispatch,
                            month,
                            isMobile: true,
                            userActiveFeatures,
                            userRole,
                            ...props,
                        });

                        return (
                            <div className={styleMobile.accountContainer}>
                                {name.content}
                                {viewType === "tracker" && <>{budgets.content}</>}
                                {viewType === "budget" && (
                                    <div className={styleMobile.budgetPlatformContainer}>
                                        {paidPlatforms.map((platform) => rest[platform]?.content)}
                                    </div>
                                )}
                                {/* Hiding for now */}
                                {/* {viewType === "metrics" && (
                                    <div className={styleMobile.budgetPlatformContainer}>
                                        {Object.keys(rest)
                                            .filter((key) => !paidPlatforms.includes(key))
                                            .map((key) => rest[key]?.content)}
                                    </div>
                                )} */}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }

    const sortedRows = React.useMemo(
        () =>
            accountSort({
                accounts: enhancedViews,
                sort,
                sortOrder,
            }).map((props) =>
                getAccount({
                    handlePrompt,
                    toggleRenameAccount,
                    toggleDuplicateAccount,
                    isRenameAccountOpen,
                    isLoading,
                    updatingAccount,
                    selectedMetrics,
                    isAuthorized,
                    dispatch,
                    month,
                    saveBudget,
                    saveGoal,
                    toggleAlertSetting,
                    onAddIntegrationButtonClick,
                    userActiveFeatures,
                    userRole,
                    ...props,
                }),
            ),
        [
            enhancedViews,
            sort,
            sortOrder,
            isAuthorized,
            isLoading,
            updatingAccount,
            selectedMetrics,
            userActiveFeatures,
            userRole,
        ],
    );

    return (
        <>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className={style.accountList}>
                    {!noAccountsFound && (
                        <React.Suspense fallback={<div>Loading...</div>}>
                            <TableWrapper isLoading={false} className={style.tableWrapper}>
                                <MaterialUITable
                                    totalsRow={getAccount({
                                        isLoading,
                                        selectedMetrics,
                                        id: "total",
                                        name: "Totals",
                                        performance: totalPerformance,
                                        currencies,
                                        totalType,
                                        toggleTotalType,
                                        userActiveFeatures,
                                        userRole,
                                    })}
                                    allowScroll={true}
                                    headCells={headCells({
                                        metrics: selectedMetrics,
                                        accountId,
                                        filteredAccounts: views,
                                        allAccounts,
                                        viewType,
                                        month,
                                    })}
                                    rowClass={style.rowTableWrapper}
                                    pagination={search ? false : true}
                                    defaultRowsPerPage={10}
                                    rows={sortedRows}
                                    orderByDataType={orderByDataType}
                                />
                            </TableWrapper>
                        </React.Suspense>
                    )}
                    {noAccountsFound && (
                        <ZeroState
                            containerClass={style.zeroState}
                            title={views.length === 0 ? "Start Your Journey 🚀" : "No Accounts Found"}
                            message={zeroStateMessage(currentSearch, tags, views, dispatch)}
                            hasBorder
                        />
                    )}
                </div>
                <AccountRename
                    accountId={isRenameAccountOpen}
                    isOpen={!!isRenameAccountOpen}
                    toggleDrawer={() => toggleRenameAccount("")}
                />
                <AccountDuplicate
                    accountId={isDuplicateAccountOpen}
                    isOpen={!!isDuplicateAccountOpen}
                    toggleDrawer={() => toggleDuplicateAccount("")}
                />
                <WarningDialog
                    actions={[
                        <Button tertiary onClick={() => handlePrompt({ isOpen: false, accountId: null })}>
                            Cancel
                        </Button>,
                        <Button
                            red
                            onClick={() => {
                                dispatch(removeView({ accountId: prompt.accountId }));
                                handlePrompt({
                                    isOpen: false,
                                    accountId: null,
                                });
                            }}
                        >
                            Delete
                        </Button>,
                    ]}
                    isOpen={prompt.isOpen}
                    onClose={() => handlePrompt({ isOpen: false, accountId: null })}
                >
                    <h3>Delete Account</h3>
                    <p>Are you sure you want to delete this Account?</p>
                </WarningDialog>
            </form>
            <DialogVFour
                open={showAddIntegrationModal}
                classes={{
                    rootClass: style.addIntegration,
                    paperClass: style.addIntegrationPaper,
                }}
            >
                <CloseDialog
                    closeActions={() => {
                        setShowAddIntegrationModal(false);
                    }}
                />
                <AddIntegrations
                    account={selectedAccount}
                    closeIntegrationModal={() => setShowAddIntegrationModal(false)}
                />
            </DialogVFour>
        </>
    );
}, isEqual);

const selectView = ({ accountId, navigateTo = null, userRole }) => {
    if (userRole === "reporting") {
        return history.push(navigateTo || `/dashboard/${accountId}/kpi/summary`);
    }
    return history.push(navigateTo || `/dashboard/${accountId}/overview`);
};

const thresholdTrend = (spend = 0, monthlyBudget = 0, min, max) => {
    if (spend === 0 && monthlyBudget === 0) {
        return -1;
    }
    if (spend < min || spend > max) {
        return -1;
    }
    return 0;
};

const calcRange = ({ baseline, comparison, percent, min, max }) => {
    //+ - threshold from expected spend
    const difference = baseline * percent;
    return {
        min: Math.max(min, comparison - difference),
        max: Math.min(max, comparison + difference),
        difference,
    };
};

export const MixCurrency = ({}) => (
    <div className={style.multipleCurrencies}>
        <p
            data-for="multipleCurrencies"
            data-html={true}
            data-tip={infoWrapper(
                "Connected ad platforms contain a mix of multiple currencies resulting in a currency mismatch for total values.",
            )}
        >
            Mixed Currencies Detected
        </p>
        <TooltipDark id={`multipleCurrencies`} proximity={2} />
    </div>
);

export const Metric = ({ metric, accountId, actual = 0, oneMonth = 0, noMetricData = false, currency }) => {
    const integrationIdsSelector = useMemo(() => makeSelectIntegrationIds(accountId));
    const integrations = useSelector((state) => integrationIdsSelector(state));
    const customMetricsSelector = useMemo(makeSelectCustomMetrics);
    const customMetrics = useSelector((state) => customMetricsSelector(state));
    const [platformKey, ...metricTemp] = metric.split("-");

    // Check custom metrics to see if this account has connected platforms
    const customMetricAvailable =
        customMetrics[metric] &&
        Object.keys(customMetrics[metric].metrics).some((key) => {
            const metrics = customMetrics[metric].metrics || {};
            const mPlatform = metrics[key].split("-")[0];
            return accountId === "total" || integrations[mPlatform];
        });

    const nA =
        noMetricData ||
        (platformKey !== "custom" && accountId !== "total" && !integrations[platformKey]) ||
        (platformKey === "custom" && !customMetricAvailable);

    const metricKey = metricTemp.join("-");
    return (
        <div className={classNames(style.metric, { [style.na]: nA })}>
            {nA ? (
                "-"
            ) : (
                <div className={style.metricNumber}>
                    <span className={`${style.recentValue} ${accountId === "total" && style.total}`}>
                        {toPrettyNumber(actual, metric, accountId, null, currency)}
                    </span>
                    <div className={style.submetrics}>
                        <GrowthNumber
                            number={(100 * (actual - oneMonth)) / oneMonth}
                            metric={metricKey}
                            dataTip={`Compare To: ${toPrettyNumber(oneMonth, metric, accountId, null, currency)}`}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const EditableNumber = ({
    goal,
    actual,
    actualNumber,
    goalToDateTarget,
    platform,
    metric,
    accountId,
    saveGoal,
    month,
    type,
    currency,
}) => {
    const [p, metricKey = "cost"] = metric.split("-");

    const [saving, toggleSaving] = React.useState(false);
    const [value, setValue] = React.useState(goal);
    const [editMode, toggleMode] = useState(false);

    const ref = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                toggleMode(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    const focus = useCallback((dom) => {
        dom.focus();
    }, []);

    if (accountId === "total") {
        return actual;
    }

    goalToDateTarget = goalToDateTarget || goal;

    return (
        <div>
            <div
                className={classNames(style.metricsEdit, {
                    [style.hide]: !editMode,
                })}
                ref={ref}
            >
                <div className={classNames(style.inputContainer)}>
                    <input
                        className={style.input}
                        value={value}
                        onChange={(event) => {
                            if (!parseNumberField(event?.target?.value)) return;
                            setValue(parseNumberField(event?.target?.value));
                        }}
                        onKeyDown={async (event) => {
                            if (event.key === "Enter") {
                                toggleSaving(true);
                                await saveGoal({
                                    value: parseFloat((value + "").split(",").join("")),
                                    platform,
                                    accountId,
                                    month,
                                    metric,
                                });
                                toggleSaving(false);

                                // close
                                return toggleMode(false);
                            }
                            if (event.key === "Tab") {
                                const form = event.target.form;
                                const index = [...form].indexOf(event.target);
                                focus(form.elements[index + 3]);
                                event.preventDefault();
                                // close
                                return toggleMode(false);
                            }
                        }}
                        onFocus={() => {
                            toggleMode(true);
                        }}
                    />
                    <div className={style.prefix}>
                        <span>{getUnit(metric, accountId) || "#"}</span>
                    </div>
                </div>
                <div className={classNames(style.buttonContainer)}>
                    <Button small disabled={saving} emptyBg onClick={() => toggleMode(false)}>
                        Cancel
                    </Button>
                    <Button
                        small
                        disabled={saving}
                        onClick={() => {
                            toggleSaving(true);
                            return saveGoal({
                                value: parseFloat((value + "").split(",").join("")),
                                platform,
                                accountId,
                                month,
                                metric,
                            }).then(() => {
                                toggleSaving(false);
                                toggleMode(false);
                            });
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
            <div
                className={classNames(style.editableNumber, {
                    [style.hide]: editMode,
                })}
                onClick={() => toggleMode(!editMode)}
                data-tip={`Edit ${type}`}
                data-for={`${goal}-edit-${accountId}`}
            >
                <div className={style.editIcon}>
                    <IconEdit />
                </div>
                <div>{actual}</div>
                <div className={style.submetrics}>
                    {actualNumber > 0 && !goal && <div className={style.noBudgetCircle}></div>}
                    {type}: <strong>{toPrettyNumber(goal, metric, accountId, null, currency)}</strong>
                </div>
                {!isNaN(goal) && goal > 0 && (
                    <div className={style.growth}>
                        <GrowthNumber
                            number={(100 * (actualNumber - goalToDateTarget)) / goalToDateTarget}
                            metric={metricKey}
                        />
                    </div>
                )}
            </div>
            <TooltipDark id={`${goal}-edit-${accountId}`} proximity={2} />
        </div>
    );
};

const getAccount = ({
    name = "",
    pinned = false,
    id: accountId,
    tags = [],
    handlePrompt,
    toggleRenameAccount,
    toggleDuplicateAccount,
    onAddIntegrationButtonClick,
    performance: { metrics = {}, budgetIssues = {} } = {},
    isLoading,
    selectedMetrics,
    isAuthorized,
    dispatch,
    integrations = {},
    currencies: accountCurrencies = [],
    goals: { values: goals = {} } = {},
    month,
    saveBudget,
    saveGoal,
    created_at,
    isMobile = false,
    totalType,
    toggleTotalType,
    userRole,
}) => {
    const { startDate: metricStartDate, endDate: metricEndDate, data = {} } = metrics;
    const noMetricData = isEmpty(data);

    const metricStats = Object.keys(selectedMetrics).map((key) => {
        const metric = selectedMetrics[key];
        const { [metric]: { actual = null, oneMonth = null } = {} } = data;
        return {
            metric,
            actual,
            oneMonth,
        };
    });

    const budgetPlatforms = paidPlatforms.filter((platform) => accountId === "total" || integrations[platform]);

    const {
        date: { daysIntoMonth, daysInMonth },
    } = withDates({ start: metricStartDate, end: metricEndDate }, {});
    const monthDecimalPercent = daysIntoMonth / daysInMonth;
    const paidPlatformData = budgetPlatforms.reduce((cache, platform) => {
        const { data: { [`${platform}-cost`]: { actual: spend = 0 } = {} } = {} } = metrics || {};
        const {
            [moment(month)
                .startOf("month")
                .format("YYYY-MM-DD")]: { [`${platform}Budget`]: { value: budget = 0 } = {} } = {},
        } = goals || {};
        return {
            ...cache,
            [platform]: {
                spend,
                budget: parseFloat(budget),
                expectedSpend: budget * monthDecimalPercent,
            },
        };
    }, {});
    let spend = budgetPlatforms.reduce((sum, platform) => {
        return sum + paidPlatformData[platform].spend;
    }, 0);
    let monthlyBudget = budgetPlatforms.reduce((sum, platform) => {
        return sum + paidPlatformData[platform].budget;
    }, 0);
    let expectedSpend = budgetPlatforms.reduce((sum, platform) => {
        return sum + paidPlatformData[platform].expectedSpend;
    }, 0);

    if (accountId === "total") {
        spend = budgetIssues?.totals?.recentValue || 0;
        monthlyBudget = budgetIssues?.totals?.monthlyTarget || 0;
        expectedSpend = budgetIssues?.totals?.goalToDateTarget || 0;
    }

    const thresholdPercent = 10;
    const overallOverage = Math.max(spend - monthlyBudget, 0);
    const { min, max } = calcRange({
        baseline: monthlyBudget,
        comparison: expectedSpend,
        percent: thresholdPercent / 100,
        min: 0,
        max: monthlyBudget,
    });

    const trend = thresholdTrend(spend, monthlyBudget, min, max);
    const hasOverageOverall = overallOverage > 0;
    const hasPartialShortage = spend < expectedSpend && trend < 0;
    const hasPartialOverage = spend > expectedSpend && trend < 0;
    const totalWidthBaseline = Math.max(spend, monthlyBudget);
    const calculatedBarWidth = hasOverageOverall ? 100 : (spend / totalWidthBaseline) * 100;
    const calculatedExpectedBarWidth = (expectedSpend / totalWidthBaseline) * 99;
    const calculatedDifference = Math.abs(calculatedExpectedBarWidth - calculatedBarWidth);
    const overageWidth = (overallOverage / totalWidthBaseline) * 99;
    const hasCaps = calculatedBarWidth + overageWidth >= 99;
    const toExpectedBudgetPercent = monthlyBudget ? (100 * (spend - expectedSpend)) / monthlyBudget : null;
    const isNew = isNewAccount({ created_at });

    const addIntegrations = () => {
        onAddIntegrationButtonClick(accountId);
    };

    const currencyPerPlatform = Object.keys(integrations).reduce((cache, platform) => {
        const [{ currency: platformCurrency = "USD" }] = Object.values(integrations[platform]) || [];
        return {
            ...cache,
            [platform]: platformCurrency,
        };
    }, {});

    const [currency] = Object.values(currencyPerPlatform) || [];

    if (isMobile) {
        return {
            name: {
                content: (
                    <NameContentMobile
                        togglePinnedView={togglePinnedView}
                        accountId={accountId}
                        pinned={pinned}
                        selectView={selectView}
                        name={name}
                        isNew={isNew}
                        dispatch={dispatch}
                        userRole={userRole}
                    />
                ),
            },
            budgets: {
                content: (
                    <BudgetsContentMobile
                        spend={spend}
                        toExpectedBudgetPercent={toExpectedBudgetPercent}
                        currency={currency}
                        calculatedDifference={calculatedDifference}
                        accountId={accountId}
                        isLoading={isLoading}
                        monthlyBudget={monthlyBudget}
                        accountCurrencies={accountCurrencies}
                        hasPartialOverage={hasPartialOverage}
                        calculatedExpectedBarWidth={calculatedExpectedBarWidth}
                        calculatedBarWidth={calculatedBarWidth}
                        hasCaps={hasCaps}
                        hasOverageOverall={hasOverageOverall}
                        hasPartialShortage={hasPartialShortage}
                    />
                ),
            },
            ...budgetPlatforms.reduce((cache, platform) => {
                const { spend = 0, budget = 0 } = paidPlatformData[platform];
                const trend =
                    !isNaN(budget) &&
                    budget > 0 &&
                    getTrend({ number: (100 * (spend - budget)) / budget, metric: "cost" });

                return {
                    ...cache,
                    [platform]: {
                        content: (
                            <BudgetPlatformsContentMobile
                                isLoading={isLoading}
                                platform={platform}
                                spend={spend}
                                accountId={accountId}
                                currencyPerPlatform={currencyPerPlatform}
                                currency={currency}
                                budget={budget}
                                trend={trend}
                            />
                        ),
                    },
                };
            }, {}),
            ...metricStats.reduce((cache, { metric, actual, oneMonth }) => {
                const [p] = metric.split("-");
                const platform = getPlatformMainKey(p);
                return {
                    ...cache,
                    ...(!isNaN(actual)
                        ? {
                              [metric]: {
                                  content: (
                                      <MetricStatsContentMobile
                                          metricStats={metricStats}
                                          isLoading={isLoading}
                                          accountId={accountId}
                                          metric={metric}
                                          actual={actual}
                                          currencyPerPlatform={currencyPerPlatform}
                                          platform={platform}
                                          currency={currency}
                                          oneMonth={oneMonth}
                                          noMetricData={noMetricData}
                                          saveGoal={saveGoal}
                                          month={month}
                                      />
                                  ),
                              },
                          }
                        : {}),
                };
            }, {}),
        };
    }

    return {
        id: accountId,
        name: {
            content: (
                <div className={style.account}>
                    <div className={style.accountHeader}>
                        <div className={style.accountHeaderName}>
                            {accountId !== "total" ? (
                                <>
                                    <div
                                        className={style.favourite}
                                        onClick={() => {
                                            dispatch(
                                                togglePinnedView({
                                                    accountId,
                                                    pinned: !pinned,
                                                }),
                                            );
                                        }}
                                        data-for={`favorites-${accountId}`}
                                        data-tip={pinned ? "Remove from Favorites" : "Add to Favorites"}
                                    >
                                        <PinStar className={style.star} pinned={pinned} />
                                    </div>
                                    <h3 onClick={() => selectView({ accountId, userRole })}>{name}</h3>
                                    {isNew && <span className={style.new}>New</span>}
                                </>
                            ) : (
                                <div className={style.totalTitla}>
                                    <ToggleMulti
                                        className={style.toggleView}
                                        options={[
                                            {
                                                id: "total",
                                                tip: "Total Views",
                                                label: "Total",
                                                onClick: () => toggleTotalType("total"),
                                            },
                                            {
                                                id: "average",
                                                tip: "Average Views",
                                                label: "Average",
                                                onClick: () => toggleTotalType("average"),
                                            },
                                        ]}
                                        active={totalType}
                                    />
                                    <p>
                                        Includes all accounts based on search results and <br />
                                        tag filters, including those on subsequent pages.
                                    </p>
                                </div>
                            )}
                        </div>
                        {isAuthorized && accountId !== "total" && (
                            <ManageTags iconClass={style.manageIcon} tags={tags} accountId={accountId} />
                        )}
                        {isAuthorized && (
                            <AccountMenu
                                accountId={accountId}
                                className={style.menuButton}
                                handlePrompt={handlePrompt}
                                selectView={selectView}
                                toggleRenameAccount={() => toggleRenameAccount(accountId)}
                                toggleDuplicateAccount={() => toggleDuplicateAccount(accountId)}
                            />
                        )}
                    </div>
                    {accountId !== "total" && (
                        <div className={style.connectedIntegration}>
                            <AccountTags
                                manageButtonClassName={style.manageIcon}
                                tags={tags}
                                accountId={accountId}
                                integrations={integrations}
                                onAddIntegration={addIntegrations}
                                selectView={selectView}
                            />
                        </div>
                    )}
                    {accountId !== "total" && Object.keys(integrations).length === 0 && (
                        <AddIntegrationButton onClick={addIntegrations} />
                    )}
                    <TooltipDark id={`favorites-${accountId}`} proximity={2} />
                </div>
            ),
            hasDropDown: true,
            value: name,
            verticalAlign: "top",
        },
        budgets: {
            content:
                accountId === "total" || accountCurrencies.length > 1 ? (
                    <MixCurrency />
                ) : isLoading ? (
                    <div className={style.loadingDots}>
                        <ThreeDots color="#1b264f" />
                    </div>
                ) : monthlyBudget ? (
                    <div
                        className={style.budgetBarContainer}
                        onClick={() => history.push(`/dashboard/${accountId}/financials`)}
                    >
                        <div
                            className={style.barWrapper}
                            data-html={true}
                            data-tip={[
                                `<div>
                                <span>
                                    <strong>Platforms</strong>
                                </span>
                                <span>
                                    <strong>Budget</strong>
                                </span>
                                <span>
                                    <strong>Spend</strong> 
                                </span>
                                <span>
                                    <strong>Expected Spend</strong> 
                                </span>
                                <span>
                                    <strong>Remaining Budget</strong> 
                                </span>
                            </div>`,
                                ...budgetPlatforms.map((platform) => {
                                    const { spend = 0, budget = 0, expectedSpend = 0 } = paidPlatformData[platform];
                                    return `<div>
                                            <span>
                                                <img src="${getIcon(platform)}" />
                                                ${channelFriendlyName(platform)}
                                            </span>
                                            <span>
                                            <strong> ${toPrettyNumber(
                                                budget,
                                                "spend",
                                                accountId,
                                                null,
                                                currency,
                                            )}</strong>
                                            </span>
                                            <span>
                                                ${toPrettyNumber(spend, "spend", accountId, null, currency)}
                                            </span>
                                            <span>
                                                ${toPrettyNumber(expectedSpend, "spend", accountId, null, currency)}
                                            </span>
                                            <span ${budget - spend < 0 ? "style=color:red" : ""}>
                                                ${toPrettyNumber(budget - spend, "spend", accountId, null, currency)}
                                            </span>
                                        </div>`;
                                }),
                            ].join("")}
                            data-for={`${accountId}-total`}
                        >
                            <BudgetBar
                                platform={"total"}
                                hasPartialOverage={hasPartialOverage}
                                calculatedExpectedBarWidth={calculatedExpectedBarWidth}
                                calculatedBarWidth={calculatedBarWidth}
                                hasCaps={hasCaps}
                                hasOverageOverall={hasOverageOverall}
                                size="super-small"
                                platformDetails={platformBar({
                                    platform: "total",
                                })}
                                hasPartialShortage={hasPartialShortage}
                                spend={spend}
                                calculatedDifference={calculatedDifference}
                            />
                        </div>
                        <TooltipHorizontalFloat id={`${accountId}-total`} html className={style.budgetBarTooltip} />
                        <div className={style.budgetBarInfo}>
                            <div className={style.budgetBarInfoSection}>
                                <span className={style.budgetSummaryMonthly}>
                                    {toPrettyNumber(monthlyBudget, "spend", accountId, null, currency)}
                                </span>
                                <span className={style.spendDesc}>Budget</span>
                            </div>
                            <div className={style.budgetBarInfoSection}>
                                <span className={style.spend}>
                                    {toPrettyNumber(spend, "spend", accountId, null, currency)}
                                </span>
                                <span className={style.spendDesc}>Spend To Date</span>
                            </div>
                            <div className={style.budgetBarInfoSection}>
                                <span
                                    className={classNames(style.spend, {
                                        [style.red]: toExpectedBudgetPercent > 0,
                                    })}
                                >
                                    {toPrettyNumber(toExpectedBudgetPercent, "percent", accountId, null, currency)}
                                </span>
                                <span className={style.spendDesc}>To Expected Spend</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <AddButton
                            type="Platform Budgets"
                            onClick={() => history.push(`/dashboard/${accountId}/financials`)}
                        />
                    </div>
                ),
            value: toExpectedBudgetPercent,
        },
        ...budgetPlatforms.reduce((cache, platform) => {
            const { spend = 0, budget = 0, expectedSpend = 0 } = paidPlatformData[platform];
            return {
                ...cache,
                [platform]: {
                    content: (
                        <div className={classNames(`${style.group} ${style.metricsGroup}`)}>
                            {isLoading && (
                                <div className={style.loadingDots}>
                                    <ThreeDots color="#1b264f" />
                                </div>
                            )}
                            {!isLoading && (
                                <EditableNumber
                                    goal={budget}
                                    metric={"budget"}
                                    actual={
                                        <span className={style.recentValue}>
                                            {toPrettyNumber(
                                                spend,
                                                "spend",
                                                accountId,
                                                null,
                                                currencyPerPlatform[platform] || currency,
                                            )}
                                        </span>
                                    }
                                    actualNumber={spend}
                                    platform={platform}
                                    accountId={accountId}
                                    saveGoal={saveBudget}
                                    month={month}
                                    currency={currencyPerPlatform[platform] || currency}
                                    type={"Budget"}
                                    goalToDateTarget={expectedSpend}
                                />
                            )}
                        </div>
                    ),
                    value: spend || 0,
                    growth: budget ? (100 * (spend - budget)) / budget : 0,
                },
            };
        }, {}),
        ...metricStats.reduce((cache, { metric, actual, oneMonth }) => {
            const [p] = metric.split("-");
            const platform = getPlatformMainKey(p);
            return {
                ...cache,
                [metric]: {
                    content: (
                        <div
                            className={classNames(`${style.group} ${style.metricsGroup}`, {
                                [style.empty]: metricStats.length === 0,
                            })}
                        >
                            {isLoading && (
                                <div className={style.loadingDots}>
                                    <ThreeDots color="#1b264f" />
                                </div>
                            )}
                            {!isLoading && (
                                <div className={style.metrics}>
                                    <Metric
                                        accountId={accountId}
                                        metric={metric}
                                        actual={actual}
                                        currency={currencyPerPlatform[platform] || currency}
                                        oneMonth={oneMonth}
                                        noMetricData={noMetricData}
                                        saveGoal={saveGoal}
                                        month={month}
                                    />
                                </div>
                            )}
                        </div>
                    ),
                    value: actual || 0,
                    growth: oneMonth ? (100 * (actual - oneMonth)) / oneMonth : 0,
                },
            };
        }, {}),
    };
};
