import Connect from "features/Integrations/Dependant/ConnectButton";
import SlackConnect from "features/Integrations/Connections/SlackConnect";
import onboardingStyles from "features/Onboarding/onboarding.module.scss";
import React, { useState, useEffect } from "react";
import style from "./style.module.scss";
import Button from "components/Button";
import SlackIcon from "assets/images/Menu/JSX/Slack";
import IconPlus from "assets/images/icons/JSX/Icon-Plus-Small";
import NotificationIcon from "assets/images/Menu/JSX/Email";
import ResegmentIcon from "assets/images/Dashboard/JSX/Resegment";
import Trash from "assets/images/Dashboard/JSX/Trash";
import { TooltipDark } from "components/Tooltip";
import MultiSelector from "components/MultiSelector";
import { isEqual } from "lodash";

const ConnectButton = ({ onAdd, loading, label, platform, selected, list, changeConnection }) => {
    return (
        <div className={`${style.connectButtonWrapper} ${onboardingStyles.formInputs}`}>
            <Connect
                selected={selected}
                list={list}
                changeConnection={({ connectionId }) => changeConnection({ connectionId, platform })}
                loading={loading}
                onAdd={onAdd}
                label={label}
            />
        </div>
    );
};

export default React.memo(
    ({
        list,
        selected,
        changeConnection,
        saveSlackAccessToken,
        channels,
        toggleOpen,
        availableChannels,
        getSlackChannels,
        addSlackChannel,
        deleteSlackChannel,
        testSlackMessage,
        accountName,
        channelTestError,
        channelTestSuccess,
        accountId,
    }) => {
        const [isDirty, toggleDirty] = useState(false);
        return (
            <div style={{ width: "600px" }}>
                <SlackConnect
                    exchangeCode={saveSlackAccessToken}
                    component={({ onAdd, loading, label, platform }) =>
                        ConnectButton({
                            list,
                            selected,
                            changeConnection,
                            onAdd,
                            loading,
                            label,
                            platform,
                        })
                    }
                />
                <SlackChannels
                    getSlackChannels={getSlackChannels}
                    addSlackChannel={addSlackChannel}
                    deleteSlackChannel={deleteSlackChannel}
                    testSlackMessage={testSlackMessage}
                    accountName={accountName}
                    channelTestError={channelTestError}
                    channelTestSuccess={channelTestSuccess}
                    accountId={accountId}
                    channels={channels}
                    toggleDirty={toggleDirty}
                    availableChannels={availableChannels}
                    isSlackActive={true}
                    toggleOpen={toggleOpen}
                    selected={selected}
                />
            </div>
        );
    },
    isEqual,
);

const SlackChannels = ({
    getSlackChannels = () => null,
    addSlackChannel = () => null,
    deleteSlackChannel = () => null,
    testSlackMessage = () => null,
    accountName,
    accountId,
    channels = {},
    toggleDirty,
    availableChannels = [],
    isSlackActive,
    selected,
}) => {
    const [isLoading, toggleLoading] = useState(false);
    const [selectedChannel, updateSelectedChannel] = useState("");

    useEffect(() => {
        if (selected) {
            toggleLoading(true);
            getSlackChannels({ accountId, connectionId: selected }).finally(() => toggleLoading(false));
        }
    }, [selected]);

    if (!isSlackActive) return null;

    return (
        <>
            <h3 className={style.header}>Add a Slack Channel</h3>
            <div className={style.channelAddInputWrapper}>
                <div className={style.selectWrapper}>
                    <MultiSelector
                        className={style.channelSelect}
                        input={{
                            value: selectedChannel,
                            onChange: (change) => {
                                if (change) {
                                    const { value = "" } = change || {};
                                    return updateSelectedChannel(value);
                                }
                                updateSelectedChannel("");
                            },
                        }}
                        placeholder={isLoading ? "Loading..." : "Type or Select Channel"}
                        options={availableChannels
                            .filter(({ name }) => {
                                return !channels[name];
                            })
                            .map(({ name }) => ({
                                label: name,
                                value: `#${name}`,
                            }))}
                        disabled={isLoading}
                        darkBorder
                    />
                    {!selectedChannel && (
                        <>
                            <div
                                className={style.refreshButton}
                                onClick={() => {
                                    toggleLoading(true);
                                    getSlackChannels({ accountId, connectionId: selected }).finally(() =>
                                        toggleLoading(false),
                                    );
                                }}
                                data-for="refresh"
                                data-tip="Refresh Slack Channels"
                            >
                                <ResegmentIcon />
                            </div>
                            <TooltipDark id="refresh" proximity={2} />
                        </>
                    )}
                </div>
                <Button
                    onClick={() => {
                        addSlackChannel({
                            channel: selectedChannel.replace("#", ""),
                            integrationId: selected,
                        });
                        updateSelectedChannel("");
                        toggleDirty(true);
                    }}
                    headerHeight
                    disabled={!selectedChannel}
                    icon={IconPlus}
                    className={style.addButton}
                >
                    Add Channel
                </Button>
            </div>
            <h3 className={style.header}>Slack Channels</h3>
            <ul className={style.channelList}>
                {Object.values(channels)
                    .filter((item) => item)
                    .sort((a, b) => {
                        if (a > b) {
                            return 1;
                        }
                        if (a < b) {
                            return -1;
                        }
                        return 0;
                    })
                    .map(({ channel }) => (
                        <li key={channel}>
                            <span className={style.slackIcon}>
                                <SlackIcon />
                            </span>
                            <span className={style.channelName}>#{channel}</span>
                            <Button
                                small
                                slate
                                icon={NotificationIcon}
                                onClick={() =>
                                    testSlackMessage({
                                        channel,
                                        accountId,
                                        accountName,
                                        connectionId: selected,
                                    })
                                }
                            >
                                Send Test
                            </Button>
                            <div
                                className={style.deleteChannel}
                                onClick={() => {
                                    deleteSlackChannel({
                                        channel: channel.replace("#", ""),
                                        integrationId: selected,
                                    });
                                    toggleDirty(true);
                                }}
                                data-tip="Remove Channel"
                                data-for={channel}
                            >
                                <Trash />
                            </div>
                            <TooltipDark id={channel} proximity={6} />
                        </li>
                    ))}
            </ul>
        </>
    );
};
