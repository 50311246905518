import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import { ReactComponent as WarningIcon } from "assets/images/icons/Icon-Alert.svg";

export default ({ children, className, largeIcon = false, styles }) => (
    <div className={`${style.WarningWrapper} ${className}`} style={styles}>
        <div className={classNames(style.alertAvatar, { [style.largeIcon]: largeIcon })}>
            <WarningIcon />
        </div>
        <p>{children}</p>
    </div>
);
