import React from "react";
import withErrorBoundary from "components/ErrorBoundary";

const ErrorView = () => (
    <div>
        Disabled due to tracking prevention.
        <a
            href="https://support.mozilla.org/en-US/kb/tracking-protection#w_turn-tracking-protection-on-or-off"
            rel="noopener"
            target="_blank"
        >
            Learn more
        </a>
    </div>
);

class BigQueryConnect extends React.Component {
    state = {
        loading: false,
        platform: "bigQuery",
        label: "Google BigQuery",
        error: false,
    };

    componentDidMount() {
        const { google } = window;

        if (typeof google === "undefined") {
            throw new Error(
                "Google API not loaded for Google BigQuery integration"
            );
        }

        const client = google.accounts.oauth2.initCodeClient({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            scope:
                "https://www.googleapis.com/auth/bigquery",
            ux_mode: "popup",
            callback: async (response) => {
                const { code } = response;
                if (!code) {
                    throw new Error(
                        "Couldn't generate Google authorization code"
                    );
                }
                try {
                    await this.props.exchangeBigQueryCode(code);
                } catch (error) {
                    this.setState({ error: true });
                }
            },
        });

        return this.setState({
            auth2: client,
        });
    }

    onAdd = async () => {
        this.setState({ loading: true });
        await this.state.auth2.requestCode();
        this.setState({ loading: false });
    };

    render() {
        const { loading, platform, label, error } = this.state;

        const { component: Component } = this.props;

        return (
            <Component
                error={error}
                loading={loading}
                platform={platform}
                onAdd={this.onAdd}
                label={label}
            />
        );
    }
}

export default withErrorBoundary(ErrorView)(BigQueryConnect);
