import React from "react";

export default () => (
    <svg
        fill="#6A80A7"
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
    >
        <path
            d="M29,19.0178571 L25.1785714,19.0178571 C25.0700945,19.0178571 24.9821429,18.9299055 24.9821429,18.8214286 L24.9821429,15 C24.9821429,14.4576154 24.5423846,14 24,14 C23.4576154,14 23.0178571,14.4576154 23.0178571,15 L23.0178571,18.8214286 C23.0178571,18.9299055 22.9299055,19.0178571 22.8214286,19.0178571 L19,19.0178571 C18.4576154,19.0178571 18,19.4576154 18,20 C18,20.5423846 18.4576154,20.9821429 19,20.9821429 L22.8214286,20.9821429 C22.9299055,20.9821429 23.0178571,21.0700945 23.0178571,21.1785714 L23.0178571,25 C23.0178571,25.5423846 23.4576154,26 24,26 C24.5423846,26 24.9821429,25.5423846 24.9821429,25 L24.9821429,21.1785714 C24.9821429,21.0700945 25.0700945,20.9821429 25.1785714,20.9821429 L29,20.9821429 C29.5423846,20.9821429 30,20.5423846 30,20 C30,19.4576154 29.5423846,19.0178571 29,19.0178571 Z"
            transform="translate(-18 -14)"
        />
    </svg>
);
