import React from "react";

import createReducer from "lib/createReducer";
import { handle } from "redux-pack";
import types from "./types";
import merge from "lodash/fp/merge";
import moment from "moment";
import { channelFriendlyName } from "lib/utility";
import { Icon, GreyIcon } from "components/Icon/PlatformIcon";

export const filters = {
    types: {
        goals: false,
        budgets: false,
        seo: false,
        landingPages: false,
    },
    platforms: {
        analyticsV4: false,
        analytics: false,
        adwords: false,
        facebook: false,
        tiktok: false,
        pinterest: false,
        linkedin: false,
        bing: false,
        custom: false,
        googleSearch: false,
        campaignGroup: false,
        maropost: false,
        stackAdapt: false,
    },
    statuses: {
        red: false,
        yellow: false,
        green: false,
    },
    date: {
        start: moment()
            .add(-7, "days")
            .toDate(),
        end: moment().toDate(),
    },
};

export const defaultState = {
    list: {},
    filterNames: {
        types: {
            goals: {
                name: "Goal Monitoring",
                channels: ["goalZeroState", "goal", "goal_pacing", "goal_milestone"],
            },
            landingPages: {
                name: "Landing Page Monitoring",
                channels: ["page_health_hourly"],
            },
            budgets: {
                name: "Budget Monitoring",
                channels: [
                    "budget",
                    "budgetReminders",
                    "budgetZeroState",
                    "budget_pacing",
                    "budget_milestone",
                    "enhancedBudgetAnomalies",
                    "enhancedBudgetReminders",
                    "enhancedBudgetZeroState",
                ],
            },
            seo: {
                name: "SEO Rank Monitoring",
                channels: ["seo"],
            },
        },
        platforms: {
            analyticsV4: {
                name: channelFriendlyName("analyticsV4"),
                icon: () => <Icon platform="analyticsV4" />,
                disabledIcon: () => <GreyIcon platform="analyticsV4" />,
            },
            analytics: {
                name: channelFriendlyName("analytics"),
                icon: () => <Icon platform="analytics" />,
                disabledIcon: () => <GreyIcon platform="analytics" />,
            },
            adwords: {
                name: channelFriendlyName("adwords"),
                icon: () => <Icon platform="adwords" />,
                disabledIcon: () => <GreyIcon platform="adwords" />,
            },
            facebook: {
                name: channelFriendlyName("facebook"),
                icon: () => <Icon platform="facebook" />,
                disabledIcon: () => <GreyIcon platform="facebook" />,
            },
            tiktok: {
                name: channelFriendlyName("tiktok"),
                icon: () => <Icon platform="tiktok" />,
                disabledIcon: () => <GreyIcon platform="tiktok" />,
            },
            pinterest: {
                name: channelFriendlyName("pinterest"),
                icon: () => <Icon platform="pinterest" />,
                disabledIcon: () => <GreyIcon platform="pinterest" />,
            },
            linkedin: {
                name: channelFriendlyName("linkedin"),
                icon: () => <Icon platform="linkedin" />,
                disabledIcon: () => <GreyIcon platform="linkedin" />,
            },
            bing: {
                name: channelFriendlyName("bing"),
                icon: () => <Icon platform="bing" />,
                disabledIcon: () => <GreyIcon platform="bing" />,
            },
            stackAdapt: {
                name: channelFriendlyName("stackAdapt"),
                icon: () => <Icon platform="stackAdapt" />,
                disabledIcon: () => <GreyIcon platform="stackAdapt" />,
            },
            shopify: {
                name: channelFriendlyName("shopify"),
                icon: () => <Icon platform="shopify" />,
                disabledIcon: () => <GreyIcon platform="shopify" />,
            },
            maropost: {
                name: channelFriendlyName("maropost"),
                icon: () => <Icon platform="maropost" />,
                disabledIcon: () => <GreyIcon platform="maropost" />,
            },
            klaviyo: {
                name: channelFriendlyName("klaviyo"),
                icon: () => <Icon platform="klaviyo" />,
                disabledIcon: () => <GreyIcon platform="klaviyo" />,
            },
            custom: {
                name: channelFriendlyName("custom"),
                icon: () => <Icon platform="custom" />,
                disabledIcon: () => <GreyIcon platform="custom" />,
            },
            googleSearch: {
                name: "Google Search Rank",
                icon: () => <Icon platform="googleSearch" />,
                disabledIcon: () => <GreyIcon platform="googleSearch" />,
            },
            campaignGroup: {
                name: "Campaign Groups",
                icon: () => <Icon platform="campaignGroup" />,
                disabledIcon: () => <GreyIcon platform="campaignGroup" />,
            },
        },
        statuses: {
            red: {
                name: "Error",
            },
            yellow: {
                name: "Warning",
            },
            green: {
                name: "Success",
            },
        },
    },
    filtersDismissed: filters,
    filters: filters,
};

export default createReducer(defaultState, {
    [types.UPDATE_ALERTS_FILTER]: (state, action) => {
        return merge(state, {
            ...state,
            filters: {
                [action.payload.type]: {
                    [action.payload.filter]: action.payload.value,
                },
            },
        });
    },
    [types.UPDATE_DISMISSED_ALERTS_FILTER]: (state, action) => {
        return merge(state, {
            ...state,
            filtersDismissed: action.payload.filters,
        });
    },
    [types.UPDATE_ALERTS_FILTERS]: (state, action) => {
        return merge(state, {
            ...state,
            filters: action.payload.filters,
        });
    },
    [types.TOGGLE_ALERT_VISIBILITY]: (state, action) => {
        const id = action.payload.id;

        return merge(state, {
            ...state,
            list: {
                [id]: {
                    dismissed: {
                        value: action.payload.dismissed,
                        updated: new Date().toISOString(),
                        userId: action.payload.userId,
                    },
                },
            },
        });
    },
    [types.DISMISS_ALERT_REQUEST]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                dismissPending: true,
            }),
            finish: (prevState) => ({
                ...prevState,
                dismissPending: false,
            }),
        }),
    [types.GET_ALERTS]: (state, action) =>
        handle(state, action, {
            start: (prevState) =>
                merge(prevState, {
                    ...prevState,
                    alertsPending: true,
                }),
            success: (prevState) => ({
                ...prevState,
                list: action.payload.data,
            }),
            finish: (prevState) => ({
                ...prevState,
                alertsPending: false,
            }),
        }),
});
