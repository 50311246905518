import React from "react";
import { useSelector } from "react-redux";
import { getFormError, isSubmitting } from "redux-form";
import { EDIT_CAMPAIGN_NAME, REVIEW_STEP_INDEX } from "../helpers";
import { platforms as platformMeta } from "lib/paidPlatforms";
import Loading from "components/UpdateEntities/Loading";
import FinalStatus from "components/UpdateEntities/FinalStatus";

function SavingStep({ platform, onCloseModalHandle, changeType }) {
    const submitting = useSelector(isSubmitting(EDIT_CAMPAIGN_NAME));
    const isSubmitFailed = !!useSelector(getFormError(EDIT_CAMPAIGN_NAME));

    const platformName = platformMeta[platform]?.name || platform;
    const message = isSubmitFailed
        ? `Sorry there was an issue updating the ${platformName} Campaign, we ask that you please try again.`
        : `The campaign has been successfully updated on ${platformName}.`;
    const title = isSubmitFailed ? "Update Error" : "Campaign Updated";

    if (submitting) {
        return (
            <Loading
                platforms={[platform]}
                loadingTitle={"Update Campaign"}
                loadingMessage={`Please wait one moment while we connect to ${platformName} to update your Campaign.`}
            />
        );
    }

    return (
        <FinalStatus
            isSubmitFailed={isSubmitFailed}
            onCloseModalHandler={onCloseModalHandle}
            onButtonClick={() => changeType(REVIEW_STEP_INDEX)}
            message={message}
            title={title}
        />
    );
}

export default SavingStep;
