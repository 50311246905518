import React, { useState } from "react";
import DataStudioIcon from "assets/images/Integrations/JSX/Logo-DataStudio";
import Icon from "features/Onboarding/IntegrateStep/Icon";
import integrationStyle from "../style.module.scss";
import style from "./style.module.scss";
import CopyButton from "components/Button/CopyButton";
import copy from "copy-to-clipboard";
import Button from "components/Button";
import Integrations from "assets/images/Menu/JSX/Integrations";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { TooltipFlexible } from "components/Tooltip";
import anomalyIcon from "assets/images/Connectors/Icon-Anomaly.svg";
import kpiIcon from "assets/images/Connectors/Icon-KPI.svg";
import seoIcon from "assets/images/Connectors/Icon-SEO.svg";
import seoAuditIcon from "assets/images/Connectors/Icon-HealthCheck.svg";
import landingPagesIcon from "assets/images/Menu/Icon-LandingPages.svg";
import audienceIcon from "assets/images/Connectors/Icon-Audience.svg";
import HelpIcon from "assets/images/icons/JSX/Icon-Help";

const connectors = {
    kpi: {
        url:
            "https://datastudio.google.com/datasources/create?connectorId=AKfycbyg3iqmdyQZTAMjpuH28zRRlCTjgSUuxcnj8LzCaKmijgFPFT9jHFrEfErl9X-CFhxL6A",
        icon: kpiIcon,
        color: "rgba(82, 46, 255,0.09)",
        title: "KPI Report Connector",
        subtitle: "Providing our clients with a dashboard to track their KPIs against their goals.",
    },
    seo: {
        url:
            "https://datastudio.google.com/datasources/create?connectorId=AKfycbySu36NVAqgzNDA-cMHSUUIuPI2LeXwg2RnqmDeHsEJIGeMADAPQxDbmaj4vBxauZqL",
        icon: seoIcon,
        color: "rgba(0,147,255,0.09)",
        title: "SEO Rank Monitoring Connector",
        subtitle: "Providing a high-level view of covered keywords, impressions and growth to our clients.",
    },
    anomalies: {
        url:
            "https://datastudio.google.com/datasources/create?connectorId=AKfycbziPj3d2W-FLJOmNaR_gc3ZLEIMRWn0YVM1kubrjp423FzlEoOy6cmK26qbffhis-I09w",
        icon: anomalyIcon,
        color: "rgba(255,188,0,0.09)",
        title: "KPI Anomalies Connector",
        subtitle: "Providing our clients with a dashboard to track the anomalies in their KPIs.",
    },
};

const CopyRow = ({ title, copyText, tipText }) => {
    const [copied, toggleCopied] = useState(false);

    return (
        <div className={style.cardRow}>
            <div className={style.cardLabel}>
                <h4>{title}</h4>
            </div>
            <div className={`${style.copyText} ${copied && style.copied}`}>
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={copied}
                        classNames={{
                            enter: style.contentEnter,
                            enterActive: style.contentEnterActive,
                            exit: style.contentExit,
                            exitActive: style.contentExitActive,
                        }}
                        timeout={300}
                    >
                        {copied ? (
                            <input value="Copied To Clipboard" className={style.copied} type="text" disabled />
                        ) : (
                            <input value={copyText} type="text" disabled />
                        )}
                    </CSSTransition>
                </SwitchTransition>
            </div>
            <TooltipFlexible
                delay
                message={copied ? "Copied!" : tipText}
                className={style.copyTooltip}
                width="8rem"
                align="center"
            >
                <CopyButton
                    copied={copied}
                    onClick={() => {
                        toggleCopied(true);
                        copy(copyText);
                        setTimeout(() => {
                            toggleCopied(false);
                        }, 2000);
                    }}
                />
            </TooltipFlexible>
        </div>
    );
};

export default ({ onCancel, apiToken, accountId }) => {
    return (
        <div className={`${integrationStyle.integration} ${style.dataStudio}`}>
            <Icon icon={DataStudioIcon} />
            <h1>Connect Google Data Studio</h1>
            <div className={style.credentials}>
                <h3>Credentials</h3>
                <div className={style.card}>
                    <CopyRow title="API Key" tipText="Copy API Key" copyText={apiToken} />
                    <CopyRow title="Account ID" tipText="Copy Account ID" copyText={accountId} />
                </div>
            </div>
            <div className={style.connectors}>
                <h3>Connectors</h3>
                <div>
                    {Object.keys(connectors).map((key) => {
                        const { title, subtitle, url, icon, color } = connectors[key] || {};

                        return (
                            <div key={title} className={`${style.card} ${style.connectorCard}`}>
                                <div className={style.connectorIcon} style={{ backgroundColor: color }}>
                                    <img src={icon} alt={title} />
                                </div>
                                <div className={style.text}>
                                    <h4>{title}</h4>
                                    <p>{subtitle}</p>
                                </div>
                                <Button
                                    className={style.connectButton}
                                    onClick={async () => {
                                        window.open(url, "_blank");
                                    }}
                                    hollow
                                    medium
                                    icon={Integrations}
                                >
                                    Connect To Data Studio
                                </Button>
                            </div>
                        );
                    })}
                </div>
            </div>

            <Button
                secondary
                onClick={async () => {
                    onCancel();
                }}
                style={{ marginTop: "3rem", width: "11.25rem" }}
            >
                Close
            </Button>
        </div>
    );
};
