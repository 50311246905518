import React from "react";

export default () => (
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="⚙️-Settings"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="5.1.1-Settings-(Option)"
                transform="translate(-328.000000, -761.000000)"
                fill="#00B178"
            >
                <g id="Framework-Copy-5">
                    <g
                        id="Content"
                        transform="translate(262.000000, 136.000000)"
                    >
                        <g
                            id="Optionsal"
                            transform="translate(10.000000, 457.000000)"
                        >
                            <g
                                id="Component"
                                transform="translate(0.000000, 56.000000)"
                            >
                                <g
                                    id="Connected-Copy"
                                    transform="translate(32.000000, 32.000000)"
                                >
                                    <path
                                        d="M32,96 C27.581722,96 24,92.418278 24,88 C24,83.581722 27.581722,80 32,80 C36.418278,80 40,83.581722 40,88 C40,92.418278 36.418278,96 32,96 Z M35.7855688,85.2142261 C35.4994013,84.9285913 35.035745,84.9285913 34.7495905,85.2142261 L30.392529,89.5633622 L29.2339897,88.5699752 C28.9517437,88.2882287 28.4941789,88.2882287 28.2116845,88.5699752 C27.9294385,88.8517086 27.9294385,89.3086774 28.2116845,89.5904108 L29.9425458,91.0744044 C30.2247918,91.3561509 30.6823566,91.3561509 30.964851,91.0744044 C30.9931775,91.0461423 31.0168636,91.0146965 31.0395693,90.9832638 C31.0437131,90.9793624 31.0483536,90.9766745 31.0525104,90.9727732 L35.7855819,86.2480621 C36.0714749,85.9626752 36.0714749,85.499613 35.7855688,85.2142261 Z"
                                        id="Icon-Checkmark"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
