import { connect } from "react-redux";
import IntegrateStep from "./index";
import { operations as connectOperations } from "features/Integrations/_ducks";
import { change, formValueSelector, getFormValues } from "redux-form";
import { makeSelectGetIntegrationIdList, makeSelectIntegrationIds } from "_redux/sites/selectors";
import { makeSelectUserId, makeSelectUserConnections } from "_redux/users/selectors";
import { selectOnboardingCampaigns, makeSelectOnboardingIntegrations } from "features/Onboarding/ducks/selectors";
import { makeSelectIntegrationOpts } from "features/Integrations/_ducks/selectors";

import { operations, actions } from "../../../Onboarding/ducks";
const { onboardingPlatformConnection } = actions;

const { exchangeAdwordsCode } = connectOperations;

const { getAdwordsAccounts, getAdwordsCampaigns } = operations;

const mapStateToProps = (state, { formName, connection, integrationId }) => {
    const userId = makeSelectUserId()(state);
    const { adwords } = makeSelectUserConnections()(state);
    const integrations = makeSelectIntegrationIds()(state);
    const allIntegrations = makeSelectOnboardingIntegrations()(state);
    const campaigns = selectOnboardingCampaigns(state);
    const { adwords: adwordsOptions } = makeSelectIntegrationOpts()(state);
    const { form } = state;
    const { adwords: { [integrationId]: { campaigns: savedCampaigns = {}, regex: savedRegex = "" } = {} } = {} } =
        integrations || {};

    const { [`${connection}_adwords`]: adwordsIntegrations = false } = allIntegrations;

    const selector = formValueSelector(formName);
    const selectedCampaigns = selector({ form }, "adwords-campaigns") || {};
    const regex = selector({ form }, "regexadwords") || "";
    const alreadyIntegrated = makeSelectGetIntegrationIdList("adwords")(state);

    return {
        userId,
        formName,
        list: adwords,
        selected: connection,
        options: adwordsOptions,
        selectedIntegrations: getFormValues(formName)({ form }),
        integrations: adwordsIntegrations,
        campaigns,
        savedCampaigns,
        selectedCampaignLength: Object.keys(selectedCampaigns).filter((campaign) => selectedCampaigns[campaign]).length,
        currentAndFuture: selectedCampaigns["_all"] && !regex,
        savedRegex,
        integrationId,
        alreadyIntegrated: formName === "onboarding" ? [] : alreadyIntegrated,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getAdwordsCampaigns: ({ userId, connectionId, adwordsId }) =>
        dispatch(getAdwordsCampaigns({ userId, connectionId, adwordsId })),
    getAdwordsAccounts: ({ userId, connectionId }) => dispatch(getAdwordsAccounts({ userId, connectionId })),
    exchangeAdwordsCode: (code) => {
        return dispatch(exchangeAdwordsCode(code)).then(({ id }) => {
            dispatch(
                onboardingPlatformConnection({
                    platform: "adwords",
                    connectionId: id,
                }),
            );
        });
    },
    change: (formName, ...args) => dispatch(change(formName, ...args)),
});

const mergeProps = ({ formName, ...stateProps }, { change, ...dispatchProps }, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    change: (...args) => {
        return change(formName, ...args);
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IntegrateStep);
