import React, { useEffect } from "react";
import isEmpty from "lodash/fp/isEmpty";
import { CardWarning } from "components/Warnings";
import onboardStyles from "features/Onboarding/onboarding.module.scss";
import { Field } from "redux-form";
import RenderInput from "components/RenderInput";
import MultiSelector from "components/MultiSelector";
import { TooltipDark } from "components/Tooltip";
import Loading from "features/Integrations/Dependant/common/Loading";

const required = (value) => (value ? undefined : "This field is required");

const Warning = () => {
    return (
        <CardWarning
            className={onboardStyles.warning}
            title="Pinterest Ads Error"
            subtitle="We could not find any accounts in your connection. Please choose another Pinterest Ads connection."
        />
    );
};

const PinterestIntegrate = ({
    options,
    selected,
    userId,
    getPinterestAccounts,
    integrations,
    change,
    isLoading,
    toggleLoading,
    selectedIntegrations,
    isEditing,
    alreadyIntegrated,
}) => {
    useEffect(() => {
        if (isEmpty(selected) || integrations) return;
        toggleLoading(true);
        getPinterestAccounts({
            userId,
            connectionId: selected,
        }).finally(() => toggleLoading(false));
    }, [selected, userId]);

    if (isLoading) return <Loading />;

    if (!isEmpty(selected) && isEmpty(integrations)) return <Warning />;

    if (!integrations) return null;

    const label = selectedIntegrations?.pinterest?.label;
    const placeholder = label ? `Default: ${label}` : "";

    const accountOptions = Object.keys(integrations.pinterest).map((id) => {
        const disable = alreadyIntegrated.includes(id.toString());

        const { [id]: integrationAccount = {} } = integrations.pinterest;
        return {
            value: integrationAccount.id,
            label: `${integrationAccount.name}${disable ? " (In Use)" : ""}`,
            currency: integrationAccount.currency,
            disabled: disable,
        };
    });

    return (
        <div className={onboardStyles.additionalIntegrationAccount}>
            <Field
                component={RenderInput}
                className={onboardStyles.renderInput}
                name="pinterestIntegrationName"
                label="Connection Name"
                darkBorder
                large
                type="text"
                placeholder={placeholder}
                tip="Name that will appearin the app forthis Ads account."
            />
            <div
                className={onboardStyles.multiSelectWrapper}
                data-for="pinterest-field-account"
                data-tip="This field isn't editable. You may remove this integration and add a new one."
                data-tip-disable={!isEditing}
            >
                <Field
                    component={MultiSelector}
                    onChange={() => change("pinterest-campaigns", { _all: true })}
                    name="pinterest"
                    placeholder="Select Pinterest Ads Account"
                    className={onboardStyles.multiSelect}
                    label={options.pinterest.humanReadable}
                    options={accountOptions}
                    darkBorder
                    validate={[required]}
                    disabled={isEditing}
                />
            </div>
            <TooltipDark id="pinterest-field-account" proximity={34} />
        </div>
    );
};
export default PinterestIntegrate;
