import React, { useEffect, useCallback, useState } from "react";
import style from "./style.module.scss";
import { history } from "../../../_redux";
import { ReactComponent as DashboardIcon } from "assets/images/icons/Icon-Dashboard.svg";
import CustomIconButton from "./CustomIcon";
import { ReactComponent as DoubleArrow } from "assets/images/Menu/Icon-NavArrows.svg";
import { ReactComponent as AnalysisIcon } from "assets/images/icons/Icon-Analysis.svg";
import AlertDropdown from "./AlertDropdown";
import ReportingDropdown from "./ReportingDropdown";
import ClientAccountSettingsDropdown from "./ClientAccountSettingsDropdown";
import { Permissions } from "features/Permissions";
import { TooltipDark } from "components/Tooltip";
import UpgradeModal from "components/YoutubeVideo/UpgradeModal";
import InsightsDropdown from "features/Navigation/Navigation/InsightsDropdown";
import { IconImage } from "components/Icon";
import moment from "moment";

const Dashboard = ({ active, selectedSite } = {}) => (
    <CustomIconButton
        active={active}
        icon={DashboardIcon}
        label="Dashboard"
        onClick={() => history.push(`/dashboard/${selectedSite}/overview`)}
    />
);

const Analysis = ({ active, selectedSite } = {}) => (
    <CustomIconButton
        active={active}
        icon={AnalysisIcon}
        label="Analysis"
        onClick={() => history.push(`/dashboard/${selectedSite}/analysis`)}
    />
);

export default ({
    accountId: selectedSite,
    toggleNavigation,
    isNavOpen,
    getAccountsData,
    userId,
    togglePlanOptions,
    userType,
    userRole,
    userActiveFeatures,
    subscriptionType,
    signUpDate,
}) => {
    const showLegacyFeatures = moment(signUpDate).isBefore(moment("20220701"));

    const [isOpen, toggleModal] = useState(false);
    const [feature, featureText] = useState("N/A");
    const [video, featureVideo] = useState("N/A");

    const toggleModalFeature = useCallback(({ isOpen, feature, video }) => {
        toggleModal(isOpen);
        featureText(feature);
        featureVideo(video);

        return true;
    }, []);

    const toggleNavCollapse = useCallback(() => toggleNavigation(!isNavOpen), [isNavOpen]);

    useEffect(() => {
        getAccountsData({
            accountId: selectedSite,
            userId,
        });
    }, [selectedSite, userId, getAccountsData]);

    const upgradeMessage = "Upgrade Required";

    return (
        <>
            <UpgradeModal
                videoId={video}
                open={isOpen}
                toggleModal={useCallback(() => toggleModal(!isOpen), [isOpen])}
                feature={feature}
                togglePlanOptions={togglePlanOptions}
            />
            <div className={`${style.navigationBar} ${isNavOpen && style.open}`}>
                <nav className={style.navigationList}>
                    <ul>
                        {userRole !== "reporting" && (
                            <>
                                <li
                                    id="dashboardIconHelp"
                                    data-for="navTooltip"
                                    data-tip-disable={isNavOpen}
                                    data-tip="Dashboard"
                                >
                                    <Dashboard
                                        active={
                                            history.location.pathname.split("/")[3] === "" ||
                                            history.location.pathname.split("/")[3] === undefined ||
                                            history.location.pathname.split("/")[3] === "overview"
                                        }
                                        selectedSite={selectedSite}
                                    />
                                </li>
                                <li
                                    id="dashboardIconHelp"
                                    data-for="navTooltip"
                                    data-tip-disable={isNavOpen}
                                    data-tip="Analysis"
                                >
                                    <Analysis
                                        active={history.location.pathname.split("/")[3] === "analysis"}
                                        selectedSite={selectedSite}
                                    />
                                </li>
                            </>
                        )}
                        {userRole !== "reporting" && subscriptionType !== "reporting" && (
                            <>
                                <li id="competitorsIconHelp">
                                    <AlertDropdown
                                        toggleModalFeature={toggleModalFeature}
                                        upgradeMessage={upgradeMessage}
                                        selectedSite={selectedSite}
                                        userType={userType}
                                        showLegacyFeatures={showLegacyFeatures}
                                    />
                                </li>
                                <li>
                                    <InsightsDropdown
                                        toggleModalFeature={toggleModalFeature}
                                        upgradeMessage={upgradeMessage}
                                        userActiveFeatures={userActiveFeatures}
                                        selectedSite={selectedSite}
                                        userType={userType}
                                    />
                                </li>
                            </>
                        )}
                        <Permissions actions={"ACTION_PLATFORMS"}>
                            <li id="channelDataIconHelp">
                                <ReportingDropdown
                                    selectedSite={selectedSite}
                                    userActiveFeatures={userActiveFeatures}
                                    active={history.location.pathname.split("/")[3] === "kpi"}
                                    toggleModalFeature={toggleModalFeature}
                                    upgradeMessage={upgradeMessage}
                                    userRole={userRole}
                                />
                            </li>
                        </Permissions>
                        <li id="settingsIconHelp" style={{ marginTop: "auto" }}>
                            <Permissions actions={"ACTION_ACCOUNT_SETTINGS"}>
                                <ClientAccountSettingsDropdown
                                    selectedSite={selectedSite}
                                    toggleModalFeature={toggleModalFeature}
                                    upgradeMessage={upgradeMessage}
                                />
                            </Permissions>
                        </li>
                        <div className={style.divider}></div>
                    </ul>
                    <div
                        className={style.toggle}
                        onClick={toggleNavCollapse}
                        data-for="navTooltip"
                        data-tip-disable={isNavOpen}
                        data-tip="Expand Menu"
                    >
                        <IconImage icon={DoubleArrow} alt="Collapse Menu" />
                        <div className={style.label}>Collapse Menu</div>
                    </div>
                </nav>
                <TooltipDark id="navTooltip" position="right" proximity={-6} />
            </div>
        </>
    );
};
