import style from "./style.module.scss";
import React from "react";

function Wrapper({ children, message }) {
    return (
        <div className={style.wrapper}>
            <p className={style.subTitle}>{message}</p>
            {children}
        </div>
    );
}

export default Wrapper;
