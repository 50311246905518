import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import compose from "lodash/fp/flowRight";
import Component from "./";
import operations from "features/Integrations/_ducks/operations";
import { makeSelectSearchConsoleConnection } from "_redux/sites/selectors";
import { makeSelectSearchConsoleOptions } from "features/Integrations/_ducks/selectors";
import { makeSelectUserId } from "_redux/users/selectors";
import moment from "moment";
import { isEmpty } from "lodash";

const { getSearchConsoleAccounts } = operations;

const mapStateToProps = (state, { integrationId, connections }) => {
    const connectedSearchConsoleAccount = makeSelectSearchConsoleConnection()(state);
    const searchConsoleOptions = makeSelectSearchConsoleOptions()(state);
    const { [integrationId]: { siteUrl, permissionLevel } = {} } = connectedSearchConsoleAccount || {};

    const {
        form: {
            searchConsole: {
                values: { searchConsoleAccount: selectedSearchConsole = {} } = {},
            } = {},
        } = {},
    } = state;

    return {
        initialValues: {
            searchConsoleAccount: !isEmpty(connectedSearchConsoleAccount)
                ? {
                      value: siteUrl,
                      label: siteUrl,
                      permissionLevel,
                  }
                : undefined,
        },
        selectedSearchConsoleId: selectedSearchConsole?.value,
        selected: connections?.searchConsole,
        userId: makeSelectUserId()(state),
        options: searchConsoleOptions.map(({ siteUrl, permissionLevel } = {}) => {
            return {
                label: siteUrl,
                value: siteUrl,
                permissionLevel,
            };
        }),
    };
};

const mapDispatchToProps = (dispatch) => ({
    getSearchConsoleAccounts: ({ userId, connectionId }) =>
        dispatch(getSearchConsoleAccounts({ userId, connectionId })),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: "searchConsole",
        enableReinitialize: true,
        onSubmit: async (
            item,
            _,
            { updateIntegration, onCancel, accountId, userId, selected: connectionId } = {}
        ) => {
            if (!item.searchConsoleAccount) {
                // Disconnection
                await updateIntegration({
                    integrations: {
                        searchConsole: {
                            value: null,
                        },
                    },
                    accountId,
                });
            } else {
                const {
                    searchConsoleAccount: { permissionLevel, value, label },
                } = item || {};
                await updateIntegration({
                    integrations: {
                        searchConsole: {
                            value: {
                                [value.replace(/[^\w]/g, '_')]: {
                                    userId,
                                    created_at: moment().toISOString(),
                                    connectionId,
                                    integrationId: value,
                                    integrationName: label,
                                    siteUrl: value,
                                    permissionLevel: permissionLevel,
                                },
                            },
                        },
                    },
                    accountId,
                });
            }
            onCancel();
        },
    }),
)(Component);
