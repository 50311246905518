import { combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { middleware as reduxPackMiddleware } from "redux-pack";
import { createBrowserHistory } from "history";
import { reducer as formReducer } from "redux-form";
import { sites } from "./sites";
import views from "../features/ControlPanel/ducks";
import { preload } from "./preload";
import { user } from "./users";
import { kpi } from "./kpi";
import goals from "../features/Settings/_ducks";
import adwords from "../features/Reports/KpiAdwords/ducks";
import tiktok from "../features/Reports/KpiTikTok/_ducks";
import pinterest from "../features/Reports/KpiPinterest/_ducks";
import channelData from "../features/Reports/KpiChannelData/ducks";
import analyticsV4 from "../features/Reports/KpiGoogleAnalyticsV4/ducks";
import facebook from "../features/Reports/KpiFacebook/ducks";
import organicFacebook from "../features/Reports/KpiOrganicFacebook/ducks";
import linkedin from "../features/Reports/KpiLinkedin/ducks";
import shopify from "../features/Reports/KpiShopify/ducks";
import maropost from "../features/Reports/KpiMaropost/_ducks";
import bigQuery from "../features/Reports/KpiBigQuery/_ducks";
import klaviyo from "../features/Reports/KpiKlaviyo/_ducks";
import stackAdapt from "../features/Reports/KpiStackAdapt/ducks";
import searchConsole from "../features/Reports/KpiSearchConsole/ducks";
import googleBusinessProfile from "../features/Reports/KpiGoogleBusinessProfile/ducks";
import bing from "../features/Reports/KpiBing/ducks";
import anomalies from "../features/Anomalies/ducks";
import competitors from "../features/Competitors/ducks";
import team from "../features/AccountSettings/TeamMembers/ducks";
import connect from "../features/Integrations/_ducks";
import notifications from "../features/UserPanel/Notifications/ducks";
import goalsSummary from "../features/Dashboard/_ducks";
import onboarding from "../features/Onboarding/ducks";
import planOptions from "../features/PlanOptions/_ducks";
import campaigns from "../features/SmartCampaign/ducks";
import healthcheck from "features/PageHealth/ducks";
import permissions from "features/Permissions/reducer";
import navigation from "features/Navigation/ducks";
import sharedlink from "../features/SharedPage/ducks";
import sharedReportLink from "../features/SharedReport/ducks";
import socketMiddleware from "./middleware/socket";
import userSocketMiddleware from "./middleware/userSocket";
import optimizer from "../features/Forecast/ducks";
import assistant from "features/Assistant/ducks";
import plans from "features/AccountSettings/PlansAndBilling/_ducks";
import { connectRouter, routerMiddleware } from "connected-react-router";
import enhancedBudgets from "features/Budgets/EnhancedBudgeting/_ducks";
import insights from "features/Insights/_ducks";
import auditReport from "features/AuditReport/_ducks";
import alerts from "features/AlertsPage/_ducks";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import sharedReportSettings from "features/Reports/_ducks";
import benchmarks from "features/Benchmarking/ducks";
import analysis from "features/Analysis/_ducks";
import reportMaker from "features/ReportMaker/_ducks";
import alertSettings from "features/AlertSettings/_ducks";
import goalsData from "features/GoalMonitoring/_ducks";
import newDashboard from "features/NewDashboard/_ducks";
import budgetPlanner from "features/BudgetPlanner/ducks";
import reportBuilder from "features/Reports/NewKpiSummary/_ducks";

const history = createBrowserHistory();

const reducer = combineReducers({
    newDashboard,
    analysis,
    budgetPlanner,
    benchmarks,
    healthcheck,
    preload,
    form: formReducer,
    connect,
    team,
    sharedlink,
    sharedReportLink,
    goalsSummary,
    campaigns,
    navigation,
    plans,
    insights,
    auditReport,
    alerts,
    enhancedBudgets,
    kpi: combineReducers({
        data: kpi,
        settings: combineReducers({
            goals,
        }),
        dashboards: combineReducers({
            channelData,
            analyticsV4,
            facebook,
            organicFacebook,
            linkedin,
            bing,
            adwords,
            tiktok,
            pinterest,
            maropost,
            klaviyo,
            shopify,
            searchConsole,
            googleBusinessProfile,
            bigQuery,
            stackAdapt,
            sharedSettings: sharedReportSettings,
        }),
    }),
    goalsData,
    notifications,
    views,
    sites,
    user,
    permissions,
    router: connectRouter(history),
    anomalies,
    competitors,
    optimizer,
    onboarding,
    assistant,
    planOptions,
    reportMaker,
    alertSettings,
    reportBuilder,
});

// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history);

const loggerMiddleware = createLogger({
    predicate: (getState, action) => Boolean(process.env.REACT_APP_REDUX_LOGGING),
});

const sagaMiddleware = createSagaMiddleware();

const rootReducer = (reducer) => (state, action) => {
    if (action.type === "RESET_APP") {
        // resets to default state
        state = undefined;
    }

    return reducer(state, action);
};

const store = configureStore({
    reducer: rootReducer(reducer),
    devTools: process.env.NODE_ENV === "development",
    middleware: [
        sagaMiddleware,
        thunkMiddleware,
        reduxPackMiddleware,
        loggerMiddleware,
        middleware,
        userSocketMiddleware({
            host: process.env.REACT_APP_DASHBOARD_API_DOMAIN,
        }),
        socketMiddleware({
            host: process.env.REACT_APP_DASHBOARD_API_DOMAIN,
        }),
    ],
});

export { store, history };
