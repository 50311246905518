import React, { useState, useEffect, useCallback } from "react";
import RenderInput from "components/RenderInput";
import { SimpleDrawer, DrawerHeader } from "components/Drawer";
import Button from "components/Button";
import style from "./style.module.scss";
import v from "validate.js";
import DuplicateIcon from "assets/images/icons/JSX/Icon-Duplicate";
import { TipCard } from "components/Warnings";
import TipIcon from "assets/images/icons/JSX/Icon-Tip";

const validate = (values) =>
    v(values, {
        accountName: {
            presence: {
                message: "^Required",
            },
            length: {
                minimum: 3,
                maximum: 50,
            },
        },
    });

export default ({ userId, accountId, accountOriginName, toggleDrawer: toggleOpen, isOpen, duplicateAccount }) => {
    const [inputValue, onInputChange] = useState("");
    const [error, errorOnChange] = useState(false);
    const [isLoading, toggleLoading] = useState(false);

    const closeDrawer = useCallback(() => {
        toggleOpen(false);
        onInputChange("");
    }, [toggleOpen]);

    useEffect(() => {
        onInputChange(`${accountOriginName} (Copy)`);
    }, [accountId]);

    return (
        <SimpleDrawer open={isOpen} docked={false} width={744} openSecondary onRequestChange={closeDrawer}>
            <DrawerHeader
                title="Duplicate Account"
                description={`Duplicating the following account: ${accountOriginName}`}
                handleClose={closeDrawer}
                icon={() => <DuplicateIcon fill={"#FFFFFF"} />}
                centered
            />
            <div style={{ padding: "2rem 2.5rem 3rem" }}>
                <RenderInput
                    name="renameAccount"
                    label="Account Name"
                    input={{
                        onChange: (e) => {
                            onInputChange(e.target.value);
                        },
                        value: inputValue,
                    }}
                    className={`${error && style.errorInput} ${style.input}`}
                />
                <TipCard
                    icon={() => <TipIcon />}
                    title="How it works?"
                    subtitle={
                        <span>
                            By duplicating an account, the same selected metrics on the dashboard, goal monitoring and
                            reporting summary pages will be used. The alert settings will also be copied.
                            <br />
                            <strong>
                                To fully set up this account, you will need to select your integrations, create any
                                budget and campaign groups and add team members to receive alerts.
                            </strong>
                        </span>
                    }
                />
                {error && <div className={style.error}>{error}</div>}
                <div className={style.renameButtons}>
                    <Button
                        onClick={async () => {
                            errorOnChange(false);
                            toggleLoading(true);

                            const valid = validate({
                                accountName: inputValue,
                            });

                            if (valid) {
                                toggleLoading(false);
                                return errorOnChange(valid.accountName[0]);
                            }

                            return duplicateAccount({
                                accountId,
                                userId,
                                name: inputValue,
                            })
                                .then(() => {
                                    toggleOpen(false);
                                })
                                .finally(() => {
                                    toggleLoading(false);
                                });
                        }}
                    >
                        {isLoading ? "Saving..." : "Save"}
                    </Button>
                    <Button disabled={isLoading} onClick={() => toggleOpen(false)} secondary>
                        Cancel
                    </Button>
                </div>
            </div>
        </SimpleDrawer>
    );
};
