import React from "react";
import EditableCell from "../EditableCell";
import { toPrettyNumber } from "lib/metricCalculations";
import style from "./style.module.scss";
import { isArray, isEmpty } from "lodash";

const BudgetCell = ({
    id,
    currency,
    budgets,
    budget = 0,
    type,
    disable = false,
    disableMessage = "This field is uneditable",
    onEditHandle = () => {},
}) => {
    if (!isEmpty(budgets) && isArray(budgets)) {
        return (
            <EditableCell
                id={id}
                iconPosition="left"
                dataTip="Edit Budget"
                disable={true}
                disableMessage={disableMessage}
            >
                <div className={style.budgetWrapper}>
                    {budgets.map((currentBudget) => {
                        return (
                            <>
                                <p className={style.budget}>
                                    {toPrettyNumber(currentBudget.budget, "cost", null, "-", currency)}
                                </p>
                                {currentBudget.type && <p className={style.type}>{currentBudget.type}</p>}
                            </>
                        );
                    })}
                </div>
            </EditableCell>
        );
    }

    if (type === "unlimited") {
        return <p className={style.unlimited}>Unlimited</p>;
    }

    if (!budget) {
        return null;
    }

    const formattedBudget = toPrettyNumber(budget, "cost", null, "-", currency);
    return (
        <EditableCell
            id={id}
            iconPosition="left"
            dataTip="Edit Budget"
            disable={disable}
            disableMessage={disableMessage}
            onEditButtonClick={onEditHandle}
        >
            <div className={style.budgetWrapper}>
                <p className={style.budget}>{formattedBudget}</p>
                {type && <p className={style.type}>{type}</p>}
            </div>
        </EditableCell>
    );
};

export default BudgetCell;
