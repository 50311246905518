import React from "react";
import style from "./style.module.scss";
import isEmpty from "lodash/isEmpty";
import { IconImage } from "components/Icon";

export const CardWarning = ({ icon: Icon, title, subtitle, children, cta, className }) => (
    <div className={`${style.cardWarningWrapper} ${className && className}`}>
        {Icon && (
            <div className={style.icon}>
                <Icon />
            </div>
        )}
        {title && <h3>{title}</h3>}
        <p>{subtitle}</p>
        {!isEmpty(cta) && (
            <a className={style.cta} target="_blank" rel="noopener noreferrer" href={cta.link}>
                {cta.name}
            </a>
        )}
        {children}
    </div>
);

export const CardError = ({ icon: Icon, title, subtitle, className }) => (
    <div className={`${style.cardErrorWrapper} ${className && className}`}>
        {Icon && (
            <div className={style.icon}>
                <IconImage icon={Icon} />
            </div>
        )}
        <div>
            {title && <h3>{title}</h3>}
            {subtitle && <p>{subtitle}</p>}
        </div>
    </div>
);

export const Warning = ({ icon: Icon, title, subtitle, children, cta, className }) => (
    <div className={`${style.warningWrapper} ${className && className}`}>
        {Icon && (
            <div className={style.icon}>
                <Icon />
            </div>
        )}
        <h3>{title}</h3>
        <p>{subtitle}</p>
        {!isEmpty(cta) && (
            <a target="_blank" rel="noopener noreferrer" href={cta.link}>
                {cta.name}
            </a>
        )}
        {children}
    </div>
);

export const TipCard = ({ icon: Icon, title, subtitle, children, cta, className, style: inlineStyle }) => (
    <div className={`${style.tipCardWrapper} ${className && className}`} style={inlineStyle}>
        {Icon && (
            <div className={style.icon}>
                <Icon />
            </div>
        )}
        <h3>{title}</h3>
        <p>{subtitle}</p>
        {!isEmpty(cta) && (
            <a class={style.cta} target="_blank" rel="noopener noreferrer" href={cta.link}>
                {cta.name}
            </a>
        )}
        {children}
    </div>
);
