import { connect } from "react-redux";
import Component from "./";
import { compose, withState, lifecycle } from "recompose";
import { createApiToken } from "_redux/users";
import { makeSelectType } from "_redux/users/selectors";

const mapStateToProps = ({
    sites: {
        selectedSite: { _id: accountId },
    },
    user = {},
}) => {
    const { user: { user_id: userId, apiToken = null } = {} } = user;

    return {
        accountId,
        userId,
        apiToken,
    };
};

const mapDispatchToProps = (dispatch) => ({
    createApiToken: ({ userId }) => {
        dispatch(createApiToken({ userId }));
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({
        async componentDidMount() {
            const { userId, apiToken, createApiToken } = this.props;

            if (!apiToken) {
                return createApiToken({ userId });
            }

            return;
        },
    }),
)(Component);
