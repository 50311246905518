import createReducer from "../../../../lib/createReducer";
import clearReducer from "../../../../lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";

const defaultState = {
    channelData: { current: {}, previous: {} },
    pendingChannelData: true,
    errorChannelData: null,
    pages: {},
    productCategories: {},
    products: {}
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.CLEAR_CHANNEL_DATA](state, action) {
            return {
                ...state,
                ...defaultState
            };
        },
        [types.GET_CHANNEL_DATA]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    pendingChannelData: true
                }),
                finish: (prevState) => ({
                    ...prevState,
                    pendingChannelData: false
                }),
                success: (prevState) => ({
                    ...prevState,
                    channelData: action.payload || []
                }),
                failure: (prevState) => ({
                    ...prevState,
                    errorChannelData: action.error
                })
            }),
        [types.PAGES_REPORT]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    pendingChannelData: true
                }),
                finish: (prevState) => ({
                    ...prevState,
                    pendingChannelData: false
                }),
                success: (prevState) => ({
                    ...prevState,
                    pages: action.payload
                })
            }),
        [types.KEYWORDS_REPORT]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    pendingChannelData: true
                }),
                finish: (prevState) => ({
                    ...prevState,
                    pendingChannelData: false
                }),
                success: (prevState) => ({
                    ...prevState,
                    keywords: action.payload
                })
            }),
        [types.PRODUCTS_REPORT]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    pendingChannelData: true
                }),
                finish: (prevState) => ({
                    ...prevState,
                    pendingChannelData: false
                }),
                success: (prevState) => {
                    const { products = {}, productCategories = {} } =
                        action.payload || {};
                    return {
                        ...prevState,
                        products,
                        productCategories
                    };
                }
            });
        }
    })
);
