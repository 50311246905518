import React, { useState } from "react";
import onboardingStyles from "features/Onboarding/onboarding.module.scss";
import { Field } from "redux-form";
import RenderInput from "components/RenderInput";
import style from "./style.module.scss";
import KlaviyoCampaigns from "features/Integrations/Dependant/Klaviyo/KlaviyoCampaigns";
import { isEqual } from "lodash";
import MultiSelector from "components/MultiSelector";
import { timeZonesNames } from "@vvo/tzdb";

const required = (value) => (value ? undefined : "This field is required");

export default React.memo(
    ({
        userId,
        getKlaviyoCampaigns,
        options,
        selectedIntegrations,
        campaigns,
        selectedCampaignLength,
        savedCampaigns,
        change,
        currentAndFuture = false,
        savedRegex,
        alreadyIntegrated = [],
        apiKey,
        isEditing,
        isFormActive,
        timezone,
        ...props
    }) => {
        const [isCampaignsLoading, toggleCampaignsLoading] = useState(false);
        const label = selectedIntegrations?.apiKey;
        const placeholder = label ? `Default: ${label}` : "";

        return (
            <div className={`${onboardingStyles.additionalIntegrationFields} ${onboardingStyles.formInputs}`}>
                <Field
                    name="integrationName"
                    type="text"
                    label="Integration Name"
                    placeholder={placeholder || "Example: klaviyo integration name"}
                    component={RenderInput}
                    darkBorder
                    large
                />
                <div className={style.mainFormContainer}>
                    <Field
                        name="apiKey"
                        type="password"
                        autoComplete="new-password"
                        label="API Key"
                        placeholder="Example: klaviyo api key"
                        className={style.input}
                        component={RenderInput}
                        darkBorder
                        large
                        validate={[required]}
                        disabled={isEditing}
                    />
                </div>

                <div className={onboardingStyles.multiSelectWrapper}>
                    <Field
                        name="timezone"
                        type="text"
                        label="Klaviyo Account Timezone"
                        placeholder="Select Klaviyo Account Timezone"
                        className={onboardingStyles.multiSelect}
                        component={MultiSelector}
                        options={timeZonesNames.map((timezone) => {
                            return {
                                value: timezone,
                                label: timezone,
                            };
                        })}
                        darkBorder
                        validate={[required]}
                    />
                </div>

                {apiKey && !isFormActive && timezone && (
                    <KlaviyoCampaigns
                        options={options}
                        campaigns={campaigns}
                        savedCampaigns={savedCampaigns}
                        selectedCampaignLength={selectedCampaignLength}
                        userId={userId}
                        regex={selectedIntegrations.regexklaviyo}
                        getKlaviyoCampaigns={getKlaviyoCampaigns}
                        selected={selectedIntegrations.apiKey}
                        change={change}
                        isLoading={isCampaignsLoading}
                        toggleLoading={toggleCampaignsLoading}
                        currentAndFuture={currentAndFuture}
                        savedRegex={savedRegex}
                        apiKey={apiKey}
                    />
                )}
            </div>
        );
    },
    isEqual
);
