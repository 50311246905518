import { createSelector } from "reselect";
import { defaultState } from "./reducers";

export const selectOnboarding = (state = {}) =>
    state.onboarding || defaultState;

export const makeSelectOnboardingConnections = () =>
    createSelector(selectOnboarding, ({ connections = {} }) => connections);

export const makeSelectOnboardingIntegrations = () =>
    createSelector(selectOnboarding, ({ integrations = {} }) => integrations);

export const selectOnboardingCampaigns = createSelector(
    selectOnboarding,
    ({ campaigns = {} }) => campaigns
);

export const selectOnboardingBigQueryTables = createSelector(
    selectOnboarding,
    ({ bigQueryTables = {} }) => bigQueryTables
);
export const selectOnboardingBigQueryTablesLoading = createSelector(
    selectOnboarding,
    ({ bigQueryTablesLoading = false }) => bigQueryTablesLoading
);
export const selectOnboardingBigQueryFields = createSelector(
    selectOnboarding,
    ({ bigQueryFields = {} }) => bigQueryFields
);
export const selectOnboardingBigQueryFieldsLoading = createSelector(
    selectOnboarding,
    ({ bigQueryFieldsLoading = false }) => bigQueryFieldsLoading
);
