import _ from "lodash/fp";
import { analyticsRequest } from "_redux/analytics/async";
import types from "./types.js";
import moment from "moment";
import { grouper, getTotals } from "lib/utility";
import { makeSelectGoals } from "_redux/sites/selectors.js";

// GET CHANNEL DATA
const getChannelData = ({ dateRange, selectedChannel }) => async (dispatch, getState) => {
    const [{ start, end } = {}, { start: compareToStart, end: compareToEnd } = {}] = dateRange || [];

    const startDate = moment(compareToStart).isBefore(start) ? compareToStart : start;
    const endDate = moment(compareToEnd).isAfter(end) ? compareToEnd : end;

    const goals = makeSelectGoals()(getState());
    const { conversion_goal_types: selectedGoals = [] } = goals || {};
    const goalFilter = selectedGoals
        .map(({ id, analyticsViewId } = {}) => `${analyticsViewId}-${id}`)
        .filter((id) => id)
        .join(",");

    return dispatch({
        type: types.GET_CHANNEL_DATA,
        promise: getChannel({
            endDate: moment(endDate).format("YYYY-MM-DD"),
            startDate: moment(startDate).format("YYYY-MM-DD"),
            selectedChannel,
            filter: goalFilter,
        }).then((result) => {
            const metrics = (metrics) => {
                const {
                    revenue = 0,
                    sessions = 0,
                    goalCompletions = 0,
                    transactions = 0,
                    bounces = 0,
                    users = 0,
                    itemQuantity = 0,
                    newUsers = 0,
                    pageviews = 0,
                    ...props
                } = metrics;

                const adjustedReport = {
                    goalCompletions: parseFloat(goalCompletions),
                    transactions: parseFloat(transactions),
                    sessions: parseFloat(sessions),
                    revenue: parseFloat(revenue),
                    bounces: parseFloat(bounces),
                    users: parseFloat(users),
                    itemQuantity: parseFloat(itemQuantity),
                    newUsers,
                    pageviews,
                    ...props,
                };

                adjustedReport.transactionRate = (adjustedReport.transactions / adjustedReport.sessions) * 100;
                adjustedReport.goalCompletionRate = (adjustedReport.goalCompletions / adjustedReport.sessions) * 100;
                adjustedReport.bounceRate = (adjustedReport.bounces / adjustedReport.sessions) * 100;
                adjustedReport.avgOrderValue = adjustedReport.revenue / adjustedReport.transactions;
                adjustedReport.returningUsers = adjustedReport.users - adjustedReport.newUsers;
                adjustedReport.pageviewsPerSession = adjustedReport.pageviews / adjustedReport.sessions;

                return adjustedReport;
            };

            const currentData = result.filter(({ date } = {}) =>
                moment(date).isBetween(moment(start), moment(end), "day", "[]"),
            );
            const previousData = result.filter(({ date } = {}) =>
                moment(date).isBetween(moment(compareToStart), moment(compareToEnd), "day", "[]"),
            );

            const current = metrics(grouper([], getTotals([]))(currentData)[0] || {});

            const previous = metrics(grouper([], getTotals([]))(previousData)[0] || {});

            const currentChannel = grouper(["channels"], getTotals([]))(currentData);
            const previusChannel = grouper(["channels"], getTotals([]))(previousData);

            return {
                current,
                previous,
                currentData: currentChannel,
                previousData: previusChannel,
            };
        }),
    });
};

export const getChannel = ({ startDate, endDate, selectedChannel, filter }) => {
    const options = {
        dimensions: "ga:channelGrouping,ga:date",
        metrics: [
            "ga:sessions",
            "ga:goalCompletions",
            "ga:bounces",
            "ga:users",
            "ga:transactions",
            "ga:transactionRevenue",
            "ga:newUsers",
            "ga:pageviewsPerSession",
            "ga:itemQuantity",
        ].join(","),
        startDate,
        endDate,
        channel: selectedChannel,
        filter,
    };

    return analyticsRequest(options).then(({ data }) =>
        data.map((item) => {
            return {
                ...item,
                pageviews: parseFloat(item.sessions) * parseFloat(item.pageviewsPerSession),
            };
        }),
    );
};

// GET EXTRA DATA

const getProducts = ({ dateRange, accountId, selectedChannel }) => (dispatch, getState) => {
    const goals = makeSelectGoals()(getState());
    const { conversion_goal_types: selectedGoals = [] } = goals || {};
    const goalFilter = selectedGoals
        .map(({ id, analyticsViewId } = {}) => `${analyticsViewId}-${id}`)
        .filter((id) => id)
        .join(",");

    return dispatch({
        type: types.PRODUCTS_REPORT,
        promise: Promise.all(
            dateRange.map(({ start, end }) =>
                fetchProducts({
                    endDate: moment(end).format("YYYY-MM-DD"),
                    startDate: moment(start).format("YYYY-MM-DD"),
                    accountId,
                    selectedChannel,
                    goalFilter,
                }),
            ),
        ).then(([result = [], prevResult = []]) => {
            return {
                products: {
                    current: result[0] || [],
                    previous: prevResult[0] || [],
                },
                productCategories: {
                    current: result[1] || [],
                    previous: prevResult[1] || [],
                },
            };
        }),
    });
};

const fetchProducts = ({ startDate, endDate, selectedChannel, goalFilter } = {}) => {
    const optionsProduct = {
        dimensions: "ga:productName",
        metrics: ["ga:itemQuantity", "ga:itemRevenue"].join(","),
        startDate,
        endDate,
        channel: selectedChannel,
        filter: goalFilter,
    };

    const optionsProductCategories = {
        dimensions: "ga:productCategoryHierarchy",
        metrics: ["ga:itemQuantity", "ga:itemRevenue"].join(","),
        startDate,
        endDate,
        channel: selectedChannel,
        filter: goalFilter,
    };

    return Promise.all([
        analyticsRequest(optionsProduct).then(({ data }) => data),
        analyticsRequest(optionsProductCategories).then(({ data }) => data),
    ]).catch((error) => {
        console.log(error);
        return [];
    });
};

export default {
    getChannel,
    getChannelData,
    getProducts,
};
