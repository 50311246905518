import React from "react";
import styleMobile from "./styleMobile.module.scss";
import ThreeDots from "components/Loading/ThreeDots";
import classNames from "classnames";
import { Metric } from ".";

export const MetricStatsContentMobile = ({
    metricStats,
    isLoading,
    accountId,
    metric,
    actual,
    currencyPerPlatform,
    goalToDateTarget,
    platform,
    currency,
    goal,
    noMetricData,
    saveGoal,
    month,
}) => {
    return (
        <div
            className={classNames(`${styleMobile.group} ${styleMobile.metricsGroup}`, {
                [styleMobile.empty]: metricStats.length === 0,
            })}
        >
            {isLoading && (
                <div className={styleMobile.loadingDots}>
                    <ThreeDots color="#1b264f" />
                </div>
            )}
            {!isLoading && (
                <Metric
                    isMobile
                    accountId={accountId}
                    metric={metric}
                    actual={actual}
                    currency={currencyPerPlatform[platform] || currency}
                    goal={goal}
                    oMetricData={noMetricData}
                    saveGoal={saveGoal}
                    month={month}
                    goalToDateTarget={goalToDateTarget}
                />
            )}
        </div>
    );
};
