import React, { useState } from "react";
import styles from "./style.module.scss";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import HelpIcon from "assets/images/icons/JSX/CircleHelpIcon";

export const TooltipBox = ({ id = null, position = "right", effect = "float", hasArrow = false }) => {
    const [show, toggleShow] = useState(false);

    return (
        <div className={classNames(styles.tooltipBox, { [styles.show]: show, [styles.hasArrow]: hasArrow })}>
            <ReactTooltip
                id={id}
                place={position}
                type="light"
                effect={effect}
                multiline={true}
                afterShow={() => toggleShow(true)}
                afterHide={() => toggleShow(false)}
                border
                borderColor="#e2e9f1"
            />
        </div>
    );
};

export const TooltipCustom = ({ className, id = null, position = "right", effect = "float" }) => {
    const [show, toggleShow] = useState(false);

    return (
        <div className={`${styles.tooltipCustom} ${className && className} ${show && styles.show}`}>
            <ReactTooltip
                id={id}
                place={position}
                type="light"
                effect={effect}
                multiline={true}
                afterShow={() => toggleShow(true)}
                afterHide={() => toggleShow(false)}
            />
        </div>
    );
};

export const TooltipHorizontalFloat = ({ className, id = null, position = "right", effect = "float" }) => {
    const [show, toggleShow] = useState(false);

    const override = (left, top, currentTarget, node) => {
        const nodeHeight = node.offsetHeight;
        const targetTop = currentTarget.getBoundingClientRect().top;
        const targetHeight = currentTarget.offsetHeight;

        const newTop = position === "top" ? targetTop - nodeHeight : targetTop - nodeHeight / 2 + targetHeight / 2;

        return { left, top: newTop };
    };

    return (
        <div className={`${styles.tooltipCustom} ${className && className} ${show && styles.show}`}>
            <ReactTooltip
                id={id}
                place={position}
                type="light"
                effect={effect}
                multiline={true}
                afterShow={() => toggleShow(true)}
                afterHide={() => toggleShow(false)}
                overridePosition={({ left, top }, currentEvent, currentTarget, node) =>
                    override(left, top, currentTarget, node)
                }
            />
        </div>
    );
};

/**
 * Dark themed tooltip component
 *
 * @param {object} props
 * @param {string|null} props.id
 * @param {string} props.position position of tooltip around element top | right | left | bottom
 * @param {arrowPosition} props.arrowPosition postion of arrow on tooltip | top | right
 * @param {number} props.delay
 * @param {boolean} props.fitContent
 * @param {number} props.proximity tooltips offset in pixels to element, relative to position .. eg position:top proximity:10 -> 10pixels closer to element
 *
 */
export const TooltipDark = React.memo(
    ({ id = null, position = "top", arrowPosition = "center", delay, fitContent, proximity = 0, type = "" }) => {
        const positionStyle = {
            top: styles.top,
            bottom: styles.bottom,
            left: styles.left,
            right: styles.right,
        };

        const arrowPositionStyle = {
            top: styles.arrowTop,
            center: styles.arrowCenter,
            right: styles.arrowRight,
        };

        const override = (position, arrowPosition, left, top, node) => {
            if ((position === "right" || position === "left") && arrowPosition === "top") {
                return {
                    left: left + proximity,
                    top: top + node.offsetHeight / 2 - 17,
                };
            }
            if ((position === "top" || position === "bottom") && arrowPosition === "right") {
                return {
                    left: left - node.offsetWidth / 2 + 17,
                    top: top + proximity,
                };
            }
            if (position === "right" || position === "left") {
                return { left: left + proximity, top };
            }
            if (position === "top" || position === "bottom") {
                return { left, top: top + proximity };
            }

            return { left, top };
        };

        const [show, toggleShow] = useState(false);

        const classes = classNames(
            `${styles.tooltipDark} ${positionStyle[position]} ${arrowPositionStyle[arrowPosition]}`,
            {
                [styles.show]: show,
                [styles.fitContent]: fitContent,
                [styles.red]: type === "red",
            },
        );

        return (
            <div className={classes}>
                <ReactTooltip
                    id={id}
                    place={position}
                    type="dark"
                    effect="solid"
                    delayShow={delay ? 500 : 0}
                    multiline={true}
                    afterShow={() => toggleShow(true)}
                    afterHide={() => toggleShow(false)}
                    overridePosition={({ left, top }, currentEvent, currentTarget, node) =>
                        override(position, arrowPosition, left, top, node)
                    }
                    globalEventOff="click"
                />
            </div>
        );
    },
);

export const TooltipFlexible = ({
    message,
    position = "top",
    arrowPosition = "",
    width = "15rem",
    maxWidth = "none",
    className,
    thick,
    delay,
    children,
    align,
    noWrap = false,
    ...props
}) => {
    const positionStyle = {
        top: styles.top,
        bottom: styles.bottom,
        left: styles.left,
        right: styles.right,
    };

    const arrowStyle = {
        left: styles.arrowLeft,
        right: styles.arrowRight,
    };

    const alignText = {
        left: styles.alignLeft,
        right: styles.alignRight,
        center: styles.alignCenter,
    };

    return (
        <div
            className={`
                ${styles.tooltipFlexible}
                ${className}
                ${thick && styles.thick}
                ${delay && styles.delay}
                ${positionStyle[position]}
                ${arrowStyle[arrowPosition]}
            `}
            {...props}
        >
            {children}
            <span
                style={{ width: width, maxWidth: maxWidth }}
                className={`${styles.message} ${align && alignText[align]} ${noWrap && styles.noWrap}`}
            >
                {message}
            </span>
        </div>
    );
};

export default ({
    message,
    className,
    id = null,
    width = "auto",
    position = "top",
    arrowPosition = "center",
    delay = false,
    proximity,
    iconSize = "1rem",
    margin = 0,
}) => (
    <div className={className} style={{ lineHeight: 0 }}>
        <div
            data-tip={`<span style="display:block;max-width:${width}">${message}<span>`}
            data-for={id}
            data-html
            className={styles.tooltip}
            style={{ marginLeft: margin }}
        >
            <HelpIcon style={{ width: iconSize, height: iconSize }} />
        </div>
        <TooltipDark id={id} position={position} delay={delay} proximity={proximity} arrowPosition={arrowPosition} />
    </div>
);
