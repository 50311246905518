import { connect } from "react-redux";
import Component from "./index";
import { operations as onboardingOperations } from "features/Onboarding/ducks";
import isEmpty from "lodash/fp/isEmpty";
import { makeSelectAccountId } from "_redux/sites/selectors";
import { makeSelectSelectedConnections } from "features/Integrations/_ducks/selectors";
import { makeSelectUserId } from "_redux/users/selectors";
import { makeSelectOnboardingIntegrations } from "features/Onboarding/ducks/selectors";
import { formatIntegrationKey } from "lib/bigQuery/helpers";
import { mapKeys } from "lodash";

const { updateIntegrations } = onboardingOperations;

const makeMapStateToProps = () => {
    const accountIdSelector = makeSelectAccountId();
    const selectedConnectionsSelector = makeSelectSelectedConnections();
    const onboardingIntegrations = makeSelectOnboardingIntegrations();
    const userIdSelector = makeSelectUserId();

    return (state) => ({
        accountId: accountIdSelector(state),
        connections: selectedConnectionsSelector(state),
        integrations: onboardingIntegrations(state),
        userId: userIdSelector(state),
    });
};

const mapDispatchToProps = (
    dispatch,
    { currentIntegrations, onUpdateIntegrationSuccess }
) => ({
    // Updating integartion parmeters for each platform / integration ID
    updateIntegration: async ({ accountId, integrations }) => {
        if (isEmpty(integrations)) return;
        const newIntegrations = Object.keys(integrations)
            .filter((platform) => platform)
            .reduce((obj, key) => {
                const { value } = integrations[key] || {};
                const newIntegrations = mapKeys(
                    value,
                    (integrationVal, integrationKey) =>
                        formatIntegrationKey(integrationKey)
                );
                return {
                    ...obj,
                    [key]: {
                        ...obj[key],
                        ...newIntegrations, // new integration (i.e. {[id]: props})
                    },
                };
            }, currentIntegrations || {});

        const { payload: { data = {} } = {} } = await dispatch(
            updateIntegrations({
                accountId,
                integrations: newIntegrations,
            })
        );

        if (
            onUpdateIntegrationSuccess &&
            typeof onUpdateIntegrationSuccess === "function"
        ) {
            return onUpdateIntegrationSuccess(data);
        }
        return data;
    },
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Component);
