import style from "./style.module.scss";
import IconEdit from "assets/images/icons/JSX/Icon-Edit";
import React from "react";
import { TooltipDark } from "components/Tooltip";
import classNames from "classnames";
import PropTypes from "prop-types";

const EditableCell = ({
    children,
    dataTip = "Edit",
    id,
    iconPosition = "left",
    disable = false,
    disableMessage = "",
    onEditButtonClick = () => {},
}) => {
    const classes = classNames(style.wrapper, {
        [style.iconRight]: iconPosition === "right",
        [style.disable]: disable,
    });
    return (
        <>
            <div className={classes}>
                <button
                    className={style.editButton}
                    data-tip={disable ? disableMessage : dataTip}
                    data-for={id}
                    onClick={disable ? () => {} : onEditButtonClick}
                >
                    <IconEdit />
                </button>
                <div>{children}</div>
            </div>
            <TooltipDark id={id} proximity={2} />
        </>
    );
};

export default EditableCell;

EditableCell.propTypes = {
    children: PropTypes.node,
    dataTip: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    iconPosition: PropTypes.oneOf(["left", "right"]),
};
