import React, { useState } from "react";
import { Popper, ClickAwayListener } from "@material-ui/core";
import Options from "assets/images/Dashboard/JSX/HorizontalMore";
import Container from "components/DropDown/Container";
import { SimpleListItem } from "components/DropDown/ListItems";
import style from "./style.module.scss";

export default React.memo(
    ({ selectView, handlePrompt, toggleRenameAccount, toggleDuplicateAccount, accountId, className }) => {
        const [isOpen, toggleOpen] = useState(false);
        const [referenceElement, setReferenceElement] = useState(null);

        return (
            <ClickAwayListener onClickAway={() => toggleOpen(false)}>
                <div className={style.ViewOptions} onClick={() => toggleOpen(!isOpen)}>
                    <div
                        ref={setReferenceElement}
                        className={`${style.ViewOptionsButton} ${isOpen && style.OptionsOpen} ${className}`}
                    >
                        <Options />
                    </div>
                    <Popper
                        open={isOpen}
                        anchorEl={referenceElement}
                        transition
                        placement="bottom-start"
                        style={{ zIndex: 10 }}
                    >
                        <Container
                            className={style.FolderDropdown}
                            isOpen={isOpen}
                            dropdownWidth="11.75rem"
                            anchor="left"
                        >
                            <SimpleListItem
                                onClick={(e) => {
                                    return selectView({
                                        accountId,
                                        navigateTo: `/dashboard/${accountId}/audit-report`,
                                    });
                                }}
                                label={<strong>Download Audit Report</strong>}
                            />
                            <SimpleListItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleRenameAccount();
                                }}
                                label="Rename Account"
                            />
                            <SimpleListItem
                                onClick={(e) => {
                                    selectView({
                                        accountId,
                                        navigateTo: `/dashboard/${accountId}/settings/goals`,
                                    });
                                }}
                                label="Client Account Settings"
                            />
                            <SimpleListItem
                                onClick={(e) => {
                                    toggleDuplicateAccount();
                                }}
                                label="Duplicate Account"
                            />
                            <SimpleListItem
                                className={style.FolderDropdownDelete}
                                onClick={() => handlePrompt({ isOpen: true, accountId })}
                                label="Delete Account"
                            />
                        </Container>
                    </Popper>
                </div>
            </ClickAwayListener>
        );
    },
);
