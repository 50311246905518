import React from "react";
import MaropostIcon from "assets/images/Integrations/JSX/Logo-Maropost";
import MaropostIntegrate from "features/Integrations/Dependant/Maropost/container";
import Icon from "features/Onboarding/IntegrateStep/Icon";
import style from "../style.module.scss";
import { mixpanel, sendToSlack } from "components/Mixpanel";
import SubmitButtons from "../_Components/SubmitButtons";

export default ({
    onCancel,
    handleSubmit,
    submitting,
    disabled,
    invalid,
    reset,
    userId,
    integrationId,
    isNoCampaigns,
}) => {
    return (
        <div className={`${style.integration} ${style.narrow}`}>
            <Icon icon={MaropostIcon} />
            <h1>Integrate Maropost</h1>
            <p>
                Follow the instructions in the{" "}
                <a
                    className={style.link}
                    rel="noopener noreferrer"
                    href="https://hawke.ai/support/resources/maropost-integration/"
                    target="_blank"
                >
                    Resource Section
                </a>{" "}
                to connect your Maropost account.
            </p>
            <MaropostIntegrate integrationId={integrationId} />

            <SubmitButtons
                invalid={invalid || submitting}
                disabled={disabled || isNoCampaigns}
                onClick={async () => {
                    mixpanel.track("Save Integration", {
                        Platform: "maropost",
                    });
                    sendToSlack(`Save Integration: maropost`);
                    handleSubmit();
                }}
                onCancel={() => {
                    mixpanel.track("Save Integration", {
                        Platform: "maropost",
                        Canceled: true,
                    });
                    reset();
                    onCancel();
                }}
            />
        </div>
    );
};
