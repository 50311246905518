import React from "react";

export default (props) => (
    <svg width="8px" height="12px" viewBox="0 0 8 12" version="1.1" {...props}>
        <g id="🛡--Snapshot" stroke="none" strokeWidth="1" fillRule="evenodd">
            <g
                id="1.0-Snapshot"
                transform="translate(-573.000000, -370.000000)"
                fillRule="nonzero"
            >
                <g id="Framework">
                    <g
                        id="Content"
                        transform="translate(272.000000, 141.000000)"
                    >
                        <g
                            id="Metrics-Copy-2"
                            transform="translate(0.000000, 131.000000)"
                        >
                            <g
                                id="Impressions"
                                transform="translate(0.000000, 68.000000)"
                            >
                                <g
                                    id="⚡️-copy-2"
                                    transform="translate(290.000000, 23.000000)"
                                >
                                    <path
                                        d="M18.9291221,11.8488468 C18.833022,11.6829003 18.653928,11.5804248 18.4599275,11.5804248 L15.9001508,11.5804248 L15.9001508,7.53189896 C15.9001508,7.292234 15.7373675,7.08217701 15.5026802,7.01888333 C15.2679569,6.95569603 15.0192632,7.05476748 14.8952945,7.26099494 L11.0753066,13.6167437 C10.9765061,13.7811654 10.9748138,13.9851235 11.0708779,14.1511054 C11.166978,14.3170519 11.346072,14.4195274 11.5400725,14.4195274 L14.0998492,14.4195274 L14.0998492,18.4680532 C14.0998492,18.7077182 14.2625965,18.9177752 14.4973198,18.9810689 C14.7325832,19.0444689 14.9809888,18.9448301 15.1047055,18.7389927 L18.9246934,12.3832085 C19.0234939,12.2187868 19.0251862,12.0148287 18.9291221,11.8488468 Z"
                                        id="Path-Copy"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
