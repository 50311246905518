import React from "react";
import AnalyticsIcon from "assets/images/Integrations/JSX/Logo-Analytics-Small";
import AnalyticsIntegrate from "features/Integrations/Dependant/Analytics/container";
import Icon from "features/Onboarding/IntegrateStep/Icon";
import IntegrationWrapper from "features/Integrations/Independant/IntegrationWrapper/container";
import style from "../style.module.scss";
import moment from "moment";

export default ({
    onCancel,
    changeConnection,
    connections,
    updateIntegration,
    accountId,
    integrationId,
    userId,
    hasIntegrations,
}) => {
    return (
        <div className={`${style.integration} ${style.narrow}`}>
            <Icon icon={AnalyticsIcon} />
            <h1>Connect Universal Analytics</h1>
            <p style={{ maxWidth: "27.5rem" }}>
                While not required, Universal Analytics is a <strong>recommended integration</strong> that is needed for
                some features to function.
            </p>

            <IntegrationWrapper
                onCancel={onCancel}
                onSave={async (
                    {
                        analyticsAccounts = {
                            value: null,
                        },
                        analyticsIntegrationName = "",
                        analyticsWebProperties = {
                            value: null,
                        },
                        analyticsProfiles = {
                            value: null,
                        },
                    },
                    _,
                    { connection: connectionId },
                ) => {
                    await updateIntegration({
                        integrations: {
                            analytics: {
                                value: {
                                    [analyticsProfiles.value]: {
                                        integrationName: analyticsIntegrationName || analyticsProfiles.label,
                                        integrationId: analyticsProfiles.value,
                                        analyticsAccounts,
                                        analyticsWebProperties,
                                        analyticsProfiles,
                                        connectionId,
                                        currency: analyticsProfiles.currency,
                                        userId,
                                        created_at: moment().toISOString(),
                                    },
                                },
                            },
                        },
                        accountId,
                    });

                    onCancel();
                }}
                platform="analytics"
                changeConnection={changeConnection}
                connection={connections?.analytics}
                integration={AnalyticsIntegrate}
                integrationId={integrationId}
                disabled={!connections?.analytics || !hasIntegrations}
            />
        </div>
    );
};
