import React from "react";
import AdwordsIcon from "assets/images/Integrations/JSX/Logo-Ads";
import AdwordsIntegrate from "features/Integrations/Dependant/Adwords/container";
import Icon from "features/Onboarding/IntegrateStep/Icon";
import IntegrationWrapper from "features/Integrations/Independant/IntegrationWrapper/container";
import style from "../style.module.scss";
import moment from "moment";

export default ({
    onCancel,
    changeConnection,
    connections,
    updateIntegration,
    accountId,
    integrationId,
    userId,
    hasIntegrations,
}) => {
    return (
        <div className={`${style.integration} ${style.narrow}`}>
            <Icon icon={AdwordsIcon} />
            <h1>Connect Google Ads</h1>
            <p>Connect your Google Ads account (search, display, video and shopping).</p>
            <IntegrationWrapper
                onCancel={onCancel}
                onSave={async (
                    {
                        "adwords-campaigns": selectedCampaigns,
                        adwords: integration = {
                            value: null,
                        },
                        adwordsIntegrationName = "",
                        ...rest
                    },
                    _,
                    { platform, connection: connectionId },
                ) => {
                    const allCurrentAndFuture = selectedCampaigns["_all"];
                    const campaignsSelected = allCurrentAndFuture ? {} : selectedCampaigns;
                    const campaignsToSave = Object.keys(campaignsSelected)
                        .filter((key) => {
                            return campaignsSelected[key];
                        })
                        .reduce((obj, key) => {
                            const [name, value] = key.split("____");

                            return {
                                ...obj,
                                [value]: {
                                    id: value,
                                    name: name,
                                },
                            };
                        }, {});

                    await updateIntegration({
                        integrations: {
                            adwords: {
                                value: {
                                    [integration.value]: {
                                        integrationName: adwordsIntegrationName || integration.label,
                                        integrationId: integration.value,
                                        campaigns: campaignsToSave,
                                        regex: rest[`regex${platform}`],
                                        connectionId,
                                        currency: integration.currency,
                                        userId,
                                        created_at: moment().toISOString(),
                                    },
                                },
                            },
                        },
                        accountId,
                    });

                    onCancel();
                }}
                platform="adwords"
                changeConnection={changeConnection}
                connection={connections?.adwords}
                integrationId={integrationId}
                integration={AdwordsIntegrate}
                disabled={!connections?.adwords || !hasIntegrations}
            />
        </div>
    );
};
