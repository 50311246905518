import { connect } from "react-redux";
import IntegrateStep from "./index";
import { operations as connectOperations } from "features/Integrations/_ducks";
import { change, getFormValues } from "redux-form";
import { actions, operations } from "features/Onboarding/ducks";
import { makeSelectGetBigQueryIntegrationIdList } from "_redux/sites/selectors";
import {
    makeSelectUserId,
    makeSelectUserConnections,
} from "_redux/users/selectors";
import {
    makeSelectOnboardingIntegrations,
    selectOnboardingBigQueryTables,
    selectOnboardingBigQueryTablesLoading,
    selectOnboardingBigQueryFields,
    selectOnboardingBigQueryFieldsLoading,
} from "features/Onboarding/ducks/selectors";
import { makeSelectIntegrationOpts } from "features/Integrations/_ducks/selectors";

const { exchangeBigQueryCode } = connectOperations;
const { onboardingPlatformConnection } = actions;
const {
    getBigQueryDatasets,
    getBigQueryTables,
    getBigQueryTableFields,
} = operations;

const mapStateToProps = (state, { formName, connection, integrationId }) => {
    const { form } = state;
    const alreadyIntegrated = makeSelectGetBigQueryIntegrationIdList()(state);
    const userId = makeSelectUserId()(state);
    const { bigQuery } = makeSelectUserConnections()(state);
    const allIntegrations = makeSelectOnboardingIntegrations()(state);
    const { bigQuery: bigQueryOptions } = makeSelectIntegrationOpts()(state);
    const bigQueryTables = selectOnboardingBigQueryTables(state);
    const bigQueryTablesLoading = selectOnboardingBigQueryTablesLoading(state);
    const bigQueryFields = selectOnboardingBigQueryFields(state);
    const bigQueryFieldsLoading = selectOnboardingBigQueryFieldsLoading(state);

    const {
        [`${connection}_bigQuery`]: bigQueryIntegrations = false,
    } = allIntegrations;
    const formValues = getFormValues(formName)({ form });

    return {
        formName,
        userId,
        list: bigQuery,
        connectionId: connection,
        options: bigQueryOptions,
        selectedIntegrations: formValues,
        integrations: bigQueryIntegrations,
        integrationId,
        alreadyIntegrated: formName === "onboarding" ? [] : alreadyIntegrated,
        bigQueryTables,
        bigQueryTablesLoading,
        bigQueryFields,
        bigQueryFieldsLoading,
    };
};

const mapDispatchToProps = (dispatch) => ({
    exchangeBigQueryCode: (code) => {
        return dispatch(exchangeBigQueryCode(code)).then(({ id }) => {
            dispatch(
                onboardingPlatformConnection({
                    platform: "bigQuery",
                    connectionId: id,
                })
            );
        });
    },
    getBigQueryDatasets: ({ userId, connectionId }) =>
        dispatch(getBigQueryDatasets({ userId, connectionId })),
    getBigQueryTables: ({ userId, connectionId, projectId, datasetId }) =>
        dispatch(
            getBigQueryTables({ userId, connectionId, projectId, datasetId })
        ),
    getBigQueryTableFields: ({
        userId,
        connectionId,
        projectId,
        datasetId,
        tableId,
    }) =>
        dispatch(
            getBigQueryTableFields({
                userId,
                connectionId,
                projectId,
                datasetId,
                tableId,
            })
        ),
    change: (formName, ...args) => dispatch(change(formName, ...args)),
});

const mergeProps = (
    { formName, ...stateProps },
    { change, ...dispatchProps },
    ownProps
) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    change: (...args) => {
        return change(formName, ...args);
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(IntegrateStep);
