import React from "react";
import style from "../../style.module.scss";
import Button from "components/Button";

export default ({ disabled, invalid, onClick, onCancel }) => (
    <>
        <div className={style.buttons}>
            <Button disabled={disabled || invalid} onClick={onClick}>
                Save
            </Button>
            <Button secondary onClick={onCancel}>
                {disabled ? "Close" : "Cancel"}
            </Button>
        </div>
    </>
);
