import React from "react";
import withErrorBoundary from "../../../../components/ErrorBoundary";
import { ORGANIC_FACEBOOK_AUTHENTICATION_SCOPE } from "lib/campaignUtils";

class OrganicFacebookConnect extends React.Component {
    state = {
        loading: false,
        platform: "organicFacebook",
        label: "Organic Facebook",
        error: false,
    };

    organicFacebookCallback = (response) => {
        if (response.authResponse) {
            this.props.saveOrganicFacebookAccessToken(response.authResponse.accessToken);
            return this.setState({ loading: false });
        } else {
            return this.setState({ loading: false, error: true });
        }
    };

    componentDidMount() {
        window.FB.init({
            appId: process.env.REACT_APP_FACEBOOK_APP_ID,
            cookie: true,
            xfbml: true,
            version: "v3.2",
        });
    }

    onAdd = async (event) => {
        await this.setState({ loading: true });
        await window.FB.login(this.organicFacebookCallback, {
            scope: ORGANIC_FACEBOOK_AUTHENTICATION_SCOPE,
        });
        return this.setState({ loading: false });
    };

    render() {
        const { loading, platform, label, error } = this.state;
        const { component: Component } = this.props;
        return <Component platform={platform} loading={loading} error={error} onAdd={this.onAdd} label={label} />;
    }
}

export default withErrorBoundary()(OrganicFacebookConnect);
